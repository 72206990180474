import * as React from 'react'
import style from './expander.css'

interface ExpanderProps {
  maxHeight: number
  expanded: boolean
  children: React.ReactNode
  transitionDuration: number
}

const calculateExpandedStyle = (maxHeight: number, transitionDuration: number) => {
  const transition = `max-height ${transitionDuration}s ease-in, visibility ${transitionDuration}s, opacity ${transitionDuration}s`

  return {
    maxHeight,
    transition,
    visibility: 'visible',
    opacity: 1,
  } as React.CSSProperties
}

const calculateCollapsedStyle = (transitionDuration: number) => {
  const transition = `max-height ${transitionDuration}s ease-out, visibility ${transitionDuration}s, opacity ${transitionDuration}s`

  return {
    transition,
    maxHeight: 0,
    visibility: 'hidden',
    opacity: 0,
  } as React.CSSProperties
}

export const Expander = (props: ExpanderProps) => {
  const { maxHeight, expanded, children, transitionDuration } = props
  const calculatedStyle = expanded
    ? calculateExpandedStyle(maxHeight, transitionDuration)
    : calculateCollapsedStyle(transitionDuration)

  return (
    <div className={style.root} style={calculatedStyle}>
      {children}
    </div>
  )
}
