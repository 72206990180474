import moment from 'moment'

export const getNumOfDaysInCurrentMonth = () => {
  const today = new Date()
  const numOfDays = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()

  return numOfDays
}

export const getMonth = () => {
  return moment().utc().format('MMM').toUpperCase()
}
