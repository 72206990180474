declare global {
  interface Window {
    dataLayer: any
    gtag: any
  }
}

function gtag(..._args: any) {
  window.dataLayer.push(arguments)
}

export const initGoogleAnalytics = () => {
  if (process.env.PORTAL_ENV !== 'local') {
    const GA_TRACKING_ID = process.env.GA_TRACKING_ID
    window.dataLayer = window.dataLayer || []

    gtag('js', new Date())
    gtag('config', GA_TRACKING_ID)

    const script = window.document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`
    const elem = window.document.getElementsByTagName('script')[0]
    elem.parentNode.insertBefore(script, elem)
  }
}

export const initGtag = (path: string) => {
  if (process.env.PORTAL_ENV !== 'local') {
    gtag('js', new Date())
    gtag('config', process.env.GA_TRACKING_ID, {
      page_path: path,
    })
  }
}
