export const ACCOUNT_ROUTE = '/account'
export const ADD_PASSWORD_ROUTE = '/add-password'
export const ADD_PASSWORD_SUCCESS_ROUTE = '/set-password-success'
export const APPLICATIONS_ROUTE = '/applications'
export const DASHBOARD_ROOT_ROUTE = '/'
export const DASHBOARD_USERS_ROUTE = '/users'
export const EXCEPTION_429_ROUTE = '/exception/429'
export const EXCEPTION_500_ROUTE = '/exception/500'
export const HOME_ROUTE = '/home'
export const IMPLICIT_CALLBACK_ROUTE = '/implicit/callback'
export const KNOWLEDGE_CENTER_ROUTE = '/knowledge-center'
export const LOGIN_ROUTE = '/login'
export const MISSING_ACCESS_ROUTE = '/missing-access'
export const MISSING_COMPANY_ROUTE = '/missing-company'
export const NDA_ROUTE = '/nda'
export const PASSWORD_RESET_COMPLETE_ROUTE = '/password-reset-complete'
export const PASSWORD_RESET_REQUEST_ROUTE = '/password-reset-request'
export const SELECT_COMPANY_ROUTE = '/select-company'
export const MANAGE_PARTNERS_ROUTE = '/manage-partners'
export const MANAGE_INVENTORY_ROUTE = '/manage-inventory'
export const SELECT_PARTNER_ROUTE = '/select-partner'
export const SIGN_PARTNERSHIP_CONTRACT_ROUTE = '/sign-partnership-contract'
export const TERMS_AND_CONDITIONS_ROUTE = '/terms-and-conditions'
export const PORTAL_PRIVACY_POLICY_ROUTE = '/portal-privacy-policy'
export const PORTAL_TERMS_ROUTE = '/portal-terms'
export const UNLOCK_COMPLETE_ROUTE = '/unlock-complete'
export const IDP_SETTINGS_ROUTE = '/idp-settings'
