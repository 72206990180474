import * as React from 'react'
import style from './footer.css'
import { PartialLogo } from 'components/icons'

const PortalNameVersionEnv = () => {
  const portalEnv = process.env.PORTAL_ENV
  const version = process.env.PORTAL_VERSION
  return (
    <div className={style.versionWrapper}>
      <span className={style.portalName}>Plume Partner Portal </span>
      <span>v{version} </span>
      <span>{portalEnv.charAt(0).toUpperCase() + portalEnv.slice(1)}</span>
    </div>
  )
}

export const Footer = () => {
  return (
    <footer className={style.footer}>
      <span className={style.partialLogo}>
        <PartialLogo />
      </span>
      <PortalNameVersionEnv />
      <span className={style.text}>© {new Date().getFullYear()} Plume Design, Inc.</span>
    </footer>
  )
}
