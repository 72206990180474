import mixpanel from 'mixpanel-browser'

const mixpanelToken = process.env.MIXPANEL_TOKEN

if (mixpanelToken) {
  mixpanel.init(mixpanelToken)
}

const isProduction = process.env.NODE_ENV === 'production'

export const mixpanelActions = {
  identify: (id: string) => {
    if (isProduction) mixpanel.identify(id)
  },
  alias: (id: string) => {
    if (isProduction) mixpanel.alias(id)
  },
  track: (name: string, props: any) => {
    if (isProduction) mixpanel.track(name, props)
  },
  people: {
    set: (props: any) => {
      if (isProduction) mixpanel.people.set(props)
    },
  },
}
