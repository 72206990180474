import { action, observable } from 'mobx'
import { FieldState, FormState } from 'formstate'
import { AppStore } from 'stores/app-store'

export class ApplicationsStore {
  @observable
  public isLoading?: boolean

  @observable
  public serverError = ''

  @observable
  isLoadingModal = false

  @observable
  public accountId?: number

  constructor(private readonly appStore: AppStore) {}

  public hideTableau = new FieldState<boolean>(false).validators()

  private formState = new FormState({}).validators()

  @action
  public clearFormState() {
    this.hideTableau.reset()
  }

  @action
  public async validateAndSubmit(redirectUrl: string) {
    await this.formState.validate()

    if (!this.formState.hasError) {
      this.clearServerError()
      await this.submit(redirectUrl)
    }
  }

  @action
  private async submit(redirectUrl: string) {
    this.isLoadingModal = true

    try {
      await this.appStore.userApi.hideTableauWelcomeModal(this.appStore.authStore.currentUser.id)

      this.handleSuccess(redirectUrl)
    } catch (e) {
      const message = typeof e === 'string' ? e : e.message
      this.handleServerError(message)
    }
  }

  @action
  public async handleSuccess(redirectUrl: string) {
    this.setHideTableauWelcomeModal()
    this.removeModal()
    window.open(redirectUrl, '_blank')
  }

  @action.bound
  public setAccountId(value: number) {
    this.accountId = value
  }

  @action
  private setHideTableauWelcomeModal() {
    const { currentUser } = this.appStore.authStore

    currentUser.hideTableauWelcomeModal = true
    this.hideTableau.value = true
  }

  @action.bound
  private handleServerError(error: string) {
    this.serverError = error
    this.isLoadingModal = false
    this.isLoading = false
  }

  @action
  public redirectTo(url: string) {
    this.removeModal()
    window.open(url, '_blank')
  }

  @action
  private clearServerError() {
    this.serverError = ''
  }

  @action.bound
  private async removeModal() {
    this.appStore.actionModalStore.removeModal()
    this.clearFormState()
    this.setIsLoadingModal(false)
  }

  @action
  private setIsLoadingModal = (value: boolean) => {
    this.isLoadingModal = value
  }

  @action.bound
  public setLoading(value: boolean) {
    this.isLoading = value
  }
}
