import { PortalBaseApi } from './portal-base-api'
import {
  ICompanyApi,
  ICompany,
  IAppCustomizationUpdateResponse,
  ISupportContactsPayload,
  ISecondaryCloudSettings,
  ISecondaryCloudSettingsResponse,
  IM2MPayload,
  IM2MResponse,
  IAddSSOConfigPayload,
  ICRMNotificationConfig,
  ICRMNotificationConfigPayload,
  IGetCompaniesResponse,
  Team,
  IM2MProvisionedAppsConfigResult,
  IM2MCloudPayload,
  IIdpInfo,
  IUpdateIdpPayload,
  ISetIdpTeams,
  IOriginalLogoUrls,
  INetworkStatusSuspension,
  INetworkStatusSuspensionPayload,
  IGetNodeInfoResponse,
  IPlumeCloudAPIAccmgrAccessInfo,
  IPlumeCloudAPIAccmgrAccessPayload,
  IAdditionalPartnerInfoResponse,
  ISuite,
  IStartExtendTrial,
  INodesClaimableBySharedParentResponse,
  INodesClaimableBySharedParentPayload,
  IBillingContactPayload,
  IReleaseNotesContactPayload,
  IMobileConfig,
  IPortalLanguagePayload,
  GetPartnerConfigFeatureFlagsResponse,
  GlobalAuthFeatureFlagsRequest,
  PartnerConfigFeatureFlagsResponse,
  RetrievePartnerConfigGetCallsResponse,
} from 'interfaces/api/portal/company-api'
import { TeamApplications } from 'modules/dashboard/account/team/team-constants'

export class CompanyApi extends PortalBaseApi implements ICompanyApi {
  public async getCompanyByPartnerId(id: string) {
    return await this.get<ICompany>(`/companies/${id}`)
  }

  public async getParentCompanyInfo(partnerId: string) {
    return await this.get<Partial<ICompany>>(`/companies/${partnerId}/parent-company-info`)
  }

  public async getCompanies() {
    return await this.get<IGetCompaniesResponse>('/companies')
  }

  public async getAdditionalPartnerInfo(partnerId: string) {
    return await this.get<IAdditionalPartnerInfoResponse>(
      `/companies/${partnerId}/additional-partner-info`,
    )
  }

  public async listActiveCompanies() {
    return await this.get<ICompany[]>(`/companies/list-active`)
  }

  public async getTeams(partnerId: string): Promise<Team[]> {
    return this.get<Team[]>(`/companies/${partnerId}/teams`)
  }

  public async getTeamApps(partnerId: string, teamGroupId: string, clientId: string) {
    return this.get(`/companies/${partnerId}/teams/${teamGroupId}/apps`, {
      params: { clientId },
    })
  }

  public async addTeam(
    partnerId: string,
    teamName: string,
    teamApps: TeamApplications,
    teamDescription: string,
    clientId: string,
  ) {
    return this.post(`/companies/${partnerId}/teams`, {
      teamName,
      teamApps,
      teamDescription,
      clientId,
    })
  }

  public async editTeam(
    partnerId: string,
    newTeamName: string,
    teamApps: TeamApplications,
    originalTeamName: string,
    teamGroupId: string,
    teamDescription: string,
    clientId: string,
  ) {
    return this.patch(`/companies/${partnerId}/teams/${teamGroupId}`, {
      originalTeamName,
      teamApps,
      newTeamName,
      teamDescription,
      clientId,
    })
  }

  public async deleteTeam(partnerId: string, teamGroupId: string) {
    return this.delete(`/companies/${partnerId}/teams/${teamGroupId}`)
  }

  public async getUserExportTeamApps(partnerId: string, clientId: string) {
    return this.get<void>(`/companies/${partnerId}/user-export-team-apps`, {
      params: { clientId },
    })
  }

  // public updateGeneralInfo(partnerId: string, payload: any) {
  //   return this.post<any>(`/companies/general-info/${partnerId}`, payload)
  // }

  public updateBillingContact(partnerId: string, payload: IBillingContactPayload) {
    return this.post<void>(`/companies/billing-contact/${partnerId}`, payload)
  }

  public getMobileConfig(partnerId: string) {
    return this.get<[IMobileConfig, IMobileConfig]>(`/companies/${partnerId}/mobile-config`)
  }

  public updatePortalLanguage(partnerId: string, payload: IPortalLanguagePayload) {
    return this.post<any>(`/companies/portal-language/${partnerId}`, payload)
  }

  public updateReleaseNotesContactEmail(partnerId: string, payload: IReleaseNotesContactPayload) {
    return this.post<void>(`/companies/${partnerId}/release-notes`, payload)
  }

  public updateMobileConfig(
    partnerId: string,
    payload: Record<string, string | boolean | IMobileConfig>,
  ) {
    return this.post<IAppCustomizationUpdateResponse>(
      `/companies/${partnerId}/mobile-config`,
      payload,
    )
  }

  public uploadAppLogo(partnerId: string, formData: FormData) {
    return this.post<void>(`/companies/${partnerId}/app-logo`, formData, {
      headers: {
        'Content-Type': 'x-www-form-urlencoded',
      },
    })
  }

  public uploadAccMgrAppLogo(partnerId: string, formData: FormData) {
    return this.post<void>(`/companies/${partnerId}/mc-app-logo`, formData, {
      headers: {
        'Content-Type': 'x-www-form-urlencoded',
      },
    })
  }

  public getAppLogoUrl(partnerId: string) {
    return this.get<IOriginalLogoUrls>(`/companies/${partnerId}/app-logo`)
  }

  // public async getCompanyOwnerById(ownerId: string) {
  //   return this.get<any>(`/companies/owner/${ownerId}`)
  // }

  public async forceDeleteAccount(partnerId: string) {
    return this.delete(`/companies/${partnerId}`)
  }

  public async updateSecondaryCloudSettings(partnerId: string, payload: ISecondaryCloudSettings) {
    return this.post<ISecondaryCloudSettingsResponse>(
      `/companies/${partnerId}/secondary-cloud-settings`,
      payload,
    )
  }

  public async setSupportContacts(partnerId: string, payload: ISupportContactsPayload) {
    return this.post<any>(`/companies/${partnerId}/support-contacts`, payload)
  }

  public async getParentPartnerId(partnerId: string) {
    return this.get<any>(`/companies/${partnerId}/parent-partner-id`)
  }

  public async resetM2MSecret(partnerId: string, appId: string, payload: IM2MCloudPayload) {
    return this.post<IM2MResponse>(
      `/companies/${partnerId}/machineToMachine/oauth2clients/${appId}/resetSecret`,
      payload,
    )
  }

  public async deleteM2MProvisionedApp(
    partnerId: string,
    appId: string,
    payload: IM2MCloudPayload,
  ) {
    return this.delete(`/companies/${partnerId}/machineToMachine/oauth2clients/${appId}`, {
      data: payload,
    })
  }

  public async getM2MProvisionedApps(partnerId: string) {
    return this.get<IM2MProvisionedAppsConfigResult>(
      `/companies/${partnerId}/machineToMachine/oauth2clients`,
    )
  }

  public async addM2MProvisionedApp(partnerId: string, payload: IM2MPayload) {
    return this.post<IM2MResponse>(
      `/companies/${partnerId}/machineToMachine/oauth2clients`,
      payload,
    )
  }

  public async updatePortalNickname(partnerId: string, payload: Pick<ICompany, 'portalNickname'>) {
    return this.post<ICompany>(`/companies/${partnerId}/portal-nickname`, payload)
  }

  public async getCRMNotificationConfiguration(partnerId: string) {
    return this.get<ICRMNotificationConfig>(`/companies/${partnerId}/notification-configuration`)
  }

  public async updateCRMNotificationConfiguration(
    partnerId: string,
    payload: ICRMNotificationConfigPayload,
  ) {
    return this.post<ICRMNotificationConfig>(
      `/companies/${partnerId}/notification-configuration`,
      payload,
    )
  }

  public async getSSOConfig(partnerId: string) {
    return this.get<any>(`/companies/${partnerId}/sso-config`)
  }

  public async addSSOConfig(partnerId: string, payload: Partial<IAddSSOConfigPayload>) {
    return this.post<any>(`/companies/${partnerId}/sso-config`, payload)
  }

  public async updateSSOConfig(partnerId: string, payload: Partial<IAddSSOConfigPayload>) {
    return this.put<any>(`/companies/${partnerId}/sso-config`, payload)
  }

  public async deleteSSOConfig(partnerId: string) {
    return this.delete(`/companies/${partnerId}/sso-config`)
  }

  public async getNetworkStatusSuspension(partnerId: string) {
    return this.get<INetworkStatusSuspension>(`/companies/${partnerId}/network-status-suspension`)
  }

  public async updateNetworkStatusSuspension(
    partnerId: string,
    payload: INetworkStatusSuspensionPayload,
  ) {
    return this.patch<INetworkStatusSuspension>(
      `/companies/${partnerId}/network-status-suspension`,
      payload,
    )
  }

  public async getIdpInfo(partnerId: string) {
    return this.get<IIdpInfo>(`/companies/${partnerId}/idp-info`)
  }

  public async getIdpMetadata(partnerId: string) {
    return this.get<string>(`/companies/${partnerId}/idp-metadata`)
  }

  public async createSkeletonIdp(partnerId: string, isMultiPartnerIdP?: boolean) {
    return this.post<void>(
      `/companies/${partnerId}/skeleton-idp`,
      isMultiPartnerIdP ? { isMultiPartnerIdP } : undefined,
    )
  }

  public async updateIdp(partnerId: string, payload: IUpdateIdpPayload) {
    return this.post<void>(`/companies/${partnerId}/update-idp`, payload)
  }

  public async setIdpTeams(partnerId: string, payload: ISetIdpTeams) {
    return this.post<void>(`/companies/${partnerId}/set-idp-teams`, payload)
  }

  public async activateIdp(partnerId: string) {
    return this.post<void>(`/companies/${partnerId}/activate-idp`)
  }

  public async deactivateIdp(partnerId: string) {
    return this.post<void>(`/companies/${partnerId}/deactivate-idp`)
  }

  public async checkIsIdpValid(idpId: string) {
    return this.get<boolean>(`/companies/is-idp-valid/${idpId}`)
  }

  public async getNodeInfo(partnerId: string, nodeId: string) {
    return this.get<IGetNodeInfoResponse>(`/companies/${partnerId}/node-info/${nodeId}`)
  }

  public async getPlumeCloudAPIAccmgrAccessInfo(partnerId: string) {
    return this.get<IPlumeCloudAPIAccmgrAccessInfo>(
      `/companies/${partnerId}/cloud-config-accmgr-access`,
    )
  }

  public async updatePlumeCloudAPIAccmgrAccess(
    partnerId: string,
    payload: IPlumeCloudAPIAccmgrAccessPayload,
  ) {
    return this.post<IPlumeCloudAPIAccmgrAccessInfo>(
      `/companies/${partnerId}/cloud-config-accmgr-access`,
      payload,
    )
  }

  public async listSuites() {
    return this.get<ISuite[]>(`/companies/list-suites`)
  }

  public async startOrExtendSuiteTrial(partnerId: string, payload: IStartExtendTrial) {
    return this.post<void>(`/companies/${partnerId}/start-extend-trial`, payload)
  }

  public async getNodesClaimableByLocationsSharedParentInfo(partnerId: string) {
    return this.get<INodesClaimableBySharedParentResponse>(
      `/companies/${partnerId}/nodes-claimable-locations-shared-parent`,
    )
  }

  public async updateNodesClaimableByLocationsSharedParentInfo(
    partnerId: string,
    payload: INodesClaimableBySharedParentPayload,
  ) {
    return this.post<INodesClaimableBySharedParentResponse>(
      `/companies/${partnerId}/nodes-claimable-locations-shared-parent`,
      payload,
    )
  }

  public async getSubordinatePartnersOnAllClouds(partnerId: string, hasPortal?: boolean) {
    return this.get<string[]>(`/companies/${partnerId}/subordinate-partners-on-all-clouds`, {
      params: { hasPortal },
    })
  }

  public async getPartnerConfigFeatureFlags(partnerId: string) {
    return this.get<GetPartnerConfigFeatureFlagsResponse>(
      `/companies/${partnerId}/partner-config-feature-flags`,
    )
  }

  public async updatePartnerConfigFeatureFlags(
    partnerId: string,
    payload: GlobalAuthFeatureFlagsRequest,
  ) {
    return this.patch<PartnerConfigFeatureFlagsResponse>(
      `/companies/${partnerId}/partner-config-feature-flags/global-auth`,
      payload,
    )
  }

  public async retrievePartnerConfigGetCalls(partnerId: string) {
    return this.get<RetrievePartnerConfigGetCallsResponse>(
      `/companies/${partnerId}/partner-config-get-calls`,
    )
  }
}
