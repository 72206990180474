import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from 'utils/hooks/useStores'
import { PercentageRing } from 'plume-ui'
import classNames from 'classnames'
import style from './user.css'

export const CsvPercentageRing = observer(() => {
  const appStore = useStores()

  const usersExportProgress = appStore?.usersStore?.usersExportProgress

  return (
    // percentageRingColor class and percentColor prop needed because of weird interactions of
    // Notification svg circle styling
    <div className={classNames(style.override, style.percentageRingColor)}>
      <PercentageRing
        percentColor="#6269ff"
        percentValue={usersExportProgress}
        label={`${usersExportProgress}%`}
      />
    </div>
  )
})
