import * as React from 'react'
import style from './app-customization.css'
import { commonStyle } from 'style/commonly-used-styles'
import { Close, LeftArrow, Copy } from 'components/icons/index'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { AppStore } from 'stores/app-store'
import { AccountStore, AccountStoreFields } from '../account-store'
import { FieldState } from 'formstate'
import { observer } from 'mobx-react'
import { Loader } from 'components/loader'
import { Document, Page, PDFPageProxy, pdfjs, PDFPageItem } from 'react-pdf'
import classNames from 'classnames'
import { when } from 'mobx'
import { LogoPreview } from './preview/logo-preview'
import { PodsPreview } from './preview/adaptive-wifi-preview'
import { SupportPreview } from './preview/support-preview'
import { withTranslation, WithTranslation } from 'react-i18next'
import { showAlert } from 'utils/show-alert'
import { PlumeProduct } from 'enums/plume-product.enum'
import {
  TextToggler,
  AccordionItem,
  Dropdown,
  DropdownItem,
  Button as UiButton,
  Icons,
} from 'plume-ui'
import { TogglerStyles as TextTogglerStyles } from 'plume-ui/dist/components/Toggler/TextToggler'
import { AccordionItemStyles } from 'plume-ui/dist/components/Accordion/AccordionItem'
import { Tooltip } from 'components/tooltip'
import { splitWithEmpty } from 'helpers/general-helpers'

const dropdownURLOptions = ['no', 'plumeUrl', 'customUrl'] as const
const employeeLoginPriorityOptions = ['email', 'sms', 'free']

type DropdownURLOption = (typeof dropdownURLOptions)[number]

interface AppCustomizationProps extends WithTranslation {
  appStore: AppStore
  onClose: () => Promise<void>
  accountStore: AccountStore
}

interface IAppCustomizationState {
  isUploadingLogo: boolean
  advancedOptionsShown: boolean
  dtcShowPartnerBuyPodURLInput: boolean
}

const logoDimensions = {
  [PlumeProduct.HomePass]: { height: 40, width: 284 },
  [PlumeProduct.WorkPass]: { height: 32, width: 472 },
}

const getPDFPage = (file: File) =>
  new Promise<PDFPageItem>((resolve, reject) => {
    const fr = new FileReader()
    fr.onload = () => {
      const fileArrayBuffer = new Uint8Array(fr.result as ArrayBuffer)

      pdfjs.getDocument(fileArrayBuffer).promise.then(pdf => {
        pdf.getPage(1).then(page => resolve(page))
      })
    }
    fr.onerror = e => reject(e)
    fr.readAsArrayBuffer(file)
  })

@observer
export class AppCustomizationComponent extends React.Component<
  AppCustomizationProps,
  IAppCustomizationState
> {
  private store: AccountStore

  private CALL_US_NUM = 'callUsNumber'
  private CALL_US_DISPLAY_NUM = 'callUsDisplayNumber'

  constructor(props: AppCustomizationProps) {
    super(props)
    this.store = props.accountStore

    this.state = {
      isUploadingLogo: false,
      advancedOptionsShown: false,
      dtcShowPartnerBuyPodURLInput: false,
    }
  }

  componentDidMount() {
    when(
      () =>
        !!this.props.appStore.authStore.currentUser &&
        !!this.props.appStore.authStore.currentUser.company,
      () => {
        this.store.initAppCustomization().then(() => {
          this.refreshDtcShowPartnerBuyPodURLInput()
        })
      },
    )
  }

  dispatchEmployeeLoginPriority = (value: { action: 'toggle' | 'increase'; option: string }) => {
    const cpdloArray = splitWithEmpty(this.store.captivePortalDefaultLoginOptions.value, ',')
    const index = cpdloArray.findIndex(i => i === value.option)
    if (value.action === 'toggle' && index === -1) {
      cpdloArray.push(value.option)
      this.handleTextFieldChange('captivePortalDefaultLoginOptions', cpdloArray.join(','))
    } else if (value.action === 'increase') {
      cpdloArray.splice(index, 1)
      cpdloArray.splice(index - 1, 0, value.option)
      this.handleTextFieldChange('captivePortalDefaultLoginOptions', cpdloArray.join(','))
    } else if (value.action === 'toggle' && index !== -1) {
      cpdloArray.splice(index, 1)
      this.handleTextFieldChange('captivePortalDefaultLoginOptions', cpdloArray.join(','))
    }
  }

  getEmployeeLoginDropdownOptions = () => {
    const cpdloArray = splitWithEmpty(this.store.captivePortalDefaultLoginOptions.value, ',')
    const dropdownOptions = cpdloArray.map(option => {
      return {
        option,
        selected: cpdloArray.includes(option),
      }
    })
    for (const option of employeeLoginPriorityOptions) {
      if (!cpdloArray.includes(option)) {
        dropdownOptions.push({ option, selected: false })
      }
    }
    return dropdownOptions
  }

  private refreshDtcShowPartnerBuyPodURLInput() {
    const isPartnerBuyPodUrlOptional =
      this.props.appStore.authStore.currentUser.company.partnerOfferedType === 'Turnkey' ||
      this.props.appStore.authStore.currentUser.company.isDTCCompany
    this.setState({
      dtcShowPartnerBuyPodURLInput:
        isPartnerBuyPodUrlOptional &&
        this.store.buyPodEnabled.value &&
        !!this.store.urlPartnerBuyPod.value,
    })
  }

  private switchPlumeProduct(product: PlumeProduct) {
    this.store.switchProduct(product)
    this.refreshDtcShowPartnerBuyPodURLInput()
    this.store.setUrlPartnerBuyPodValidators()
  }

  private handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (this.state.isUploadingLogo) {
      await this.store.submitAccMgrLogoUpload()
    } else {
      await this.store.validateAndSubmitAppCustomization()
    }

    if (this.store.serverError) {
      return showAlert({
        title: 'errors.errorUpdatingAppCustomization',
        message: (
          <span className={style.showLineBreaks}>
            {`${this.store.serverError}\n\n${this.props.t('errors.pleaseReload')}`}
          </span>
        ),
        buttonText: 'btn.close',
        onAcknowledge: this.props.onClose,
      })
    }
  }

  onSvgChangeHandler = async (event: React.FormEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files[0]
    const { t } = this.props
    const selectedProduct = this.store.selectedProduct || PlumeProduct.HomePass
    if (file && file.type === 'image/svg+xml') {
      let width
      let height
      let isValid = false
      const img = new Image()
      await this.store.setsvgBase64DataUrl(file)
      img.src = this.store.svgBase64DataUrl

      img.onload = () => {
        width = img.naturalWidth
        height = img.naturalHeight

        isValid =
          width &&
          width <= logoDimensions[selectedProduct].width &&
          height <= logoDimensions[selectedProduct].height

        if (!isValid) {
          this.store.setSvgFileValidationError(t('validators.svgInvalidDimensions'))
        } else {
          this.store.setSvgFile(file)
          this.store.setIsSVGDeleted(false)
        }
      }
    } else {
      this.store.setSvgFileValidationError(t('validators.onlySvgAllowed'))
    }
    if (!this.store.isMobileConfigEdited) {
      this.store.setIsMobileConfigEdited(true)
    }
  }

  private validatePdfDimensions = (data: PDFPageItem) => {
    const selectedProduct = this.store.selectedProduct || PlumeProduct.HomePass
    const viewport = data.getViewport({ scale: 1 })
    const width = viewport.width
    const height = viewport.height
    const isValid =
      width &&
      width <= logoDimensions[selectedProduct].width &&
      height <= logoDimensions[selectedProduct].height
    return isValid
  }

  onPdfChangeHandler = async (event: React.FormEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files[0]
    const { t } = this.props
    if (file && file.type === 'application/pdf') {
      const pdfPage = await getPDFPage(file)
      const isValid = this.validatePdfDimensions(pdfPage)
      if (isValid) this.store.setPdfFile(file)
      else this.store.setPdfFileValidationError(t('validators.invalidDimensions'))
    } else {
      this.store.setPdfFileValidationError(t('validators.onlyPdfAllowed'))
    }
    if (!this.store.isMobileConfigEdited) {
      this.store.setIsMobileConfigEdited(true)
    }
  }

  onDocumentLoadSuccess = (data: PDFPageProxy) => {
    const { t } = this.props
    const selectedProduct = this.store.selectedProduct || PlumeProduct.HomePass
    const width = data.originalWidth
    const height = data.originalHeight
    const isValid =
      width &&
      width <= logoDimensions[selectedProduct].width &&
      height <= logoDimensions[selectedProduct].height
    if (isValid) {
      this.store.setIsPdfFileValid(true)
      this.store.setIsPDFDeleted(false)
    } else {
      this.store.setIsPdfFileValid(false)
      this.store.setPdfFileValidationError(t('validators.invalidDimensions'))
    }
  }

  handleRemovePdf = () => {
    this.store.removePdf()
    this.store.setIsPDFDeleted(true)
    this.store.setIsMobileConfigEdited(true)
  }
  handleRemoveSvg = () => {
    this.store.removeSvg()
    this.store.setIsSVGDeleted(true)
    this.store.setIsMobileConfigEdited(true)
  }

  handleSetStep = (newStep: number) => {
    this.store.setCurrentStep(newStep)
  }

  handleOSPreviewChange = (os: string) => {
    this.store.setOsPreview(os)
  }

  handleLogoPreview = (os: string) => {
    this.setState({
      isUploadingLogo: true,
    })
    this.handleSetStep(1)
    this.handleOSPreviewChange(os)
  }

  handleYesNoFieldChange = (field: AccountStoreFields<FieldState<boolean>>) => {
    this.store.toggleFieldChanged(field)
    this.store.modifyProductCustomization(field, this.store[field].value)
    if (!this.store.isMobileConfigEdited) {
      this.store.setIsMobileConfigEdited(true)
    }
    this.store.validateMobileConfigFormState()
  }

  handleTextFieldChange = (field: AccountStoreFields<FieldState<string>>, value: string) => {
    if (
      (field === this.CALL_US_NUM || field === this.CALL_US_DISPLAY_NUM) &&
      value.length > this.store.APP_CUSTOMIZATION_PHONE_NUMBER_MAX
    ) {
      return
    }
    this.store[field].onChange(value)
    this.store.modifyProductCustomization(field, value)
    if (!this.store.isMobileConfigEdited) {
      this.store.setIsMobileConfigEdited(true)
    }
    this.store.validateMobileConfigFormState()
  }

  handleManageAccountDropdownChange = (value: DropdownURLOption) => {
    if (value === 'no') {
      this.store.manageAccountEnabled.onChange(false)
      this.store.modifyProductCustomization('manageAccountEnabled', false)
      this.store.usePartnerAccountUrl.onChange(false)
      this.store.modifyProductCustomization('usePartnerAccountUrl', false)
      this.store.getPartnerAccountUrl.onChange('')
      this.store.modifyProductCustomization('getPartnerAccountUrl', '')
    } else if (value === 'customUrl') {
      this.store.manageAccountEnabled.onChange(true)
      this.store.modifyProductCustomization('manageAccountEnabled', true)
      this.store.usePartnerAccountUrl.onChange(true)
      this.store.modifyProductCustomization('usePartnerAccountUrl', true)
    } else if (value === 'plumeUrl') {
      this.store.manageAccountEnabled.onChange(true)
      this.store.modifyProductCustomization('manageAccountEnabled', true)
      this.store.usePartnerAccountUrl.onChange(false)
      this.store.modifyProductCustomization('usePartnerAccountUrl', false)
      this.store.getPartnerAccountUrl.onChange('')
      this.store.modifyProductCustomization('getPartnerAccountUrl', '')
    }
    if (!this.store.isMobileConfigEdited) {
      this.store.setIsMobileConfigEdited(true)
    }
    this.store.validateMobileConfigFormState()
  }

  handlePurchasePodsDropdownChange = (value: DropdownURLOption) => {
    if (value === 'no') {
      this.store.buyPodEnabled.onChange(false)
      this.store.modifyProductCustomization('buyPodEnabled', false)
      this.store.urlPartnerBuyPod.reset('')
      this.store.modifyProductCustomization('urlPartnerBuyPod', '')
      this.setState({ dtcShowPartnerBuyPodURLInput: false })
      this.store.setUrlPartnerBuyPodValidators(false)
    } else if (value === 'customUrl') {
      this.store.buyPodEnabled.onChange(true)
      this.store.modifyProductCustomization('buyPodEnabled', true)
      this.store.urlPartnerBuyPod.onChange('')
      this.store.modifyProductCustomization('urlPartnerBuyPod', '')
      this.setState({ dtcShowPartnerBuyPodURLInput: true })
      this.store.setUrlPartnerBuyPodValidators(true)
    } else if (value === 'plumeUrl') {
      this.store.buyPodEnabled.onChange(true)
      this.store.modifyProductCustomization('buyPodEnabled', true)
      this.store.urlPartnerBuyPod.reset('')
      this.store.modifyProductCustomization('urlPartnerBuyPod', '')
      this.setState({ dtcShowPartnerBuyPodURLInput: false })
      this.store.setUrlPartnerBuyPodValidators(false)
    }
    if (!this.store.isMobileConfigEdited) {
      this.store.setIsMobileConfigEdited(true)
    }
    this.store.validateMobileConfigFormState()
  }

  handlePreviewAppCustomization = () => {
    const { isEmployee } = this.props.appStore.authStore.currentUser
    this.setState({
      isUploadingLogo: false,
    })
    if (isEmployee) {
      this.handleSetStep(this.store.selectedProduct === PlumeProduct.HomePass ? 2 : 3)
    } else {
      this.handleSetStep(1)
    }
  }

  handleMCLogoUpload = () => {
    this.setState({
      isUploadingLogo: true,
    })
    this.handleSetStep(1)
  }

  render() {
    const { onClose } = this.props
    const {
      store: {
        isLoadingModal,
        urlPrivacyPolicy,
        urlTemsOfUse,
        faqEnabled,
        urlFaq,
        buyPodEnabled,
        urlPartnerBuyPod,
        addPodEnabled,
        removePodEnabled,
        contactUsEnabled,
        contactUsEmail,
        callUsEnabled,
        callUsNumber,
        callUsDisplayNumber,
        multilocationEnabled,
        removeGatewayEnabled,
        manageYourDataEnabled,
        manageYourDataUrl,
        autorunSpeedtestToggleEnabled,
        manageAccountEnabled,
        getPartnerAccountUrl,
        usePartnerAccountUrl,
        chatSupportEnabled,
        chatSupportUrl,
        hideIspNameEnabled,
        serverError,
        svgFile,
        pdfFile,
        svgFileError,
        pdfFileError,
        isPdfFileValid,
        svgUrl,
        pdfUrl,
        isSvgEdited,
        isSetupCompleted,
        currentStep,
        isImageUploadRequired,
        mobileConfigFormState,
        selectedProduct,
        copyCustomization,
        svgBase64DataUrl,
      },
      props: { t },
    } = this

    const renderPdf = (pdfFile && isPdfFileValid) || pdfUrl
    const renderSvgImage = !svgFile && svgUrl
    const renderSvgInput = (!isSvgEdited && !svgUrl) || (!svgFile && !svgUrl)

    const computedClassNamePdf =
      (renderPdf && classNames(style.displayBlock, style.filePreview)) || style.displayNone

    const { company, isEmployee } = this.props.appStore.authStore.currentUser

    const appCustomizationFieldsNotValidated = mobileConfigFormState.hasError

    const hideScrollbar = isLoadingModal || !!serverError

    const companyHasBothProducts =
      company.profiles.includes('HomePass') && company.profiles.includes('WorkPass')

    const isPartnerBuyPodUrlOptional =
      company.partnerOfferedType === 'Turnkey' || company.isDTCCompany

    const isHomePass = selectedProduct === PlumeProduct.HomePass
    const isWorkPass = selectedProduct === PlumeProduct.WorkPass

    const isMCLogoUploadDisabled =
      isImageUploadRequired || !svgFile || !pdfFile || !!svgFileError || !!pdfFileError

    const captivePortalDefaultLoginOptionsArray = splitWithEmpty(
      this.store.captivePortalDefaultLoginOptions.value,
      ',',
    )
    const oc = style.override
    const textTogglerClasses = (current: TextTogglerStyles) => ({
      ...current,
      root: classNames(current.root, oc, style.toggleRoot),
      toggleContainer: classNames(current.toggleContainer, oc, style.toggleAdjustment),
      togglerTitle: style.textTogglerTitle,
    })

    const getManageAccountLabel = () => {
      if (!manageAccountEnabled.value) {
        return t('common.no')
      } else if (manageAccountEnabled.value && usePartnerAccountUrl.value) {
        return t('appCustomization.customUrl')
      } else if (manageAccountEnabled.value && !usePartnerAccountUrl.value) {
        return t('appCustomization.plumeUrl')
      }
    }

    const getPartnerBuyPodURLLabelKey: () => DropdownURLOption = () => {
      if (!buyPodEnabled.value) {
        return 'no'
      } else if (
        buyPodEnabled.value &&
        (urlPartnerBuyPod.value || this.state.dtcShowPartnerBuyPodURLInput)
      ) {
        return 'customUrl'
      } else if (
        buyPodEnabled.value &&
        !urlPartnerBuyPod.value &&
        !this.state.dtcShowPartnerBuyPodURLInput
      ) {
        return 'plumeUrl'
      }
    }

    return (
      <div className={classNames(hideScrollbar && style.noScrollbar, style.root)}>
        <div className={style.tabsContainer}>
          {companyHasBothProducts && (
            <div
              className={classNames(style.tab, isHomePass && style.tabSelected)}
              onClick={() => this.switchPlumeProduct(PlumeProduct.HomePass)}
            >
              {t('common.HomePass')}
            </div>
          )}
          {companyHasBothProducts && (
            <div
              className={classNames(style.tab, !isHomePass && style.tabSelected)}
              onClick={() => this.switchPlumeProduct(PlumeProduct.WorkPass)}
            >
              {t('common.WorkPass')}
            </div>
          )}
        </div>
        <div className={style.subTitle}>{`${selectedProduct} ${t(
          'appCustomization.formTitle',
        )}`}</div>
        {companyHasBothProducts && (
          <div className={style.copyContainer}>
            <Button
              className={style.copyButton}
              type="button"
              disabled={false}
              onClick={() => copyCustomization()}
            >
              <Copy className={style.copyIcon} />
              <span>{`${t('appCustomization.copyCustomizationFrom')} ${
                isHomePass ? t(`common.WorkPass`) : t(`common.HomePass`)
              }`}</span>
            </Button>
          </div>
        )}
        <div className={style.closeButton}>
          <Close onClick={onClose} />
        </div>
        {currentStep !== 0 && (
          <div className={style.backButton}>
            <LeftArrow
              width={'18'}
              height={'18'}
              strokeWidth={'1'}
              onClick={() => this.handleSetStep(0)}
            />
          </div>
        )}

        {!isSetupCompleted && (
          <div className={style.setupCompletedWrapper}>
            <div>
              <span className={style.setupNotification}>1</span>
              {t('appCustomization.formMsgInitialSubmit1')}
            </div>
            <div>{t('appCustomization.formMsgInitialSubmit2')}</div>
          </div>
        )}

        <form onSubmit={this.handleFormSubmit}>
          {currentStep === 0 && (
            <div>
              <div className={style.formInputs}>
                <div className={style.formInputFull}>
                  <Input
                    className={style.input}
                    autoFocus
                    label={`${t('appCustomization.privacyPolicyUrl')}*`}
                    hasBeenValidated={urlPrivacyPolicy.hasBeenValidated}
                    value={urlPrivacyPolicy.value}
                    error={urlPrivacyPolicy.error}
                    onChange={e => this.handleTextFieldChange('urlPrivacyPolicy', e.target.value)}
                  />
                </div>
                <div className={style.formInputFull}>
                  <Input
                    className={style.input}
                    label={`${t('appCustomization.termsOfServiceUrl')}*`}
                    hasBeenValidated={urlTemsOfUse.hasBeenValidated}
                    value={urlTemsOfUse.value}
                    error={urlTemsOfUse.error}
                    onChange={e => this.handleTextFieldChange('urlTemsOfUse', e.target.value)}
                  />
                </div>

                <TextToggler
                  onClick={() => this.handleYesNoFieldChange('faqEnabled')}
                  title={t('appCustomization.provideFaqLink')}
                  classes={textTogglerClasses}
                  selected={faqEnabled.value}
                  onToggle={() => this.handleYesNoFieldChange('faqEnabled')}
                />

                {faqEnabled.value && (
                  <div className={style.formInputFull}>
                    <Input
                      className={style.input}
                      label={`${t('appCustomization.faqUrl')}*`}
                      hasBeenValidated={urlFaq.hasBeenValidated}
                      value={urlFaq.value}
                      error={urlFaq.error}
                      onChange={e => this.handleTextFieldChange('urlFaq', e.target.value)}
                    />
                  </div>
                )}

                {!isPartnerBuyPodUrlOptional ? (
                  <TextToggler
                    onClick={() => this.handleYesNoFieldChange('buyPodEnabled')}
                    title={t('appCustomization.allowPodPurchase')}
                    classes={textTogglerClasses}
                    selected={buyPodEnabled.value}
                    onToggle={() => this.handleYesNoFieldChange('buyPodEnabled')}
                  />
                ) : (
                  <div className={style.urlDropdownContainer}>
                    <div className={style.textTogglerTitle}>
                      {t('appCustomization.allowPodPurchase')}
                    </div>
                    <Dropdown
                      openInPortal
                      classes={curr => ({
                        ...curr,
                        root: classNames(curr.root, style.override, style.dropdownRoot),
                      })}
                      closeOnItemClick={true}
                      label={t(`appCustomization.${getPartnerBuyPodURLLabelKey()}`)}
                    >
                      {dropdownURLOptions.map(option => {
                        return (
                          <DropdownItem
                            key={option}
                            onClick={() => this.handlePurchasePodsDropdownChange(option)}
                          >
                            {t(`appCustomization.${option}`)}
                          </DropdownItem>
                        )
                      })}
                    </Dropdown>
                  </div>
                )}

                {((isPartnerBuyPodUrlOptional && getPartnerBuyPodURLLabelKey() === 'customUrl') ||
                  (!isPartnerBuyPodUrlOptional && buyPodEnabled.value)) && (
                  <div className={style.formInputFull}>
                    <Input
                      className={style.input}
                      label={`${t('appCustomization.purchasePodUrl')}*`}
                      hasBeenValidated={urlPartnerBuyPod.hasBeenValidated}
                      value={urlPartnerBuyPod.value}
                      error={urlPartnerBuyPod.error}
                      onChange={e => this.handleTextFieldChange('urlPartnerBuyPod', e.target.value)}
                    />
                  </div>
                )}

                <TextToggler
                  onClick={() => this.handleYesNoFieldChange('addPodEnabled')}
                  title={t('appCustomization.allowAddPods')}
                  classes={textTogglerClasses}
                  selected={addPodEnabled.value}
                  onToggle={() => this.handleYesNoFieldChange('addPodEnabled')}
                />

                <TextToggler
                  onClick={() => this.handleYesNoFieldChange('removePodEnabled')}
                  title={t('appCustomization.allowRemovePods')}
                  classes={textTogglerClasses}
                  selected={removePodEnabled.value}
                  onToggle={() => this.handleYesNoFieldChange('removePodEnabled')}
                />

                <TextToggler
                  onClick={() => this.handleYesNoFieldChange('contactUsEnabled')}
                  title={t('appCustomization.showSupportEmail')}
                  classes={textTogglerClasses}
                  selected={contactUsEnabled.value}
                  onToggle={() => this.handleYesNoFieldChange('contactUsEnabled')}
                />

                {contactUsEnabled.value && (
                  <div className={style.formInputFull}>
                    <Input
                      className={style.input}
                      label={`${t('appCustomization.emailSupportUse')}*`}
                      hasBeenValidated={contactUsEmail.hasBeenValidated}
                      value={contactUsEmail.value}
                      error={contactUsEmail.error}
                      onChange={e => this.handleTextFieldChange('contactUsEmail', e.target.value)}
                    />
                  </div>
                )}

                <TextToggler
                  onClick={() => this.handleYesNoFieldChange('callUsEnabled')}
                  title={t('appCustomization.showPhoneNumber')}
                  classes={textTogglerClasses}
                  selected={callUsEnabled.value}
                  onToggle={() => this.handleYesNoFieldChange('callUsEnabled')}
                />

                {callUsEnabled.value && (
                  <div className={style.formInputFull}>
                    <Input
                      className={style.input}
                      label={`${t('appCustomization.displayPhoneNumForSuupport')}*`}
                      hasBeenValidated={callUsDisplayNumber.hasBeenValidated}
                      value={callUsDisplayNumber.value}
                      error={callUsDisplayNumber.error}
                      onChange={e =>
                        this.handleTextFieldChange('callUsDisplayNumber', e.target.value)
                      }
                    />
                  </div>
                )}
                {callUsEnabled.value && (
                  <div className={style.formInputFull}>
                    <Input
                      className={style.input}
                      label={`${t('appCustomization.phoneNumForSuupport')}*`}
                      hasBeenValidated={callUsNumber.hasBeenValidated}
                      value={callUsNumber.value}
                      error={callUsNumber.error}
                      onChange={e => this.handleTextFieldChange('callUsNumber', e.target.value)}
                    />
                  </div>
                )}

                <AccordionItem
                  title={t('appCustomization.advancedOptions')}
                  isActive={this.state.advancedOptionsShown}
                  classes={(curr: AccordionItemStyles) => ({
                    ...curr,
                    item: classNames(
                      curr.item,
                      style.override,
                      this.state.advancedOptionsShown && style.accordionOpen,
                    ),
                    // open: classNames(curr.open, style.override, style.accordionOpen),
                    title: classNames(curr.title, style.override, style.accordionTitle),
                    content: classNames(curr.content, style.override, style.accordionContent),
                  })}
                  onItemClick={() =>
                    this.setState(old => ({ advancedOptionsShown: !old.advancedOptionsShown }))
                  }
                >
                  {isHomePass && (
                    <>
                      <div className={style.urlDropdownContainer}>
                        <div className={style.textTogglerTitle}>
                          {t('appCustomization.manageAccountEnabled')}
                        </div>
                        <Dropdown
                          openInPortal
                          classes={curr => ({
                            ...curr,
                            root: classNames(curr.root, style.override, style.dropdownRoot),
                          })}
                          closeOnItemClick={true}
                          label={getManageAccountLabel()}
                        >
                          {dropdownURLOptions.map(option => {
                            return (
                              <DropdownItem
                                key={option}
                                onClick={() => this.handleManageAccountDropdownChange(option)}
                              >
                                {t(`appCustomization.${option}`)}
                              </DropdownItem>
                            )
                          })}
                        </Dropdown>
                      </div>

                      {manageAccountEnabled.value && usePartnerAccountUrl.value && (
                        <div className={style.formInputFull}>
                          <Input
                            className={style.input}
                            label={`${t('appCustomization.getPartnerAccountUrl')}*`}
                            hasBeenValidated={getPartnerAccountUrl.hasBeenValidated}
                            value={getPartnerAccountUrl.value}
                            error={getPartnerAccountUrl.error}
                            onChange={e =>
                              this.handleTextFieldChange('getPartnerAccountUrl', e.target.value)
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                  {isWorkPass && (
                    <>
                      <div className={style.urlDropdownContainer}>
                        <div className={style.textTogglerTitle}>
                          {t('appCustomization.captivePortalLoginOptions')}
                          <Tooltip
                            placement="top"
                            overlay={t('appCustomization.captivePortalLoginDescription')}
                            align={{
                              offset: [0, -5],
                            }}
                          >
                            <Icons.InfoIcon className={style.infoIcon} />
                          </Tooltip>
                        </div>
                        <Dropdown
                          openInPortal
                          classes={curr => ({
                            ...curr,
                            root: classNames(curr.root, style.override, style.dropdownRoot),
                            label: classNames(
                              curr.label,
                              !this.store.captivePortalDefaultLoginOptions.value &&
                                style.dropdownLabelWarning,
                            ),
                          })}
                          label={
                            captivePortalDefaultLoginOptionsArray
                              .map(item => t(`appCustomization.${item}`))
                              .join(', ') || t('appCustomization.pleaseSelectOne')
                          }
                        >
                          {this.getEmployeeLoginDropdownOptions().map(item => {
                            const isSelected = !!item.selected
                            const index = captivePortalDefaultLoginOptionsArray.findIndex(
                              o => o === item.option,
                            )
                            return (
                              <li key={item.option}>
                                <div
                                  className={classNames(
                                    style.dropdownPriorityItem,
                                    isSelected && style.dropdownPriorityItemSelected,
                                  )}
                                  onClick={() =>
                                    this.dispatchEmployeeLoginPriority({
                                      action: 'toggle',
                                      option: item.option,
                                    })
                                  }
                                >
                                  {index !== 0 && isSelected ? (
                                    <UiButton
                                      styleVariant="tertiary"
                                      classes={curr => ({
                                        ...curr,
                                        root: classNames(curr.root, style.dropdownPriorityArrow),
                                      })}
                                      onClick={e => {
                                        e.stopPropagation()
                                        this.dispatchEmployeeLoginPriority({
                                          action: 'increase',
                                          option: item.option,
                                        })
                                      }}
                                    >
                                      <Tooltip
                                        placement="top"
                                        overlay={t('appCustomization.moveUp')}
                                        align={{
                                          offset: [0, -5],
                                        }}
                                      >
                                        <Icons.UpArrowIcon width={20} height={20} />
                                      </Tooltip>
                                    </UiButton>
                                  ) : (
                                    <div className={commonStyle.visibilityHidden} />
                                  )}
                                  <div>{t(`appCustomization.${item.option}`)}</div>

                                  <Icons.CheckIcon className={style.dropdownPriorityTick} />
                                </div>
                              </li>
                            )
                          })}
                        </Dropdown>
                      </div>
                    </>
                  )}
                  <TextToggler
                    onClick={() => this.handleYesNoFieldChange('removeGatewayEnabled')}
                    title={t('appCustomization.removeGatewayEnabled')}
                    classes={textTogglerClasses}
                    selected={removeGatewayEnabled.value}
                    onToggle={() => this.handleYesNoFieldChange('removeGatewayEnabled')}
                  />

                  <TextToggler
                    onClick={() => this.handleYesNoFieldChange('manageYourDataEnabled')}
                    title={t('appCustomization.manageYourDataEnabled')}
                    classes={textTogglerClasses}
                    selected={manageYourDataEnabled.value}
                    onToggle={() => this.handleYesNoFieldChange('manageYourDataEnabled')}
                  />

                  {manageYourDataEnabled.value && (
                    <div className={style.formInputFull}>
                      <Input
                        className={style.input}
                        label={`${t('appCustomization.manageYourDataUrl')}*`}
                        hasBeenValidated={manageYourDataUrl.hasBeenValidated}
                        value={manageYourDataUrl.value}
                        error={manageYourDataUrl.error}
                        onChange={e =>
                          this.handleTextFieldChange('manageYourDataUrl', e.target.value)
                        }
                      />
                    </div>
                  )}

                  <TextToggler
                    onClick={() => this.handleYesNoFieldChange('hideIspNameEnabled')}
                    title={t('appCustomization.hideIspNameEnabled')}
                    classes={textTogglerClasses}
                    selected={hideIspNameEnabled.value}
                    onToggle={() => this.handleYesNoFieldChange('hideIspNameEnabled')}
                  />

                  {isHomePass && (
                    <>
                      <TextToggler
                        onClick={() => this.handleYesNoFieldChange('multilocationEnabled')}
                        title={t('appCustomization.multilocationEnabled')}
                        classes={textTogglerClasses}
                        selected={multilocationEnabled.value}
                        onToggle={() => this.handleYesNoFieldChange('multilocationEnabled')}
                      />

                      <TextToggler
                        onClick={() => this.handleYesNoFieldChange('autorunSpeedtestToggleEnabled')}
                        title={t('appCustomization.autorunSpeedtestToggleEnabled')}
                        classes={textTogglerClasses}
                        selected={autorunSpeedtestToggleEnabled.value}
                        onToggle={() =>
                          this.handleYesNoFieldChange('autorunSpeedtestToggleEnabled')
                        }
                      />

                      <TextToggler
                        onClick={() => this.handleYesNoFieldChange('chatSupportEnabled')}
                        title={t('appCustomization.chatSupportEnabled')}
                        classes={textTogglerClasses}
                        selected={chatSupportEnabled.value}
                        onToggle={() => this.handleYesNoFieldChange('chatSupportEnabled')}
                      />

                      {chatSupportEnabled.value && (
                        <div className={style.formInputFull}>
                          <Input
                            className={style.input}
                            label={`${t('appCustomization.chatSupportUrl')}*`}
                            hasBeenValidated={chatSupportUrl.hasBeenValidated}
                            value={chatSupportUrl.value}
                            error={chatSupportUrl.error}
                            onChange={e =>
                              this.handleTextFieldChange('chatSupportUrl', e.target.value)
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                </AccordionItem>
              </div>

              <div className={style.labelInfoText}>{t('appCustomization.allFieldsRequired')}</div>
              <div className={style.btnDiv}>
                <Button
                  className={style.sendInviteBtn}
                  onClick={this.handlePreviewAppCustomization}
                  disabled={isLoadingModal || appCustomizationFieldsNotValidated}
                >
                  {t('btn.previewChanges')}
                </Button>
              </div>
              {isEmployee && (
                <div>
                  <div className={style.filesHeader}>
                    <span className={style.labelText}>
                      *{t('appCustomization.logoFiles').toUpperCase()}*
                    </span>
                  </div>
                  <div className={style.labelInfoText}>
                    {t('appCustomization.maxFileSizeMsg', {
                      product: t(`common.${selectedProduct}`),
                      height: logoDimensions[selectedProduct]?.height,
                      width: logoDimensions[selectedProduct]?.width,
                    })}
                  </div>

                  {/* Disabled until homepass and workpass logo dimensions align */}
                  {/* {companyHasBothProducts && (
                    <div className={style.copyContainer}>
                      <Button
                        className={classNames(style.copyButton, style.copyLogoButton)}
                        type="button"
                        disabled={false}
                        onClick={() => copyLogo()}
                      >
                        <Copy className={style.copyIcon} />
                        <span>{`${t('appCustomization.copyLogoFrom')} ${
                          isHomePass ? t(`common.WorkPass`) : t(`common.HomePass`)
                        }`}</span>
                      </Button>
                    </div>
                  )} */}

                  {renderSvgInput && (
                    <div>
                      <input
                        id="svgFile"
                        className={style.fileInput}
                        type="file"
                        accept="image/svg+xml"
                        name="svgFile"
                        onChange={this.onSvgChangeHandler}
                      />
                      <div className={style.fileSelection}>
                        <label htmlFor="svgFile" className={style.labelLogoTitle}>
                          {t('appCustomization.svgLogoForAndroid')}
                        </label>
                        <label htmlFor="svgFile" className={style.labelLogoText}>
                          {t('appCustomization.selectFileForUpload')}
                        </label>
                      </div>
                    </div>
                  )}
                  {(svgFile || renderSvgImage) && (
                    <div>
                      <div className={style.labelPreviewTitle}>
                        {t('appCustomization.svgLogoForAndroid')}
                      </div>
                      <div className={style.previewLogoWrapper}>
                        <div className={style.previewLogoBg}>
                          {svgFile && <img src={svgBase64DataUrl} alt="SVG logo" />}
                          {renderSvgImage && <img src={svgUrl} alt="SVG logo" />}
                        </div>
                        <span
                          onClick={() => this.handleLogoPreview('android')}
                          className={style.previewLogoLink}
                        >
                          {t('btn.preview')}
                        </span>
                      </div>
                    </div>
                  )}

                  {(svgFile || !!renderSvgImage) && (
                    <div>
                      <span className={style.labelRemoveFile} onClick={this.handleRemoveSvg}>
                        {t('btn.remove')}
                      </span>
                    </div>
                  )}

                  {svgFileError && <div className={style.error}>{svgFileError}</div>}

                  {!renderPdf && (
                    <div>
                      <input
                        id="pdfFile"
                        className={style.fileInput}
                        type="file"
                        accept="application/pdf"
                        name="pdfFile"
                        onChange={this.onPdfChangeHandler}
                      />
                      <div className={style.fileSelection}>
                        <label htmlFor="pdfFile" className={style.labelLogoTitle}>
                          {t('appCustomization.pdfLogoForIos')}
                        </label>
                        <label htmlFor="pdfFile" className={style.labelLogoText}>
                          {t('appCustomization.selectFileForUpload')}
                        </label>
                      </div>
                    </div>
                  )}

                  <div className={classNames(renderPdf ? style.displayBlock : style.displayNone)}>
                    <div className={style.labelPreviewTitle}>
                      {t('appCustomization.pdfLogoForIos')}
                    </div>
                    <div className={style.previewLogoWrapper}>
                      <div className={style.previewLogoBg}>
                        <Document
                          file={pdfFile || pdfUrl}
                          className={computedClassNamePdf}
                          renderMode="svg"
                        >
                          <Page
                            pageNumber={1}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                            className={style.reactPdfPage}
                          />
                        </Document>
                      </div>
                      <span
                        onClick={() => this.handleLogoPreview('ios')}
                        className={style.previewLogoLink}
                      >
                        {t('btn.preview')}
                      </span>
                    </div>
                  </div>

                  <div className={classNames(renderPdf ? style.displayBlock : style.displayNone)}>
                    <span className={style.labelRemoveFile} onClick={this.handleRemovePdf}>
                      {t('btn.remove')}
                    </span>
                  </div>

                  {pdfFileError && <div className={style.error}>{pdfFileError}</div>}
                  {isImageUploadRequired && (
                    <div className={style.error}>
                      {t('appCustomization.bothSvgAndPdfAreRequired')}
                    </div>
                  )}

                  <div className={style.btnDiv}>
                    <Button
                      className={style.sendInviteBtn}
                      onClick={this.handleMCLogoUpload}
                      disabled={isLoadingModal || isMCLogoUploadDisabled}
                    >
                      {t('appCustomization.uploadLogo', {
                        product: t(`common.${selectedProduct}`),
                      })}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
          {isLoadingModal && <Loader />}

          {/* Preview Step 1 */}

          <LogoPreview
            accountStore={this.store}
            fromAccMgrLogoUpload={this.state.isUploadingLogo}
          />

          {/* Preview Step 2 */}

          <PodsPreview accountStore={this.store} previewAppCustomizationOnly={isEmployee} />

          {/* Preview Step 3 */}

          <SupportPreview accountStore={this.store} />
        </form>
      </div>
    )
  }
}

export const AppCustomization = withTranslation()(AppCustomizationComponent)
