import * as React from 'react'
import style from './user.css'

export class Title extends React.Component<{
  children?: React.ReactNode
  title?: string
  errorMode?: boolean
}> {
  render() {
    const { children, title, errorMode } = this.props
    const rootProps: any = {}

    if (errorMode) {
      rootProps.className = style.userErrorMode
    }

    return (
      <div {...rootProps}>
        <div className={style.title}>{title}</div>
        <div className={style.linkWrapper}>{children}</div>
      </div>
    )
  }
}
