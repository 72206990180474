import * as React from 'react'
import style from './network-action-card.css'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { User } from 'models'
import { Button, Heading } from 'plume-ui'
import { mixpanelActions } from 'utils/mixpanelActions'
import { SalesforceProductStatus } from 'interfaces/api/portal/company-api'
import { howManyDaysLeft } from 'helpers/company-helpers'

interface NetworkActionCardProps {
  // id: string
  title: string
  description: string
  link: string
  icon: string
  btnText: string
  btnDisabled?: boolean
  imageCls?: string
  onClick?: any
  banner?: string
  currentUser?: User
  loadingUsers?: boolean
  extra?: string
  partnerId?: string
  productStatus?: SalesforceProductStatus
}

export const NetworkActionCard = ({
  // id,
  title,
  description,
  extra,
  link,
  icon,
  btnText,
  imageCls,
  onClick,
  btnDisabled,
  banner,
  partnerId,
  productStatus,
}: NetworkActionCardProps) => {
  const launchLink = (link: string) => {
    window.open(link, '_blank')
  }
  const handleClick = () => {
    mixpanelActions.track('Applications - Launch', {
      application: title,
      'Partner Id': partnerId,
    })

    if (onClick) return onClick()

    return launchLink(link)
  }
  const bg = require(`../../../assets/icons/${icon}`)
  const { t } = useTranslation()

  const titleLower = title.toLowerCase()
  const cloud = titleLower.includes('cloud)')
    ? title.substring(title.indexOf('(') + 1, titleLower.indexOf(' cloud'))
    : null
  const appName = titleLower.includes('cloud)') ? title.substring(0, title.indexOf(' (')) : title

  const trialDuration = productStatus?.trialEndDate
    ? howManyDaysLeft(productStatus?.trialEndDate)
    : null

  return (
    <div className={style.root}>
      {!!banner && <sup className={style.banner}>{banner}</sup>}
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className={classNames(style.image, imageCls)}
      />
      <div className={style.content}>
        <div className={classNames(style.title, !trialDuration && style.titleBottomMargin)}>
          <Heading size={'l'} ocrey>
            {appName}
          </Heading>
          {trialDuration > 5 ? (
            <div className={style.trialSection}>
              {t('header.trialLeft', {
                numOfDays: trialDuration,
              })}
            </div>
          ) : trialDuration ? (
            <div className={style.trialSection}>
              {t('header.trialLeftOnly', {
                dayOrDays: t('common.day', { count: trialDuration }),
                numOfDays: trialDuration,
              })}
            </div>
          ) : undefined}
        </div>
        <div className={style.description}>
          <div>{cloud && `(Cloud: ${cloud})`}</div>
          {t(description)}
          {extra && <div className={style.extra} dangerouslySetInnerHTML={{ __html: t(extra) }} />}
        </div>
      </div>
      <div className={style.button}>
        <div className={style.buttonContainer}>
          <Button onClick={handleClick} disabled={btnDisabled} styleVariant="superprimary">
            {btnText}
          </Button>
        </div>
      </div>
    </div>
  )
}
