import { FieldState } from 'formstate'
import { showAlert } from 'utils/show-alert'
import { email, required } from 'utils/form/validators'
import { action, observable } from 'mobx'
import { OktaAuth } from '@okta/okta-auth-js'
import { AppStore } from 'stores/app-store'

export class PasswordResetRequestStore {
  public email = new FieldState('').validators(required('Email Address'), email)

  private authClient: OktaAuth

  @observable
  public isLoading = false
  @observable
  public error = ''

  constructor(private readonly appStore: AppStore) {
    this.authClient = new OktaAuth({
      issuer: process.env.OKTA_ISSUER,
      clientId: process.env.OKTA_CLIENT_ID,
    })
  }

  @action
  public async validateAndSubmit() {
    await this.email.validate()
    if (!this.email.hasError) {
      await this.submit()
    }
  }

  @action
  private async submit() {
    this.isLoading = true
    this.clearError()

    try {
      await this.appStore.userApi.expireRecoveryPassword(this.email.value)

      const transaction = await this.authClient.forgotPassword({
        username: this.email.value,
        factorType: 'EMAIL',
      })
      if (transaction.status === 'RECOVERY_CHALLENGE') {
        this.handleSuccess()
      } else {
        throw `We cannot handle the ${transaction.status} status`
      }
    } catch (e) {
      const error =
        typeof e === 'string' || e instanceof String ? e : e.message || 'errors.somethingWentWrong'
      this.handleServerError(error)
    }
  }

  @action
  private clearError() {
    this.error = ''
  }

  @action.bound
  private async handleSuccess() {
    this.isLoading = false
    await showAlert({
      title: 'auth.passwordRestLinkSent',
      message: 'auth.passwordResetLinkMsg',
      buttonText: 'btn.gotIt',
      isCloseShowing: true,
    })
  }

  @action.bound
  private handleServerError(error: string) {
    this.error = error
    this.isLoading = false
  }
}
