import * as React from 'react'
import style from '../app-customization.css'
import { LeftArrow, RightArrow } from 'components/icons/index'
import { Button } from 'components/button'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { AccountStore } from '../../account-store'
import hpV1IosSupport from '../../../../../assets/png/ios_support.png'
import hpV2IosSupport from '../../../../../assets/png/homepass_v2_ios_support.png'
import hpV1AndroidSupport from '../../../../../assets/png/android_support.png'
import hpV2AndroidSupport from '../../../../../assets/png/homepass_v2_android_support.png'
import wpIosSupport from '../../../../../assets/png/workpass_ios_support.png'
import wpAndroidSupport from '../../../../../assets/png/workpass_android_support.png'
import { PlumeProduct } from 'enums/plume-product.enum'

interface SupportPreviewProps {
  accountStore: AccountStore
}

export const SupportPreview = observer((props: SupportPreviewProps) => {
  const handlePrevStep = () => {
    props.accountStore.setPrevStep()
  }

  const handleNextStep = () => {
    props.accountStore.setNextStep()
  }

  const handleOSPreviewChange = (os: string) => {
    props.accountStore.setOsPreview(os)
  }

  const handleCallUsNumberHover = () => {
    if (props.accountStore.callUsNumber.value.length > 4) {
      props.accountStore.toggleCallUsHover()
    }
  }

  const {
    accountStore: {
      isLoadingModal,
      urlPrivacyPolicy,
      urlTemsOfUse,
      faqEnabled,
      contactUsEnabled,
      callUsEnabled,
      callUsNumber,
      callUsDisplayNumber,
      currentStep,
      previewOS,
      isCallUsHovering,
      isAppCustomizationSubmitDisabled,
      selectedProduct,
    },
  } = props
  const { t } = useTranslation()

  const isHomePassV2Enabled = process.env.HOMEPASS_V2_ENABLED === 'true'

  const isHomePassSelected = selectedProduct === PlumeProduct.HomePass

  const iosBg = isHomePassSelected
    ? isHomePassV2Enabled
      ? hpV2IosSupport
      : hpV1IosSupport
    : wpIosSupport
  const androidBg = isHomePassSelected
    ? isHomePassV2Enabled
      ? hpV2AndroidSupport
      : hpV1AndroidSupport
    : wpAndroidSupport

  const supportCssVars =
    !isHomePassV2Enabled && isHomePassSelected
      ? previewOS === 'ios'
        ? style.iosSupportHomePass
        : style.androidSupportHomePass
      : isHomePassV2Enabled && isHomePassSelected
      ? style.supportHomePassV2
      : style.supportWorkPass

  const notShownKey =
    isHomePassSelected && isHomePassV2Enabled
      ? 'appCustomization.notShownHomePass'
      : 'appCustomization.itemWillNotBeShown'

  return (
    <div
      className={classNames(
        (currentStep !== 3 || isLoadingModal) && style.displayNone,
        supportCssVars,
      )}
    >
      <div className={style.setupCompletedWrapper}>{t('appCustomization.formMsg')}</div>
      <div className={style.previewContent}>
        <div
          className={classNames(style.arrowLeft, currentStep === 1 && style.hidden)}
          onClick={handlePrevStep}
        >
          <LeftArrow />
        </div>
        <div>
          <div className={style.previewHeader}>
            <span
              className={classNames(
                style.previewItem,
                previewOS === 'ios' && style.previewItemActive,
              )}
              onClick={() => handleOSPreviewChange('ios')}
            >
              {t('appCustomization.iOS')}
            </span>
            <span
              className={classNames(
                style.previewItem,
                previewOS === 'android' && style.previewItemActive,
              )}
              onClick={() => handleOSPreviewChange('android')}
            >
              {t('appCustomization.android')}
            </span>
          </div>
          <div className={style.imagePreview}>
            {previewOS === 'ios' && <img src={iosBg} alt="iOS Support page" />}
            {previewOS === 'android' && <img src={androidBg} alt="Android Support page" />}
            <div className={classNames(style.previewOverlay, style.supportOverlayTop)} />
            {!faqEnabled.value ? (
              <div className={classNames(style.previewFieldDisabled, style.faqDisabled)}>
                {t(notShownKey)}
              </div>
            ) : (
              (!isHomePassSelected || !isHomePassV2Enabled) && (
                <div className={classNames(style.previewFieldEnabled, style.faqEnabled)} />
              )
            )}
            {!contactUsEnabled.value ? (
              <div className={classNames(style.previewFieldDisabled, style.contactUsDisabled)}>
                {t(notShownKey)}
              </div>
            ) : (
              (!isHomePassSelected || !isHomePassV2Enabled) && (
                <div className={classNames(style.previewFieldEnabled, style.contactUsEnabled)} />
              )
            )}
            {!callUsEnabled.value ? (
              <div className={classNames(style.previewFieldDisabled, style.callUsDisabled)}>
                {t(notShownKey)}
              </div>
            ) : (
              (!isHomePassSelected || !isHomePassV2Enabled) && (
                <div onMouseEnter={handleCallUsNumberHover} onMouseLeave={handleCallUsNumberHover}>
                  <div className={style.callUsEnabled} />
                  <div className={style.callUsEnabledDN}>{callUsDisplayNumber.value}</div>
                  {isCallUsHovering && (
                    <div>
                      <div className={style.callUsEnabledNumber}>{callUsNumber.value}</div>
                      <div className={style.callUsEnabledNumberTr} />
                    </div>
                  )}
                </div>
              )
            )}

            <div className={classNames(style.previewOverlay, style.supportOverlayMiddle1)} />

            {urlTemsOfUse.value ? (
              <div className={classNames(style.previewFieldEnabled, style.termsEnabled)} />
            ) : (
              <div className={classNames(style.previewFieldDisabled, style.termsDisabled)}>
                {t('appCustomization.itemWillNotBeShown')}
              </div>
            )}

            <div className={classNames(style.previewOverlay, style.supportOverlayMiddle2)} />

            {urlPrivacyPolicy.value ? (
              <div className={classNames(style.previewFieldEnabled, style.privacyEnabled)} />
            ) : (
              <div className={classNames(style.previewFieldDisabled, style.privacyDisabled)}>
                {t('appCustomization.itemWillNotBeShown')}
              </div>
            )}

            <div className={classNames(style.previewOverlay, style.supportOverlayBottom)} />
          </div>
        </div>
        <div
          onClick={handleNextStep}
          className={classNames(style.arrowRight, currentStep === 3 && style.hidden)}
        >
          <RightArrow />
        </div>
      </div>

      <div className={style.btnDiv}>
        <Button
          className={style.sendInviteBtn}
          type="submit"
          disabled={isAppCustomizationSubmitDisabled}
        >
          {t('appCustomization.submitCustomization', {
            product: t(`common.${selectedProduct}`),
          })}
        </Button>
      </div>
    </div>
  )
})
