import * as React from 'react'
import style from '../edit-account.css'
import { Close } from 'components/icons/index'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { AppStore } from 'stores/app-store'
import { AccountStore } from '../account-store'
import { observer } from 'mobx-react'
import { Loader } from 'components/loader'
import { withTranslation, WithTranslation } from 'react-i18next'

interface EditBillingContactProps extends WithTranslation {
  appStore?: AppStore
  onClose: () => Promise<void>
  accountStore?: AccountStore
}

@observer
export class EditBillingContactComponent extends React.Component<EditBillingContactProps> {
  private store: AccountStore

  constructor(props: EditBillingContactProps) {
    super(props)
    this.store = props.accountStore
  }

  private handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    this.store.validateAndSubmitBillingContact()
  }

  render() {
    const { onClose, t } = this.props
    const {
      store: {
        billingContactName,
        billingContactPhone,
        billingContactEmail,
        serverError,
        isLoadingModal,
      },
    } = this

    return (
      <div className={style.root}>
        <div className={style.subTitle}>{t('account.editBillingContact')}</div>

        <div className={style.closeButton}>
          <Close onClick={onClose} />
        </div>
        <form onSubmit={this.handleFormSubmit}>
          <div className={style.formInputs}>
            <div className={style.formInput}>
              <Input
                className={style.input}
                autoFocus
                label={t('common.contactName')}
                hasBeenValidated={billingContactName.hasBeenValidated}
                value={billingContactName.value}
                error={billingContactName.error || !!serverError}
                onChange={e => billingContactName.onChange(e.target.value)}
                onBlur={e => billingContactName.onChange(e.target.value.trim())}
              />
            </div>
            <div className={style.formInput}>
              <Input
                className={style.input}
                label={t('common.phoneNumber')}
                hasBeenValidated={billingContactPhone.hasBeenValidated}
                value={billingContactPhone.value}
                error={billingContactPhone.error || !!serverError}
                onChange={e => billingContactPhone.onChange(e.target.value)}
                onBlur={e => billingContactPhone.onChange(e.target.value.trim())}
              />
            </div>
            <div className={style.formInput}>
              <Input
                className={style.input}
                label={t('common.email')}
                hasBeenValidated={billingContactEmail.hasBeenValidated}
                value={billingContactEmail.value}
                error={billingContactEmail.error || !!serverError}
                onChange={e => billingContactEmail.onChange(e.target.value)}
                onBlur={e => billingContactEmail.onChange(e.target.value.trim())}
              />
            </div>
          </div>
          {serverError && <div className={style.serverError}>{serverError}</div>}
          <div className={style.btnDiv}>
            {this.store.isLoadingModal && <Loader />}
            <Button
              className={style.sendInviteBtn}
              type="submit"
              disabled={
                isLoadingModal ||
                !!billingContactName.error ||
                !!billingContactPhone.error ||
                !!billingContactEmail.error
              }
            >
              {t('btn.save')}
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

export const EditBillingContact = withTranslation()(EditBillingContactComponent)
