import * as React from 'react'
import style from '../edit-account.css'
import { Close } from 'components/icons/index'
import { Select } from 'components/select'
import { Button } from 'components/button'
import { AppStore } from 'stores/app-store'
import { AccountStore } from '../account-store'
import { observer } from 'mobx-react'
import { Loader } from 'components/loader'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input } from 'components/input'
import { AuthStore } from 'stores/auth-store'

interface EditGeneralInfoProps extends WithTranslation {
  appStore?: AppStore
  onClose: () => Promise<void>
  accountStore?: AccountStore
  authStore?: AuthStore
}

@observer
export class EditPortalConfigurationComponent extends React.Component<EditGeneralInfoProps> {
  private store: AccountStore

  constructor(props: EditGeneralInfoProps) {
    super(props)
    this.store = props.accountStore
  }

  private handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    this.store.validateAndSubmitPortalConfiguration()
  }

  render() {
    const { onClose, t } = this.props
    const {
      store: { portalLanguage, serverError, isLoadingModal, portalNickname },
    } = this
    const canEditNickname = !this.props.authStore.currentUser.isEmployee
    const localeOptions = [
      {
        value: 'English',
        text: t(`common.portalLanguages.en_US`),
      },
      {
        value: 'French',
        text: t(`common.portalLanguages.fr_FR`),
      },
      {
        value: 'German',
        text: t(`common.portalLanguages.de_DE`),
      },
      {
        value: 'Japanese',
        text: t(`common.portalLanguages.ja_JP`),
      },
      {
        value: 'Portuguese',
        text: t(`common.portalLanguages.pt_PT`),
      },
      {
        value: 'Spanish',
        text: t(`common.portalLanguages.es_ES`),
      },
      {
        value: 'Italian',
        text: t(`common.portalLanguages.it_IT`),
      },
      {
        value: 'Vietnamese',
        text: t(`common.portalLanguages.vi_VN`),
      },
    ]

    return (
      <div className={style.root}>
        <div className={style.subTitle}>{t('account.editPortalConfiguration')}</div>
        <div className={style.closeButton}>
          <Close onClick={onClose} />
        </div>
        <form onSubmit={this.handleFormSubmit}>
          <div className={style.portalLanguageSelect}>
            <div className={style.languageSelectLabel}>{t('account.portalLanguage')}</div>
            <div className={style.portalLanguageFormInput}>
              <Select
                options={localeOptions}
                value={portalLanguage.value}
                onChange={e => portalLanguage.onChange(e.target.value)}
              />
            </div>
          </div>
          {canEditNickname && (
            <Input
              className={style.input}
              label={t('account.portalName')}
              value={portalNickname.value ? portalNickname.value : ''}
              onChange={e => portalNickname.onChange(e.target.value)}
              error={portalNickname.error}
            />
          )}
          {serverError && <div className={style.serverError}>{serverError}</div>}
          <div className={style.btnDiv}>
            {this.store.isLoadingModal && <Loader />}
            <Button
              className={style.sendInviteBtn}
              type="submit"
              disabled={isLoadingModal || portalNickname?.value?.length > 20}
            >
              {t('btn.save')}
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

export const EditPortalConfiguration = withTranslation()(EditPortalConfigurationComponent)
