import * as React from 'react'
import style from './radio-button.css'
import classNames from 'classnames'

interface IRadioButtonProps {
  id: string
  name: string
  value?: string
  checked: boolean
  label: string
  disabled?: boolean
  handleOnFieldChange: (name: string) => void
}

export const RadioButton = (props: IRadioButtonProps) => {
  const { id, name, value, checked, label, disabled, handleOnFieldChange, ...otherInputProps } =
    props
  const computedClassName = classNames(style.root, style.radio, { [style.disabled]: disabled })

  return (
    <div className={computedClassName}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={() => handleOnFieldChange(name)}
        disabled={disabled}
        {...otherInputProps}
      />
      <div className={style.check} />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}
