import * as React from 'react'
import style from './progress-bar.css'
import classNames from 'classnames'

interface ProgressBarProps {
  width: number
}

export const ProgressBar = (props: ProgressBarProps) => {
  const progressBarStyle: any = {
    '--progressBarWidth': `${props.width}`,
  }
  const progressPercentage = `${(props.width * 100).toFixed(0)}%`

  return (
    <div className={style.progressBarContainer}>
      <div className={classNames(style.progressBar, style.blendWhite)}></div>
      <div
        className={classNames(style.progressBar, style.blendBlack)}
        style={progressBarStyle}
      ></div>
      <div className={classNames(style.progressBar, style.blendBlue)}></div>
      <span className={style.progressBarText}>{progressPercentage}</span>
    </div>
  )
}
