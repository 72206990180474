export const teamAppsEvent = 'teamApps'
export const addTeamSuccessEvent = 'addTeamSuccess'
export const editTeamSuccessEvent = 'editTeamSuccess'
export const teamErrorEvent = 'teamError'

export const addEditUsersSuccessEvent = 'addEditUserSuccess'
export const userErrorEvent = 'userError'

export const idpSettingsSetTeamsSuccessEvent = 'idpSettingsSetTeamsSuccess'
export const idpUserGetCompaniesEvent = 'idpUserCompanies'
export const idpUserGetCompaniesErrorEvent = 'idpUserCompaniesError'
export const idpErrorEvent = 'idpError'

export const userExportTeamAppsEvent = 'userExportTeamApps'
export const userExportTeamAppsErrorEvent = 'userExportTeamAppsError'
