import * as React from 'react'
import style from './manage-inventory.css'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { DashboardLayout } from 'modules/dashboard/common/dashboard-layout'
import { useTranslation } from 'react-i18next'
import { useUpdateCurrentUser } from 'utils/hooks/use-update-current-user'
import { useStores } from 'utils/hooks/useStores'
import classNames from 'classnames'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { AddUserReturnValue } from 'interfaces/utils/add-user'
import { Table, SearchInputField, Heading, Pagination, Tooltip, Button } from 'plume-ui'
import { Heading as TableHeaderGeneric } from 'plume-ui/dist/components/Table/Table'
import { matchesCaseInsensitive, parseJsonSafe } from 'helpers/general-helpers'
import { Redirect } from 'react-router-dom'
import { ManageInventoryStore } from './manage-inventory-store'
import { Nda } from 'modules/account-setup/nda/nda'
import { NodeReport } from './node-report'
import { NodeImportUpdate } from './node-reassignment'
import { mixpanelActions } from 'utils/mixpanelActions'
import { ReportJob } from './report-job'
import { ManageInventoryJob } from 'interfaces/api/portal/i.inventory-api'
import { Tooltip as PortalTooltip } from 'components/tooltip'
import { canAccess } from 'modules/auth/auth-action-permission'

type TableHeader = Omit<TableHeaderGeneric, 'render'> & {
  fieldName?: keyof ManageInventoryJob
  render?: (row: Partial<ManageInventoryJob>) => any
}

const COMPANIES_PER_PAGE = 10

const showNodeReportModal = async (
  actionModalStore: ModalLayerStore,
  manageInventoryStore: ManageInventoryStore,
  viewJobName: string,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      manageInventoryStore.clearServerError()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    manageInventoryStore.clearServerError()

    actionModalStore.addModal(
      <NodeReport
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        viewJobName={viewJobName}
        manageInventoryStore={manageInventoryStore}
      />,
    )
  })
}

const showCNRJModal = async (
  actionModalStore: ModalLayerStore,
  manageInventoryStore: ManageInventoryStore,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      manageInventoryStore.clearServerError()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    manageInventoryStore.clearServerError()

    actionModalStore.addModal(
      <NodeImportUpdate
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        manageInventoryStore={manageInventoryStore}
        action="update"
      />,
    )
  })
}

const showNodeImportModal = async (
  actionModalStore: ModalLayerStore,
  manageInventoryStore: ManageInventoryStore,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      manageInventoryStore.clearServerError()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    manageInventoryStore.clearServerError()

    actionModalStore.addModal(
      <NodeImportUpdate
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        manageInventoryStore={manageInventoryStore}
        action="import"
      />,
    )
  })
}

const showCRJModal = async (
  actionModalStore: ModalLayerStore,
  manageInventoryStore: ManageInventoryStore,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      manageInventoryStore.clearServerError()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    manageInventoryStore.clearServerError()

    actionModalStore.addModal(
      <ReportJob
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        manageInventoryStore={manageInventoryStore}
      />,
    )
  })
}

const isUpdateNodesEnabledForCompany = (partnerId: string) => {
  const enabledPartnerIds: string[] = parseJsonSafe(
    process.env.INVENTORY_UPDATE_NODES_ENABLED_COMPANIES,
  )
  return !!enabledPartnerIds?.includes(partnerId)
}

const isImportNodesEnabledForCompany = (partnerId: string) => {
  const enabledPartnerIds: string[] = parseJsonSafe(
    process.env.INVENTORY_IMPORT_NODES_ENABLED_COMPANIES,
  )
  return !!enabledPartnerIds?.includes(partnerId)
}

type NodeTableRowKey = 'name' | 'createdAt' | 'submittedByEmail'

type TableSortArgument = { fieldName: NodeTableRowKey; direction: number }

export const ManageInventory = observer(() => {
  const { t } = useTranslation()
  const appStore = useStores()
  const currentUser = useUpdateCurrentUser()

  const [manageInventoryStore] = React.useState(new ManageInventoryStore(appStore))
  const { jobs, isLoading } = manageInventoryStore

  const [searchString, setSearchString] = React.useState('')
  const [tablePage, setTablePage] = React.useState(0)
  const [sortParameters, setSortParameters] = React.useState<TableSortArgument>({
    fieldName: 'createdAt',
    direction: 1,
  })

  const jobDescriptionRefs = React.useRef<HTMLDivElement[]>([])
  const refsToHide = React.useRef<HTMLDivElement[]>([])

  React.useEffect(() => {
    if (jobDescriptionRefs.current) {
      for (const el of jobDescriptionRefs.current) {
        if (el?.parentElement?.parentElement) {
          el.parentElement.parentElement.setAttribute('colspan', '3')
        }
      }
    }
    jobDescriptionRefs.current.length = 0

    if (refsToHide.current) {
      for (const el of refsToHide.current) {
        if (el?.parentElement?.parentElement) {
          el.parentElement.parentElement.style.display = 'none'
        }
      }
    }
    refsToHide.current.length = 0
  })

  React.useEffect(() => {
    mixpanelActions.track('Manage inventory - Open', {})
    if (!!currentUser && manageInventoryStore.jobs === null) {
      manageInventoryStore.initManageInventory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageInventoryStore, currentUser])

  if (!currentUser) {
    return <Loader />
  }

  if (!canAccess('viewInventory', currentUser)) {
    return <Redirect to="/" />
  }

  if (
    appStore.authStore.currentUser.companies?.length
      ? !appStore.authStore.currentUser.companies?.[0]?.signedNdaTimestamp
      : !appStore.authStore.currentUser.company.signedNdaTimestamp
  ) {
    return <Nda />
  }

  const oc = style.override

  // const getCompany = (partnerId: string) => {
  //   return appStore.authStore.currentUser.companies?.[0]?.partnerId === partnerId
  //     ? appStore.authStore.currentUser.companies?.[0]
  //     : appStore.authStore.currentUser.companies?.[0]?.subordinateCompanies?.find(
  //         sc => sc.partnerId === partnerId,
  //       )
  // }

  const searchFilteredData = jobs
    ?.filter(
      j =>
        // matchesCaseInsensitive(currentUser.company.name, searchString) ||
        // matchesCaseInsensitive(currentUser.company.portalNickname, searchString) ||
        // matchesCaseInsensitive(j.partnerId, searchString) ||
        matchesCaseInsensitive(j.name, searchString) ||
        matchesCaseInsensitive(j.submittedByEmail, searchString),
    )
    .sort((j1, j2) =>
      (j1[sortParameters.fieldName]?.toLowerCase() || '') >
      (j2[sortParameters.fieldName]?.toLowerCase() || '')
        ? -1 * sortParameters.direction
        : sortParameters.direction,
    )

  const isUpdateNodesEnabled = isUpdateNodesEnabledForCompany(currentUser.company.partnerId)
  const isImportNodesEnabled = isImportNodesEnabledForCompany(currentUser.company.partnerId)

  const dataRows = searchFilteredData?.slice(
    tablePage * COMPANIES_PER_PAGE,
    (tablePage + 1) * COMPANIES_PER_PAGE,
  )

  const headerRow: TableHeader[] = [
    {
      name: t('manageInventory.jobName'),
      fieldName: 'name',
      render: row => (
        <Tooltip openInPortal label={row.name} alignment="left">
          <div className={style.limitTextWidth}>{row.name}</div>
        </Tooltip>
      ),
    },
    {
      name: t('manageInventory.jobType'),
      fieldName: 'jobType',
      render: row => t(`manageInventory.${row.jobType}`),
    },
    {
      name: t('manageInventory.created'),
      fieldName: 'createdAt',
      render: row => new Date(row.createdAt).toLocaleString(),
    },
    {
      name: t('manageInventory.createdBy'),
      fieldName: 'submittedByEmail',
      render: row => (
        <PortalTooltip
          placement="topLeft"
          overlay={row.submittedByEmail}
          align={{
            offset: [0, -5],
          }}
          trigger={['hover']}
          mouseLeaveDelay={0}
        >
          <div className={style.limitTextWidth}>{row.submittedByEmail}</div>
        </PortalTooltip>
      ),
    },
    {
      name: t('common.successful'),
      sortable: false,
      render: (row: Partial<ManageInventoryJob>) => {
        return (
          <div
            ref={el => {
              if (!!el && row.status === 'IN_PROGRESS') {
                jobDescriptionRefs.current.push(el)
              }
            }}
          >
            {row.status !== 'IN_PROGRESS'
              ? row.nodesSucceded
              : row.nodesStarted > 0
              ? t('manageInventory.jobStarted')
              : t('manageInventory.jobQueued')}
          </div>
        )
      },
    },
    {
      name: t('common.failed'),
      sortable: false,
      render: (row: Partial<ManageInventoryJob>) => {
        return row.status === 'IN_PROGRESS' ? (
          <div
            ref={el => {
              if (!!el) {
                refsToHide.current.push(el)
              }
            }}
          />
        ) : (
          row.nodesFailed
        )
      },
    },
    {
      name: '',
      sortable: false,
      render: (row: Partial<ManageInventoryJob>) =>
        row.status === 'IN_PROGRESS' ? (
          <div
            ref={el => {
              if (!!el) {
                refsToHide.current.push(el)
              }
            }}
          />
        ) : (
          <Button
            classes={curr => ({
              ...curr,
              root: classNames(curr.root, oc, style.extendTrialButtonCustomization),
            })}
            styleVariant="tertiary"
            onClick={() =>
              showNodeReportModal(appStore.actionModalStore, manageInventoryStore, row.name)
            }
          >
            {t('manageInventory.viewReport')}
          </Button>
        ),
    },
  ]

  if (tablePage && tablePage >= Math.ceil(searchFilteredData?.length / COMPANIES_PER_PAGE)) {
    setTablePage(0)
  }
  return (
    <DashboardLayout currentUser={currentUser}>
      {isLoading || jobs === null ? (
        <Loader theme="small" rootClassName={style.loaderRoot} />
      ) : (
        <div className={style.pageRoot}>
          <div className={style.headerWrapper}>
            <Heading size="xxl">{t('manageInventory.title')}</Heading>
            <Button
              classes={curr => ({
                ...curr,
                container: classNames(curr.container, style.marginLeftAuto),
              })}
              styleVariant="superprimary"
              onClick={() => showCRJModal(appStore.actionModalStore, manageInventoryStore)}
            >
              {t('manageInventory.createReportJob')}
            </Button>
            {isUpdateNodesEnabled && (
              <Button
                classes={curr => ({
                  ...curr,
                  container: classNames(curr.container, style.marginLeft10px),
                })}
                styleVariant="superprimary"
                onClick={() => showCNRJModal(appStore.actionModalStore, manageInventoryStore)}
              >
                {t('manageInventory.createJob')}
              </Button>
            )}
            {isImportNodesEnabled && (
              <Button
                classes={curr => ({
                  ...curr,
                  container: classNames(curr.container, style.marginLeft10px),
                })}
                styleVariant="superprimary"
                onClick={() => showNodeImportModal(appStore.actionModalStore, manageInventoryStore)}
              >
                {t('manageInventory.importNodesJob')}
              </Button>
            )}
          </div>

          <SearchInputField
            id={'jobSearchString1'}
            min={3}
            onInput={e => {
              setSearchString(e.currentTarget.value || '')
              setTablePage(0)
            }}
            placeholder={t('common.search')}
            messages={[{ status: 'hint', message: t('manageInventory.searchByJobName') }]}
            value={searchString}
          />
          <div className={style.tableWrapper}>
            <Table
              classes={curr => ({
                ...curr,
                root: classNames(curr.root, style.override, style.tableRoot),
              })}
              truncateCellContent={false}
              noResultsMessage={t('header.noResults')}
              externalSort={true}
              onSortChange={sp => {
                setSortParameters(sp as TableSortArgument)
                setTablePage(0)
              }}
              headerRow={headerRow}
              dataRows={dataRows as any}
            />
          </div>
          <div className={style.paginationWrapper}>
            <Pagination
              classes={curr => ({
                ...curr,
                root: classNames(curr.root, style.paginationCustomization),
              })}
              expandDirection="top"
              currentPage={tablePage}
              onPageSelect={setTablePage}
              totalPageCount={Math.ceil(searchFilteredData?.length / COMPANIES_PER_PAGE)}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  )
})
