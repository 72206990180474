import { action, observable, computed } from 'mobx'
import { AppStore } from 'stores/app-store'
import { errorsMap } from 'constants/errors'
import { showAlert } from 'utils/show-alert'
import { PlumeServiceProductType } from 'enums/plume-product.enum'

type LogoData = {
  originalFile: File
  originalFileUrl: string
  isOriginalFileEdited: boolean
  objectUrl: string
}
export class LogoUploadStore {
  @observable
  isLoadingModal = false

  @observable
  public serverError = ''

  @observable
  public originalFileError = ''

  @observable
  public selectedProduct: PlumeServiceProductType

  @observable
  public logoProductsData: Record<PlumeServiceProductType, LogoData> = {
    HomePass: {
      originalFile: null,
      originalFileUrl: null,
      isOriginalFileEdited: false,
      objectUrl: '',
    },
    WorkPass: {
      originalFile: null,
      originalFileUrl: null,
      isOriginalFileEdited: false,
      objectUrl: '',
    },
  }

  constructor(private readonly appStore: AppStore) {}

  @action.bound
  private setLogoProductsData(logoProductData: Record<PlumeServiceProductType, LogoData>) {
    this.logoProductsData = logoProductData
  }

  @action setLogoData(logoData: Partial<LogoData>, product?: PlumeServiceProductType) {
    this.setLogoProductsData({
      ...this.logoProductsData,
      [product || this.selectedProduct]: {
        ...this.logoProductsData[product || this.selectedProduct],
        ...logoData,
      },
    })
  }

  @action setSelectedProduct(product: PlumeServiceProductType) {
    this.selectedProduct = product
  }

  @action selectProduct(product: PlumeServiceProductType) {
    this.selectedProduct = product
    this.originalFileError = ''
    this.serverError = ''
  }

  @action
  private setInitialProduct() {
    const { profiles } = this.appStore.authStore.currentUser.company
    if (profiles?.includes('HomePass')) {
      this.setSelectedProduct('HomePass')
    } else if (profiles?.includes('WorkPass')) {
      this.setSelectedProduct('WorkPass')
    }
  }

  @action
  public async initLogoUploadStore() {
    try {
      this.setIsLoadingModal(true)
      this.setInitialProduct()
      const { partnerId } = this.appStore.authStore.currentUser.company
      const fileUrls = await this.appStore.companyApi.getAppLogoUrl(partnerId)
      this.setOriginalLogoData(fileUrls.HomePass, 'HomePass')
      this.setOriginalLogoData(fileUrls.WorkPass, 'WorkPass')
      this.setIsLoadingModal(false)
    } catch (e) {
      this.removeModal()

      showAlert({
        title: 'errors.somethingWentWrong',
        message: 'errors.errorFetchingLogoUpload',
        buttonText: 'btn.gotIt',
        isCloseShowing: true,
        onAcknowledge: () => this.removeModal(),
      })
    }
  }

  @action
  private setIsLoadingModal = (value: boolean) => {
    this.isLoadingModal = value
  }

  @action.bound
  setOriginalLogoData(fileUrl: string, product?: PlumeServiceProductType) {
    this.setLogoData({ originalFileUrl: fileUrl }, product)
  }

  @action
  public async validateAndSubmitAppLogo() {
    this.isLoadingModal = true
    try {
      const formData = new FormData()
      formData.append('file', this.logoProductsData[this.selectedProduct].originalFile)
      formData.append('product', this.selectedProduct)

      await this.appStore.companyApi.uploadAppLogo(
        this.appStore.authStore.currentUser.company.partnerId,
        formData,
      )
      this.handleAppLogoUploadSuccess()
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action
  async handleAppLogoUploadSuccess() {
    this.removeModal()
  }

  @action.bound
  private async removeModal() {
    this.appStore.actionModalStore.removeModal()
    this.setIsLoadingModal(false)
  }

  @action
  setOriginalFile(file: File) {
    this.clearOriginalFileError()
    this.setLogoData({ originalFile: file, isOriginalFileEdited: true })
  }

  @action
  setObjectUrl(objectUrl: string) {
    this.setLogoData({ objectUrl })
  }

  @action
  removeOriginalFile() {
    this.logoProductsData[this.selectedProduct].originalFile = null
    this.clearOriginalFileError()
    this.setLogoData({ originalFileUrl: '', isOriginalFileEdited: true })
  }

  @action
  public setOriginalFileError(error: string) {
    this.originalFileError = error
  }

  @action
  private clearOriginalFileError() {
    this.originalFileError = ''
  }

  @action.bound
  private handleServerError(error: string) {
    this.serverError = errorsMap[error] ? errorsMap[error] : error
    this.isLoadingModal = false
  }

  @computed
  get originalFileExists() {
    return (
      !!this.logoProductsData[this.selectedProduct].originalFile ||
      !!this.logoProductsData[this.selectedProduct].originalFileUrl
    )
  }

  @computed
  get isUrlPdf() {
    return this.logoProductsData[this.selectedProduct].originalFileUrl
      ? this.logoProductsData[this.selectedProduct].originalFileUrl
          .substr(0, this.logoProductsData[this.selectedProduct].originalFileUrl.lastIndexOf('?'))
          .split('.')
          .pop()
          .toLocaleLowerCase() === 'pdf'
      : false
  }
}
