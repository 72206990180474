import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import 'style/global.css'
import { isBrowserSupported } from 'helpers/browser-detect'
import { UnsupportedBrowser } from 'components/unsupported-browser/unsupported-browser'
import './utils/i18n/i18n'
import { Exception500 } from 'components/exception/500'
import { AppStore } from 'stores/app-store'

const portalEnv = process.env.PORTAL_ENV
const version = process.env.PORTAL_VERSION

const versionCommentNode = document.createComment(`Plume Partner Portal v${version}`)
document.head.appendChild(versionCommentNode)

const rootNode = document.createElement('div')
rootNode.setAttribute('id', 'root')
document.body.appendChild(rootNode)

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  release: `v${version}`,
  integrations: [new Integrations.BrowserTracing()],
  environment: portalEnv,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.2,
})

export const StoreContext = React.createContext(null)
export const StoreProvider = StoreContext.Provider
export const appStoreContainer: { appStore?: AppStore } = {}

const SentryFallback = () => {
  return <Exception500 statusCode={null} />
}

if (!isBrowserSupported()) {
  ReactDOM.render(<UnsupportedBrowser />, rootNode)
} else {
  const { Provider } = require('mobx-react')
  const { Router } = require('react-router-dom')
  const { configure } = require('mobx')
  const { App } = require('app')
  const { AppStore } = require('./stores/app-store')
  const { QueryClientProvider, QueryClient } = require('react-query')

  const queryClient = new QueryClient()

  configure({
    enforceActions: 'observed',
  })

  const appStore = new AppStore()
  appStoreContainer.appStore = appStore

  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <Provider appStore={appStore}>
        <StoreProvider value={appStore}>
          <Router history={appStore.history}>
            <Sentry.ErrorBoundary fallback={SentryFallback} showDialog>
              <React.Suspense fallback={null}>
                <App />
              </React.Suspense>
            </Sentry.ErrorBoundary>
          </Router>
        </StoreProvider>
      </Provider>
    </QueryClientProvider>,
    rootNode,
  )
}
