import * as React from 'react'
import style from './home.css'
import { DashboardLayout } from '../common/dashboard-layout'
import { Loader } from 'components/loader'
import { Redirect } from 'react-router-dom'
import { TrialExpiredOrTerminated } from 'modules/account-setup/trial-expired-or-terminated/trial-expired-or-terminated'
import { useTranslation } from 'react-i18next'
import { Nda } from 'modules/account-setup/nda/nda'
import { observer } from 'mobx-react-lite'
import { useStores } from 'utils/hooks/useStores'
import { isTerminated } from 'helpers/company-helpers'
import { AccountInfo } from './account-info'
import { HomeData } from './home-data'
import { useEffect } from 'react'
import { mixpanelActions } from 'utils/mixpanelActions'

export const DashboardHome = observer(() => {
  const { t } = useTranslation()
  const appStore = useStores()
  const { authStore } = appStore
  const { currentUser } = authStore

  useEffect(() => {
    authStore.update()

    mixpanelActions.track('Pageview - Home', {
      'Page name': 'Home',
      'Partner Id': currentUser?.company?.partnerId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!authStore.currentUser) {
    return <Loader />
  }

  if (!authStore.currentUser.company.signedNdaTimestamp) {
    return <Nda />
  }

  if (!authStore.currentUser.featureAccessRightsArr.includes('HOME_DASHBOARD')) {
    return <Redirect to={`/missing-access`} />
  }

  if (
    !appStore?.homeStore?.data ||
    appStore?.homeStore?.data?.id !== currentUser?.company?.partnerId
  ) {
    return (
      <DashboardLayout>
        <Loader theme="small" rootClassName={style.loaderRoot} />
      </DashboardLayout>
    )
  }

  if (!authStore.currentUser.company.signedNdaTimestamp) {
    return <Redirect to="/nda" />
  }

  const { hasUserAccess } = appStore.accountSetupStore
  const hasAccess = hasUserAccess(window.location.pathname, currentUser)

  const isTrialExpiredOrTerminated = !currentUser?.isEmployee && isTerminated(currentUser?.company)

  return hasAccess ? (
    <React.Fragment>
      {isTrialExpiredOrTerminated && <TrialExpiredOrTerminated />}
      <DashboardLayout>
        <div className={style.root}>
          <div className={style.headerTitle}>{t('home.title')}</div>
          <AccountInfo />
          <HomeData />
        </div>
      </DashboardLayout>
    </React.Fragment>
  ) : (
    <Redirect
      to={{
        pathname: '/missing-access',
        state: { from: location },
      }}
    />
  )
})
