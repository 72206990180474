import * as React from 'react'
import style from './text-area.css'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string
  error?: string | boolean
  hasBeenValidated?: boolean
}

export const TextArea = (props: TextAreaProps) => {
  const { t } = useTranslation()
  const { className, label, error, hasBeenValidated, ...otherProps } = props
  const invalid = hasBeenValidated && !!error
  const valid = hasBeenValidated && !error

  return (
    <div
      className={classNames(style.root, className, valid && style.valid, invalid && style.invalid)}
    >
      <label className={style.label} htmlFor={label}>
        {label}
      </label>
      <textarea id={label} maxLength={400} {...otherProps} />
      {typeof error === 'string' && error !== '' && (
        <div className={style.error}>{t([`errors.${error}`, 'errors.somethingWentWrong'])}</div>
      )}
    </div>
  )
}
