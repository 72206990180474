import * as React from 'react'
import { Alert } from 'components/alert'
import { AlertReturnValue } from 'interfaces/utils/alert'
import style from 'components/alert/alert.css'
import { appStoreContainer } from 'index'

interface IAlertOptions {
  title: string
  message: React.ReactNode
  skipI18n?: boolean
  buttonText?: string
  isCloseShowing?: boolean
  onAcknowledge?: () => void | Promise<void>
  onClose?: () => Promise<void>
}

/**
 * Shows alert by adding the alert modal to the modal store
 *
 * If you wish to have different actions for closing the alert and clicking the button
 * please provide both onAcknowledge and onClose parameters
 *
 * If you provide onAcknowledge only,
 * onAcknowledge will be used for both clicking the button and closing the alert
 *
 * If you wish onClose to do nothing:
 * onClose: () => undefined
 */
export const showAlert = async ({
  title,
  message,
  skipI18n,
  buttonText = 'OK',
  isCloseShowing = true,
  onAcknowledge,
  onClose,
}: IAlertOptions) => {
  const modalStore = appStoreContainer.appStore.actionModalStore

  return new Promise<AlertReturnValue>(res => {
    const handleButtonClick = async (closeButton: AlertReturnValue) => {
      modalStore.removeModal()
      onAcknowledge && onAcknowledge()
      return res(closeButton)
    }

    const handleClose = async (closeButton: AlertReturnValue) => {
      modalStore.removeModal()
      // if onAcknowledge is provided without onClose, onAcknowledge will be used for closing
      onClose ? onClose() : onAcknowledge && onAcknowledge()
      return res(closeButton)
    }

    modalStore.addModal(
      <div
        className={!!modalStore.modals.length ? style.darkenBackground : undefined}
        key={modalStore.getKey()}
      >
        <Alert
          title={title}
          message={message}
          skipI18n={skipI18n}
          buttonText={buttonText}
          isCloseShowing={isCloseShowing}
          onButtonClick={() => handleButtonClick(AlertReturnValue.ActionButton)}
          onClose={() => handleClose(AlertReturnValue.CloseButton)}
        />
      </div>,
    )
  })
}
