import * as React from 'react'
import { Close } from '../../../../components/icons/index'
import style from './confirm-delete.css'
import { Button } from 'components/button'
import { observer } from 'mobx-react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Loader } from 'components/loader'

interface ConfirmDeleteProps extends WithTranslation {
  onClose: () => Promise<void>
  onConfirm: () => void
  confirmQuestion: string
  confirmText: string
  accountStore?: any
  isDeletingVariable?: string
}

@observer
class ConfirmDeleteComponent extends React.Component<ConfirmDeleteProps> {
  constructor(props: ConfirmDeleteProps) {
    super(props)
  }

  render() {
    const {
      onClose,
      onConfirm,
      confirmQuestion,
      confirmText,
      accountStore,
      t,
      isDeletingVariable,
    } = this.props
    const isDeleting = isDeletingVariable || 'isDeletingAccount'

    return (
      <div className={style.root}>
        <div className={style.closeButton}>
          <Close onClick={onClose} />
        </div>

        <div className={style.title}>{t(confirmQuestion)}</div>
        <div className={style.subTitle}>{t(confirmText)}</div>

        <div className={style.deleteBtn}>
          {accountStore && accountStore[isDeleting] && <Loader fit />}
          <Button onClick={onConfirm}> {t('btn.delete')}</Button>
        </div>
        <div className={style.cancelBtn}>
          <Button onClick={onClose}> {t('btn.cancel')}</Button>
        </div>
      </div>
    )
  }
}

export const ConfirmDelete = withTranslation()(ConfirmDeleteComponent)
