import { User } from 'models'
import { canAccess } from 'modules/auth/auth-action-permission'

export const canUserClone = (currentUser: User) => {
  const canEditUsers = canAccess('editUsers', currentUser)
  return !currentUser.cloned && canEditUsers && (currentUser.isEmployee || currentUser.canClone)
}

export function sortUserApps<T1 extends { id: string }>(arr1: Array<T1>, arr2: string[]) {
  const index: Record<string, number> = {}

  for (let i = 0; i < arr2.length; i++) {
    if (!index[arr2[i]]) {
      index[arr2[i]] = i + 1
    }
  }

  return arr1.slice().sort((a, b) => {
    const idx1 = index[a.id] || Infinity
    const idx2 = index[b.id] || Infinity

    if (idx1 === Infinity && idx2 === Infinity) {
      return -1
    }

    return idx1 - idx2
  })
}
