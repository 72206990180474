import * as React from 'react'
import style from './edit-release-notes-contact.css'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import { Close } from 'components/icons/index'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { AccountStore, AccountStoreFields } from '../account-store'
import { FieldState } from 'formstate'
import { Loader } from 'components/loader'
import { YesNoRadioButton } from 'components/radio-button/yesno-radio-button/yesno-radio-button'

interface RNContactProps {
  onClose: () => Promise<void>
  accountStore?: AccountStore
}

export const EditReleaseNotesContact = observer((props: RNContactProps) => {
  const { accountStore: store, onClose } = props
  const { t } = useTranslation()
  const company = useStores().authStore.currentUser.company
  const [emailFieldFocused, setEmailFieldFocused] = React.useState(false)

  const { releaseNotesContactEmail, serverError, isLoadingModal, releaseNotesContactEnabled } =
    store

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    store.submitReleaseNotesContactEmail()
  }

  const handleEmailAddressOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { releaseNotesContactEmail, serverError } = store

    if (releaseNotesContactEmail.error) {
      setEmailFieldFocused(false)
    }

    if (!!serverError) {
      store.clearServerError()
    }
    releaseNotesContactEmail.onChange(e.target.value)
  }

  const handleYesNoFieldChange = (field: AccountStoreFields<FieldState<boolean>>) => {
    store.clearServerError()
    store.toggleFieldChanged(field)

    if (!releaseNotesContactEnabled.value) {
      releaseNotesContactEmail.reset('')
      releaseNotesContactEmail.disableAutoValidation()
    } else {
      releaseNotesContactEmail.reset(company.releaseNotesContactEmail)
    }
  }

  const hasNotChanged =
    releaseNotesContactEmail.value === company.releaseNotesContactEmail &&
    releaseNotesContactEnabled.value === company.releaseNotesContactEnabled

  const isRNCEmailEmpty =
    (!!releaseNotesContactEmail.error || !releaseNotesContactEmail.value) &&
    releaseNotesContactEnabled.value

  return (
    <div className={style.root}>
      <div className={style.subTitle}>{t('account.editReleaseNotesContact')}</div>
      <div className={style.labelText}>{t('account.addReleaseNotesContactMessage')}</div>

      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>
      <form onSubmit={handleFormSubmit}>
        <div className={style.formInputs}>
          <YesNoRadioButton
            id="releaseNotesContactEnabled"
            name="releaseNotesContactEnabled"
            checkedValue={releaseNotesContactEnabled.value}
            handleOnFieldChange={handleYesNoFieldChange}
          />
          {releaseNotesContactEnabled.value && (
            <div className={style.releaseNotesFormInput}>
              <Input
                className={style.input}
                label={t('common.email')}
                hasBeenValidated={emailFieldFocused && releaseNotesContactEmail.hasBeenValidated}
                value={releaseNotesContactEmail.value}
                error={(emailFieldFocused && releaseNotesContactEmail.error) || !!serverError}
                onChange={handleEmailAddressOnChange}
                onBlur={e => setEmailFieldFocused(!!e.target.value)}
                disabled={!releaseNotesContactEnabled.value}
              />
            </div>
          )}
        </div>
        {serverError && (
          <div className={style.rncServerError}>{t(`errors.${serverError}`, serverError)}</div>
        )}
        <div className={style.btnDiv}>
          {isLoadingModal && <Loader />}
          <Button
            className={style.sendInviteBtn}
            type="submit"
            disabled={isLoadingModal || hasNotChanged || isRNCEmailEmpty}
          >
            {t('btn.save')}
          </Button>
        </div>
      </form>
    </div>
  )
})
