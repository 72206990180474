import * as React from 'react'
import { Title } from './title'
import style from './user.css'
import { UserInfo } from 'modules/dashboard/user/user-info'
import { ResetUserPassword } from 'modules/dashboard/user/reset-user-password'
import { AddUserReturnValue } from 'interfaces/utils/add-user'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { inject, observer } from 'mobx-react'
import { DashboardUsersStore } from 'modules/dashboard/user/user-store'
import { IUser } from 'interfaces/api/portal/user-api'
import { Loader } from 'components/loader'
import { DashboardLayout } from '../common/dashboard-layout'
import { AuthStore } from 'stores/auth-store'
import { AppStore } from 'stores/app-store'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { showAlert } from 'utils/show-alert'
import { TrialExpiredOrTerminated } from 'modules/account-setup/trial-expired-or-terminated/trial-expired-or-terminated'
import classNames from 'classnames'
import { UsersTab } from './users-tab'
import { UserSearchResult } from './search/user-search-result'
import { Export } from 'modules/export/export'
import { withTranslation, WithTranslation } from 'react-i18next'
import { when } from 'mobx'
import { CloneUserModal } from './clone-user-modal'
import { ErrorScreen } from 'components/error-screen'
import { CloneUserProgressModal } from './clone-user-progress-modal/clone-user-progress-modal'
import { isTerminated } from 'helpers/company-helpers'
import { Button, SearchInputField, Box, notify, Toggler, Icons } from 'plume-ui'
import { AddEditUser } from './add-user/add-edit-user'
import { canUserClone } from 'helpers/user-helpers'
import { mixpanelActions } from 'utils/mixpanelActions'
import { CsvPercentageRing } from './csv-percentage-ring'

export const showAddUserModal = async (
  actionModalStore: ModalLayerStore,
  usersStore: DashboardUsersStore,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      usersStore.clearFormState()
      actionModalStore.removeModal()
      return res(closeButton)
    }
    usersStore.initAddEditUserWebSocketEvents()
    actionModalStore.addModal(
      <AddEditUser
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        action="add"
      />,
    )
  })
}

export const showCloneUserModal = async (
  actionModalStore: ModalLayerStore,
  usersStore: DashboardUsersStore,
  user: IUser,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      usersStore.clearCloneUserForm()
      actionModalStore.removeModal()
      return res(closeButton)
    }
    usersStore.fillCloneUserForm(user)
    actionModalStore.addModal(
      <CloneUserModal
        key={actionModalStore.getKey()}
        onClose={() => {
          usersStore.fetchUsers()

          return onClose(AddUserReturnValue.CloseButton)
        }}
      />,
    )
  })
}

export const showCloneUserProgressModal = async (actionModalStore: ModalLayerStore) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      actionModalStore.removeModal()
      return res(closeButton)
    }
    actionModalStore.addModal(
      <CloneUserProgressModal
        key={actionModalStore.getKey()}
        onClose={() => {
          return onClose(AddUserReturnValue.CloseButton)
        }}
      />,
    )
  })
}

export const showEditUserModal = async (
  actionModalStore: ModalLayerStore,
  usersStore: DashboardUsersStore,
  user: IUser,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      usersStore.clearFormState()
      actionModalStore.removeModal()
      return res(closeButton)
    }
    usersStore.initAddEditUserWebSocketEvents()
    usersStore.fillEditForm(user)
    actionModalStore.addModal(
      <AddEditUser
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        action="edit"
      />,
    )
  })
}

export const showUserInfoModal = async (
  actionModalStore: ModalLayerStore,
  usersStore: DashboardUsersStore,
  user: IUser,
) => {
  return new Promise<AddUserReturnValue>(async res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      usersStore.clearUserInfoFields()
      actionModalStore.removeModal()
      return res(closeButton)
    }
    await usersStore.fillUserInfoFields(user)
    actionModalStore.addModal(
      <UserInfo
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
      />,
    )
  })
}

export const showResetPasswordModal = async (
  actionModalStore: ModalLayerStore,
  usersStore: DashboardUsersStore,
  user: IUser,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      usersStore.clearResetPasswordForm()
      actionModalStore.removeModal()
      return res(closeButton)
    }
    usersStore.fillResetPasswordForm(user)
    actionModalStore.addModal(
      <ResetUserPassword
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
      />,
    )
  })
}

interface IDashboardUsersParams {
  partnerId: string
}

interface IDashboardUserProps extends RouteComponentProps<IDashboardUsersParams>, WithTranslation {
  appStore?: AppStore
  actionModalStore?: ModalLayerStore
  authStore?: AuthStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  appStore,
  actionModalStore: appStore.actionModalStore,
  authStore: appStore.authStore,
}))
@observer
class DashboardUsersComponent extends React.Component<IDashboardUserProps> {
  constructor(props: IDashboardUserProps) {
    super(props)
    const {
      match: {
        params: { partnerId },
      },
    } = this.props

    this.props.authStore.update(partnerId)

    when(
      () => !!this.props.authStore.currentUser && !!this.props.authStore.currentUser.company,
      () => {
        if (this.props.appStore.usersStore.users.length === 0) {
          this.props.appStore.usersStore.fetchUsers()
        }
      },
    )
  }

  componentDidMount() {
    const partnerId = this.props.authStore.currentUser?.company?.partnerId

    mixpanelActions.track('Pageview - Users', { 'Page name': 'Users', 'Partner Id': partnerId })
  }

  componentWillUnmount() {
    this.props.appStore.usersStore?.handleEndSearch()
  }

  handleAddUser = () => {
    const partnerId = this.props.authStore.currentUser?.company?.partnerId

    mixpanelActions.track('Users - Add a user', { 'Partner Id': partnerId })
    showAddUserModal(this.props.actionModalStore, this.props.appStore.usersStore)
  }

  handleEditUser = (user: any) => {
    const partnerId = this.props.authStore.currentUser?.company?.partnerId

    mixpanelActions.track('Users - Edit user', { 'Partner Id': partnerId })
    showEditUserModal(this.props.actionModalStore, this.props.appStore.usersStore, user)
  }

  handleUserInfo = (user: any) => {
    const partnerId = this.props.authStore.currentUser?.company?.partnerId

    mixpanelActions.track('Users - User Info', { 'Partner Id': partnerId })
    showUserInfoModal(this.props.actionModalStore, this.props.appStore.usersStore, user)
  }

  handleResetPassword = (user: any) => {
    const partnerId = this.props.authStore.currentUser?.company?.partnerId

    mixpanelActions.track('Users - Reset Password', { 'Partner Id': partnerId })
    showResetPasswordModal(this.props.actionModalStore, this.props.appStore.usersStore, user)
  }

  handleCloneUser = (user: any) => {
    const partnerId = this.props.authStore.currentUser?.company?.partnerId

    mixpanelActions.track('Users - Clone User', { 'Partner Id': partnerId })
    showCloneUserModal(this.props.actionModalStore, this.props.appStore.usersStore, user)
  }

  handleReactivateUser = async (userId: string) => {
    const { t } = this.props
    await this.props.appStore.usersStore.reactivateUser(userId)
    showAlert({
      title: t('users.activationEmailResent'),
      message: '',
      buttonText: t('btn.close'),
      isCloseShowing: false,
    })
  }

  handleUnlockUser = async (userId: string) => {
    const { t } = this.props
    await this.props.appStore.usersStore.unlockUser(userId)
    showAlert({
      title: t('users.unlockedUser'),
      message: '',
      buttonText: t('btn.close'),
      isCloseShowing: false,
    })
  }

  renderInvitedUsers(visible: boolean) {
    const { invitedUsers, isUserItemLoading } = this.props.appStore.usersStore
    const { t } = this.props
    return (
      <div className={classNames({ [style.tabHidden]: !visible })}>
        {invitedUsers.length > 0 ? (
          <UsersTab
            users={invitedUsers}
            handleEditUser={this.handleEditUser}
            handleReactivateUser={this.handleReactivateUser}
            isUserItemLoading={isUserItemLoading}
            handleResetPassword={this.handleResetPassword}
            type={'invited'}
          />
        ) : (
          <div className={style.noUsers}>{t('users.noInvitedUsers')}</div>
        )}
      </div>
    )
  }

  renderActiveUsers(visible: boolean) {
    const { currentUser } = this.props.authStore

    const { activeUsers } = this.props.appStore.usersStore
    const canClone = canUserClone(currentUser)

    const { t } = this.props
    return (
      <div className={classNames({ [style.tabHidden]: !visible })}>
        {activeUsers.length > 0 ? (
          <UsersTab
            users={activeUsers}
            handleEditUser={this.handleEditUser}
            handleUserInfo={this.handleUserInfo}
            handleResetPassword={this.handleResetPassword}
            handleCloneUser={canClone && this.handleCloneUser}
            type={'active'}
          />
        ) : (
          <div className={style.noUsers}>{t('users.noActiveUsers')}</div>
        )}
      </div>
    )
  }

  renderClonedUsers(visible: boolean) {
    const { clonedUsers } = this.props.appStore.usersStore

    return (
      clonedUsers.length > 0 && (
        <div className={classNames({ [style.tabHidden]: !visible })}>
          <UsersTab
            users={clonedUsers}
            handleResetPassword={this.handleResetPassword}
            handleUserInfo={this.handleUserInfo}
            handleEditUser={this.handleEditUser}
            type={'cloned'}
          />
        </div>
      )
    )
  }

  renderLockedOutUsers(visible: boolean) {
    const { lockedUsers, isUserItemLoading } = this.props.appStore.usersStore
    return (
      lockedUsers.length > 0 && (
        <div className={classNames({ [style.tabHidden]: !visible })}>
          <UsersTab
            users={lockedUsers}
            handleEditUser={this.handleEditUser}
            handleUserInfo={this.handleUserInfo}
            handleUnlockUser={this.handleUnlockUser}
            isUserItemLoading={isUserItemLoading}
            type={'locked'}
          />
        </div>
      )
    )
  }

  renderSuspendedUsers(visible: boolean) {
    const { suspendedUsers } = this.props.appStore.usersStore

    return (
      suspendedUsers.length > 0 && (
        <div className={classNames({ [style.tabHidden]: !visible })}>
          <UsersTab
            users={suspendedUsers}
            handleEditUser={this.handleEditUser}
            handleUserInfo={this.handleUserInfo}
            type={'suspended'}
          />
        </div>
      )
    )
  }

  renderDeactivatedUsers(visible: boolean) {
    const { deactivatedUsers } = this.props.appStore.usersStore

    return (
      deactivatedUsers.length > 0 && (
        <div className={classNames({ [style.tabHidden]: !visible })}>
          <UsersTab
            users={deactivatedUsers}
            handleEditUser={this.handleEditUser}
            handleUserInfo={this.handleUserInfo}
            type={'deactivated'}
          />
        </div>
      )
    )
  }

  renderExpiredPasswordUsers(visible: boolean) {
    const { expiredPasswordUsers } = this.props.appStore.usersStore

    return (
      expiredPasswordUsers.length > 0 && (
        <div className={classNames({ [style.tabHidden]: !visible })}>
          <UsersTab
            users={expiredPasswordUsers}
            handleEditUser={this.handleEditUser}
            handleResetPassword={this.handleResetPassword}
            handleUserInfo={this.handleUserInfo}
            type={'expired'}
          />
        </div>
      )
    )
  }

  handleTabClick = async (newTab: number) => {
    this.props.appStore.usersStore.setCurrentTab(newTab)
  }

  handleSearch = (value: string) => {
    const partnerId = this.props.authStore.currentUser?.company?.partnerId

    mixpanelActions.track('Users - Search', {
      term: value,
      'Partner Id': partnerId,
    })
    this.props.appStore.usersStore.searchInput.onChange(value)
    if (value && value.length > 2) {
      this.props.appStore.usersStore.handleSearch(value)
    } else {
      this.props.appStore.usersStore.handleEndSearch()
    }
  }

  handleUserExport = () => {
    const { t } = this.props
    const partnerId = this.props.authStore.currentUser?.company?.partnerId

    mixpanelActions.track('Users - Export Users', {
      'Partner Id': partnerId,
    })

    if (!this.props.appStore.usersStore.usersExportProgress) {
      this.props.appStore.usersStore.initUserExport()
      const toastId = notify({
        title: t('users.exportNotifyHeading'),
        body: t('users.exportNotifyContent'),
        icon: <CsvPercentageRing />,
        mustDismiss: true,
        showDismiss: false,
      })
      this.props.appStore.usersStore.setUsersExportToastId(toastId)
    }
  }

  render() {
    const { currentUser } = this.props.authStore
    if (!this.props.authStore.currentUser || !this.props.appStore.usersStore) {
      return <Loader />
    }

    if (currentUser.isEmployee && !window.localStorage.getItem('selectedEmployeeCompany')) {
      return <Redirect to="/select-partner" />
    }

    if (!this.props.appStore.authStore.currentUser.company) {
      return <Loader />
    }

    const { t } = this.props
    const { hasUserAccess } = this.props.appStore.accountSetupStore
    const hasAccess = hasUserAccess(
      window.location.pathname,
      this.props.appStore.authStore.currentUser,
    )

    const {
      users,
      isLoading,
      isSearchActive,
      isUserItemLoading,
      usersError,
      setNotificationUserColumnsShown,
      notificationUserColumnsShown,
      currentTab,
      setCurrentTab,
    } = this.props.appStore.usersStore

    const isTrialExpiredOrTerminated =
      !currentUser?.isEmployee && isTerminated(currentUser?.company)
    // restricting add-user from standard users.
    // restricting add-user from admins with non NDA signed company.
    // const adminUserAndNotNDASigned =
    //   !this.props.authStore.currentUser.company.signedNdaTimestamp &&
    //   currentUserRole === PortalRole.admin
    // const addUserRestricted = standardUser || adminUserAndNotNDASigned
    const canClone = canUserClone(currentUser)

    const activeUserCount = this.props.appStore.usersStore.activeUsers.length
    const invitedUserCount = this.props.appStore.usersStore.invitedUsers.length
    const suspendedUserCount = this.props.appStore.usersStore.suspendedUsers.length
    const deactivatedUsersCount = this.props.appStore.usersStore.deactivatedUsers.length
    const lockedUserCount = this.props.appStore.usersStore.lockedUsers.length
    const expiredUserCount = this.props.appStore.usersStore.expiredPasswordUsers.length
    const clonedUserCount = this.props.appStore.usersStore.clonedUsers.length

    const tabOptions = [
      {
        key: 'activeUsers',
        label: `${t(`users.active`)} (${activeUserCount})`,
        content: this.renderActiveUsers(true),
      },
      {
        key: 'invitedUsers',
        label: `${t(`users.invited`)} (${invitedUserCount})`,
        content: this.renderInvitedUsers(true),
      },
    ]

    if (suspendedUserCount > 0) {
      tabOptions.push({
        key: 'suspendedUsers',
        label: `${t(`users.suspended`)} (${suspendedUserCount})`,
        content: this.renderSuspendedUsers(true),
      })
    }
    if (deactivatedUsersCount > 0) {
      tabOptions.push({
        key: 'deactivatedUsers',
        label: `${t(`users.deactivated`)} (${deactivatedUsersCount})`,
        content: this.renderDeactivatedUsers(true),
      })
    }
    if (lockedUserCount > 0) {
      tabOptions.push({
        key: 'lockedUsers',
        label: `${t(`users.locked`)} (${lockedUserCount})`,
        content: this.renderLockedOutUsers(true),
      })
    }
    if (expiredUserCount > 0) {
      tabOptions.push({
        key: 'expiredUsers',
        label: `${t(`users.expired`)} (${expiredUserCount})`,
        content: this.renderExpiredPasswordUsers(true),
      })
    }
    if (canClone && clonedUserCount > 0) {
      tabOptions.push({
        key: 'clonedUsers',
        label: `${t(`users.cloned`)} (${clonedUserCount})`,
        content: this.renderClonedUsers(true),
      })
    }

    if (currentTab >= tabOptions.length) {
      setCurrentTab(0)
    }

    const oc = style.override

    return hasAccess ? (
      <div>
        {isTrialExpiredOrTerminated && <TrialExpiredOrTerminated />}
        <DashboardLayout currentUser={this.props.authStore.currentUser}>
          {isLoading ? (
            <Loader theme="small" rootClassName={style.loaderRoot} />
          ) : (
            <div className={style.userRoot}>
              {usersError && !isLoading && <ErrorScreen />}
              <div className={style.sectionRoot}>
                <Title title={t('users.headerTitle')} errorMode={usersError} />
                <div className={style.addUserButton}>
                  <Button styleVariant="superprimary" onClick={this.handleAddUser}>
                    {t('btn.addUser')}
                  </Button>
                </div>
              </div>
              <div className={style.sectionRoot}>
                <SearchInputField
                  min={3}
                  onInput={e => this.handleSearch(e.currentTarget.value)}
                  placeholder={t('users.searchPlaceholder')}
                  messages={[{ status: 'hint', message: t('users.searchHint') }]}
                  value={this.props.appStore.usersStore.searchInput.value}
                />
              </div>

              {isSearchActive ? (
                <UserSearchResult
                  handleEditUser={this.handleEditUser}
                  isUserItemLoading={isUserItemLoading}
                  handleReactivateUser={this.handleReactivateUser}
                  handleResetPassword={this.handleResetPassword}
                  handleUnlockUser={this.handleUnlockUser}
                  handleCloneUser={this.handleCloneUser}
                  handleUserInfo={this.handleUserInfo}
                />
              ) : (
                <div {...(usersError ? { className: style.userErrorMode } : {})}>
                  <div className={style.userStatusFilterWrapper}>
                    <Toggler
                      type={'togglerTabs'}
                      value={tabOptions[currentTab]?.key}
                      toggleElements={tabOptions}
                      onToggle={te => setCurrentTab(tabOptions.findIndex(to => to.key === te.key))}
                    />

                    <Button
                      classes={curr => ({
                        ...curr,
                        container: classNames(curr.container, oc, style.userColumnSwitcher),
                        root: classNames(curr.root, oc, style.userColumnSwitcherButton),
                      })}
                      styleVariant="primary"
                      onClick={() => setNotificationUserColumnsShown(!notificationUserColumnsShown)}
                    >
                      <Icons.FilterIcon className={style.filterIconSize} />
                      {notificationUserColumnsShown
                        ? t('users.showUserDetails')
                        : t('users.showEmailAlerts')}
                    </Button>
                  </div>
                  <div>{tabOptions[currentTab]?.content}</div>

                  {users.length < 2 ? (
                    <div>
                      <div className={style.lonelyText}>{t('users.lonelyText')}</div>
                      <div className={style.lonelyTextDesc}>{t('users.lonelyTextDesc')}</div>
                      <div className={style.lonelyBtnWrapper}>
                        <span onClick={this.handleAddUser}>{t('btn.inviteUser')}</span>
                      </div>
                    </div>
                  ) : (
                    <Export onClick={this.handleUserExport} />
                  )}
                </div>
              )}
              {!this.props.authStore.currentUser.company.signedNdaTimestamp && (
                <Box classes={curr => ({ root: classNames(curr.root, style.ndaBoxStyle) })}>
                  {t('nda.message')}
                </Box>
              )}
            </div>
          )}
        </DashboardLayout>
      </div>
    ) : (
      <Redirect
        to={{
          pathname: '/missing-access',
          state: { from: this.props.location },
        }}
      />
    )
  }
}

export const DashboardUsers = withTranslation()(DashboardUsersComponent)
