import { IDashboardHomeStore } from 'interfaces/stores/dashboard-home-store'
import { observable, action, runInAction, computed, when } from 'mobx'
import moment = require('moment')
import universalCookie from 'universal-cookie'
import { IDashboardApi } from 'interfaces/api/plume-cloud/dashboard-api'
import { DashboardApi } from 'api/plume-cloud/dashboard-api'
import { AuthStore } from 'stores/auth-store'
import { IDashboardData } from 'models/home/dashboard-data'
import { errorsMap } from 'constants/errors'
import { mixpanelActions } from 'utils/mixpanelActions'

const getCloudEnvUrl = (env: string) => {
  switch (env) {
    case 'Gamma':
      return 'https://piranha-gamma.prod.us-west-2.aws.plumenet.io/'
      break
    case 'Kappa':
      return 'https://piranha.eu-central-1.prod.kappa.plumenet.io/'
      break
    case 'Iota':
      return 'https://piranha.apne1.prod.iota.plumenet.io/'
      break
    case 'CI':
      return 'https://piranha-ci.dev.us-west-2.aws.plume.tech/'
      break
    default:
      return ''
  }
}

export class DashboardHomeStore implements IDashboardHomeStore {
  @observable
  public isLoading?: boolean

  @observable
  public data?: IDashboardData

  @observable
  public initialOpen: boolean

  @observable
  public serverError = ''

  private cookies: any

  private dashboardApi: IDashboardApi

  constructor(private readonly authStore: AuthStore) {
    when(
      () => !!this.authStore.currentUser.company,
      () => {
        this.getDashboardData()
      },
    )
    const cookies = new universalCookie()
    this.cookies = cookies
    this.initialOpen = cookies.get('initialOpen')
  }

  @action
  public toggleCollapse(name: 'locations' | 'nodes' | 'devices') {
    if (this && this.data && this.data[name]) {
      this.data[name].isExpanded = !this.data[name].isExpanded
    }
  }

  private getMonthlyValues(data: Partial<IDashboardData>) {
    const locations: Partial<IDashboardData['monthly']['locations']> = {}
    const nodes: Partial<IDashboardData['monthly']['nodes']> = {}
    const devices: Partial<IDashboardData['monthly']['devices']> = {}

    locations.online = []
    locations.offline = []
    nodes.online = []
    nodes.offline = []
    devices.online = []
    devices.offline = []

    let foundFirstActiveMonth = false

    for (let i = 6; i >= 1; i -= 1) {
      const firstOfMonth = moment().utc().subtract(i, 'month').startOf('month')
      const lastOfMonth = moment().utc().subtract(i, 'month').endOf('month')

      const month = firstOfMonth.format('MMM').toUpperCase()
      const locationsActive = data.locations.daily.online.filter(
        item =>
          moment(item.ts).isSameOrAfter(firstOfMonth) &&
          moment(item.ts).isSameOrBefore(lastOfMonth),
      )
      if (locationsActive.length) {
        foundFirstActiveMonth = true
      }

      if (locationsActive.length || foundFirstActiveMonth) {
        locations.online.push({
          month,
          value: locationsActive.length ? locationsActive[locationsActive.length - 1].val : 0,
        })
      }

      const locationsOffline = data.locations.daily.offline.filter(
        item =>
          moment(item.ts).isSameOrAfter(firstOfMonth) &&
          moment(item.ts).isSameOrBefore(lastOfMonth),
      )

      if (locationsOffline.length || foundFirstActiveMonth) {
        locations.offline.push({
          month,
          value: locationsOffline.length ? locationsOffline[locationsOffline.length - 1].val : 0,
        })
      }

      const nodesActive = data.nodes.daily.online.filter(
        item =>
          moment(item.ts).isSameOrAfter(firstOfMonth) &&
          moment(item.ts).isSameOrBefore(lastOfMonth),
      )

      if (nodesActive.length || foundFirstActiveMonth) {
        nodes.online.push({
          month,
          value: nodesActive.length ? nodesActive[nodesActive.length - 1].val : 0,
        })
      }

      const nodesOffline = data.nodes.daily.offline.filter(
        item =>
          moment(item.ts).isSameOrAfter(firstOfMonth) &&
          moment(item.ts).isSameOrBefore(lastOfMonth),
      )

      if (nodesOffline.length || foundFirstActiveMonth) {
        nodes.offline.push({
          month,
          value: nodesOffline.length ? nodesOffline[nodesOffline.length - 1].val : 0,
        })
      }

      const devicesActive = data.devices.daily.online.filter(
        item =>
          moment(item.ts).isSameOrAfter(firstOfMonth) &&
          moment(item.ts).isSameOrBefore(lastOfMonth),
      )

      if (devicesActive.length || foundFirstActiveMonth) {
        devices.online.push({
          month,
          value: devicesActive.length ? devicesActive[devicesActive.length - 1].val : 0,
        })
      }
    }

    return {
      locations: {
        online: locations.online,
        offline: locations.offline,
        hidden: locations.online.length < 3,
      },
      nodes: {
        online: nodes.online,
        offline: nodes.offline,
        hidden: nodes.online.length < 3,
      },
      devices: {
        online: devices.online,
        offline: devices.offline,
        hidden: devices.online.length < 3,
      },
    }
  }
  @action
  public async getDashboardData() {
    const { cloudEnvironment, partnerId } = this.authStore.currentUser.company
    this.dashboardApi = new DashboardApi(getCloudEnvUrl(cloudEnvironment), this.authStore.idToken)

    this.isLoading = true
    try {
      const data = await this.dashboardApi.getDashboardData(partnerId)

      const emptyHomes = this.checkIsEmpty(data)
      const monthly = this.getMonthlyValues(data)
      const extendedData = data
      extendedData.locations.isExpanded = true
      extendedData.nodes.isExpanded = true
      extendedData.devices.isExpanded = true
      runInAction(() => {
        this.data = extendedData
        this.data.emptyHomes = emptyHomes
        this.data.monthly = monthly
        this.isLoading = false
      })
    } catch (e) {
      console.log('Error fetching dashboard graph data.', e.message)
      this.setEmptyData(partnerId)
      this.setServerError(e.message)
    }
  }

  @action
  private setServerError(error: string) {
    this.serverError = errorsMap[error] || error
  }

  @action
  private setEmptyData(partnerId: string) {
    const data: Partial<IDashboardData> = {
      id: partnerId,
      emptyHomes: true,
      locations: {
        currentOnline: 0,
        currentOffline: 0,
        isExpanded: true,
        daily: {
          online: [],
          offline: [],
        },
      },
      nodes: {
        currentOnline: 0,
        currentOffline: 0,
        isExpanded: true,
        daily: {
          online: [],
          offline: [],
        },
      },
      devices: {
        currentOnline: 0,
        isExpanded: true,
        daily: {
          online: [],
        },
      },
    }
    const monthly = this.getMonthlyValues(data)
    const emptyData: IDashboardData = {
      monthly,
      id: data.id,
      emptyHomes: data.emptyHomes,
      locations: data.locations,
      nodes: data.nodes,
      devices: data.devices,
    }
    this.data = emptyData
  }

  private checkIsEmpty(data: IDashboardData) {
    let isEmpty = false
    const {
      locations: {
        currentOnline: locOnline,
        currentOffline: locOffline,
        daily: { online: locDOnline, offline: locDOffline },
      },
      nodes: {
        currentOnline: nodesOnline,
        currentOffline: nodesOffline,
        daily: { online: nodesDOnline, offline: nodesDOffline },
      },
      devices: {
        currentOnline: devicesOnline,
        daily: { online: devicesDOnline },
      },
    } = data
    if (
      !locOnline &&
      !locOffline &&
      !nodesOnline &&
      !nodesOffline &&
      !devicesOnline &&
      !locDOnline.length &&
      !locDOffline.length &&
      !nodesDOnline.length &&
      !nodesDOffline.length &&
      !devicesDOnline.length
    ) {
      isEmpty = true
    }

    return isEmpty
  }

  @computed
  public get currentMonthActiveHouseholds() {
    const firstOfMonth = moment().utc().startOf('month')
    const lastOfMonth = moment().utc().endOf('month')

    return this.data.locations.daily.online.filter(
      item =>
        moment(item.ts).isSameOrAfter(firstOfMonth) && moment(item.ts).isSameOrBefore(lastOfMonth),
    )
  }

  @computed
  public get currentMonthOfflineHouseholds() {
    const firstOfMonth = moment().utc().startOf('month')
    const lastOfMonth = moment().utc().endOf('month')

    return this.data.locations.daily.offline.filter(
      item =>
        moment(item.ts).isSameOrAfter(firstOfMonth) && moment(item.ts).isSameOrBefore(lastOfMonth),
    )
  }

  @computed
  public get currentMonthActiveNodes() {
    const firstOfMonth = moment().utc().startOf('month')
    const lastOfMonth = moment().utc().endOf('month')

    return this.data.nodes.daily.online.filter(
      item =>
        moment(item.ts).isSameOrAfter(firstOfMonth) && moment(item.ts).isSameOrBefore(lastOfMonth),
    )
  }

  @computed
  public get currentMonthOfflineNodes() {
    const firstOfMonth = moment().utc().startOf('month')
    const lastOfMonth = moment().utc().endOf('month')

    return this.data.nodes.daily.offline.filter(
      item =>
        moment(item.ts).isSameOrAfter(firstOfMonth) && moment(item.ts).isSameOrBefore(lastOfMonth),
    )
  }

  @computed
  public get currentMonthActiveDevices() {
    const firstOfMonth = moment().utc().startOf('month')
    const lastOfMonth = moment().utc().endOf('month')

    return this.data.devices.daily.online.filter(
      item =>
        moment(item.ts).isSameOrAfter(firstOfMonth) && moment(item.ts).isSameOrBefore(lastOfMonth),
    )
  }

  @computed
  public get currentMonthOfflineDevices() {
    // no offline devices yet
    return [] as any
  }

  @action
  handleInitialOpen = () => {
    this.initialOpen = true
    this.cookies.set('initialOpen', true)

    mixpanelActions.track('Home - Initial opening homes closed', {
      'Partner Id': this.authStore.currentUser?.company?.partnerId,
    })
  }
}
