import { action, observable } from 'mobx'
import { History } from 'history'
import { User } from 'models'
import { ICompany } from 'interfaces/api/portal/company-api'
import { accountSetupStatus } from 'constants/account-setup/account-state'
import { ACCESS_RIGHTS } from 'constants/access-rights'
import { flattenSubordinateCompanies, isActive, isTerminated } from 'helpers/company-helpers'

interface AccountSetupStoreOptions {
  history: History
  currentUser?: User
}

const features = [
  {
    feature: ACCESS_RIGHTS.HOME_DASHBOARD,
    page: '/home',
  },
  { feature: ACCESS_RIGHTS.APPLICATIONS, page: '/applications' },
  { feature: ACCESS_RIGHTS.USERS, page: '/users' },
  {
    feature: ACCESS_RIGHTS.KNOWLEDGE_CENTER,
    page: '/knowledge-center',
  },
]

export class AccountSetupStore {
  @observable
  loading = true

  @observable
  setupStatus = ''

  private history: History

  constructor(private options: AccountSetupStoreOptions) {
    this.history = this.options.history
  }

  @action
  private setLoading(value: boolean) {
    this.loading = value
  }

  public init(currentUser: User) {
    this.setLoading(true)
    this.setupStatus = this.getAccountSetupStatus(currentUser)

    if (this.setupStatus === 'SELECT_PARTNER') {
      this.history.replace('/select-partner')
      return
    }
    if (this.setupStatus === 'SELECT_COMPANY') {
      this.history.replace('/select-company')
      return
    }
    if (this.setupStatus === 'APPLICATIONS') {
      this.history.replace(`/applications`)
      return
    }
    if (this.setupStatus === 'EMPLOYEE_USERS' && !!currentUser?.company?.partnerId) {
      this.history.replace(`/${currentUser?.company?.partnerId}/users`)
      return
    }
    if (this.setupStatus === 'SIGN NDA') {
      this.history.replace('/nda')
      return
    }
    if (
      this.setupStatus === accountSetupStatus.Active ||
      this.setupStatus === accountSetupStatus.Terminated
    ) {
      const landingPage = this.getPartnerLandingPage(currentUser)

      if (!landingPage) {
        this.history.replace('/missing-access')
      } else {
        const feature = features.find(f => f.feature === landingPage)

        if (feature) {
          const path = this.getPath(feature, currentUser.company)
          this.history.replace(path)
        } else {
          this.history.replace('/missing-company')
        }
      }
    } else {
      this.history.replace('/knowledge-center')
    }

    this.setLoading(false)
  }

  public hasUserAccess(path: string, user: User) {
    if (user.isEmployee) {
      return true
    }
    const aR = features.find(f => path.includes(f.page))
    return user.featureAccessRightsArr.includes(aR.feature)
  }

  private getAccountSetupStatus(currentUser: User) {
    const selectedEmployeeCompany = window.localStorage.getItem('selectedEmployeeCompany')
    const selectedCompany = window.localStorage.getItem('selectedCompany')

    if (currentUser.isEmployee && selectedEmployeeCompany) {
      return 'EMPLOYEE_USERS'
    }
    if (currentUser.isEmployee && !selectedEmployeeCompany) {
      return 'SELECT_PARTNER'
    }
    if (!!currentUser.idp_role) {
      return 'APPLICATIONS'
    }
    if (!currentUser.isEmployee) {
      const companyCount = currentUser.companies && currentUser.companies.length
      const subordinateCompanies =
        companyCount >= 1 ? flattenSubordinateCompanies(currentUser.companies) : []

      if (
        (companyCount > 1 && !selectedCompany) ||
        (subordinateCompanies.length >= 1 && !selectedCompany)
      ) {
        return 'SELECT_COMPANY'
      }
      if (!currentUser.company.signedNdaTimestamp) {
        return 'SIGN NDA'
      }
      if (isActive(currentUser.company)) {
        return accountSetupStatus.Active
      }
      if (isTerminated(currentUser.company)) {
        return accountSetupStatus.Terminated
      }
    }

    return ''
  }

  private getPartnerLandingPage(currentUser: User) {
    return !!currentUser?.featureAccessRightsArr?.includes(ACCESS_RIGHTS.HOME_DASHBOARD)
      ? ACCESS_RIGHTS.HOME_DASHBOARD
      : ACCESS_RIGHTS.KNOWLEDGE_CENTER
  }

  private getPath(hsCompanyFeature: any, company: ICompany) {
    if (hsCompanyFeature.feature === 'USERS') {
      return `/${company.partnerId}${hsCompanyFeature.page}`
    }

    return hsCompanyFeature.page
  }
}
