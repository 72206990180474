import * as React from 'react'
import * as companyHelpers from 'helpers/company-helpers'
import { useStores } from 'utils/hooks/useStores'
import { useTranslation } from 'react-i18next'
import { Dot } from 'components/icons'
import style from './account-status.css'
import classNames from 'classnames'

interface AccountStatusProps {
  errorMode?: boolean
  centerAlign?: boolean
}

export const AccountStatus = (props: AccountStatusProps) => {
  const { t } = useTranslation()
  const appStore = useStores()
  const company = appStore?.authStore?.currentUser?.company

  const isActive = companyHelpers.isActive(company)
  const isTerminated = companyHelpers.isTerminated(company)

  const trialStyle = props.centerAlign ? classNames(style.trial, style.center) : style.trial

  return (
    <div>
      {isTerminated && (
        <div className={trialStyle}>
          <span className={style.accountStatus}>{t('header.accountStatus')}:&nbsp;</span>
          <Dot isActive={false} />
          <span className={style.trialWarning}>{t('common.terminated')}</span>
        </div>
      )}
      {isActive && (
        <div className={trialStyle}>
          <span className={style.accountStatus}>{t('header.accountStatus')}:&nbsp;</span>
          <Dot isActive={true} />
          <span className={style.active}>{t('common.active')}</span>
        </div>
      )}
    </div>
  )
}
