import * as React from 'react'
import { observer } from 'mobx-react-lite'
import style from './support-contacts.css'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/button'
import { IUser } from 'interfaces/api/portal/user-api'
import { Input } from 'components/input'
import { useStores } from 'utils/hooks/useStores'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { DashboardUsersStore } from 'modules/dashboard/user/user-store'
import { AddUserReturnValue } from 'interfaces/utils/add-user'
import { FieldState } from 'formstate'
import { AddEditUser } from 'modules/dashboard/user/add-user/add-edit-user'

const showAddUserModal = async (
  actionModalStore: ModalLayerStore,
  usersStore: DashboardUsersStore,
  setSupportContactEmail: (email: string) => void,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      usersStore.clearFormState()
      usersStore.setSupportContactEmail = null
      actionModalStore.removeModal()
      return res(closeButton)
    }
    usersStore.setSupportContactEmail = setSupportContactEmail
    usersStore.initAddEditUserWebSocketEvents()
    actionModalStore.addModal(
      <AddEditUser
        action="add"
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
      />,
    )
  })
}

interface ContactPickerProps {
  emailSearchField: FieldState<string>
  phoneField: FieldState<string>
}

export const ContactPicker = observer((props: ContactPickerProps) => {
  const { emailSearchField, phoneField } = props
  const { value: emailSearch, onChange: setEmailSearch } = emailSearchField
  const { value: phone, onChange: setPhone } = phoneField
  const { t } = useTranslation()
  const appStore = useStores()

  const [focused, setFocused] = React.useState(false)
  const [searchedUsers, setSearchedUsers] = React.useState<IUser[]>(() =>
    appStore.usersStore.users.filter(u => !u.cloned).map(user => ({ ...user })),
  )

  const handleSearch = (term: string) => {
    setEmailSearch(term)
    if (term.length > 2) {
      setSearchedUsers(
        appStore.usersStore.users
          .filter(u => !u.cloned)
          .map(user => ({ ...user }))
          .filter(
            user =>
              user.email.includes(term) || `${user.firstName} ${user.lastName}`.includes(term),
          ),
      )
    } else {
      setSearchedUsers(appStore.usersStore.users.filter(u => !u.cloned).map(user => ({ ...user })))
    }
  }

  const isSelected = (user: IUser) => {
    return user.email === emailSearch
  }

  const selectUser = (user: IUser) => {
    setEmailSearch(user.email)
    setPhone(user.phoneNumber || '')
    setFocused(false)
  }

  const addUserSetEmail = (email: string) => {
    setSearchedUsers(appStore.usersStore.users.filter(u => !u.cloned).map(user => ({ ...user })))
    const selectedUser = appStore.usersStore.users.find(u => u.email === email)
    selectUser(selectedUser)
  }

  const handleAddUser = () => {
    showAddUserModal(appStore.actionModalStore, appStore.usersStore, addUserSetEmail)
  }

  const selectedUser = appStore.usersStore.users
    .filter(u => !u.cloned)
    .find(u => u.email === emailSearch)

  const isUserAllowed = !!selectedUser

  return (
    <div className={style.contactPicker}>
      <div className={style.titleSearch}>
        <input
          className={style.searchInput}
          value={emailSearch}
          placeholder={t('users.searchByNameOrEmail')}
          onChange={e => handleSearch(e.target.value)}
          onClick={_e => setFocused(true)}
          onBlur={_e => setFocused(false)}
        />
        <div className={style.arrowDown} />
      </div>
      {!!emailSearch && (
        <div className={style.valError}>
          {emailSearch.length < 3
            ? t('users.searchCharsRequired', { count: 3 - emailSearch.length })
            : !isUserAllowed
            ? t('errors.pleaseSelectUser')
            : emailSearchField.hasError
            ? t(`errors.${emailSearchField.error}`)
            : ''}
        </div>
      )}
      {focused && (
        <div className={style.overlay}>
          <div className={style.usersWrapper}>
            {searchedUsers.map((user, index: number) => {
              return (
                <div
                  key={index}
                  className={classNames(
                    style.userWrapper,
                    isSelected(user) ? style.userWrapperSelected : style.userWrapperSelecting,
                  )}
                  onClick={() => selectUser(user)}
                  // prevent emitted blurring blocking selectUser
                  onMouseDown={e => e.preventDefault()}
                >
                  <div className={style.userInfoWrapper}>
                    <div className={style.logo}>
                      <div className={style.logoCircle}>
                        <span className={style.logoText}>
                          {user.firstName && user.lastName
                            ? `${user.firstName[0]}${user.lastName[0]}`
                            : 'n/a'}
                        </span>
                      </div>
                    </div>
                    <div className={style.userInfoColumn}>
                      <div>
                        {user.firstName} {user.lastName}
                      </div>
                      <div>{user.email}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={style.addNewBtnDiv}>
            <Button
              className={style.addNewBtn}
              type="button"
              onMouseDown={e => e.preventDefault()}
              onClick={handleAddUser}
            >
              {t('supportContacts.addNew')}
            </Button>
          </div>
        </div>
      )}
      <Input
        value={phone}
        onChange={e => setPhone(e.target.value)}
        label={t('common.phoneNumber')}
        error={!isUserAllowed && phone ? 'pleaseSelectUser' : phoneField.error}
        hasBeenValidated={phoneField.hasBeenValidated}
      />
    </div>
  )
})
