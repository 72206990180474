import * as React from 'react'

const rawTermsDocument = `<html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style>
    <!--
     /* Font Definitions */
     @font-face
      {font-family:Wingdings;
      panose-1:5 0 0 0 0 0 0 0 0 0;}
    @font-face
      {font-family:"Cordia New";
      panose-1:2 11 3 4 2 2 2 2 2 4;}
    @font-face
      {font-family:"Cambria Math";
      panose-1:2 4 5 3 5 4 6 3 2 4;}
    @font-face
      {font-family:Calibri;
      panose-1:2 15 5 2 2 2 4 3 2 4;}
    @font-face
      {font-family:"Noto Sans Symbols";
      panose-1:2 11 6 4 2 2 2 2 2 4;}
    @font-face
      {font-family:Cambria;
      panose-1:2 4 5 3 5 4 6 3 2 4;}
    @font-face
      {font-family:Tahoma;
      panose-1:2 11 6 4 3 5 4 4 2 4;}


      @font-face {
        font-family: Wigrum;
        font-weight: 300;
        src: url('../assets/fonts/wigrumweb-regular.woff') format('woff');
      }

      @font-face {
        font-family: Wigrum;
        font-weight: 500;
        src: url('../assets/fonts/wigrumweb-medium.woff') format('woff');
      }

      @font-face {
        font-family: Wigrum;
        font-style: normal;
        font-weight: 700;
        src: url('../assets/fonts/wigrumweb-bold.woff') format('woff');
      }

    @font-face
      {font-family:"Segoe UI";
      panose-1:2 11 5 2 4 2 4 2 2 3;}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
      {margin:0in;
      font-size:10.0pt;
      font-family:Wigrum;}
    h1
      {mso-style-link:"Heading 1 Char";
      margin-top:0in;
      margin-right:0in;
      margin-bottom:12.0pt;
      margin-left:.5in;
      text-indent:-.5in;
      font-size:10.0pt;
      font-family:Wigrum;
      font-weight:bold;}
    h2
      {mso-style-link:"Heading 2 Char";
      margin-top:0in;
      margin-right:0in;
      margin-bottom:12.0pt;
      margin-left:.5in;
      text-align:justify;
      text-indent:-.5in;
      font-size:10.0pt;
      font-family:Wigrum;
      font-weight:normal;}
    h3
      {mso-style-link:"Heading 3 Char";
      margin-top:0in;
      margin-right:0in;
      margin-bottom:12.0pt;
      margin-left:1.5in;
      text-indent:-.5in;
      font-size:10.0pt;
      font-family:Wigrum;
      font-weight:normal;}
    h4
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:12.0pt;
      margin-left:0in;
      text-indent:1.5in;
      font-size:10.0pt;
      font-family:Wigrum;
      font-weight:normal;}
    h5
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:12.0pt;
      margin-left:0in;
      text-indent:2.0in;
      font-size:10.0pt;
      font-family:Wigrum;
      font-weight:normal;}
    h6
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:12.0pt;
      margin-left:0in;
      text-indent:2.5in;
      font-size:10.0pt;
      font-family:Wigrum;
      font-weight:normal;}
    p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:12.0pt;
      margin-left:0in;
      text-indent:3.0in;
      font-size:10.0pt;
      font-family:Wigrum;}
    p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:12.0pt;
      margin-left:0in;
      text-indent:3.5in;
      font-size:10.0pt;
      font-family:Wigrum;}
    p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
      {margin-top:0in;
      margin-right:0in;
      margin-bottom:12.0pt;
      margin-left:0in;
      text-indent:4.0in;
      font-size:10.0pt;
      font-family:Wigrum;}
    p.MsoHeader, li.MsoHeader, div.MsoHeader
      {mso-style-link:"Header Char";
      margin:0in;
      font-size:10.0pt;
      font-family:Wigrum;}
    p.MsoFooter, li.MsoFooter, div.MsoFooter
      {mso-style-link:"Footer Char";
      margin:0in;
      font-size:10.0pt;
      font-family:Wigrum;}
    p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
      {mso-style-link:"Body Text Char";
      margin-top:0in;
      margin-right:0in;
      margin-bottom:12.0pt;
      margin-left:0in;
      text-align:justify;
      font-size:10.0pt;
      font-family:Wigrum;}
    a:link, span.MsoHyperlink
      {color:blue;
      text-decoration:underline;}
    span.BodyTextChar
      {mso-style-name:"Body Text Char";
      mso-style-link:"Body Text";
      font-family:Wigrum;}
    span.FooterChar
      {mso-style-name:"Footer Char";
      mso-style-link:Footer;
      font-family:"Calibri",sans-serif;}
    span.HeaderChar
      {mso-style-name:"Header Char";
      mso-style-link:Header;
      font-family:"Calibri",sans-serif;}
    p.BodyTextIndent5Justified, li.BodyTextIndent5Justified, div.BodyTextIndent5Justified
      {mso-style-name:"Body Text Indent \.5\0022 Justified";
      margin-top:0in;
      margin-right:0in;
      margin-bottom:12.0pt;
      margin-left:.5in;
      text-align:justify;
      font-size:10.0pt;
      font-family:Wigrum;}
    p.TitleCenteredBold, li.TitleCenteredBold, div.TitleCenteredBold
      {mso-style-name:"Title Centered Bold";
      margin-top:0in;
      margin-right:0in;
      margin-bottom:12.0pt;
      margin-left:0in;
      text-align:center;
      page-break-after:avoid;
      font-size:10.0pt;
      font-family:Wigrum;
      font-weight:bold;}
    span.Heading1Char
      {mso-style-name:"Heading 1 Char";
      mso-style-link:"Heading 1";
      font-family:Wigrum;
      font-weight:bold;}
    span.Heading2Char
      {mso-style-name:"Heading 2 Char";
      mso-style-link:"Heading 2";
      font-family:Wigrum;}
    .MsoChpDefault
      {font-size:10.0pt;}
     /* Page Definitions */
     @page WordSection1
      {size:8.5in 11.0in;
      margin:1.0in 1.0in 1.0in 1.0in;}
    div.WordSection1
      {page:WordSection1;}
     /* List Definitions */
     ol
      {margin-bottom:0in;}
    ul
      {margin-bottom:0in;}
    -->
  </style>
</head>

<body
  lang="EN-US"
  link="blue"
  vlink="purple"
  style="word-wrap: break-word; background-color: white"
>
  <div class="WordSection1">
    <p class="MsoBodyText" style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%; color: black"
        >Effective Date: December 18, 2023</span
      >
    </p>

    <p class="MsoBodyText" style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%; color: black"
        >These Plume Portal Terms (<b>Portal</b> </span
      ><b><span style="font-size: 9.5pt; line-height: 115%">Terms</span></b
      ><span style="font-size: 9.5pt; line-height: 115%; color: black"
        >) apply to you as any person or business entity who is a potential customer (<b>you</b>)
        of Plume Design, Inc. (<b>Plume</b>) HomePass</span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Symbol; color: black"
        >â</span
      ><span style="font-size: 9.5pt; line-height: 115%; color: black">, WorkPass</span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Symbol; color: black"
        >â</span
      ><span style="font-size: 9.5pt; line-height: 115%; color: black">, Haystack</span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Symbol; color: black"
        >â</span
      ><span style="font-size: 9.5pt; line-height: 115%; color: black">, Harvest</span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Symbol; color: black"
        >â</span
      ><span style="font-size: 9.5pt; line-height: 115%; color: black">, and/or Uprise</span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Symbol; color: black"
        >â</span
      ><span style="font-size: 9.5pt; line-height: 115%; color: black">
        (<b>Plume Service Suites</b>) and/or SuperPods in connection with or through the Plume
        Portal (<b>Portal</b>). The Plume Service Suites, any model of SuperPod, OpenSync and any
        other Plume product or service are collectively as <b>Plume Products</b>.
      </span>
    </p>

    <p class="MsoBodyText" style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%; color: black"
        >These Portal Terms form a legally binding agreement between you and Plume concerning
        access to any information about any Plume Service Suites through the Portal. You and Plume
        are each a <b>Party</b> and collectively the <b>Parties</b>.
      </span>
    </p>

    <p class="MsoBodyText" style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%; color: black"
        >BY (A) CLICKING THE “ACCEPT” BUTTON, (B) THROUGH THE ACCOUNT REGISTRATION PROCESS, (C) BY
        OTHERWISE ACCESSING THIS WEB ADDRESS WHICH REFERENCES THESE PORTAL TERMS, OR (D) USING THE
        PORTAL, YOU GRANT CONSENT AND AGREE TO BE BOUND BY THESE PORTAL TERMS TO THE SAME EXTENT
        AS IF A PAPER COPY OF THESE PORTAL TERMS HAD BEEN MANUALLY EXECUTED, AND YOU UNDERSTAND,
        INTEND TO, AND AGREE TO ENTER INTO THESE PORTAL TERMS ELECTRONICALLY. YOU REPRESENT AND
        WARRANT THAT YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO AND BE BOUND BY THESE
        PORTAL TERMS.</span
      ><span style="font-size: 9.5pt; line-height: 115%; color: black">&nbsp;&nbsp;</span
      ><span style="font-size: 9.5pt; line-height: 115%; color: black"
        >IF YOU DO NOT AGREE WITH THESE PORTAL TERMS, YOU SHOULD NOT GIVE CONSENT, AND YOU HAVE NO
        RIGHT TO ACCESS OR USE THE PORTAL. THESE PORTAL TERMS MAY CHANGE FROM TIME TO TIME AND YOU
        AGREE IT IS YOUR RESPONSIBILITY TO BE AWARE OF THE CURRENT VERSION OF THESE PORTAL
        TERMS.</span
      >
    </p>

    <p class="MsoBodyText" style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%; color: black"
        >Through the Portal you may have access to Plume proprietary and confidential materials
        including but not limited to information on and demo versions of Plume Service Suites and
        Plume Products, data sheets, marketing materials, customer testimonials, and articles
        pertaining to Plume Services (collectively, <b>Plume Materials</b>). </span
      ><b><span style="font-size: 9.5pt; line-height: 115%">Plume Business Agreement </span></b
      ><span style="font-size: 9.5pt; line-height: 115%"
        >means the agreement between Plume and you governing your use of any Plume
        Materials.</span
      >
    </p>

    <p class="MsoBodyText" style="line-height: 115%">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; color: black"
          >CHANGES TO TERMS.
        </span></b
      ><span style="font-size: 9.5pt; line-height: 115%; color: black"
        >The Effective Date of these Terms is set forth at the top of this webpage. </span
      ><span style="font-size: 9.5pt; line-height: 115%; color: black"
        >As we add new features to the Plume Products, we may need to modify these Terms. When we
        modify these Terms, we will update the Effective Date and, when possible, we also will
        provide you with advance written notice of our changes. Your continued use of the Portal
        after the Effective Date<span class="MsoCommentReference"
          ><span style="line-height: 115%"> </span></span
        >constitutes your acceptance of the amended Terms. </span
      ><span style="font-size: 9.5pt; line-height: 115%; color: black"
        >The amended Terms supersede all previous versions of or </span
      ><span style="font-size: 9.5pt; line-height: 115%; color: black"
        >agreements, notices or statements of or</span
      ><span style="font-size: 9.5pt; line-height: 115%; color: black">
        about the use of the Portal, except to the extent governed by a Plume Business
        Agreement.</span
      >
    </p>

    <h1 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >1.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%">LICENSE AND ACCESS TO PORTAL</span>
    </h1>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >1.1<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >Subject to these Portal Terms, Plume grants you a non-exclusive, non-transferable,
        non-sublicensable, revocable, limited license to display, access, and use the Portal, for
        the duration of the Term for your internal evaluation, purchase of and/or subscriptions to
        Plume Materials. Any sale or commercial use (including subscriptions) of Plume Materials
        will be subject to a Plume Business Agreement between you and Plume.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >1.2<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >You may grant, to any of your employees you authorize, access and use rights to the
        Portal provided that such employee has a need to use the Portal and know the Plume
        Materials in the scope and course of their employment with you (<b
          ><span style="color: black">Authorized Users</span></b
        >). You hereby represent and warrant that (i) you have all necessary rights, licenses and
        approvals to access the Portal, (ii) that all Authorized Users who access and use the
        Portal, and to undertake any actions within the Portal, have been duly authorized by you,
        to do so on your behalf, and (iii) you are and will be responsible for your acts and
        omissions and the acts and omissions of each of such Authorized Users in connection with
        access to and use of the Portal, including compliance with these Portal Terms. In
        connection with an Authorized User’s use of the Portal, Plume may collect use, store and
        disclose certain personal information that you or your Authorized Users provide to us.
        Plume may use this personal information as described in the
        <u
          ><a target="_blank" href="/portal-privacy-policy" style="color: #4f81bd"
            >Plume Portal Privacy Policy</a
          ></u
        >
        including to validate the identity of an Authorized User. You acknowledge and agree that
        our collection, use, storage, and disclosure of your personal information is governed by
        the
        <u
          ><a target="_blank" href="/portal-privacy-policy" style="color: #4f81bd"
            >Plume Portal Privacy Policy
          </a></u
        >.  Prior to initial access to the Portal, each Authorized User shall electronically
        execute an acknowledgement appearing on the Portal to the effect that such Authorized User
        personally consents to the disclosure, collection, storage and processing of the personal
        information provided in connection with the Portal. IF YOU OR ANY OF YOUR AUTHORIZED USERS
        DO NOT AGREE TO THESE PORTAL TERMS, YOU MUST NOT ACCESS OR USE THE PORTAL AND MUST NOT
        AUTHORIZE ANY THIRD PARTY TO USE THE PORTAL. If you or your Authorized Users choose to
        provide input and suggestions regarding problems, modifications or improvements to the
        Portal or the Plume Materials (<b>Feedback</b>), then you hereby grant Plume an
        unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right to
        exploit the Feedback in any manner and for any purpose, including to improve the Service
        and create other products and services.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >1.3<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >You and each of your Authorized Users will be given a unique user identification and a
        corresponding passcode or other access code to enable access and use the Portal (<b
          ><span style="color: black">User ID</span></b
        >). You agree to keep your passcode secure and strictly confidential and ensure that all
        Authorized Users agree to the same.</span
      ><span style="font-size: 9.5pt; line-height: 115%">&nbsp;</span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >You agree to notify Plume immediately of any change in access and use rights of any
        Authorized User. You acknowledge that you, and not Plume, are ultimately responsible for
        the access to your account by Authorized Users, and Plume disclaims all liability
        resulting from or arising out of the unauthorized access of your account through any User
        ID assigned to you or any of your Authorized Users. </span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >User ID, password, password reset questions and answers and other access credentials (<b
          >User Information</b
        >) is provided to Authorized Users of the Portal for their sole access to the Portal and
        Plume Service Suites they have been authorized to access by Plume. You are responsible for
        ensuring that you and your Authorized Users keep, any User Information secure and
        confidential. You are solely responsible for you and your Authorized Users’ usage and
        security of User Information and any activities that occur under the applicable User IDs.
      </span>
    </h2>

    <h1 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >2.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >PERMISSIBLE USE OF PORTAL AND PLUME MATERIALS</span
      >
    </h1>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >2.1<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >Use of Plume Materials and the Portal is permitted only for use with the Plume Materials
        in the scope of and in accordance with these Terms and any Plume Business Agreement, as
        applicable. Use of the Portal and any Plume Materials for any other purpose is strictly
        prohibited. Plume Materials, including the Portal and any Plume Service Suites made
        available on the Portal may not be accessed, used or distributed by or to any third party
        unless such right is expressly granted to you in the applicable Plume Business Agreement
        between you and Plume. You must ensure that any use of the Portal by any such Affiliate or
        third party is in compliance with these Terms and the requirements of such Plume Business
        Agreement. You hereby agree to notify Plume if you become aware of any violation of these
        Terms by you, any of your Authorized Users. 
      </span>
    </h2>

    <h1 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >3.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%">CONDITIONS OF USE</span>
    </h1>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >3.1<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >You or your Authorized Users will not nor attempt to, directly or indirectly (i)
        decompile, transform, translate, disassemble, reproduce, modify, create derivative works
        based on, or reverse engineer all or any portion of the Portal, Plume Materials, or
        otherwise attempt to decrypt or derive any source code, trade secrets, or sensitive or
        proprietary information included in or related to the Portal or Plume Materials, (ii) 
        rent, share, lend or operate the Portal or Plume Service Suites on a time share or service
        bureau basis, (iii) use any part of the Portal, Plume Service Suites or Plume’s
        Confidential Information to create, market, or distribute any product or service that is
        competitive with the Plume Service Suites, (iv) transfer, sell, license, or disclose the
        Portal, Plume Materials to, or permit use of or access to the Portal, Plume Materials by,
        any other person or entity, (v) remove, alter, or obscure any intellectual property or
        similar notice contained or included on the Portal, any Plume Materials, (vi) contest,
        challenge, or otherwise make any claim or take any action adverse to Plume’s ownership of,
        or interest in, the Portal, Plume Materials, or Plume’s trademarks, (vii) use or access
        the Portal or Plume Service Suites to knowingly or intentionally, publish, transfer,
        display, store distribute or disseminate or otherwise transmit, load upon or make
        available (A) any information, files, software, data, content or other materials that
        infringes a third party’s intellectual property or other rights, is libelous, tortious,
        unlawful or otherwise illegal, or (B) any viruses, Trojan horses, works, time bombs,
        cancelbots, corrupted files, or any other similar software, program or device that is
        intended to harm or designed to enable the misuse of all or any component of the Portal or
        Plume Service Suites, or (x) engage in any unlawful, illegal, deceptive, misleading, or
        unethical practices in connection with its activities under these Portal Terms. You are
        responsible for a breach of any of such terms of use by any of your Authorized Users
        connection with each such Authorized User’s use of the Portal. Plume commodities,
        technology, and software are subject to the U.S. Export Administration Regulations.
        Diversion contrary to U.S. law is prohibited. They may not be resold, transferred, or
        released to any person, entity, organization, or destination that is subject to export
        control restrictions or economic sanctions administered by any U.S. Government agency
        without U.S. Government authorization.
      </span>
    </h2>

    <h1 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >4.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%">ORDER OF PRECEDENCE</span>
    </h1>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >4.1<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >Use of the Plume Portal, Plume Materials, Plume Service Suites and any other Plume
        Products may also be subject to disclaimers, legal notices, click-through agreements, one
        or more Plume Business Agreements, or other legal agreements (<b>Additional Legal Terms</b
        >), which may be posted on the Portal from time-to-time, where applicable. These Portal
        Terms and the applicable Additional Legal Terms form a legally binding agreement between
        you and Plume regarding access and use of the Portal, Plume Materials, Plume Service
        Suites and any other Plume Products by you and your and Authorized Users. Whenever there
        is a conflict between these Portal Terms and the Additional Legal Terms, the Additional
        Legal Terms shall govern and prevail as to the subject matter of such Additional Legal
        Terms.<b> </b>Whenever there is a conflict between these Portal Terms and the applicable
        Plume Business Agreement, these Portal Terms shall control access and use of the Portal
        and Plume Materials, and the Plume Business Agreement shall control access and use of the
        applicable other Plume Products.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >4.2<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >For example, if you participate in a beta program offered by Plume with respect to a
        Plume Service Suite or any other Plume Product, then the applicable Plume Business
        Agreement for such beta program will apply. If you or an Authorized User orders a Plume
        Service Suite or other Plume Product via the Portal, the applicable Plume Business
        Agreement for such Plume Service Suite or other Plume Product will apply.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >4.3<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >In the event of a conflict between an invoice for Plume Service Suites and information in
        the Portal, the invoice shall control as to amounts payable to Plume for purchases of
        subscriptions to Plume Service Suites.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >4.4<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >In the event that no Plume Business Agreement has been signed by Plume and the applicable
        Plume Portal Customer, Plume’s standard terms and conditions located at</span
      ><span style="font-size: 9.5pt; line-height: 115%">&nbsp;</span
      ><a
        href="https://www.akamai.com/legal/terms-and-conditions"
        target="_top"
        title="Follow link"
        ><span style="font-size: 9.5pt; line-height: 115%; color: #4f81bd"
          >https://www.Plume.com/legal/terms-and-conditions</span
        ></a
      ><span style="font-size: 9.5pt; line-height: 115%">&nbsp;</span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >shall apply to use of Plume Service Suites and the usage of the Portal by you, your
        Administrators, and your designated Users, if applicable.</span
      >
    </h2>

    <h1 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >5.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%">FEES</span>
    </h1>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >5.1<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >Plume does not currently charge a fee to access the Portal. Your use of any Plume Suite
        Services separate from or outside of the Portal will be subject to subscription fees,
        which can be reviewed in the Portal, or prior to the execution of the applicable Plume
        Business Agreement.</span
      >
    </h2>

    <h1 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >6.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%">TERM AND TERMINATION</span>
    </h1>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >6.1<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >These Portal Terms commence on the date you register to receive access to the Portal and
        continue for sixty days (60) days from your registration date and at such time
        automatically terminates (<b><span style="color: black">Term</span></b
        >).
      </span>
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >6.2<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >Plume may terminate these <span style="color: black">Portal Terms </span>and access to
        the Portal at any time, without notice. You may terminate these Portal Terms at any time
        by deleting your registered account.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >6.3<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >Plume may, directly or indirectly, and by use of a disabling device or any other lawful
        means, suspend, terminate or otherwise deny you or any Authorized User’s access to or use
        of all or any part of the Portal without incurring any resulting obligation or liability,
        including if: (i) Plume receives a judicial or other governmental demand or order or law
        enforcement request that expressly or by reasonable implication requires Plume to do so,
        in which case Plume shall provide you prompt written notice; (ii) Plume believes, in its
        reasonable discretion, that: (a) you or any Authorized User has accessed or used the
        Portal beyond the scope of the rights granted or for a purpose not authorized under these
        Portal Terms or in any Plume Business Agreement; (b) you or any Authorized User is or has
        been involved in any fraudulent or unlawful activities relating to or in connection with
        the Portal; (c) the transmission of any information, poses any security or vulnerability
        risk to Plume or the Portal or (d) the Term expires or is otherwise terminated.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >6.4<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >You hereby agree not to use the Portal in violation of the Plume Portal Privacy Policy
        located
        <u><a target="_blank" href="/portal-privacy-policy" style="color: #4f81bd">HERE</a></u
        >, or Plume’s </span
      ><a target="_blank" href="https://www.plume.com/legal/acceptable-use-policy/"
        ><span style="font-size: 9.5pt; line-height: 115%; color: #4f81bd"
          >Acceptable Use Policy</span
        ></a
      ><span style="font-size: 9.5pt; line-height: 115%; color: #4f81bd"> </span
      ><span style="font-size: 9.5pt; line-height: 115%">located at</span
      ><u><span style="font-size: 9.5pt; line-height: 115%; color: #4f81bd">&nbsp;</span></u
      ><u
        ><span style="font-size: 9.5pt; line-height: 115%; color: #4f81bd"
          >https://www.plume.com/legal/acceptable-use-policy/</span
        ></u
      ><span style="font-size: 9.5pt; line-height: 115%">. </span>
    </h2>

    <h1 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >7.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%">CONFIDENTIALITY</span>
    </h1>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >7.1<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >You hereby agree not to reproduce any Confidential Information (defined below) to which
        you are provided access through the Portal in any form except as authorized at the time of
        disclosure.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >7.2<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >Any reproduction of Confidential Information shall remain the property of Plume and/or
        its original owner, and shall contain any and all confidential or proprietary notices or
        legends that appear on the original.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >7.3<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >As a Portal User, you hereby agree (a) to take all reasonable steps (defined below) to
        keep all Confidential Information confidential; (b) to use Confidential Information solely
        as authorized at the time of disclosure; (c) not to disclose any Confidential Information
        to any party without the prior written consent of Plume; and (d) not to cause or permit
        reverse engineering of any Confidential Information or disassembly of any software
        programs which are a part of the Confidential Information. As a Portal user, you do not
        acquire any rights in Confidential Information except the limited rights as described
        above.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >7.4<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >In no event shall you use Confidential Information to create, enhance, modify, rent,
        lease, loan, sell, distribute or create derivative works based on Plume Materials, or use
        Plume Materials to develop similar or competing products of offerings.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >7.5<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><b><span style="font-size: 9.5pt; line-height: 115%">Confidential Information</span></b
      ><span style="font-size: 9.5pt; line-height: 115%">
        means all confidential or proprietary information, trade secrets and all other
        information, and Plume Materials which Plume or any one or more third parties protect
        against unrestricted disclosure which is either labeled “Proprietary”, “Confidential”,
        accessed through the Portal, or reasonably identifiable as confidential based on the type
        of information and the manner of its disclosure.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >7.6<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >Without limiting the foregoing, information provided via the Portal regarding the
        configuration, utilization, availability, location and/or performance of Plume Service
        Suites or assets deployed as part of, or used by, the Plume’s network and systems or to
        enable the delivery of a Plume Service Suite, are Confidential Information of Plume
        hereunder. User IDs and related credentials to the Portal issued to you and your
        respective Authorized Users, are also Confidential Information. The availability of the
        functionality to download or export Confidential Information from the Portal is not a
        waiver of confidentiality hereunder, and the downloaded or exported files of such
        information shall also be treated as Confidential Information hereunder.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >7.7<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >Reasonable steps means those steps you take to protect your own similar Confidential
        Information, which shall not be less than a reasonable standard of care.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >7.8<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >You shall not be obligated to treat information as Confidential Information if such
        information: (a) was rightfully in your possession prior to receipt from or disclosure by
        Plume; (b) is or becomes public knowledge other than through a breach of confidentiality;
        (c) is or was rightfully disclosed to you by a source other than Plume or any party under
        their respective control; (d) is independently developed by you without use of the
        Confidential Information disclosed hereunder; or (e) must be disclosed by you according to
        mandatory applicable law.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >7.9<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >You are not permitted to access Plume or third party Confidential Information that you
        are not explicitly licensed or otherwise granted a right to use for the intended purpose
        as set forth in its applicable Plume Business Agreement or as otherwise permitted
        hereunder.</span
      >
    </h2>

    <h1 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >8.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%">INFORMATION TECHNOLOGY</span>
    </h1>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >8.1<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >You are solely responsible for any equipment, devices, hardware, cabling, materials,
        services, support, products, applications, software or other materials supplied by any
        third party that you use in connection with any Plume Service Suite, SuperPod or other
        Plume product or service. Plume shall not be directly or indirectly responsible or liable
        for any damage or loss caused or alleged to be caused by or in connection with your use of
        or reliance on any equipment, devices, hardware, cabling, materials, services, support,
        products, applications, software or any other materials supplied by any one or more third
        parties.</span
      >
    </h2>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >8.2<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >You may only use two-factor authentication (“2FA”) for the
        <span class="MsoPageNumber"><span style="font-family: Wigrum">Portal</span></span> in
        connection with accessing your Plume Portal account. Your use of 2FA requires use of other
        Plume Products. You are responsible for all applicable fees associated with use of any
        Plume Product with 2FA. You are solely responsible for the procurement, configuration,
        operation, performance and security of any hardware or non-Plume software that you, or
        your Authorized Users, and/or use in connection with 2FA, including, without limitation,
        any authentication application or device. 2FA configuration values are Plume’s
        Confidential Information subject to the terms of confidentiality set forth in these Portal
        Terms.</span
      >
    </h2>

    <h1 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >9.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%">INTELLECTUAL PROPERTY </span>
    </h1>

    <h2 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >9.1<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><b
        ><u><span style="font-size: 9.5pt; line-height: 115%">Proprietary Rights</span></u></b
      ><b><span style="font-size: 9.5pt; line-height: 115%">.</span></b
      ><span style="font-size: 9.5pt; line-height: 115%">
        Plume reserves all rights in and to the Portal not expressly granted to you under these
        Portal Terms. As between the Parties, Plume is and will be the exclusive owner of all
        right, title, and interest in the Portal, Plume Materials, Plume’s trademarks and any
        related data (including Feedback). If you acquire any rights in or to any of the foregoing
        (other than the rights expressly granted herein), you hereby irrevocably assign all such
        rights to Plume. Further, you hereby grant Plume an unlimited, non-exclusive, perpetual,
        irrevocable, assignable, worldwide license to use any suggestion or idea for the Portal,
        Plume’s products or services that You communicates to Plume (<b>Feedback</b>), without
        compensation or restriction. The foregoing license grant from you to Plume includes the
        right for Plume to exploit Feedback in any and every way, as well as the right for Plume
        to grant sublicenses to and otherwise disclose such Feedback to the public.
      </span>
    </h2>

    <h1 style="line-height: 115%">
      <a name="_heading=h.2s8eyo1"></a><a name="_heading=h.26in1rg"></a
      ><span style="font-size: 9.5pt; line-height: 115%"
        >10.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%">INDEMNIFICATION</span>
    </h1>

    <p class="BodyTextIndent5Justified" style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%; color: black; background: white"
        >You acknowledge and agree that you are responsible for all actions taken within the
        Portal by you, and your Authorized Users. You hereby agree to indemnify, defend and hold
        Plume and its </span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >Affiliates<span style="color: black; background: white">
          and its and their officers, directors, agents, partners, employees, suppliers, and
          licensors harmless from any claim or demand, including reasonable attorney’s fees, made
          by any third party in connection with, related to, or arising out of your or any
          Authorized User’s usage of the Portal, any breach of these Portal Terms or, its alleged
          violation of any other rights of a third party.</span
        ></span
      >
    </p>

    <h1 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >11.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%">DISCLAIMERS</span>
    </h1>

    <p class="BodyTextIndent5Justified" style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%; color: black; background: white"
        >THE PORTAL AND THE PLUME MATERIALS ARE PROVIDED “AS-IS” WITHOUT REPRESENTATION OR
        WARRANTY OF ANY KIND, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, COURSE OF DEALING, COURSE OF
        PERFORMANCE, USAGE OF THE TRADE, NON-INFRINGEMENT, ACCURACY OR SECURITY. WITHOUT
        LIMITATION OF THE FOREGOING, PLUME DOES NOT WARRANT ANY THIRD-PARTY EQUIPMENT, DEVICES,
        HARDWARE, CABLING, MATERIALS, SERVICES, SUPPORT, PRODUCTS, APPLICATIONS OR LICENSED
        SOFTWARE SUPPLIED BY PARTIES OTHER THAN PLUME. YOU ACKNOWLEDGE THAT DOWN-TIME, LOSS OF
        CONTENT, AND VIRUSES ARE RISKS INHERENT IN THE USE OF THE INTERNET AND SOFTWARE PRODUCTS
        FOR WHICH PLUME EXPRESSLY DISCLAIMS ALL RESPONSIBILITY.  PLUME AGREES TO PROVIDE
        REASONABLE SUPPORT, IN ITS SOLE DISCRETION, FOR THE PORTAL, PLUME MATERIALS AND/OR PLUME
        PRODUCTS, SUBJECT IN ALL CASES TO THE APPLICABLE PLUME BUSINESS AGREEMENT, IF ANY;
        PROVIDED HOWEVER, THAT NO SERVICE LEVEL AGREEMENT SHALL APPLY.</span
      >
    </p>

    <p class="BodyTextIndent5Justified" style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%; color: black; background: white"
        >STATEMENTS AND EXPLANATIONS OF THE PORTAL, ANY PLUME MATERIALS OR PLUME PRODUCTS IN
        PROMOTIONAL MATERIAL OR ON THE PORTAL ARE MADE FOR EXPLANATORY PURPOSES ONLY; SUCH
        STATEMENTS ARE NOT MEANT TO CONSTITUTE ANY GUARANTEE OR WARRANTY OF ANY KIND. PLUME DOES
        NOT WARRANT THAT THE FEATURES OR FUNCTIONS CONTAINED IN THE PLUME MATERIALS WILL MEET YOUR
        REQUIREMENTS, THAT ANY ENABLED HARDWARE CHOSEN BY YOU TO DEPLOY PLUME MATERIALS WILL
        SUPPORT ALL OF THE FEATURES OR FUNCTIONS OF THE PLUME MATERIALS THAT ARE DESCRIBED IN THE
        APPLICABLE PLUME BUSINESS AGREEMENT, OR THAT THE USE OF ANY PLUME MATERIALS WILL BE
        UNINTERRUPTED OR ERROR FREE. WITHOUT LIMITATION OF THE FOREGOING, PLUME DOES NOT REPRESENT
        OR ENDORSE THE ACCURACY OR RELIABILITY OF ANY: (A) LINKS TO WEB-PAGES OF THIRD PARTIES
        CONTAINED ON THE PORTAL, OR THE CONTENT OBTAINABLE ON SUCH WEB-PAGES, (B) INFORMATION
        PROVIDED BY THIRD PARTIES ON THE PORTAL, IF ANY, OR (C) ANY PLUME MATERIALS ACCESSED
        THROUGH THE PORTAL ON AN EVALUATION BASIS.
      </span>
    </p>

    <h1 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >12.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%; color: black; background: white"
        >LIMITATION OF LIABILITY</span
      >
    </h1>

    <p class="BodyTextIndent5Justified" style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%; color: black; background: white"
        > TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, PLUME AND ITS AFFILIATES, AND ITS AND
        THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS, AND
        LICENSORS ARE NOT LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE,
        CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
        PROFITS, REVENUE, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF PLUME HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), HOWEVER CAUSED, WHETHER IN CONTRACT, TORT, OR
        OTHERWISE, ARISING OUT OF OR RESULTING FROM: (A) YOUR AND YOUR AUTHORIZED USERS’ USE OR
        THE INABILITY TO USE THE PORTAL, ANY PLUME MATERALS, PLUME SERVICE SUITES OR ANY PLUME
        PRODUCT; (B) STATEMENTS, MESSAGES, OR </span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >CONDUCT<span style="color: black; background: white">
          OF ANY THIRD PARTY ON OR LINKED VIA THE PORTAL; OR (C) ANY OTHER MATTER RELATING TO THE
          PORTAL, PLUME MATERALS, PLUME SERVICE SUITES OR ANY PLUME PRODUCT. TO THE MAXIMUM EXTENT
          PERMITTED BY LAW, THE MAXIMUM LIABLITY OF PLUME AND ITS AFFILIATES, AND ITS AND THEIR
          RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS AND LICENSORS IN
          CONNECTION WITH YOUR AND YOUR AUTHORIZED USERS’ USE OF THE PORTAL SHALL BE LIMITED TO
          ONE HUNDRED DOLLARS ($100).</span
        ></span
      >
    </p>

    <h1 style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >13.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 9.5pt; line-height: 115%">MISCELLANEOUS</span>
    </h1>

    <p class="BodyTextIndent5Justified" style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%"
        >To extent permitted by applicable law, the laws of the State of California govern these
        Portal Terms, without regard to conflict of law principles. You and Plume submit to the
        personal and exclusive jurisdiction of the state courts and federal courts located within
        Santa Clara, California, for resolution of any lawsuit or court proceeding permitted under
        these Portal Terms. These Portal Terms constitute the entire agreement between you and use
        with respect to the Portal. Any amendment or modification must be in writing and duly
        executed by both of us. You and Plume are independent contractors with respect to these
        Portal Terms. The provisions of the Portal Terms will not be construed to establish any
        form of partnership, agency or other joint venture of any kind between You and Plume, nor
        to constitute either Party as the agent, employee or legal representative of the
        other.</span
      ><span style="font-size: 9.5pt; line-height: 115%"> </span
      ><span style="font-size: 9.5pt; line-height: 115%"
        >In the event that any portion of these Portal Terms are held unenforceable, the
        unenforceable portion will be construed in accordance with applicable law as nearly as
        possible to reflect the original intentions of the parties, and the rest of the Portal
        Terms will remain in full force and effect. Waiver of any breach or provision of these
        Portal Terms will not be construed as a waiver of any other breach or provision. You shall
        not assign these Portal Terms or any of its rights or obligations under these Portal Terms
        without our prior written consent, and any such attempted assignment will be void and of
        no effect. We may assign these Portal Terms and all of its rights and obligations
        hereunder without limitation. This Portal Terms will bind the parties’ respective heirs,
        successors, and permitted assigns.
      </span>
    </p>

    <p class="MsoBodyText" style="line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%">&nbsp;</span>
    </p>
  </div>
</body>
</html>
`

export class PortalTermsContent extends React.Component {
  render() {
    return (
      <iframe title="PLUME PORTAL TERMS" width="100%" height="100%" srcDoc={rawTermsDocument} />
    )
  }
}
