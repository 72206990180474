export const accountSetupStatus = {
  PARTNERSHIP_CONTRACT_SIGNED: 'CONTRACT SIGNED',
  TRIAL_STARTED: 'TRIAL ACCESS',
  TERMINATED_TRIAL: 'TERMINATED - TRIAL',
  DEMO_AND_PRICING: 'DEMO & PRICING',
  Active: 'Active',
  Terminated: 'Terminated',
} as const

type AccountStateKey = keyof typeof accountSetupStatus
export type AccountState = (typeof accountSetupStatus)[AccountStateKey]
