import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import style from './idp-settings.css'
import { useTranslation } from 'react-i18next'
import { useUpdateCurrentUser } from 'utils/hooks/use-update-current-user'
import { useStores } from 'utils/hooks/useStores'
import { DashboardLayout } from 'modules/dashboard/common/dashboard-layout/dashboard-layout'
import {
  Icons,
  Sequence,
  CollapseCard,
  Button,
  Box,
  InputField,
  AccordionItem,
  DropdownItem,
  Dropdown,
} from 'plume-ui'
import { NavLink, Redirect } from 'react-router-dom'
import { ACCOUNT_ROUTE } from 'constants/routes'
import { SequenceElement } from 'plume-ui/dist/components/Sequence/Sequence'
import classNames from 'classnames'
import { CopyToClipboard, Dot, MinusRounded } from 'components/icons'
import { TextArea } from 'components/teaxt-area'
import { InputMessage } from 'plume-ui/dist/components/InputField/InputField'
import {
  copyTextToClipboard,
  getCaseInsensitiveDuplicateInArray,
  getPropertiesInObject,
} from 'helpers/general-helpers'
import { useFieldArray, useForm, Controller } from 'react-hook-form'
import { Input } from 'components/input'
import { mixpanelActions } from 'utils/mixpanelActions'
import { InfoPopover } from 'components/info-popover/info-popover'
import { isProductActive } from 'helpers/company-helpers'
import { XMLParser } from 'fast-xml-parser'

import * as forge from 'node-forge'
import luStyle from 'modules/dashboard/account/logo-upload/logo-upload.css'

import JSONPretty from 'react-json-pretty'
import { Tooltip } from 'components/tooltip'
import { PartnerIdentificationRow } from 'interfaces/api/portal/company-api'
import { canAccess } from 'modules/auth/auth-action-permission'

const jsonPrettyTheme = require('react-json-pretty/dist/monikai')

type FormValues = {
  teamNames: { teamName: string }[]
}

const computeOktaExpressionLanguageStringForPartnerIdentificationRows = (
  partnerIdentificationAttribute: string,
  partnerIdentificationRows: PartnerIdentificationRow[],
) => {
  let oktaELString = ''
  for (const row of partnerIdentificationRows) {
    oktaELString = `${oktaELString}source.${partnerIdentificationAttribute}=='${row.value}'?'${row.partnerId}':`
  }
  oktaELString = `${oktaELString}null`
  return oktaELString
}

export const IdpSettings = observer(() => {
  const { t } = useTranslation()
  const currentUser = useUpdateCurrentUser()
  const appStore = useStores()
  const accountStore = appStore?.accountStore
  const idpStep = accountStore?.idpStep
  const idpSetup = accountStore?.idpSetup
  const subordinatePartnerIds = accountStore?.subordinatePartnerIds

  const [uiStep, setUiStep] = React.useState(0)
  const [issuer, setIssuer] = React.useState('')
  const [certificate, setCertificate] = React.useState('')
  const [ssoUrl, setSsoUrl] = React.useState('')
  const [ssoDestination, setSsoDestination] = React.useState('')
  const [advancedIsOpen, setAdvancedIsOpen] = React.useState(false)
  const [partnerIdentificationAttribute, setPartnerIdentificationAttribute] = React.useState('')

  const [xmlParsingError, setXmlParsingError] = React.useState('')

  function partnerIdentificationReducer(
    prevArray: PartnerIdentificationRow[],
    value: {
      row?: PartnerIdentificationRow
      changes?: Partial<PartnerIdentificationRow>
      remove?: boolean
      add?: boolean
      reset?: boolean
      set?: boolean
      arrayToSet?: PartnerIdentificationRow[]
    },
  ) {
    if (value.reset) {
      return [
        {
          value: '',
          partnerId: '',
        },
      ]
    }

    if (value.set) {
      return JSON.parse(JSON.stringify(value.arrayToSet)) as PartnerIdentificationRow[]
    }

    const newArray = [...prevArray]
    if (value.add) {
      newArray.push({
        value: '',
        partnerId: '',
      })
      return newArray
    }
    const index = prevArray.findIndex(n => n === value.row)
    if (value.remove) {
      newArray.splice(index, 1)
    } else {
      newArray[index] = { ...prevArray[index], ...value.changes }
    }
    return newArray
  }

  const [partnerIdentificationRows, dispatchPartnerIdentificationRows] = React.useReducer(
    partnerIdentificationReducer,
    [
      {
        value: '',
        partnerId: '',
      },
    ],
  )

  const { control, watch, formState, setValue, handleSubmit, trigger } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      teamNames: idpSetup?.idpTeams?.map(t => ({ teamName: t })) || [{ teamName: '' }],
    },
  })
  const { errors: formErrors, dirtyFields: formDirtyFields, isValid: isFormValid } = formState

  const {
    fields: teamNameFields,
    append: appendTeam,
    remove: removeTeam,
  } = useFieldArray({
    control,
    name: 'teamNames',
  })
  const watchTeamNames = watch('teamNames')
  const teamNamesArray = teamNameFields.map((field, index) => {
    return {
      ...field,
      ...watchTeamNames[index],
    }
  })

  React.useEffect(() => {
    accountStore?.initIdpSettings()
  }, [accountStore])

  // on unmount
  React.useEffect(() => {
    return () => {
      accountStore?.cleanupIdpSettings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const options: SequenceElement[] = [
    {
      key: 'createSkeletonIdp',
      label: t('idpSettings.createSkeletonIdp'),
    },
    {
      key: 'realIdpData',
      label: t('idpSettings.realIdpData'),
    },
    {
      key: 'setupIdpTeams',
      label: t('idpSettings.setupIdpTeams'),
    },
    {
      key: 'activateIdp',
      label: t('idpSettings.activateIdp'),
    },
  ]

  React.useEffect(() => {
    setUiStep(idpStep < options.length ? idpStep + 1 : idpStep || 0)
  }, [idpSetup, idpStep, options.length])

  React.useEffect(() => {
    idpSetup?.ssoDestination && setSsoDestination(idpSetup?.ssoDestination)
    idpSetup?.ssoUrl && setSsoUrl(idpSetup?.ssoUrl)
    idpSetup?.issuer && setIssuer(idpSetup?.issuer)
    idpSetup?.idpTeams?.length &&
      setValue(
        'teamNames',
        idpSetup?.idpTeams?.map(t => ({ teamName: t })),
      )

    idpSetup?.idpCertificate && setCertificate(idpSetup?.idpCertificate)
    idpSetup?.partnerIdentificationAttribute &&
      setPartnerIdentificationAttribute(idpSetup?.partnerIdentificationAttribute)
    idpSetup?.partnerIdentificationRows?.length &&
      dispatchPartnerIdentificationRows({
        set: true,
        arrayToSet: idpSetup?.partnerIdentificationRows,
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idpSetup])

  if (!currentUser || !currentUser.company) {
    return <Loader />
  }

  const hasSamlProduct =
    isProductActive(currentUser?.company?.productsInfo?.['SAML']) ||
    currentUser?.company?.samlEnabled

  if (!canAccess('idpSettings', currentUser) || !hasSamlProduct) {
    return <Redirect to={`/missing-access`} />
  }

  const oc = style.override
  const { isLoadingModal } = accountStore

  const onXmlUploadHandler = async (event: React.FormEvent<HTMLInputElement>) => {
    type IdpSsoData = { '@_Location': string; '@_Binding': string }
    type IdpEntityData = { '@_entityID': string }

    const getPropertiesIfExist = (arr1: any[], arr2: any[]) => {
      return arr1?.length ? arr1 : arr2?.length ? arr2 : undefined
    }

    setSsoUrl('')
    setIssuer('')
    setCertificate('')

    const file = event.currentTarget.files[0]
    if (file) {
      const xmlText = await file.text()
      const xml = new XMLParser({ ignoreAttributes: false })
      let xmlMetadata
      try {
        xmlMetadata = xml.parse(xmlText)
      } catch (e) {
        setXmlParsingError('idpSettings.xmlInvalid')
      }

      const idpSSO: IdpSsoData[] = getPropertiesIfExist(
        getPropertiesInObject(xmlMetadata, 'SingleSignOnService'),
        getPropertiesInObject(xmlMetadata, 'md:SingleSignOnService'),
      )?.[0]
      if (!idpSSO) {
        setXmlParsingError('idpSettings.xmlIdpSSOInvalid')
      }

      const idpEntity: IdpEntityData = getPropertiesIfExist(
        getPropertiesInObject(xmlMetadata, 'EntityDescriptor'),
        getPropertiesInObject(xmlMetadata, 'md:EntityDescriptor'),
      )?.[0]
      if (!idpEntity) {
        setXmlParsingError('idpSettings.xmlIdpEntityInvalid')
      }

      const keys: string[] = getPropertiesIfExist(
        getPropertiesInObject(xmlMetadata, 'X509Certificate'),
        getPropertiesInObject(xmlMetadata, 'ds:X509Certificate'),
      )
      if (!keys) {
        setXmlParsingError('idpSettings.xmlKeysInvalid')
      }

      if (!!idpSSO && !!idpEntity && !!keys) {
        const xmlIdpSsoURL = idpSSO?.find(
          d => d?.['@_Binding'] === 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST',
        )?.['@_Location']
        setSsoUrl(xmlIdpSsoURL)

        setIssuer(idpEntity?.['@_entityID'])

        for (const key of keys) {
          const paddedKey = `-----BEGIN CERTIFICATE-----\n${key}\n-----END CERTIFICATE-----`
          const cert = forge.pki.certificateFromPem(paddedKey)
          if (new Date(cert.validity.notAfter).valueOf() > new Date().valueOf()) {
            setCertificate(paddedKey)
            break
          }
        }

        setXmlParsingError('')
      }
    }
  }

  let cert
  try {
    cert = forge.pki.certificateFromPem(certificate)
  } catch (e1) {}

  const textAreaCorrect =
    certificate?.slice(0, 27) === '-----BEGIN CERTIFICATE-----' &&
    certificate?.slice(-25) === '-----END CERTIFICATE-----'
  const processedCertificate = certificate?.slice(27, -25)?.trim()

  const textAreaError = !certificate
    ? 'requiredField'
    : !textAreaCorrect
    ? 'beginEndCertificateRequired'
    : !cert
    ? 'idpCertificateInvalid'
    : undefined

  const issuerInputFieldErrorMessages: Partial<InputMessage>[] = []
  if (!issuer) {
    issuerInputFieldErrorMessages.push({ status: 'error', message: t('errors.requiredField') })
  }

  const ssoUrlInputFieldErrorMessages: Partial<InputMessage>[] = []
  if (!ssoUrl) {
    ssoUrlInputFieldErrorMessages.push({ status: 'error', message: t('errors.requiredField') })
  }

  const ssoDestInputFieldHints: Partial<InputMessage>[] = [
    { status: 'hint', message: t('idpSettings.ssoDestinationHint') },
  ]

  const partnerIdentificationAttributeMessages: Partial<InputMessage>[] = [
    { status: 'hint', message: t('idpSettings.piaHint') },
  ]
  if (
    !partnerIdentificationAttribute ||
    !/^[a-zA-Z][A-Za-z0-9]*$/.test(partnerIdentificationAttribute)
  )
    partnerIdentificationAttributeMessages.push({
      status: 'error',
      message: t('idpSettings.partnerIdentificationAttributeInvalid'),
    })

  const isParentInPartnerIdentificationRows = !!partnerIdentificationRows?.find(
    r => r?.partnerId === currentUser?.company?.partnerId,
  )?.value
  const incompletePartnerIdentificationRow = !!partnerIdentificationRows?.find(
    r => !r.partnerId || !r.value,
  )

  const partnerIdentificationTooLong =
    computeOktaExpressionLanguageStringForPartnerIdentificationRows(
      partnerIdentificationAttribute,
      partnerIdentificationRows,
    )?.length > 2000

  const maxIdpRoles = 20

  const handleAppendTeam = () => {
    teamNamesArray.length < maxIdpRoles && appendTeam({ teamName: '' })
    trigger()
  }

  const duplicateTeamName = getCaseInsensitiveDuplicateInArray(
    teamNamesArray.map(tn => tn.teamName),
  )

  const handleCreateSkeletonIdp = () => {
    if (idpStep === -1) {
      accountStore.createSkeletonIdp()
    }
  }

  const handleCreateMultiPartnerSkeletonIdp = () => {
    if (idpStep === -1) {
      accountStore.createSkeletonIdp(true)
    }
  }

  const showXmlUpload =
    !!textAreaError ||
    !!issuerInputFieldErrorMessages.length ||
    !!ssoUrlInputFieldErrorMessages.length ||
    xmlParsingError

  const isUpdateIdpDisabled =
    !!textAreaError ||
    !!issuerInputFieldErrorMessages.length ||
    !!ssoUrlInputFieldErrorMessages.length ||
    (idpSetup?.isMultiPartnerIdP
      ? partnerIdentificationAttributeMessages?.length > 1 ||
        !isParentInPartnerIdentificationRows ||
        incompletePartnerIdentificationRow ||
        partnerIdentificationTooLong
      : false)

  const handleUpdateIdp = () => {
    if (!isUpdateIdpDisabled) {
      if (idpSetup?.isMultiPartnerIdP && idpSetup?.idpTeams?.length) {
        accountStore.updateExistingMultiPartnerIdp(
          {
            certificate: processedCertificate,
            issuer,
            ssoUrl,
            ssoDestination: ssoDestination || ssoUrl,
            isMultiPartnerIdP: idpSetup?.isMultiPartnerIdP,
            partnerIdentificationAttribute,
            partnerIdentificationRows,
          },
          idpSetup?.idpTeams,
          idpSetup?.partnerIdentificationRows.map(r => r.partnerId),
        )
      } else {
        accountStore.updateIdp({
          certificate: processedCertificate,
          issuer,
          ssoUrl,
          ssoDestination: ssoDestination || ssoUrl,
          isMultiPartnerIdP: idpSetup?.isMultiPartnerIdP,
          partnerIdentificationAttribute,
          partnerIdentificationRows,
        })
      }
    }
  }

  const isSetTeamsDisabled = !isFormValid || !!duplicateTeamName
  const handleSetTeams = handleSubmit(() => {
    if (!isSetTeamsDisabled) {
      accountStore.setIdpTeams(teamNamesArray.map(tn => tn.teamName))
    }
  })

  const handleActivateIdp = () => {
    if (idpSetup.status !== 'ACTIVE') {
      mixpanelActions.track('IdP Settings - IdP Activated', {
        form: 'IdP Settings',
        'Partner Id': currentUser?.company?.partnerId,
      })
      accountStore.activateIdp()
    }
  }
  const samlSetupInstructionsUrl = process.env.SAML_SETUP_INSTRUCTIONS_URL

  const multiPartnerDropdownPartnerIds = subordinatePartnerIds?.filter(
    pid => !partnerIdentificationRows?.find(r => r?.partnerId === pid),
  )

  return (
    <DashboardLayout currentUser={currentUser}>
      {idpStep === null || isLoadingModal ? (
        <Loader theme="small" rootClassName={style.loaderRoot} />
      ) : (
        <div className={style.root}>
          <div className={style.section}>
            <div className={style.header}>
              <div className={style.headerButtons}>
                <NavLink to={ACCOUNT_ROUTE} exact={true}>
                  <span className={style.backText}>
                    <Icons.ArrowLeftIcon className={style.arrowLeftSize} />
                    {t('btn.back')}
                  </span>
                </NavLink>
                <div className={style.headerFlowButtons}>
                  {uiStep > 0 && (
                    <Button
                      classes={curr => ({
                        ...curr,
                        root: classNames(curr.root, style.buttonMargins),
                      })}
                      onClick={() =>
                        setUiStep(uiStep >= options.length ? options.length - 2 : uiStep - 1)
                      }
                      styleVariant="secondary"
                    >
                      {t('common.previous')}
                    </Button>
                  )}
                  {uiStep < options.length - 1 && (
                    <Button
                      classes={curr => ({
                        ...curr,
                        root: classNames(curr.root, style.buttonMargins),
                      })}
                      onClick={() => setUiStep(uiStep + 1)}
                      styleVariant="secondary"
                      disabled={uiStep > idpStep}
                    >
                      {t('common.next')}
                    </Button>
                  )}
                </div>
              </div>

              <div className={style.headerTitle}>
                {t('idpSettings.title')}
                {samlSetupInstructionsUrl && (
                  <a href={samlSetupInstructionsUrl} target="_blank">
                    <Icons.InfoIcon className={style.infoButtonUrl} />
                  </a>
                )}
              </div>
            </div>

            <div className={style.contentWrapper}>
              <div className={classNames(oc, style.cardSize)}>
                <Sequence
                  sequenceElements={options}
                  stepNumber={uiStep}
                  classes={curr => ({
                    ...curr,
                    root: classNames(curr.root, oc, style.sequenceRoot),
                    label: classNames(curr.label, oc, style.sequenceLabel),
                  })}
                />
                <div className={style.sequenceSeparator} />
                <CollapseCard
                  title={options[uiStep < options.length ? uiStep : options.length - 1].label}
                  actions={[
                    <div
                      key="stepstatus"
                      className={classNames(
                        uiStep <= idpStep || idpStep === options.length - 1
                          ? style.colorComplete
                          : style.colorIncomplete,
                        style.stepStatus,
                      )}
                    >
                      <Dot className={style.statusDot} />
                      {uiStep <= idpStep || idpStep === options.length - 1
                        ? t('idpSettings.complete')
                        : t('idpSettings.incomplete')}
                    </div>,
                  ]}
                  open={true}
                  classes={current => ({
                    ...current,
                    root: classNames(current.root, style.cardMargins),
                  })}
                >
                  {uiStep === 0 && (
                    <>
                      {currentUser?.company?.samlEnabled &&
                        !currentUser?.company?.canCreateMultiPartnerIdP && (
                          <Button
                            styleVariant="superprimary"
                            disabled={idpStep > -1}
                            onClick={handleCreateSkeletonIdp}
                          >
                            {t('idpSettings.createSkeletonIdp')}
                          </Button>
                        )}
                      {currentUser?.company?.samlEnabled &&
                        currentUser?.company?.canCreateMultiPartnerIdP && (
                          <Button
                            styleVariant="superprimary"
                            disabled={idpStep > -1}
                            onClick={handleCreateMultiPartnerSkeletonIdp}
                          >
                            {t('idpSettings.createMultiPartner')}
                          </Button>
                        )}
                    </>
                  )}

                  {uiStep === 1 && (
                    <>
                      {showXmlUpload && (
                        <>
                          <input
                            id="partnerXmlMetadata"
                            className={luStyle.fileInput}
                            type="file"
                            name="partnerXmlMetadata"
                            onChange={onXmlUploadHandler}
                            accept=".xml"
                          />
                          <div className={luStyle.fileSelection}>
                            <label htmlFor="partnerXmlMetadata" className={luStyle.labelLogoTitle}>
                              {t('idpSettings.uploadXMLHere')}
                            </label>
                            <label htmlFor="partnerXmlMetadata" className={luStyle.labelLogoText}>
                              {t('logoUpload.selectFileForUpload')}
                            </label>
                          </div>
                          {xmlParsingError && (
                            <div className={style.xmlIdpError}>{t(xmlParsingError)}</div>
                          )}
                          <div className={style.inputSeparator} />
                        </>
                      )}

                      <div className={style.inputInfoWrapper}>
                        <div className={style.inputInfoWidth}>
                          <InputField
                            id={'issuer'}
                            type="text"
                            label={t('idpSettings.issuer')}
                            value={issuer}
                            required={true}
                            messages={issuerInputFieldErrorMessages}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                              e.stopPropagation()
                              setIssuer(e.target.value)
                            }}
                          />
                        </div>
                        <InfoPopover
                          infoButtonClass={classNames(oc, style.infoButtonModifications)}
                          text={t('idpSettings.issuerDescription')}
                        />
                      </div>

                      <div className={style.inputSeparator} />
                      <div className={style.inputInfoWrapper}>
                        <div className={style.inputInfoWidth}>
                          <InputField
                            id={'ssoUrl'}
                            type="text"
                            label={t('idpSettings.ssoUrl')}
                            value={ssoUrl}
                            required={true}
                            messages={ssoUrlInputFieldErrorMessages}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                              e.stopPropagation()
                              setSsoUrl(e.target.value)
                            }}
                          />
                        </div>
                        <InfoPopover
                          infoButtonClass={classNames(oc, style.infoButtonModifications)}
                          text={t('idpSettings.ssoUrlDescription')}
                        />
                      </div>
                      <div className={style.inputSeparator} />
                      <div className={style.inputInfoWrapper}>
                        <TextArea
                          label={t('idpSettings.certificate')}
                          value={certificate}
                          error={textAreaError}
                          onChange={e => setCertificate(e.target.value)}
                          className={classNames(
                            oc,
                            style.portalTextAreaModifications,
                            textAreaError && style.errorText,
                          )}
                          maxLength={100000}
                        />
                        <InfoPopover
                          infoButtonClass={classNames(oc, style.infoButtonModifications)}
                          rootClass={style.infoButtonRoot}
                          text={t('idpSettings.certificateDescription')}
                        />
                      </div>
                      <div className={style.inputSeparator} />
                      {!!cert && (
                        <>
                          <Tooltip
                            placement="top"
                            overlay={t('idpSettings.expiresAt', {
                              date: new Date(cert.validity.notAfter).toLocaleDateString(),
                            })}
                            align={{
                              offset: [0, -5],
                            }}
                            trigger={['hover']}
                            destroyTooltipOnHide={true}
                          >
                            <div className={style.fitContent}>
                              {t('idpSettings.expiresIn', {
                                days: Math.floor(
                                  (new Date(cert.validity.notAfter).valueOf() -
                                    new Date().valueOf()) /
                                    (1000 * 3600 * 24),
                                ),
                              })}
                            </div>
                          </Tooltip>

                          <Tooltip
                            placement="right"
                            overlay={
                              <JSONPretty theme={jsonPrettyTheme} data={cert.subject.attributes} />
                            }
                            align={{
                              offset: [0, -5],
                            }}
                            mouseLeaveDelay={0}
                            trigger={['click']}
                            destroyTooltipOnHide={true}
                          >
                            <Button
                              classes={curr => ({
                                ...curr,
                                root: classNames(curr.root, style.noPadding),
                                container: classNames(curr.container, style.fitContent),
                              })}
                              styleVariant="tertiary"
                            >
                              {t('idpSettings.seeCertificateInfo')}
                            </Button>
                          </Tooltip>
                        </>
                      )}
                      {idpSetup?.isMultiPartnerIdP && (
                        <>
                          <div className={style.inputSeparator} />
                          <div className={style.inputInfoWrapper}>
                            <div className={style.inputInfoWidth}>
                              <InputField
                                id={'partnerIdentificationAttribute'}
                                type="text"
                                label={t('idpSettings.partnerIdentificationAttribute')}
                                value={partnerIdentificationAttribute}
                                required={true}
                                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  e.stopPropagation()
                                  setPartnerIdentificationAttribute(e.target.value)
                                }}
                                messages={partnerIdentificationAttributeMessages}
                              />
                            </div>
                            <InfoPopover
                              infoButtonClass={classNames(oc, style.infoButtonModifications)}
                              text={t('idpSettings.partnerIdentificationAttributeDescription')}
                            />
                          </div>
                          <div className={style.inputSeparator} />
                          <div className={style.inputSeparator} />
                          {partnerIdentificationRows.map((row, index) => (
                            <div key={index}>
                              <div className={style.contentWrapper}>
                                <Dropdown
                                  classes={curr => ({
                                    ...curr,
                                    label: classNames(
                                      curr.label,
                                      style.multiPartnerDropdownMinWidth,
                                      !row.partnerId && style.dropdownLabelWarning,
                                    ),
                                  })}
                                  openInPortal
                                  label={row.partnerId || t('idpSettings.choosePartnerId')}
                                  closeOnItemClick
                                >
                                  {(multiPartnerDropdownPartnerIds.length
                                    ? multiPartnerDropdownPartnerIds
                                    : row.partnerId
                                    ? [row.partnerId]
                                    : []
                                  ).map(s => (
                                    <DropdownItem
                                      key={s}
                                      selected={row.partnerId === s}
                                      onClick={() =>
                                        dispatchPartnerIdentificationRows({
                                          row,
                                          changes: { partnerId: s },
                                        })
                                      }
                                    >
                                      {s}
                                    </DropdownItem>
                                  ))}
                                </Dropdown>
                                <div className={style.partnerIdentificationColon}>:</div>

                                <div className={style.partnerIdentificationWidth}>
                                  <InputField
                                    id={`idpidentification${index}`}
                                    type="text"
                                    label={t('idpSettings.identification')}
                                    value={row.value}
                                    required={true}
                                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      e.stopPropagation()
                                      dispatchPartnerIdentificationRows({
                                        row,
                                        changes: { value: e.target.value },
                                      })
                                    }}
                                    messages={
                                      !row.value
                                        ? [
                                            {
                                              status: 'error',
                                              message: t('errors.requiredField'),
                                            },
                                          ]
                                        : undefined
                                    }
                                  />
                                </div>
                                <div
                                  onClick={() =>
                                    dispatchPartnerIdentificationRows({ row, remove: true })
                                  }
                                >
                                  <MinusRounded
                                    className={classNames(style.override, style.minusSize)}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}

                          {!isParentInPartnerIdentificationRows && (
                            <div className={style.xmlIdpError}>
                              {t('idpSettings.missingParentPartnerIdIdentification', {
                                partnerId: currentUser?.company?.partnerId,
                              })}
                            </div>
                          )}
                          {partnerIdentificationTooLong && (
                            <div className={style.xmlIdpError}>
                              {t('idpSettings.partnerIdentificationTooLong')}
                            </div>
                          )}
                          {partnerIdentificationRows?.length < subordinatePartnerIds?.length && (
                            <Button
                              styleVariant="superprimary"
                              onClick={() => dispatchPartnerIdentificationRows({ add: true })}
                            >
                              {t('common.add')}
                            </Button>
                          )}
                          <div className={style.inputSeparator} />
                        </>
                      )}

                      <AccordionItem
                        title={t('common.advanced')}
                        isActive={advancedIsOpen}
                        onItemClick={() => setAdvancedIsOpen(!advancedIsOpen)}
                      >
                        <div className={style.inputSeparator} />
                        <InputField
                          id="ssoDestination"
                          type="text"
                          label={t('idpSettings.ssoDestination')}
                          value={ssoDestination}
                          required={true}
                          messages={ssoDestInputFieldHints}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.stopPropagation()
                            setSsoDestination(e.target.value)
                          }}
                        />
                      </AccordionItem>
                      <div className={style.inputSeparator} />
                      <Button
                        styleVariant="superprimary"
                        disabled={isUpdateIdpDisabled}
                        onClick={handleUpdateIdp}
                      >
                        {t('idpSettings.updateIdp')}
                      </Button>
                    </>
                  )}
                  {uiStep === 2 && (
                    <>
                      <div>{t('idpSettings.mapRolesToTeams')}</div>
                      <div className={style.inputSeparator} />
                      {teamNamesArray.map((field, index) => {
                        return (
                          <Controller
                            key={field.id}
                            name={`teamNames.${index}.teamName`}
                            shouldUnregister={false}
                            control={control}
                            defaultValue={field.teamName}
                            rules={{
                              required: 'requiredField',
                              pattern: {
                                value: /^[a-zA-Z0-9]([A-Za-z0-9\-_ ]*[a-zA-Z0-9])?$/,
                                message: 'roleNameInvalid',
                              },
                              maxLength: { value: 50, message: 'max50CharsAllowed' },
                            }}
                            render={({ field: { ref, ...inputProps } }) => {
                              return (
                                <div className={style.arrayFieldContainer}>
                                  <Input
                                    inputRef={ref as any}
                                    label={null}
                                    error={
                                      !!formErrors.teamNames?.[index]?.teamName
                                        ? formErrors?.teamNames?.[index]?.teamName?.message
                                        : field.teamName?.toLowerCase() ===
                                          duplicateTeamName?.toLowerCase()
                                        ? 'duplicateTeamNames'
                                        : undefined
                                    }
                                    hasBeenValidated={formDirtyFields?.teamNames?.[index]?.teamName}
                                    {...inputProps}
                                    className={style.arrayInput}
                                  />
                                  <div
                                    onClick={() => removeTeam(index)}
                                    className={style.minusRounded}
                                  >
                                    <MinusRounded />
                                  </div>
                                </div>
                              )
                            }}
                          />
                        )
                      })}
                      <div
                        className={classNames('InputField__message', 'InputField__message--hint')}
                      >
                        {t('idpSettings.roleNameContains')}
                      </div>
                      {teamNamesArray.length < maxIdpRoles && (
                        <Button styleVariant="secondary" onClick={handleAppendTeam}>
                          {t('common.add')}
                        </Button>
                      )}
                      <div className={style.inputSeparator} />
                      <div className={style.setTeamsWarning}>
                        {t(
                          !idpSetup?.isMultiPartnerIdP
                            ? 'idpSettings.setupRoleApplications'
                            : 'idpSettings.multiSetupRoleApplications',
                        )}
                      </div>
                      {idpStep > 1 && (
                        <div className={style.setTeamsWarning}>
                          {t('idpSettings.setTeamsWarning')}
                        </div>
                      )}

                      <div className={style.inputSeparator} />
                      <Button
                        styleVariant="superprimary"
                        disabled={isSetTeamsDisabled}
                        onClick={handleSetTeams}
                      >
                        {t('idpSettings.setRoles')}
                      </Button>
                    </>
                  )}

                  {uiStep >= 3 && (
                    <>
                      <Button
                        styleVariant="superprimary"
                        disabled={idpSetup.status === 'ACTIVE'}
                        onClick={handleActivateIdp}
                      >
                        {t('idpSettings.activateIdp')}
                      </Button>
                      <div className={style.inputSeparator} />
                      {idpSetup.status === 'ACTIVE' && (
                        <Button
                          styleVariant="superprimary"
                          onClick={() => accountStore.deactivateIdp()}
                        >
                          {t('idpSettings.deactivateIdp')}
                        </Button>
                      )}
                    </>
                  )}
                </CollapseCard>
              </div>

              {idpStep >= 0 && (
                <Box classes={curr => ({ root: classNames(curr.root, oc, style.additional) })}>
                  <div className={style.infoBoxTitle}>{t('idpSettings.idpInfo')}</div>

                  <InputField
                    classes={current => ({
                      ...current,
                      root: classNames(current.root, oc, style.copyField),
                    })}
                    noClearIcon
                    type="text"
                    id="deepLink"
                    label={t('idpSettings.deepLink')}
                    value={idpSetup?.deepLink}
                    disabled={true}
                    icon={
                      <CopyToClipboard
                        className={style.copyIcon}
                        onClick={() => copyTextToClipboard(idpSetup?.deepLink)}
                      />
                    }
                  />

                  <div className={style.inputSeparator} />
                  <InputField
                    classes={current => ({
                      ...current,
                      root: classNames(current.root, oc, style.copyField),
                    })}
                    noClearIcon
                    type="text"
                    id="audienceUri"
                    label={t('idpSettings.audienceUri')}
                    value={idpSetup?.audienceUri}
                    disabled={true}
                    icon={
                      <CopyToClipboard
                        className={style.copyIcon}
                        onClick={() => copyTextToClipboard(idpSetup?.audienceUri)}
                      />
                    }
                  />
                  {idpStep === 3 && (
                    <>
                      <div className={style.inputSeparator} />
                      <InputField
                        classes={current => ({
                          ...current,
                          root: classNames(current.root, oc, style.copyField),
                        })}
                        noClearIcon
                        type="text"
                        id="clientSpLogin"
                        label={t('idpSettings.clientSpLogin')}
                        value={`${process.env.PORTAL_BASE_URL}/login?idpValue=${idpSetup?.id}`}
                        disabled={true}
                        icon={
                          <CopyToClipboard
                            className={style.copyIcon}
                            onClick={() =>
                              copyTextToClipboard(
                                `${process.env.PORTAL_BASE_URL}/login?idpValue=${idpSetup?.id}`,
                              )
                            }
                          />
                        }
                      />
                    </>
                  )}
                  <div className={style.inputSeparator} />
                  <a
                    className={style.downloadMetadata}
                    onClick={() => accountStore.getIdpMetadata()}
                  >
                    {t('idpSettings.donwloadMetadata')}
                  </a>
                </Box>
              )}
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  )
})
