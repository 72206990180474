import { isEmail, isLength, isURL, isFQDN } from 'validator'

export function required<T>(_fieldName: string) {
  return (val: T) => !val && 'fieldIsRequiered'
}

export function makeOptional<T>(validator: (v: T) => string) {
  return function (value: T) {
    if (!value) return null
    return validator(value)
  }
}

export function email(val: string) {
  return (!isEmail(val) || /[?&]/g.test(val)) && 'invalidEmail'
}

export function url(val: string) {
  return (
    !isURL(val, { protocols: ['http', 'https'], require_protocol: true }) &&
    'mustBeACompleteURLincludingHttp'
  )
}

export function password(val: string) {
  return (
    !(
      isLength(val, { min: 8 }) &&
      val.match(/[A-Z]/) &&
      val.match(/[a-z]/) &&
      val.match(/[0-9]/) &&
      val.match(/[^a-zA-Z0-9]/)
    ) && 'passwordError'
  )
}

export function notDomainOrUrl(val: string) {
  const isDomainOrUrl =
    isURL(val) ||
    val.match(/^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/)
  return isDomainOrUrl && 'mustBeAPhoneNumber'
}

export function phoneNumber(val: string) {
  return !val.match(/^[+]*[0-9]*$/) && 'mustBeAPhoneNumber'
}

export function phoneNumberHelpDesc(val: string) {
  return !val.match(/^[+]*[0-9]*$/) && 'phoneNuberHelpDescription'
}

export function pdf(val: string) {
  return val.slice(val.length - 4, val.length) === '.pdf' && 'mustNotBeAPDFFileURL'
}

export function min3chars(val: string) {
  return val.length < 3 && 'min3CharsRequired'
}

export function min7chars(val: string) {
  return val.length < 7 && 'min7CharsNumsRequired'
}

export function max10chars(val: string) {
  return val.length > 10 && 'max10CharsNumsAllowed'
}

export function max15chars(val: string) {
  return val.length > 15 && 'max15CharsNumsAllowed'
}

export function max50chars(val: string) {
  return val.length > 50 && 'max50CharsAllowed'
}

export function max40chars(val: string) {
  return val.length > 40 && 'max40CharsAllowed'
}

export function max80chars(val: string) {
  return val.length > 80 && 'max80CharsAllowed'
}

export function domain(val: string) {
  return !isFQDN(val) && 'invalidDomain'
}

export function max255chars(val: string) {
  return val.length > 255 && 'max255CharsAllowed'
}

export function max20chars(val: string) {
  return val.length > 20 && 'max20CharsAllowed'
}

export function noAngleBrackets(val: string) {
  return !val.match(/^((?![<>]+).)*$/) && 'noAngleBracketsAllowed'
}
