import {
  harvestClarityValue,
  harvestGenerateValue,
  harvestNurtureValue,
} from 'constants/applications/harvest.constants'
import { SalesforceProductsInfo } from 'interfaces/api/portal/company-api'
import { isProductActive } from './company-helpers'

export const getHarvestFeaturesFromOpportunityProducts = (productsInfo: SalesforceProductsInfo) => {
  const harvestFeatures: string[] = []
  for (const p in productsInfo) {
    if (!isProductActive(productsInfo[p])) {
      continue
    }
    if (p === 'Nurture') {
      harvestFeatures.push(harvestNurtureValue)
    } else if (p === 'Generate') {
      harvestFeatures.push(harvestGenerateValue)
    } else if (p === 'Clarity') {
      harvestFeatures.push(harvestClarityValue)
    }
  }
  return harvestFeatures
}

export const getHarvestFeaturesValue = (features: string[], itemValue: string) => {
  let featuresValue: string[] = []
  if (itemValue === harvestNurtureValue) {
    featuresValue = features?.includes(itemValue)
      ? features.filter(f => f !== itemValue)
      : Array.from(new Set([...features, itemValue, harvestGenerateValue]))
  } else if (itemValue === harvestGenerateValue) {
    featuresValue = features?.includes(itemValue)
      ? features.filter(f => f !== itemValue && f !== harvestNurtureValue)
      : [...features, itemValue]
  } else {
    featuresValue = features?.includes(itemValue)
      ? features.filter(f => f !== itemValue)
      : [...features, itemValue]
  }
  return featuresValue
}
