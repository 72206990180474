import * as React from 'react'
import style from '../app-customization.css'
import { LeftArrow, RightArrow } from 'components/icons/index'
import { Button } from 'components/button'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { AccountStore } from '../../account-store'
import iosAdaptiveWifi from '../../../../../assets/png/ios_adaptive_wifi.png'
import androidAdaptiveWifi from '../../../../../assets/png/android_adaptive_wifi.png'
import wpIosPods from '../../../../../assets/png/workpass_ios_pods.png'
import wpAndroidPods from '../../../../../assets/png/workpass_android_pods.png'
import hpV2IosPods from '../../../../../assets/png/homepass_v2_ios_pods.png'
import hpV2AndroidPods from '../../../../../assets/png/homepass_v2_android_pods.png'
import { PlumeProduct } from 'enums/plume-product.enum'

interface PodsPreviewProps {
  accountStore: AccountStore
  previewAppCustomizationOnly?: boolean
}

export const PodsPreview = observer((props: PodsPreviewProps) => {
  const handlePrevStep = () => {
    props.accountStore.setPrevStep()
  }

  const handleNextStep = () => {
    props.accountStore.setNextStep()
  }

  const handleOSPreviewChange = (os: string) => {
    props.accountStore.setOsPreview(os)
  }

  const {
    accountStore: {
      isLoadingModal,
      buyPodEnabled,
      addPodEnabled,
      currentStep,
      previewOS,
      isAppCustomizationSubmitDisabled,
      selectedProduct,
    },
  } = props
  const { t } = useTranslation()

  const isHomePassV2Enabled = process.env.HOMEPASS_V2_ENABLED === 'true'

  const isHomePassSelected = selectedProduct === PlumeProduct.HomePass

  const iosBg = isHomePassSelected
    ? isHomePassV2Enabled
      ? hpV2IosPods
      : iosAdaptiveWifi
    : wpIosPods
  const androidBg = isHomePassSelected
    ? isHomePassV2Enabled
      ? hpV2AndroidPods
      : androidAdaptiveWifi
    : wpAndroidPods

  const podsCssVars =
    !isHomePassV2Enabled && isHomePassSelected
      ? previewOS === 'ios'
        ? style.iosPodsHomePass
        : style.androidPodsHomePass
      : isHomePassV2Enabled && isHomePassSelected
      ? style.podsHomePassV2
      : style.podsWorkPass

  return (
    <div
      className={classNames(
        (currentStep !== 2 || isLoadingModal) && style.displayNone,
        podsCssVars,
      )}
    >
      <div className={style.setupCompletedWrapper}>{t('appCustomization.formMsg')}</div>
      <div className={style.previewContent}>
        {!props.previewAppCustomizationOnly && (
          <div
            className={classNames(style.arrowLeft, currentStep === 1 && style.hidden)}
            onClick={handlePrevStep}
          >
            <LeftArrow />
          </div>
        )}
        <div>
          <div className={style.previewHeader}>
            <span
              className={classNames(
                style.previewItem,
                previewOS === 'ios' && style.previewItemActive,
              )}
              onClick={() => handleOSPreviewChange('ios')}
            >
              {t('appCustomization.iOS')}
            </span>
            <span
              className={classNames(
                style.previewItem,
                previewOS === 'android' && style.previewItemActive,
              )}
              onClick={() => handleOSPreviewChange('android')}
            >
              {t('appCustomization.android')}
            </span>
          </div>
          <div className={style.imagePreview}>
            {previewOS === 'ios' && <img src={iosBg} alt="iOS Adaptive Wifi page" />}
            {previewOS === 'android' && <img src={androidBg} alt="Android Adaptive Wifi page" />}
            <div className={classNames(style.previewOverlay, style.podsOverlayTop)} />
            {isHomePassSelected && (
              <>
                {addPodEnabled.value ? (
                  <div className={classNames(style.previewFieldEnabled, style.addPod)} />
                ) : (
                  <div className={classNames(style.previewFieldDisabled, style.addPod)}>
                    {t('appCustomization.itemWillNotBeShown')}
                  </div>
                )}
              </>
            )}
            {buyPodEnabled.value ? (
              <div className={classNames(style.previewFieldEnabled, style.buyPodUrlEnabled)} />
            ) : (
              <div className={classNames(style.previewFieldDisabled, style.buyPodUrlDisabled)}>
                {t('appCustomization.itemWillNotBeShown')}
              </div>
            )}
            <div className={classNames(style.previewOverlay, style.podsOverlayBottom)} />
          </div>
        </div>
        <div
          onClick={handleNextStep}
          className={classNames(style.arrowRight, currentStep === 3 && style.hidden)}
        >
          <RightArrow />
        </div>
      </div>

      <div className={style.btnDiv}>
        <Button
          className={style.sendInviteBtn}
          type="submit"
          disabled={isAppCustomizationSubmitDisabled}
        >
          {t('appCustomization.submitCustomization', {
            product: t(`common.${selectedProduct}`),
          })}
        </Button>
      </div>
    </div>
  )
})
