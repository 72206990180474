import * as React from 'react'
import style from './terms-and-conditions.css'

export class TermsAndConditionsContent extends React.Component {
  render() {
    return (
      <div className={style.tcContent}>
        <p className={style.p2}>{`Updated: December 14, 2023`}</p>
        <p className={style.p3}>
          The following Terms of Use (
          <b>
            <i>Terms</i>
          </b>
          ) describe the terms and conditions on which Plume Design, Inc. (
          <b>
            <i>Plume</i>
          </b>{' '}
          or{' '}
          <b>
            <i>we</i>
          </b>
          ) offers the organization agreeing to these Terms (<b>you</b>) access to Plume’s
          enterprise (B2B) services known as Harvest, Haystack, Flex and Uprise (
          <b>
            <i>Enterprise Services</i>
          </b>
          ).
        </p>
        <p className={style.p4}>
          <br />
        </p>
        <p className={style.p3}>
          You must be authorized by your organization to accept these Terms and use the Enterprise
          Services. In addition, you must be authorized by a Plume business customer to use the
          Enterprise Service.<span>  </span>Please read these Terms carefully.<span>  </span>BY
          ACCESSING AND USING THE ENTERPRISE SERVICES, YOU AFFIRM THAT YOU HAVE READ AND UNDERSTAND
          THESE TERMS AND WILL COMPLY WITH THEM.<span>  </span>IF YOU DO NOT AGREE TO THESE TERMS
          YOU HAVE NO RIGHT TO USE THE ENTERPRISE SERVICES.
          <span> </span>
        </p>
        <p className={style.p4}>
          <b></b>
          <br />
        </p>
        <p className={style.p3}>
          <b>1.</b> <b>CHANGES TO TERMS. </b>The Effective Date of these Terms is set forth at the
          top of this webpage.<span>  </span>As we add new features to the Enterprise Services, we
          may need to modify these Terms.
          <span>  </span>When we modify these Terms, we will update the Effective Date and, when
          possible, we also will provide you with advance written notice of our changes.
          <span>  </span>We will not make changes that have a material retroactive effect unless we
          are legally required to do so or to protect the rights of other Site users.<span>  </span>
          Your continued use of the Enterprise Services after the Effective Date constitutes your
          acceptance of the amended Terms.<span>  </span>The amended Terms supersede all previous
          versions of or agreements, notices or statements of or about the Enterprise Services.
        </p>
        <p className={style.p4}>
          <br />
        </p>
        <p className={style.p3}>
          <b>2. ADDITIONAL TERMS. </b>Additional terms and conditions may apply to certain features
          of the Enterprise Services.<span>  </span>When presented to you, you must agree to the
          additional terms before using the features to which they apply.
          <span>  </span>These Terms and the additional terms will apply equally unless an
          additional term is irrevocably inconsistent with these Terms, in which case the additional
          term will prevail but solely to the extent of the inconsistency.
        </p>
        <p className={style.p4}>
          <br />
        </p>
        <p className={style.p3}>
          <b>
            3. ENTERPRISE SERVICES CONTENT.<span>  </span>
          </b>
          The Enterprise Services, including any information, graphic, text, trademark, logo and
          other content provided on, in or through the Enterprise Services (collectively,{' '}
          <b>
            <i>Services Content</i>
          </b>
          ) are owned by Plume or its licensors and protected under both United States and foreign
          copyright, trademark and other laws.<span>  </span>Plume grants to you a nonexclusive,
          nontransferable, and limited license (without the right to sublicense) to access and use
          the Enterprise Services and download, print and/or copy Services Content solely for your
          own use and subject to these Terms.
          <span>  </span>You acknowledge that the trade secrets and intellectual property embodied
          in the Enterprise Services are not and will not be licensed or otherwise disclosed to you.
          <span>  </span>All rights not expressly granted herein are reserved by Plume.
          <span>  </span>These Terms and your use of the Enterprise Services grant you only the
          limited rights described in these Terms and in Plume’s agreement with the Plume customer
          that authorized your use of the Enterprise Services (Customer Agreement).<span>  </span>
          Nothing contained in these Terms grants by implication, estoppel or otherwise, any license
          or right in or to Plume’s trademarks, logos or service marks (
          <b>
            <i>Plume Marks</i>
          </b>
          ), patents, trade secrets or other intellectual property embodied in the Enterprise
          Services.<span>  </span>Unauthorized use of any of the Plume Marks or our other
          intellectual property may violate law.
        </p>
        <p className={style.p4}>
          <br />
        </p>
        <p className={style.p3}>
          <b>
            4. USING THE ENTERPRISE SERVICES. <span> </span>
          </b>
        </p>
        <p className={style.p3}>
          <span className={style.s1}>Your Responsibilities</span>: You may use the Enterprise
          Services for lawful purposes only and in accordance with the Customer Agreement.
          <span>  </span>You may not use the Enterprise Services in any manner that could damage,
          disable, overburden or impair our servers or networks or interfere with any other party's
          use and enjoyment of the Enterprise Services.
          <span> </span>
        </p>
        <p className={style.p5}>
          You may not attempt to gain unauthorized access to the Enterprise Services or Plume’s
          computer systems or networks through hacking, password mining or any other means.
          <span>  </span>Without limiting any of the foregoing, you agree that you shall not (and
          you agree not to encourage or allow any third party to):
        </p>
        <ol className={style.ol1}>
          <li className={style.li3}>
            <span className={style.s2}></span>
            <span>
              copy, modify, adapt, translate, reverse engineer, decode or otherwise attempt to
              derive or gain access to any portion of the Enterprise Services or Services Content;
              <span> </span>
            </span>
          </li>
          <li className={style.li3}>
            <span className={style.s2}></span>
            <span>
              remove any copyright, trademark or other proprietary rights notice contained in the
              Enterprise Services or Services Content;<span> </span>
            </span>
          </li>
          <li className={style.li3}>
            <span className={style.s2}></span>
            <span>
              use any robot, spider, search/retrieval application or other automated device, process
              or means to access, retrieve, scrape or index any portion of the Enterprise Services;
              <span> </span>
            </span>
          </li>
          <li className={style.li3}>
            <span className={style.s2}></span>
            <span>
              rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or
              otherwise make available the Enterprise Services or any feature or functionality of
              the Enterprise Services to any third party for any reason;
            </span>
          </li>
          <li className={style.li3}>
            <span className={style.s2}></span>
            <span>
              reformat or frame any portion of the web pages that are part of the Enterprise
              Services;
            </span>
          </li>
          <li className={style.li3}>
            <span className={style.s2}></span>
            <span>
              collect or store personal data about any other user without his/her express prior
              written consent; or
            </span>
          </li>
          <li className={style.li3}>
            <span className={style.s2}></span>
            <span>
              use the Enterprise Services to send any unauthorized advertisement, junk mail or other
              unsolicited or unauthorized commercial or promotional content.
            </span>
          </li>
        </ol>
        <p className={style.p6}>
          <br />
        </p>
        <p className={style.p3}>
          Plume has the discretion to terminate your access to the Enterprise Services without
          notice for any violation of the above rules.
        </p>
        <p className={style.p7}>
          <br />
        </p>
        <p className={style.p3}>
          <span className={style.s1}>Submissions</span>.<span>  </span>Plume may from time to time
          offer areas in the Enterprise Services where you and other users can submit suggestions,
          ideas, notes, concepts or other information or materials to or through the Enterprise
          Services (collectively,{' '}
          <b>
            <i>Submissions</i>
          </b>
          ).<span>  </span>By sending, posting or transmitting Submissions to Plume (and/or our
          designees) through the Enterprise Services, you grant Plume and our designees a worldwide,
          non-exclusive, sub-licensable (through multiple tiers), transferable, royalty-free,
          perpetual, and irrevocable right to use, reproduce, sublicense (through multiple tiers),
          distribute, create derivative works of, perform and import your Submissions in any media
          now known or hereafter developed, for any purpose whatsoever, commercial or otherwise,
          without compensation to you.
          <span>  </span>In other words, Plume has the automatic right to use your Submissions --
          including reproducing, disclosing, publishing or broadcasting your Submissions -- in any
          medium and for any purpose.
          <span>  </span>Under no circumstances are you entitled to payment if Plume uses one of
          your Submissions.
          <span>  </span>All Submissions are deemed non-confidential and non-proprietary.{' '}
          <span> </span>
        </p>
        <p className={style.p4}>
          <br />
        </p>
        <p className={style.p3}>By submitting a Submission, you represent and warrant that:</p>
        <p className={style.p8}>
          &gt; <span className={style.appleTableSpan}> </span>you own or otherwise control all of
          the rights to your Submission, including without implied limitation all copyrights;
        </p>
        <p className={style.p8}>
          &gt; <span className={style.appleTableSpan}> </span>your Submission is true and accurate;
          <span> </span>
        </p>
        <p className={style.p8}>
          &gt; <span className={style.appleTableSpan}> </span>the individual that agreed to these
          Terms has the authority to bind the organization to these Terms, and you hereby agree to
          be bound by these Terms;
        </p>
        <p className={style.p8}>
          &gt;<span className={style.appleTableSpan}> </span>your Submission does not violate the
          rights of any other person or entity, such as rights of privacy and publicity; and
        </p>
        <p className={style.p8}>
          &gt; <span className={style.appleTableSpan}> </span>your Submission complies with
          applicable laws, rules and regulations.
        </p>
        <p className={style.p4}>
          <br />
        </p>
        <p className={style.p3}>
          You acknowledge and agree that Plume has the right (but not the obligation) to alter,
          remove or refuse to post or allow to be posted any Submission.
          <span>  </span>Plume takes no responsibility and assumes no liability for any Submission
          posted by you or any third party.
          <span>  </span>Plume is not responsible for information that you choose to communicate via
          Submissions.
        </p>
        <p className={style.p4}>
          <i></i>
          <br />
        </p>
        <p className={style.p3}>
          <b>
            5. DISCLAIMER OF WARRANTIES &amp; LIMITATIONS OF LIABILITY.
            <span>  </span>
          </b>
          Plume warrants that Plume has validly entered into these Terms and has the legal power to
          do so.<span>  </span>You warrant that you have validly entered into these Terms and have
          the legal power to do so.
        </p>
        <p className={style.p4}>
          <br />
        </p>
        <p className={style.p1}>
          <b>
            EXCEPT AS EXPRESSLY PROVIDED IN THE CUSTOMER AGREEMENT, THE ENTERPRISE SERVICES ARE
            PROVIDED "AS IS" AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED
          </b>
          .<span>  </span>Plume specifically disclaims all warranties and conditions of any kind,
          including all implied warranties and conditions of merchantability, fitness for a
          particular purpose, title, non-infringement, freedom from defects, uninterrupted use and
          all warranties implied from any course of dealing or usage of trade.<span>  </span>Plume
          does not warrant that (<span className={style.s1}>a</span>) the Enterprise Services will
          meet your requirements, (<span className={style.s1}>b</span>) operation of the Enterprise
          Services will be uninterrupted or virus- or error-free or (
          <span className={style.s1}>c</span>) errors will be corrected. <span> </span>
        </p>
        <p className={style.p2}>
          <b>
            6. PRIVACY.<span>  </span>
          </b>
          When you use the Enterprise Services, we collect from you or about how you use the
          Enterprise Services.<span>  </span>In, the following terms have the following meanings:
        </p>
        <ul className={style.ul1}>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>
              <b>
                <i>personal information</i>
              </b>
               means information that identifies or can be used to identify an individual person
              and, under some privacy laws that may apply to Plume, information that identifies a
              specific entity
            </span>
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>
              <b>
                <i>processing</i>
              </b>
               means any operation (or set if operations), such as collecting, combining and
              storing, performed on personal information
            </span>
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>
              <b>
                <i>controller</i>
              </b>
               means the person or entity that determines why and how personal information is
              processed, including a “business”
            </span>
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>
              <b>
                <i>processor</i>
              </b>
               means the person or entity processing personal information on behalf of a controller,
              including a “service provider”<span> </span>
            </span>
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>
              <b>
                <i>user</i>
              </b>{' '}
              means you and other individuals who access and use the Enterprise Services pursuant to
              a Customer Agreement
            </span>
          </li>
        </ul>
        <p className={style.p2}>
          Plume’s processing of personal information is subject to the laws in the jurisdictions in
          which Plume operates. Privacy rights also may vary depending on each user’s place of
          habitual residence.<span> </span>
        </p>
        <ol className={style.ol1}>
          <li className={style.li3}>
            <b></b>
            <span className={style.s5}>
              PERSONAL INFORMATION COLLECTION<b>: </b>
            </span>
            <span>
              Plume collects personal information from users to provide the Enterprise Services and
              for the other purposes listed below.<span>  </span>To make the Enterprise Services
              available, we collect user contact data, including name, email address or other
              username, password, telephone number and similar contact information, so that we can
              associate a user with a Customer Agreement.
              <span>  </span>This personal information is collected for the following purposes:
            </span>
          </li>
        </ol>
        <ul className={style.ul1}>
          <li className={style.li3}>
            <span className={style.s4}></span>To create and maintain a user account for the
            Enterprise Services
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>For customer service
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>For marketing, such as to send information that we
            think will interest users, which is sometimes personalized based on the information
            associated that Plume has
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>To request feedback, such as through a survey
          </li>
        </ul>
        <p className={style.p5}>
          The Enterprise Services automatically collect certain information from and about use of
          the Enterprise Services. Some automatically collected information is personal information
          under certain laws.<span>  </span>Information is automatically collected using cookies,
          pixel, web beacons and similar data collection technology and includes:<span> </span>
        </p>
        <ul className={style.ul1}>
          <li className={style.li1}>
            <span className={style.s4}></span>browser type, operating system version, domains, IP
            address, browser type, internet service provider and mobile network of users
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>how a computer or mobile device or interacts with the
            Enterprise Services, including the date and time accessed, mouse clicks and movements
            and similar log data
          </li>
        </ul>
        <p className={style.p3}>
          Please see Plume’s 
          <a href="https://www.plume.com/legal/cookie-policy">
            <span className={style.s1}>Cookie Policy</span>
          </a>{' '}
          for more information.
        </p>
        <p className={style.p4}>
          <br />
        </p>
        <p className={style.p2}>
          Plume also may receive personal information about users from customers, which Plume uses
          to provide access to the Enterprise Services.
        </p>
        <p className={style.p2}>
          Plume may ask users for consent to collect specific types of personal information, such to
          participate in testing new features related to the Enterprise Services.
        </p>
        <p className={style.p9}>
          <b>b)</b>{' '}
          <span className={style.s6}>
            <b>
              <span className={style.appleTableSpan}> </span>
            </b>
            PERSONAL INFORMATION USE
            <b>
              .<span>  </span>
            </b>
          </span>
          Plume uses personal information to provide and improve the Enterprise Services, manage our
          business, protect users and enforce our legal rights.
          <span>  </span>Plume processes personal information as a controller:
        </p>
        <ul className={style.ul1}>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>
              <b>For Plume’s lawful business purposes</b>:<span> </span>
            </span>
          </li>
          <ul className={style.ul1}>
            <li className={style.li1}>
              <span className={style.s7}></span>
              <span>
                monitor use of and protect the Enterprise Services and information processed through
                the Enterprise Services
              </span>
            </li>
            <li className={style.li1}>
              <span className={style.s7}></span>
              <span>track customer support</span>
            </li>
            <li className={style.li1}>
              <span className={style.s7}></span>
              <span>analyze, measure the effectiveness of and improve the Enterprise Services</span>
            </li>
            <li className={style.li1}>
              <span className={style.s7}></span>
              <span>identify user needs and develop new products and services to meet them</span>
            </li>
            <li className={style.li1}>
              <span className={style.s7}></span>
              <span>
                conduct marketing about the Enterprise Services or related third-party products and
                services (where permitted)
              </span>
            </li>
            <li className={style.li1}>
              <span className={style.s7}></span>
              <span>
                invite users to participate in market research and testing of current and new
                features<span> </span>
              </span>
            </li>
            <li className={style.li1}>
              <span className={style.s7}></span>
              <span>
                create statistical analyses and segment and combine data sets to identify trends
              </span>
            </li>
            <li className={style.li3}>
              <span className={style.s7}></span>
              <span>prevent, investigate and/or report security incidents</span>
            </li>
          </ul>
        </ul>
        <p className={style.p6}>
          <br />
        </p>
        <ul className={style.ul1}>
          <li className={style.li3}>
            <span className={style.s4}></span>
            <span>
              <b>To comply with applicable laws and protect legal rights:</b>
              <span> </span>
            </span>
          </li>
          <ul className={style.ul1}>
            <li className={style.li3}>
              <span className={style.s7}></span>
              <span>
                enforce and investigate actual or suspected violations of these Terms or a Customer
                Agreement
              </span>
            </li>
            <li className={style.li3}>
              <span className={style.s7}></span>
              <span>protect the safety, security and legal rights of users</span>
            </li>
            <li className={style.li3}>
              <span className={style.s7}></span>
              <span>
                detect, prevent and remediate fraud or other unlawful behavior and similar issues
                related to the Enterprise Services
              </span>
            </li>
          </ul>
        </ul>
        <p className={style.p10}>
          <br />
        </p>
        <ul className={style.ul1}>
          <li className={style.li3}>
            <span className={style.s4}></span>
            <span>
              <b>With consent</b>. Plume processes personal information as a controller based on
              consent for certain marketing activities and advertising practices.
            </span>
          </li>
        </ul>
        <p className={style.p6}>
          <br />
        </p>
        <p className={style.p2}>
          Plume also anonymizes personal information and uses the anonymized data as permitted by
          applicable law and the relevant Customer Agreements. (Once personal information is
          anonymized in compliance with applicable law, it is no longer personal information and not
          subject to this Section 6.)
        </p>
        <p className={style.p9}>
          <span className={style.s6}>
            <b>
              c) <span className={style.appleTableSpan}> </span>
            </b>
            PERSONAL INFORMATION SHARING
            <b>
              .<span>  </span>
            </b>
          </span>
          Plume shares personal information with the customers through which a user has access to
          the Enterprise Services, as well as people and businesses that help Plume operate the
          Enterprise Services and otherwise when Plume is legally permitted or required to do so.
          <span>  </span>Plume shares personal information with the following categories of
          recipients:
        </p>
        <ul className={style.ul1}>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>
              Professional advisors, such as lawyers, accountants, insurers and information security
              and forensics experts.
            </span>
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>
              Processors to enable them to perform services on our behalf, including data security,
              cloud storage providers and otherwise to help us carry out our business.
            </span>
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>
              Marketing vendors that help promote the Enterprise Services (such as by email
              marketing) and supplement personal information that we already have
            </span>
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>
              Potential or actual acquirers or investors and their professional advisers in
              connection with any actual or proposed merger, acquisition or investment in or of all
              or any part of our business. We will use our best efforts to ensure that these Terms
              apply to personal information after the transaction or that users receive advance
              notice of changes to personal information processing.
            </span>
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>
              Competent law enforcement, government regulators and courts when we believe disclosure
              is necessary (i) to comply with the law, (ii) to exercise, establish or defend legal
              rights, or (iii) to protect the vital interests of users, business partners, service
              providers or another third party.
            </span>
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>
              Our affiliated entities / subsidiaries in Poland, Slovenia, Switzerland and Taiwan.
            </span>
          </li>
          <li className={style.li1}>
            <span className={style.s4}></span>
            <span>Other third parties with user consent.</span>
          </li>
        </ul>
        <p className={style.p2}>
          When Plume shares anonymized data, Plume takes administrative measures to prohibit efforts
          by recipients to re-identify anonymized data.
        </p>
        <p className={style.p9}>
          <span className={style.s6}>
            <b>d) </b>PERSONAL INFORMATION PROTECTION.
            <b>
              <span>  </span>
            </b>
          </span>
          Plume uses technical, physical, and administrative safeguards intended to protect personal
          information from unauthorized access and use. Our safeguards are designed to provide a
          level of security appropriate to the risk of processing your personal information and
          include (as applicable) measures to ensure the ongoing confidentiality, integrity,
          availability, and resilience of processing systems and a procedure for regularly testing,
          assessing and evaluating the effectiveness of technical and organizational measures for
          ensuring the security of the processing of personal information. Like any other
          organization, Plume cannot fully eliminate security risks associated with the processing
          of personal information. You are responsible for maintaining the security of your account
          credentials. Plume will treat access to the Enterprise Services through your account
          credentials as authorized by you. We may suspend your use of all or part of the Enterprise
          Services without notice if we suspect or detect any breach of security. If you believe
          that information you provided to Plume or your account is no longer secure, please notify
          us immediately at 
          <a href="mailto:privacy@plume.com">
            <span className={style.s1}>privacy@plume.com</span>
          </a>
          .
        </p>
        <p className={style.p11}>
          If we become aware of a breach that affects the security of your personal information, we
          will provide you with notice as required by applicable law. When permitted by applicable
          law, Plume will provide this notice to you through the email address associated with the
          relevant customer account.
        </p>
        <p className={style.p11}>
          UNAUTHORIZED ACCESS TO PERSONAL INFORMATION AND THE ENTERPRISE SERVICES – INCLUDING
          SCRAPING – IS PROHIBITED AND MAY LEAD TO CRIMINAL PROSECUTION.
        </p>
        <p className={style.p9}>
          <span className={style.s6}>
            <b>
              e) <span className={style.appleTableSpan}> </span>
            </b>
            PERSONAL INFORMATION RETENTION
            <b>
              .<span>  </span>
            </b>
          </span>
          Plume retains personal information for as long as necessary to carry out the purposes
          described above. Plume also retains personal information for as long as Plume believes
          necessary to comply with our legal obligations, resolve disputes and enforce our legal
          agreements. When determining the relevant retention period, Plume considers a user’s
          purpose for using the Enterprise Services, retention periods in Plume’s data deletion
          procedures and requirements of data protection laws. At the end of the relevant retention
          period, Plume either deletes or anonymizes personal information or, if Plume cannot delete
          or anonymize the personal information, then Plume segregates and securely stores personal
          information until deletion or anonymization is possible.
          <span> </span>
        </p>
        <p className={style.p9}>
          <span className={style.s6}>
            <b>
              f)<span className={style.appleTableSpan}> </span>
            </b>
            CHOICES AVAILABLE FOR PERSONAL INFORMATION.
            <b>
              <span>  </span>
            </b>
          </span>
          Choices about personal information depend on a user’s place of habitual residence and the
          applicable Enterprise Services.<span> </span>
        </p>
        <p className={style.p12}>
          <br />
        </p>
        <p className={style.p5}>
          <span className={style.s1}>For residents of the US</span>.<span>  </span>Please see our{' '}
          <a href="https://www.plume.com/legal/privacy-rights-notice/">
            <span className={style.s8}>Privacy Rights Notice (U.S.)</span>
          </a>{' '}
          for details. To make a privacy rights request, please use the{' '}
          <a href="https://discover.plume.com/US-Privacy-Rights-Request-Form.html">
            <span className={style.s8}>Privacy Request Form</span>
          </a>
          .<span> </span>
        </p>
        <p className={style.p7}>
          <span className={style.s1}></span>
          <br />
        </p>
        <p className={style.p5}>
          <span className={style.s1}>For residents of EEA, Switzerland and UK.</span>
        </p>
        <p className={style.p4}>
          <span className={style.s1}></span>
          <br />
        </p>
        <ul className={style.ul1}>
          <li className={style.li3}>
            <span className={style.s4}></span>
            <span className={style.s1}>Data Controller</span>. The controller for the personal
            information collected in connection with use of the Enterprise Services in the European
            Economic Area, Switzerland and United Kingdom is Plume Designs, Inc. <span> </span>
          </li>
          <li className={style.li3}>
            <span className={style.s4}></span>
            <span className={style.s1}>Processing</span>. Plume’s processing of your personal
            information is described above in this Section 6. <span> </span>
          </li>
          <li className={style.li3}>
            <span className={style.s4}></span>
            <span className={style.s1}>Legal Bases for Plume’s Processing</span>. The legal bases
            for Plume’s processing of your personal information depend on the context in which the
            personal information is collected and processed. Generally, we only collect personal
            information when:<span> </span>
          </li>
          <ul className={style.ul1}>
            <ul className={style.ul2}>
              <ul className={style.ul1}>
                <ul className={style.ul1}>
                  <li className={style.li3}>
                    <span className={style.s7}></span>Plume needs the personal information to
                    perform a contract (such as these Terms);<span> </span>
                  </li>
                  <li className={style.li3}>
                    <span className={style.s7}></span>when we have consent to do so (which consent
                    you can withdraw at any time using the contact information below); or
                    <span> </span>
                  </li>
                  <li className={style.li3}>
                    <span className={style.s7}></span>when the processing is in our legitimate
                    business interests and not overridden by the privacy or other fundamental rights
                    and freedoms of users (such as when we process personal information to prevent
                    fraudulent use of the Enterprise Services).
                    <span>  </span>When we collect and use personal information in reliance on our
                    legitimate interests (or those of any third party), this interest is to provide
                    the Enterprise Services and communicate with you about the Enterprise Services
                    and for responding to queries, improving the Enterprise Services, advising users
                    about new features or maintenance or similar commercial interests to make the
                    Enterprise Services available for you.
                  </li>
                </ul>
              </ul>
            </ul>
          </ul>
        </ul>
        <p className={style.p13}>
          <br />
        </p>
        <p className={style.p3}>
          We may have other legitimate interests and if appropriate we will make clear our
          legitimate interests at the relevant time.<span>  </span>In some cases, we also may have a
          legal obligation to collect personal information from users.<span>  </span>If we ask you
          to provide personal information to comply with a legal requirement, we will make this
          clear at the relevant time and advise you whether providing your personal information is
          mandatory and the possible consequences if you do not provide your personal information.
          <span>  </span>If you have questions about or need further information concerning the
          legal basis on which we collect and use your personal information, please contact us at
          privacy@plume.com.
        </p>
        <p className={style.p4}>
          <span className={style.s1}></span>
          <br />
        </p>
        <ul className={style.ul1}>
          <li className={style.li3}>
            <span className={style.s4}></span>
            <span className={style.s1}>Data Subjects Rights</span>. To the extent provided under
            GDPR and similar laws of Switzerland and UK, you have the following rights with respect
            to personal information concerning you:
          </li>
        </ul>
        <ul className={style.ul1}>
          <ul className={style.ul1}>
            <ul className={style.ul1}>
              <li className={style.li3}>
                <span className={style.s7}></span>Right of access your personal information
              </li>
              <li className={style.li3}>
                <span className={style.s7}></span>Right to rectification (i.e., correction, update)
              </li>
              <li className={style.li3}>
                <span className={style.s7}></span>Right to erasure<span> </span>
              </li>
              <li className={style.li3}>
                <span className={style.s7}></span>Right to restrict processing
                <span> </span>
              </li>
              <li className={style.li3}>
                <span className={style.s7}></span>Right to data portability (i.e., receive an
                electronic copy of your personal information for purposes of transmitting it to
                another organization)
              </li>
              <li className={style.li3}>
                <span className={style.s7}></span>Right to withdraw consent at any time
                <span> </span>
              </li>
            </ul>
          </ul>
        </ul>
        <p className={style.p4}>
          <br />
        </p>
        <p className={style.p3}>
          If you would like to access, correct, update, suppress, restrict, object to or delete
          personal information that you have previously provided to Plume or if you would like to
          receive an electronic copy of your personal information for purposes of transmitting it to
          another company (where this right to portability is provided to you by law), please submit
          your request to us as follows:
        </p>
        <ul className={style.ul1}>
          <li className={style.li3}>
            <span className={style.s7}></span>Our EU Data Protection Officer is available at
            privacy@plume.com
          </li>
          <li className={style.li14}>
            <span className={style.s9}></span>
            <span>
              Our{' '}
              <a href="https://www.plume.com/legal/data-subject-rights-request/">
                <span className={style.s8}>
                  data subject rights request<span> </span>
                </span>
              </a>
            </span>
          </li>
        </ul>
        <p className={style.p4}>
          <br />
        </p>
        <p className={style.p3}>
          In your request, please make clear what personal information you would like to have
          changed or other limitations you would like to put on our use of your personal
          information.<span>  </span>For your protection, we verify your identity and geographic
          residency before fulfilling your request. We will comply with your request as soon as
          reasonably practicable and within the time periods required by applicable law.
          <span>  </span>Please note that we often need to retain certain personal information for
          recordkeeping purposes and/or to complete any transaction that you began prior to
          submitting your request. We also may not delete certain personal information for legal
          reasons.
        </p>
        <p className={style.p4}>
          <br />
        </p>
        <ul className={style.ul1}>
          <li className={style.li3}>
            <span className={style.s4}></span>
            <span className={style.s1}>How to Contact Data Protection Authorities</span>
          </li>
        </ul>
        <p className={style.p4}>
          <i></i>
          <br />
        </p>
        <p className={style.p15}>
          <i>EU Data Protection Authorities</i>
        </p>
        <p className={style.p15}>
          You have a right to lodge a complaint about how we process your personal information with
          the appropriate EU data protection authority.<span>  </span>
          Please click{' '}
          <a href="https://ec.europa.eu/info/law/law-topic/data-protection/reform/what-are-data-protection-authorities-dpas_en">
            <span className={style.s8}>here</span>
          </a>{' '}
          for more information. <span> </span>
        </p>
        <p className={style.p13}>
          <br />
        </p>
        <p className={style.p15}>
          <i>Switzerland’s Data Protection Regulator</i>
        </p>
        <p className={style.p15}>
          Office of the Federal Data Protection and Information Commissioner (FDPIC)
        </p>
        <p className={style.p15}>Feldeggweg 1</p>
        <p className={style.p15}>CH - 3003 Berne</p>
        <p className={style.p15}>Tel: 41 (0)58 462 43 95</p>
        <p className={style.p15}>Telefax: 41 (0)58 465 99 96</p>
        <p className={style.p16}>
          <span className={style.s1}>
            <a href="https://www.edoeb.admin.ch/edoeb/en/home/deredoeb/kontakt.html">
              https://www.edoeb.admin.ch/edoeb/en/home/deredoeb/kontakt.html
              <span className={style.s8}></span>
            </a>
          </span>
        </p>
        <p className={style.p17}>
          <br />
        </p>
        <p className={style.p15}>
          <i>United Kingdom’s Data Protection Regulator</i>
        </p>
        <p className={style.p15}>Information Commissioner's Office (ICO)</p>
        <p className={style.p15}>Wycliffe House, Water Lane, Wilmslow Cheshire SK9 5AF</p>
        <p className={style.p15}>Telephone: 0303 123 1113</p>
        <p className={style.p15}>Fax: 01625 524510</p>
        <p className={style.p16}>
          <span className={style.s1}>
            <a href="https://ico.org.uk/global/contact-us/">
              https://ico.org.uk/global/contact-us/<span className={style.s8}></span>
            </a>
          </span>
        </p>
        <p className={style.p18}>
          <span className={style.s1}></span>
          <br />
        </p>
        <p className={style.p5}>
          To stop receiving promotional emails from Plume, please click the “Unsubscribe” link at
          the bottom of the email.<span>  </span>After you opt out, we may still send you
          non-promotional communications, such as administrative information about your account.
        </p>
        <p className={style.p7}>
          <br />
        </p>
        <p className={style.p5}>
          IF YOU RESIDE IN A JURISDICTION WITH PRIVACY LAWS THAT OFFER YOU PRIVACY RIGHTS NOT
          DESCRIBED IN THIS PRIVACY POLICY, PLEASE CONTACT PLUME AT{' '}
          <a href="mailto:privacy@plume.com">
            <span className={style.s1}>PRIVACY@PLUME.COM</span>
          </a>
          .<span>  </span>PLUME RESPECTS YOUR PRIVACY RIGHTS AND WILL DO OUR BEST TO ACCOMMODATE
          YOUR REQUESTS.
        </p>
        <p className={style.p7}>
          <br />
        </p>
        <p className={style.p19}>
          <b>g)</b>
          <span className={style.appleTableSpan}> </span>
          <span className={style.s6}>
            CROSS-BORDER TRANSFERS OF PERSONAL INFORMATION<b>. </b>
          </span>
          Plume may transfer personal information across borders to any of the places where Plume
          and its processors and partners do business. Other jurisdictions may have data protection
          laws that are different from (and, in some cases, less protective) than the laws where you
          reside.<span>  </span>If your personal information is transferred across borders by Plume
          or on Plume’s behalf, Plume uses appropriate safeguards to protect personal information in
          accordance with these Terms. These safeguards include agreeing to standard contractual
          clauses and similar model contracts for transfers of personal information among the Plume
          affiliates and among Plume’s suppliers and partners. When in place, these contracts
          require the affiliates, processors and partners to protect personal information in
          accordance with applicable privacy laws. To request information about Plume’s standard
          contractual clauses and other safeguards for cross-border personal information transfers,
          please contact{' '}
          <a href="mailto:privacy@plume.com">
            <span className={style.s8}>privacy@plume.com</span>
          </a>
          .
        </p>
        <p className={style.p20}>
          <br />
        </p>
        <p className={style.p5}>
          <b>
            7. GOVERNING LAW; DISPUTE RESOLUTION.<span>  </span>
          </b>
          These Terms shall be governed by, and any dispute relating to them shall be resolved in
          accordance with the dispute resolution terms in the Customer Agreement.
        </p>
        <p className={style.p7}>
          <b></b>
          <br />
        </p>
        <p className={style.p5}>
          <b>
            8. TERMINATION.<span>  </span>
          </b>
          These Terms automatically terminate when you fail to comply with any term or condition of
          them or when the Customer Agreement expires or terminates, whichever occurs sooner.
          <span>  </span>Plume may terminate or modify your access to the Enterprise Services, with
          or without notice to you, at any time for any reason.
          <span>  </span>Any provision that must survive to give proper effect to the intent and
          purpose of these Terms shall survive termination. <span> </span>
        </p>
        <p className={style.p7}>
          <br />
        </p>
        <p className={style.p3}>
          <b>9. MISCELLANEOUS. </b>These Terms contain the entire understanding by and between Plume
          and you with respect to the Enterprise Services. These Terms inure to the benefit of and
          are binding on Plume’s and your successors and assigns, respectively. Plume may assign
          these Terms to a successor in interest (in whole or in part) but you may not assign the
          Terms without the prior express written consent of Plume.
          <span>  </span>If any provision of these Terms is or becomes unenforceable or invalid, the
          remaining provisions will continue with the same effect as if such unenforceable or
          invalid provision was not used. If Plume fails or you fail to perform any obligation under
          these Terms and the other party does not enforce such obligation, the failure to enforce
          on any occasion will not constitute a waiver of any obligation and will not prevent
          enforcement on any other occasion. Nothing contained in these Terms creates a relationship
          or partnership, joint venture, or agency between Plume and you. If Plume is or you are
          prevented from performing or unable to perform any obligation under these Terms due to any
          cause beyond the reasonable control of the party invoking this provision, the affected
          party's performance will be extended for the period of delay or inability to perform due
          to such cause.
          <span> </span>
        </p>
        <p className={style.p21}>
          <br />
        </p>
        <p className={style.p5}>
          <b>QUESTIONS?</b>
        </p>
        <p className={style.p3}>
          If you have questions about the Enterprise Services or these Terms, please contact us at
          privacy@plume.com.
        </p>
      </div>
    )
  }
}
