import { FieldState, FormState } from 'formstate'
import { password, required } from 'utils/form/validators'
import { action, computed, observable } from 'mobx'
import { showAlert } from 'utils/show-alert'
import { RouterStore } from 'mobx-react-router'
import { OktaAuth } from '@okta/okta-auth-js'
import { errorsMap } from 'constants/errors'

interface ResetCompleteStoreOptions {
  redirectUri?: string
  recoveryToken?: string
}

export class PasswordResetCompleteStore {
  public password = new FieldState('').validators(required('New Password'), password)
  public passwordConfirm = new FieldState('').validators(required('Confirm New Password'))

  private formState = new FormState({
    password: this.password,
    passwordConfirm: this.passwordConfirm,
  }).validators($ => {
    if ($.password.value !== $.passwordConfirm.value) {
      return 'twoPasswordsMustMatch'
    }
  })

  private authClient: OktaAuth

  private transaction: any

  @observable
  public serverError = ''

  @observable
  public isLoading = false

  @observable
  public isInvalidToken = false

  constructor(private readonly routerStore: RouterStore, options: ResetCompleteStoreOptions) {
    if (!!options.recoveryToken) {
      this.authClient = new OktaAuth({
        issuer: process.env.OKTA_ISSUER,
        clientId: process.env.OKTA_CLIENT_ID,
      })
      this.initTransactionObject(options.recoveryToken)
    }
  }

  @computed
  public get error() {
    return this.formState.error || this.serverError
  }

  @action
  private async initTransactionObject(recoveryToken: string) {
    try {
      this.transaction = await this.authClient.verifyRecoveryToken({
        recoveryToken,
      })
    } catch (error) {
      this.handleServerError(error.message)
      if (error.errorCode === 'E0000105') this.setIsInvalidToken() // expired or used recoveryToken
    }
  }

  @action
  public async validateAndSubmit() {
    await this.formState.validate()

    if (!this.formState.hasError) {
      this.clearServerError()
      await this.submit()
    }
  }

  @action
  private async submit() {
    this.isLoading = true
    this.transaction
      .resetPassword({
        newPassword: this.passwordConfirm.value,
      })
      .then((transaction: any) => {
        if (transaction.status === 'SUCCESS') {
          this.handleSuccess()
        } else {
          throw `We cannot handle the ${transaction.status} status`
        }
      })
      .fail((error: any) => {
        console.error(error)
        this.handleServerError(error.message)
      })
  }

  @action
  private clearServerError() {
    this.serverError = ''
  }

  @action.bound
  private async handleSuccess() {
    this.isLoading = false
    await showAlert({
      title: 'auth.yourPasswordHasBeenReset',
      message: 'auth.youMayUseNewPasswordToAccessAccount',
      buttonText: 'btn.gotIt',
      isCloseShowing: true,
    })
    this.routerStore.replace('/')
  }

  @action.bound
  private handleServerError(error: string) {
    this.serverError = errorsMap[error] || error
    this.isLoading = false
  }

  @action.bound
  private setIsInvalidToken() {
    this.isInvalidToken = true
  }
}
