import * as React from 'react'
import { convertSecondsToHMS } from 'helpers/general-helpers'
import { useTranslation } from 'react-i18next'
// import { useInterval } from 'utils/hooks/use-interval'

export const TimeExpiryTicker = ({
  time,
  className,
  i18nKey = 'common.hmsExpiresIn',
}: {
  time: string
  i18nKey?: string
  className?: string
}) => {
  const { t } = useTranslation()
  const getRemainingSeconds = () => {
    const seconds = Math.round((new Date(time).getTime() - new Date().getTime()) / 1000)
    return seconds > 0 ? seconds : 0
  }

  React.useEffect(() => {
    const intervalId = window.setInterval(() => {
      if (getRemainingSeconds() > 0) {
        setRemainingSeconds(getRemainingSeconds())
      } else {
        window.clearInterval(intervalId)
        setRemainingSeconds(0)
      }
    }, 1000)

    return () => {
      window.clearInterval(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  const [remainingSeconds, setRemainingSeconds] = React.useState(getRemainingSeconds())

  return (
    <>
      {!!remainingSeconds && (
        <div className={className}>{t(i18nKey, convertSecondsToHMS(remainingSeconds))}</div>
      )}
    </>
  )
}
