export type HeaderColumn = { key: string; display: string }

const sanitizeCell = (s: string) => {
  if (`${s}`.includes(',')) {
    return `"${s}"`
  }
  return `${s}`
}

export class ExportStore {
  convertToCSV(array: Record<string, string>[], columnsOrder: string[]) {
    let str = ''

    for (let i = 0; i < array.length; i = i + 1) {
      let line = ''
      for (const key of columnsOrder) {
        if (line !== '') line += ','
        line += array[i][key] ? sanitizeCell(array[i][key]) : ''
      }
      str += `${line}\r\n`
    }
    return str
  }

  exportCSVFile(headers: HeaderColumn[], items: Record<string, string>[], fileName: string) {
    const headerRow = headers.reduce((headerRecord, column) => {
      headerRecord[column.key] = column.display
      return headerRecord
    }, {} as Record<string, string>)
    const columnsOrder = headers.map(h => h.key)

    const exportItems = [...items]
    exportItems.unshift(headerRow)
    const csv = this.convertToCSV(exportItems, columnsOrder)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.style.visibility = 'hidden'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
