import * as React from 'react'
import { Close } from 'components/icons/index'
import { Button } from 'components/button'
import { AppStore } from 'stores/app-store'
import { ApplicationsStore } from './applications-store'
import { observer } from 'mobx-react'
import { Loader } from 'components/loader'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Checkbox } from 'components/checkbox'
import style from './edit-tableau-modal.css'

interface EditTableauModalComponentProps extends WithTranslation {
  appStore?: AppStore
  onClose: () => Promise<void>
  applicationsStore?: ApplicationsStore
  redirectUrl: string
}

@observer
export class EditTableauModalComponent extends React.Component<EditTableauModalComponentProps> {
  private store: ApplicationsStore

  constructor(props: EditTableauModalComponentProps) {
    super(props)
    this.store = props.applicationsStore
  }

  private handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    // don't show me again (save to contact)
    if (this.store.hideTableau.value) {
      return this.store.validateAndSubmit(this.props.redirectUrl)
    }

    return this.store.redirectTo(this.props.redirectUrl)
  }

  render() {
    const { onClose, t } = this.props
    const {
      store: { hideTableau, serverError, isLoadingModal },
    } = this

    return (
      <div className={style.root}>
        <div className={style.title}>{t('applications.tableauModalTitle')}</div>
        <div className={style.closeButton}>
          <Close onClick={onClose} />
        </div>

        <div className={style.content}>{t('applications.tableauModalDesc')}</div>

        <form onSubmit={this.handleFormSubmit}>
          <div className={style.checkboxWrapper}>
            <Checkbox
              name="hide-tableau"
              checked={hideTableau.value}
              onChange={e => hideTableau.onChange(e.target.checked)}
            />
            <div className={style.checkboxWrapperText}>{t('common.dontShowMeAgain')}</div>
          </div>

          {serverError && <div className={style.serverError}>{serverError}</div>}
          <div className={style.buttonWrapper}>
            {this.store.isLoadingModal && <Loader />}
            <Button
              type="submit"
              disabled={isLoadingModal}
              className={style.actionButton}
              autoFocus
              fullWidth
            >
              {t('btn.continue')}
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

export const EditTableauModal = withTranslation()(EditTableauModalComponent)
