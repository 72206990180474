import * as React from 'react'
import { Close, CopyToClipboard } from 'components/icons'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { Tooltip } from 'components/tooltip'
import { AppStore } from 'stores/app-store'
import { UiStore } from 'stores/ui-store'
import { DashboardUsersStore } from '../user-store'
import { inject, observer } from 'mobx-react'
import { IUserApi } from 'interfaces/api/portal/user-api'
import { Loader } from 'components/loader'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { AuthStore } from 'stores/auth-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import { supportEmails } from 'constants/support-emails'
import style from './clone-user-modal.css'
import { salesforcePortalProducts } from 'constants/salesforce.constants'

interface CloneUserState {
  copiedUsername: boolean
  copiedPassword: boolean
}

interface CloneUserProps extends WithTranslation {
  appStore?: AppStore
  onClose: () => Promise<void>
  uiStore?: UiStore
  userApi?: IUserApi
  usersStore?: DashboardUsersStore
  modalLayerStore?: ModalLayerStore
  authStore?: AuthStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  appStore,
  uiStore: appStore.uiStore,
  usersStore: appStore.usersStore,
  modalLayerStore: appStore.actionModalStore,
  authStore: appStore.authStore,
}))
@observer
class CloneUserComponent extends React.Component<CloneUserProps, CloneUserState> {
  private store: DashboardUsersStore
  private usernameInputRef = React.createRef<HTMLInputElement>()
  private passwordInputRef = React.createRef<HTMLInputElement>()

  constructor(props: CloneUserProps) {
    super(props)
    this.store = props.usersStore
    this.state = { copiedUsername: false, copiedPassword: false }
  }

  private handleFormSubmit = (event: React.FormEvent) => {
    const {
      store: { editUserId },
    } = this

    event.preventDefault()
    this.store.cloneUser(editUserId)
  }

  getSupportMailAddress = () => {
    const { currentUser } = this.props.authStore
    const { company } = currentUser

    const areAnyPortalProductsPurchased =
      company?.productsSummary?.purchased &&
      !!Object.keys(company?.productsSummary?.purchased).find(p =>
        salesforcePortalProducts.includes(p),
      )

    const supportEmail =
      areAnyPortalProductsPurchased || company.isChannelPartnerPortalCompany
        ? supportEmails.PARTNER_SUPPORT
        : company.ownerEmail

    return supportEmail
  }

  handleCopyUsernameToClipboard = () => {
    const usernameText = this.usernameInputRef.current

    usernameText.select()
    usernameText.setSelectionRange(0, 99999)
    document.execCommand('copy')

    this.setState({ copiedUsername: true })
  }

  handleCopyPasswordToClipboard = () => {
    const passwordText = this.passwordInputRef.current

    passwordText.select()
    passwordText.setSelectionRange(0, 99999)
    document.execCommand('copy')

    this.setState({ copiedPassword: true })
  }

  render() {
    const { onClose, t } = this.props
    const {
      store: {
        cloneLogin,
        clonePassword,
        serverError,
        firstName,
        lastName,
        cloneUserGenerated,
        cloneUserGeneratedError,
      },
    } = this
    const name = [firstName.value, lastName.value].join(' ')
    const mailAddress = this.getSupportMailAddress()

    return (
      <div className={style.root}>
        <div className={style.subTitle}>{t('users.cloneUserTitle')}</div>
        {(firstName.value || lastName.value) && !cloneUserGenerated && (
          <div className={style.resetPasswordNoticeDesc}>
            {t('users.cloneUserNoticeDesc', { name })}
          </div>
        )}
        <div className={style.resetPasswordDesc}>
          {cloneUserGenerated ? t('users.cloneUserGeneratedDesc') : t('users.cloneUserDesc')}
        </div>
        {!cloneUserGenerated && cloneUserGeneratedError && (
          <div className={style.errorWarning}>
            {t('errors.errorCloningUser')}{' '}
            {cloneUserGeneratedError && `(${t(`errors.${cloneUserGeneratedError}`)})`}
            <div>
              {t('errors.pleaseContact')} <a href={`mailto:${mailAddress}`}>{mailAddress}</a>
            </div>
          </div>
        )}
        <div className={style.closeButton}>
          <Close onClick={onClose} />
        </div>
        <form onSubmit={this.handleFormSubmit}>
          {cloneUserGenerated && (
            <>
              <div className={style.inputWrapper}>
                <Input
                  className={style.input}
                  inputStyle={{ paddingRight: 24 }}
                  autoFocus
                  label="Username"
                  hasBeenValidated={cloneLogin.hasBeenValidated}
                  value={cloneLogin.value}
                  inputRef={this.usernameInputRef}
                  readOnly
                  error={cloneLogin.error || !!serverError}
                  onChange={e => cloneLogin.onChange(e.target.value)}
                />
                <Tooltip
                  placement="top"
                  onVisibleChange={visible => {
                    if (!visible) {
                      this.setState({ copiedUsername: false })
                    }
                  }}
                  overlay={
                    <span className={style.tooltipText}>
                      {this.state.copiedUsername
                        ? t('tooltip.copied')
                        : t('tooltip.copyToClipboard')}
                    </span>
                  }
                >
                  <span
                    className={style.copyToClipboard}
                    onClick={this.handleCopyUsernameToClipboard}
                  >
                    <CopyToClipboard />
                  </span>
                </Tooltip>
              </div>
              <div className={style.inputWrapper}>
                <Input
                  className={style.input}
                  inputStyle={{ paddingRight: 28 }}
                  autoFocus
                  label="Password"
                  hasBeenValidated={clonePassword.hasBeenValidated}
                  value={clonePassword.value}
                  inputRef={this.passwordInputRef}
                  readOnly
                  error={clonePassword.error || !!serverError}
                  onChange={e => clonePassword.onChange(e.target.value)}
                />
                <Tooltip
                  placement="top"
                  onVisibleChange={visible => {
                    if (!visible) {
                      this.setState({ copiedPassword: false })
                    }
                  }}
                  overlay={
                    <span className={style.tooltipText}>
                      {this.state.copiedPassword
                        ? t('tooltip.copied')
                        : t('tooltip.copyToClipboard')}
                    </span>
                  }
                >
                  <span
                    className={style.copyToClipboard}
                    onClick={this.handleCopyPasswordToClipboard}
                  >
                    <CopyToClipboard />
                  </span>
                </Tooltip>
              </div>
            </>
          )}
          <div className={style.btnDiv}>
            {this.store.isLoadingModal && <Loader />}
            {cloneUserGenerated ? (
              <Button className={style.actionBtn} onClick={onClose}>
                {t('btn.close')}
              </Button>
            ) : (
              <Button
                className={style.actionBtn}
                type="submit"
                disabled={this.store.isLoadingModal}
              >
                {t('btn.continue')}
              </Button>
            )}
          </div>
        </form>
      </div>
    )
  }
}

export const CloneUserModal = withTranslation()(CloneUserComponent)
