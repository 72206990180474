import * as React from 'react'
import style from './logo-upload.css'
import { Close } from 'components/icons/index'
import { Button } from 'components/button'
import { useStores } from 'utils/hooks/useStores'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { LogoUploadStore } from './logo-upload-store'
import { Tabs } from 'plume-ui'
import classNames from 'classnames'
import { PlumeServiceProducts } from 'enums/plume-product.enum'

interface UploadLogoProps {
  onClose: () => Promise<void>
}

export const AppLogoUploadModal = observer((props: UploadLogoProps) => {
  const { t } = useTranslation()
  const appStore = useStores()
  const company = appStore.authStore.currentUser.company

  const [store] = React.useState(() => {
    const luStore = new LogoUploadStore(appStore)
    luStore.initLogoUploadStore()
    return luStore
  })

  const [pdfPageClass, setPdfPageClass] = React.useState(style.logoPdfPageHeight)

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    store.validateAndSubmitAppLogo()
  }

  const checkIfImageType = (fileName: string): boolean => {
    const fileExtension = fileName.split('.').pop().toLocaleLowerCase()
    const extensions = ['jpg', 'jpeg', 'png', 'svg', 'gif']
    if (extensions.includes(fileExtension)) {
      return true
    }
    return false
  }

  const isFileExtensionPdf = (fileName: string): boolean => {
    return fileName.split('.').pop().toLocaleLowerCase() === 'pdf'
  }

  const onFileChangeHandler = (event: React.FormEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files[0]
    if (file && file.size > 2097152) {
      store.setOriginalFileError(t('validators.sizeTooLarge'))
    } else if (file && checkIfImageType(file.name)) {
      const img = new Image()
      const objectUrl = window.URL.createObjectURL(file)
      img.src = objectUrl

      img.onload = () => {
        store.setOriginalFile(file)
        store.setObjectUrl(objectUrl)
      }
    } else if (file && isFileExtensionPdf(file.name)) {
      store.setOriginalFile(file)
    } else {
      store.setOriginalFileError(t('validators.onlyImagePdfAllowed'))
    }
  }

  const onDocumentLoadSuccess = (data: any) => {
    const width = data.originalWidth
    const height = data.originalHeight
    if (
      !!width &&
      !!height &&
      ((width >= height && width < 4 * height && height > 100) || width <= height)
    ) {
      setPdfPageClass(style.logoPdfPageHeight)
    } else {
      setPdfPageClass(style.logoPdfPageWidth)
    }
  }
  const onDocumentLoadError = () => {
    store.setOriginalFileError(t('logoUpload.failedPdfLoad'))
  }

  const handleRemoveFile = () => {
    store.removeOriginalFile()
  }

  const { onClose } = props
  const {
    isLoadingModal,
    serverError,
    originalFileError,
    logoProductsData,
    selectedProduct,
    originalFileExists,
    isUrlPdf,
  } = store

  const { originalFile, originalFileUrl, isOriginalFileEdited, objectUrl } =
    logoProductsData[selectedProduct]
  const renderImage = !originalFile && originalFileUrl && !isOriginalFileEdited
  const renderInput = !originalFile && !originalFileUrl

  const companyHasBothProducts =
    company.profiles.includes('HomePass') && company.profiles.includes('WorkPass')

  const isFilePDF = isUrlPdf
    ? true
    : originalFile
    ? originalFile.name.split('.').pop().toLocaleLowerCase() === 'pdf'
    : false

  const acceptedFileFormats = 'image/svg+xml, image/png, image/jpeg, image/gif, application/pdf'

  const tabOptions = PlumeServiceProducts.map(p => ({ id: p, label: p }))
  return (
    <div className={style.root}>
      <div>
        <div className={style.subTitle}>
          {t(
            'logoUpload.title',
            !companyHasBothProducts ? { product: selectedProduct } : undefined,
          )}
        </div>
        <div className={style.closeButton}>
          <Close onClick={onClose} />
        </div>
        {companyHasBothProducts && (
          <Tabs
            classes={curr => ({ ...curr, root: classNames(curr.root, style.centerTabs) })}
            active={0}
            tabType="flatTabs"
            options={tabOptions}
            onSelectTab={(tab: (typeof tabOptions)[number]) => {
              store.selectProduct(tab.id)
            }}
          />
        )}
        <form onSubmit={handleFormSubmit}>
          <div>
            <div>
              <div className={style.labelInfoText}>{t('logoUpload.maxSizeMessage')}</div>

              {renderInput && (
                <div>
                  <input
                    id="imageFile"
                    className={style.fileInput}
                    type="file"
                    accept={acceptedFileFormats}
                    name="imageFile"
                    onChange={onFileChangeHandler}
                  />
                  <div className={style.fileSelection}>
                    <label htmlFor="imageFile" className={style.labelLogoTitle}>
                      {t('logoUpload.logoHere')}
                    </label>
                    <label htmlFor="imageFile" className={style.labelLogoText}>
                      {t('logoUpload.selectFileForUpload')}
                    </label>
                  </div>
                </div>
              )}
              {(originalFile || renderImage) && (
                <div>
                  <div className={style.labelPreviewTitle}>
                    {t('logoUpload.appLogoMessage', { product: selectedProduct })}
                  </div>
                  <div className={style.previewLogoWrapper}>
                    <div className={style.previewLogoOriginalBackground}>
                      {isFilePDF && (
                        <Document
                          file={originalFile || originalFileUrl}
                          className={style.previewLogoOriginalPdf}
                          renderMode="svg"
                          onLoadError={onDocumentLoadError}
                        >
                          <Page
                            pageNumber={1}
                            onLoadSuccess={onDocumentLoadSuccess}
                            className={pdfPageClass}
                            onLoadError={onDocumentLoadError}
                          />
                        </Document>
                      )}

                      {originalFile && !isFilePDF && (
                        <img
                          src={objectUrl}
                          alt="Logo"
                          className={style.previewLogoOriginalImage}
                        />
                      )}

                      {renderImage && !isFilePDF && (
                        <img
                          src={originalFileUrl}
                          alt="Logo"
                          className={style.previewLogoOriginalImage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}

              {(originalFile || !!renderImage) && (
                <div>
                  <span className={style.labelRemoveFile} onClick={handleRemoveFile}>
                    {t('btn.remove')}
                  </span>
                </div>
              )}

              <div className={style.btnDiv}>
                <Button
                  className={style.sendInviteBtn}
                  type="submit"
                  disabled={
                    !!originalFileError ||
                    !originalFileExists ||
                    !!serverError ||
                    !isOriginalFileEdited
                  }
                >
                  {t('appCustomization.uploadLogo', { product: selectedProduct })}
                </Button>
              </div>

              {!!originalFileError && <div className={style.error}>{originalFileError}</div>}
              {!isOriginalFileEdited && !renderInput && (
                <div className={style.labelInfoText}>{t('logoUpload.alreadyUploaded')}</div>
              )}
            </div>

            {serverError && (
              <div className={style.error}>
                {serverError} {t('errors.pleaseReload')}
              </div>
            )}
            {isLoadingModal && <Loader />}
          </div>
        </form>
      </div>
    </div>
  )
})
