import * as React from 'react'
import style from './initial-opening-homes.css'
import { Button } from 'components/button'
import { Close } from 'components/icons'
import { useTranslation } from 'react-i18next'

export interface IInitialOpeningHomesProps {
  onClose: () => void
}

export const InitialOpeningHomes = (props: IInitialOpeningHomesProps) => {
  const { t } = useTranslation()

  return (
    <div className={style.root}>
      <div className={style.rootContent}>
        <div className={style.closeButton}>
          <Close onClick={props.onClose} />
        </div>
        <div className={style.title}>{t('home.welcomeMsg')},</div>
        <div className={style.content}>{t('home.welcomeDesc')}</div>
        <div className={style.buttonWrapper}>
          <Button className={style.actionButton} fullWidth onClick={props.onClose}>
            {t('btn.gotIt')}!
          </Button>
        </div>
      </div>
    </div>
  )
}
