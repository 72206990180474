import * as React from 'react'
import { Close } from 'components/icons/index'
import { Button } from 'components/button'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { useTranslation } from 'react-i18next'
import style from './secondary-cloud-management.css'
import { ProgressBar } from 'components/progress-bar/progress-bar'
import { SecondaryCloud } from 'enums/secondary-cloud.enum'
import { Checkbox } from 'plume-ui'
import classNames from 'classnames'
import { Tooltip } from 'components/tooltip'
import { useStores } from 'utils/hooks/useStores'

interface SecondaryCloudManagementProps {
  secondaryCloud: SecondaryCloud
  onClose: () => Promise<void>
}

export const SecondaryCloudManagement = observer((props: SecondaryCloudManagementProps) => {
  const { t } = useTranslation()
  const appStore = useStores()
  const accountStore = appStore.accountStore

  const { onClose, secondaryCloud } = props
  const { serverError, isLoadingModal, secondaryCloudCompanyFields, secondaryCloudProgress } =
    accountStore

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    accountStore.submitSecondaryCloudSettings(secondaryCloud)
  }

  const progressBarWidth =
    secondaryCloudProgress?.[secondaryCloud]?.count /
      secondaryCloudProgress?.[secondaryCloud]?.total || 0

  return (
    <div className={style.root}>
      <div className={style.subTitle}>
        {t('accountManager.secondaryCloudSettingsTitle', { secondaryCloud })}
      </div>
      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>
      {!!progressBarWidth ? (
        <div>
          <div className={style.label}>{t('accountManager.secondaryCloudProgress')}</div>
          <ProgressBar width={progressBarWidth} />
        </div>
      ) : (
        <form onSubmit={handleFormSubmit}>
          {secondaryCloudCompanyFields[secondaryCloud] ? (
            <>
              <Tooltip
                placement="top"
                overlay={t('accountManager.secondaryCloudCantBeDisabledOnceEnabled', {
                  secondaryCloud,
                })}
                align={{
                  offset: [0, -5],
                }}
                trigger={['hover']}
              >
                <div>
                  <Checkbox
                    label={t('accountManager.secondaryCloudEnabled', { secondaryCloud })}
                    name={'accountManager.secondaryCloudEnabled'}
                    disabled={true}
                    classes={curr => ({
                      ...curr,
                      root: classNames(curr.root, style.checkboxModifications),
                    })}
                    checked={true}
                    onChange={() => undefined}
                  />
                </div>
              </Tooltip>

              <div className={style.regenerateLabel}>
                {t('accountManager.secondaryCloudRegenerateDescription')}
              </div>
            </>
          ) : (
            <>
              <div className={style.warning}>
                {t('accountManager.secondaryCloudCantBeDisabledOnceEnabled', { secondaryCloud })}
              </div>
              <div className={style.label}>
                {t('accountManager.secondaryCloudSettingsEnabled', { secondaryCloud })}
              </div>
            </>
          )}
          {serverError && <div className={style.serverError}>{serverError}</div>}
          <div className={style.btnDiv}>
            {isLoadingModal && <Loader />}
            <Button
              className={style.sendInviteBtn}
              type="submit"
              disabled={isLoadingModal || !!serverError}
            >
              {secondaryCloudCompanyFields[secondaryCloud]
                ? t('accountManager.secondaryCloudRegenerate')
                : t('btn.enable')}
            </Button>
          </div>
        </form>
      )}
    </div>
  )
})
