import { IDashboardApi } from 'interfaces/api/plume-cloud/dashboard-api'
import * as dashboardQuery from './graphql/dashboard-query.graphql'

import { ApolloClient } from 'apollo-client'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import graphqlTag from 'graphql-tag'
import moment = require('moment')
import { LocalStorage } from 'utils/storage/local/local-storage'
import { DashboardQuery } from 'interfaces/api/plume-cloud/gql/DashboardQuery'

interface DashboardQueryVars {
  partnerId: string
}

export class DashboardApi implements IDashboardApi {
  private client: ApolloClient<NormalizedCacheObject>
  private storage: LocalStorage

  constructor(private readonly cloudEnvironmentUrl: string, idToken: string) {
    const cache = new InMemoryCache()
    const link = new HttpLink({
      uri: `${this.cloudEnvironmentUrl}reports/portal`,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
    this.client = new ApolloClient({
      cache,
      link,
    })
    this.storage = new LocalStorage()
  }
  public async getDashboardData(partnerId: string) {
    const graphData = this.storage.getJson('graph-storage') || {
      data: [],
    }
    const graphDataItem = graphData.data.find((dataItem: any) => dataItem.content.id === partnerId)
    if (graphDataItem) {
      if (moment.utc(graphDataItem.expiresAt).isBefore(moment.utc())) {
        graphData.data = graphData.data.filter((dataItem: any) => dataItem.content.id !== partnerId)
        this.storage.remove('graph-storage')
      } else {
        return graphDataItem.content
      }
    }

    try {
      const res = await this.client.query<DashboardQuery, DashboardQueryVars>({
        query: graphqlTag`${dashboardQuery}`,
        variables: { partnerId },
      })

      const { partner: partnerData } = res.data
      graphData.data.push({
        content: partnerData,
        expiresAt: moment.utc().endOf('day'),
      })
      this.storage.setJson('graph-storage', graphData)

      return partnerData
    } catch (e) {
      console.log(e.message)
    }
  }
}
