import * as React from 'react'
import { Close } from 'components/icons/index'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import style from './view-partner-config-get-calls.css'
import { mixpanelActions } from 'utils/mixpanelActions'
import { Tabs, Accordion, AccordionItem as AccordionItemTyped } from 'plume-ui'
import JSONPretty from 'react-json-pretty'
import { SalesforceCloud } from '../../../../constants/salesforce.constants'
import classNames from 'classnames'

const AccordionItem = AccordionItemTyped as any

const jsonPrettyTheme = require('react-json-pretty/dist/monikai')

interface VPCGCProps {
  onClose: () => Promise<void>
}

export const ViewPartnerConfigGetCalls = observer((props: VPCGCProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const company = appStore?.authStore?.currentUser?.company
  const { onClose } = props

  const [chosenCloud, setChosenCloud] = React.useState<SalesforceCloud>(null)

  const { partnerConfigResponses, partnerConfigResponsesError } = appStore.accountStore

  React.useEffect(() => {
    mixpanelActions.track('View Partner Config GET calls', {
      'Partner Id': company?.partnerId,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    appStore.accountStore
      .getPartnerConfigResponses()
      .then(() => setChosenCloud(appStore.accountStore?.partnerConfigResponses?.[0]?.cloud))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabOptions = partnerConfigResponses?.map(response => ({
    id: response.cloud,
    label: response.cloud,
  }))

  const isLoading = !partnerConfigResponsesError && partnerConfigResponses === null

  return (
    <div className={style.root}>
      <div className={style.subTitle}>{t('partnerConfigGetCalls.title')}</div>
      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>

      {isLoading ? (
        <Loader rootClassName={style.loaderToFront} />
      ) : partnerConfigResponses !== null ? (
        <>
          <Tabs
            classes={curr => ({ ...curr, root: classNames(curr.root, style.centerTabs) })}
            active={0}
            tabType="flatTabs"
            options={tabOptions}
            onSelectTab={(tab: (typeof tabOptions)[number]) => {
              setChosenCloud(tab.id)
            }}
          />
          <Accordion>
            {partnerConfigResponses
              ?.find(res => res.cloud === chosenCloud)
              ?.responses?.map(endpoint => (
                <AccordionItem
                  title={endpoint?.url}
                  key={endpoint?.url}
                  content={<JSONPretty theme={jsonPrettyTheme} data={endpoint?.response} />}
                ></AccordionItem>
              ))}
          </Accordion>
        </>
      ) : (
        <>
          {partnerConfigResponsesError && (
            <div className={style.serverError}>{partnerConfigResponsesError}</div>
          )}
        </>
      )}
    </div>
  )
})
