import * as React from 'react'
import style from './password-description.css'
import { withTranslation, WithTranslation } from 'react-i18next'

export class PasswordDescriptionComponent extends React.Component<WithTranslation> {
  render() {
    const { t } = this.props
    return (
      <div className={style.root}>
        <p className={style.description}>
          {t('validators.passwordRequirements')}
          {/* Your password must be at least 8 characters, contain at least one
          uppercase letter, one lowercase letter, one number and one
          non-alphanumberic character. */}
        </p>
      </div>
    )
  }
}

export const PasswordDescription = withTranslation()(PasswordDescriptionComponent)
