import * as React from 'react'
import { Close } from 'components/icons/index'
import { Button } from 'components/button'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import style from './global-auth.css'
import { mixpanelActions } from 'utils/mixpanelActions'
import classNames from 'classnames'
import { TextToggler, Tabs } from 'plume-ui'
import { getTypedObjectKeys } from '../../../../helpers/general-helpers'
import { SalesforceCloud } from '../../../../constants/salesforce.constants'
import { isGlobalAuthProductEnabledOnCloud } from './global-auth.helpers'
import { GlobalAuthFeatureFlagsRequest } from '../../../../interfaces/api/portal/company-api'
import commonStyle from '../../../../style/commonly-used.css'

interface GlobalAuthProps {
  onClose: () => Promise<void>
}

export const GlobalAuth = observer((props: GlobalAuthProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const company = appStore?.authStore?.currentUser?.company
  const { onClose } = props

  const { serverError, isLoadingModal, partnerConfigFeatureFlagsAllClouds } = appStore.accountStore

  const [chosenCloud, setChosenCloud] = React.useState(
    getTypedObjectKeys(partnerConfigFeatureFlagsAllClouds)?.[0],
  )
  const [globalAuthHomePass, setGlobalAuthHomePass] = React.useState(
    isGlobalAuthProductEnabledOnCloud(partnerConfigFeatureFlagsAllClouds, chosenCloud, 'HomePass'),
  )
  const [globalAuthWorkPass, setGlobalAuthWorkPass] = React.useState(
    isGlobalAuthProductEnabledOnCloud(partnerConfigFeatureFlagsAllClouds, chosenCloud, 'WorkPass'),
  )

  React.useEffect(() => {
    mixpanelActions.track('Global Auth - Modal Open', {
      'Partner Id': company?.partnerId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const oc = style.override
  const submitDisabled = isLoadingModal

  const handleSelectTab = (sfCloud: SalesforceCloud) => {
    setChosenCloud(sfCloud)
    setGlobalAuthHomePass(
      isGlobalAuthProductEnabledOnCloud(partnerConfigFeatureFlagsAllClouds, sfCloud, 'HomePass'),
    )
    setGlobalAuthWorkPass(
      isGlobalAuthProductEnabledOnCloud(partnerConfigFeatureFlagsAllClouds, sfCloud, 'WorkPass'),
    )
  }

  const tabOptions = getTypedObjectKeys(partnerConfigFeatureFlagsAllClouds).map(cloud => ({
    id: cloud,
    label: cloud,
  }))

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (submitDisabled) {
      return
    }
    const payload: GlobalAuthFeatureFlagsRequest = {
      HomePass: globalAuthHomePass,
      WorkPass: globalAuthWorkPass,
      cloud: chosenCloud,
    }
    appStore.accountStore.submitGlobalAuthFeatureFlags(payload)
  }

  return (
    <div className={style.root}>
      <div className={style.subTitle}>{t('globalAuth.title')}</div>
      <div className={style.labelText}>{t('globalAuth.description')}</div>
      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>

      <Tabs
        classes={curr => ({ ...curr, root: classNames(curr.root, style.centerTabs) })}
        active={0}
        tabType="flatTabs"
        options={tabOptions}
        onSelectTab={(tab: (typeof tabOptions)[number]) => {
          handleSelectTab(tab.id)
        }}
      />

      <div className={commonStyle.margin10px}></div>

      <form onSubmit={handleFormSubmit}>
        <TextToggler
          classes={current => ({
            ...current,
            root: classNames(current.root, oc, style.toggleContainer),
            toggleContainer: classNames(current.toggleContainer, oc, style.toggleAdjustment),
          })}
          title={t('globalAuth.globalAuthHomePass')}
          selected={globalAuthHomePass}
          onToggle={() => setGlobalAuthHomePass(!globalAuthHomePass)}
        />

        <TextToggler
          classes={current => ({
            ...current,
            root: classNames(current.root, oc, style.toggleContainer),
            toggleContainer: classNames(current.toggleContainer, oc, style.toggleAdjustment),
          })}
          title={t('globalAuth.globalAuthWorkPass')}
          selected={globalAuthWorkPass}
          onToggle={() => setGlobalAuthWorkPass(!globalAuthWorkPass)}
        />

        {serverError && <div className={style.serverError}>{serverError}</div>}
        <div className={style.btnDiv}>
          {isLoadingModal && <Loader />}
          <Button className={style.sendInviteBtn} type="submit" disabled={submitDisabled}>
            {t('btn.save')}
          </Button>
        </div>
      </form>
    </div>
  )
})
