import * as React from 'react'
import style from './add-password-success.css'
import { Footer } from 'components/footer'

const Header = () => <header className={style.header} />

export class AddPasswordSuccess extends React.Component {
  render() {
    return (
      <section>
        <div>
          <Header />
          <div className={style.title}>Password successfully set!</div>
          <Footer />
        </div>
      </section>
    )
  }
}
