import * as React from 'react'
import { Close, CopyToClipboard } from 'components/icons'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { Tooltip } from 'components/tooltip'
import { AppStore } from 'stores/app-store'
import { UiStore } from 'stores/ui-store'
import { DashboardUsersStore } from '../user-store'
import { inject, observer } from 'mobx-react'
import { IUserApi } from 'interfaces/api/portal/user-api'
import { Loader } from 'components/loader'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { AuthStore } from 'stores/auth-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import { supportEmails } from 'constants/support-emails'
import style from './reset-user-password.css'
import { salesforcePortalProducts } from 'constants/salesforce.constants'

interface ResetUserPasswordState {
  copied: boolean
}

interface ResetUserPasswordProps extends WithTranslation {
  appStore?: AppStore
  onClose: () => Promise<void>
  uiStore?: UiStore
  userApi?: IUserApi
  usersStore?: DashboardUsersStore
  modalLayerStore?: ModalLayerStore
  authStore?: AuthStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  appStore,
  uiStore: appStore.uiStore,
  usersStore: appStore.usersStore,
  modalLayerStore: appStore.actionModalStore,
  authStore: appStore.authStore,
}))
@observer
class ResetUserPasswordComponent extends React.Component<
  ResetUserPasswordProps,
  ResetUserPasswordState
> {
  private store: DashboardUsersStore
  private inputRef = React.createRef<HTMLInputElement>()

  constructor(props: ResetUserPasswordProps) {
    super(props)
    this.store = props.usersStore
    this.state = { copied: false }
  }

  private handleFormSubmit = (event: React.FormEvent) => {
    const {
      store: { editUserId },
    } = this

    event.preventDefault()
    this.store.generateTemporaryPassword(editUserId)
  }

  getSupportMailAddress = () => {
    const { currentUser } = this.props.authStore
    const { company } = currentUser

    const areAnyPortalProductsPurchased =
      company?.productsSummary?.purchased &&
      !!Object.keys(company?.productsSummary?.purchased).find(p =>
        salesforcePortalProducts.includes(p),
      )

    const supportEmail =
      areAnyPortalProductsPurchased || company.isChannelPartnerPortalCompany
        ? supportEmails.PARTNER_SUPPORT
        : company.ownerEmail

    return supportEmail
  }

  handleCopyToClipboard = () => {
    const passwordText = this.inputRef.current

    passwordText.select()
    passwordText.setSelectionRange(0, 99999)
    document.execCommand('copy')

    this.setState({ copied: true })
  }

  render() {
    const { onClose, t } = this.props
    const {
      store: {
        tempPassword,
        serverError,
        firstName,
        lastName,
        temporaryPasswordGenerated,
        temporaryPasswordGenerateError,
      },
    } = this
    const name = [firstName.value, lastName.value].join(' ')
    const mailAddress = this.getSupportMailAddress()

    return (
      <div className={style.root}>
        <div className={style.subTitle}>{t('users.resetPasswordTitle')}</div>
        {(firstName.value || lastName.value) && !temporaryPasswordGenerated && (
          <div className={style.resetPasswordNoticeDesc}>
            {t('users.resetPasswordNoticeDesc', {
              name,
            })}
          </div>
        )}
        <div className={style.resetPasswordDesc}>
          {temporaryPasswordGenerated
            ? t('users.resetPasswordGeneratedDesc')
            : t('users.resetPasswordDesc')}
        </div>
        {!temporaryPasswordGenerated && temporaryPasswordGenerateError && (
          <div className={style.errorWarning}>
            {t('errors.errorGenerateTemporaryPassword')}&nbsp;
            <a href={`mailto:${mailAddress}`}>{mailAddress}</a>
          </div>
        )}
        <div className={style.closeButton}>
          <Close onClick={onClose} />
        </div>
        <form onSubmit={this.handleFormSubmit}>
          {temporaryPasswordGenerated && (
            <div className={style.inputWrapper}>
              <Input
                className={style.input}
                autoFocus
                label={t('users.resetPasswordInputLabel')}
                hasBeenValidated={tempPassword.hasBeenValidated}
                value={tempPassword.value}
                inputRef={this.inputRef}
                readOnly
                error={tempPassword.error || !!serverError}
                onChange={e => tempPassword.onChange(e.target.value)}
              />
              <Tooltip
                placement="top"
                onVisibleChange={visible => {
                  if (!visible) {
                    this.setState({ copied: false })
                  }
                }}
                overlay={
                  <span className={style.tooltipText}>
                    {this.state.copied ? t('tooltip.copied') : t('tooltip.copyToClipboard')}
                  </span>
                }
              >
                <span className={style.copyToClipboard} onClick={this.handleCopyToClipboard}>
                  <CopyToClipboard />
                </span>
              </Tooltip>
            </div>
          )}
          <div className={style.btnDiv}>
            {this.store.isLoadingModal && <Loader />}
            {temporaryPasswordGenerated ? (
              <Button className={style.actionBtn} onClick={onClose}>
                {t('btn.close')}
              </Button>
            ) : (
              <Button
                className={style.actionBtn}
                type="submit"
                disabled={this.store.isLoadingModal}
              >
                {t('btn.temporaryPassword')}
              </Button>
            )}
          </div>
        </form>
      </div>
    )
  }
}

export const ResetUserPassword = withTranslation()(ResetUserPasswordComponent)
