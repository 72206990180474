import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { AppStore } from 'stores/app-store'
import { Loader } from 'components/loader'
import { when } from 'mobx'
import { Route } from 'react-router-dom'
import { KnowledgeCenter } from 'modules/knowledge-center/knowledge-center'

interface AccountSetupRouteProps {
  appStore?: AppStore
  path: string
  children?: React.ReactNode
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  appStore,
}))
@observer
export class AccountSetupRoute extends React.Component<AccountSetupRouteProps> {
  componentDidMount() {
    this.props.appStore.authStore.update()

    when(
      () => !!this.props.appStore.authStore.currentUser,
      () => {
        this.props.appStore.accountSetupStore.init(this.props.appStore.authStore.currentUser)
      },
    )
  }

  render() {
    const { currentUser } = this.props.appStore.authStore

    if (!currentUser || !currentUser.company) {
      return <Loader />
    }

    const { loading } = this.props.appStore.accountSetupStore

    if (loading) {
      return <Loader />
    }

    return <Route exact path={'/knowledge-center'} render={this.renderChildren} />
  }

  renderChildren = () => {
    return <KnowledgeCenter />
  }
}
