import * as React from 'react'
import { Close } from 'components/icons/index'
import { Button } from 'components/button'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import style from './network-status-suspension.css'
import { AccountStore } from 'modules/dashboard/account/account-store'
import { mixpanelActions } from 'utils/mixpanelActions'
import { handleNumberInputFieldChange } from 'helpers/general-helpers'
import classNames from 'classnames'
import hmsStyle from 'components/hms-picker/hms-picker.css'
import { Input } from 'components/input'
import { TextToggler } from 'plume-ui'
import { TimeExpiryTicker } from './time-expiry-ticker'

interface NSSProps {
  onClose: () => Promise<void>
  accountStore: AccountStore
}

export const NetworkStatusSuspension = observer((props: NSSProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const company = appStore?.authStore?.currentUser?.company
  const { onClose } = props

  const { serverError, isLoadingModal, networkStatusSuspensionConfig } = props.accountStore

  const getRemainingSeconds = () => {
    const seconds = Math.round(
      (new Date(networkStatusSuspensionConfig?.schedule?.endsAt).getTime() - new Date().getTime()) /
        1000,
    )
    return seconds > 0 ? seconds : 0
  }

  const [nssnEnabled, setNssnEnabled] = React.useState(
    !!networkStatusSuspensionConfig?.enabled && getRemainingSeconds() > 0,
  )

  React.useEffect(() => {
    mixpanelActions.track('Network Status Notifications Suspension - Modal Open', {
      'Partner Id': company?.partnerId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [suspendHours, setSuspendHours] = React.useState('48')

  const handleSuspendHoursChange = (value: string) =>
    handleNumberInputFieldChange(value, setSuspendHours, 48)

  const oc = style.override
  const suspendForError = !suspendHours || Number.parseInt(suspendHours, 10) <= 0
  const submitDisabled =
    isLoadingModal ||
    (nssnEnabled && suspendForError) ||
    (!nssnEnabled && !networkStatusSuspensionConfig?.enabled)

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (submitDisabled) {
      return
    }
    props.accountStore.submitNetworkStatusSuspension({
      suspendHours: Number.parseInt(suspendHours, 10),
      enabled: nssnEnabled,
    })
  }

  return (
    <div className={style.root}>
      <div className={style.subTitle}>{t('account.nssTitle')}</div>
      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>

      <form onSubmit={handleFormSubmit}>
        <div className={style.labelText}>{t('networkNotificationsSuspension.description')}</div>
        {networkStatusSuspensionConfig?.enabled && (
          <TimeExpiryTicker
            time={networkStatusSuspensionConfig?.schedule?.endsAt}
            className={style.expiresInLabel}
            i18nKey={'networkNotificationsSuspension.expiresIn'}
          />
        )}
        <TextToggler
          classes={current => ({
            ...current,
            root: classNames(current.root, oc, style.toggleContainer),
            toggleContainer: classNames(current.toggleContainer, oc, style.toggleAdjustment),
          })}
          title={t('accountManager.enabled')}
          selected={nssnEnabled}
          onToggle={() => setNssnEnabled(!nssnEnabled)}
        />

        {nssnEnabled && (
          <div className={style.inputHourWrapper}>
            <Input
              className={classNames(hmsStyle.hmsInput, style.inputLabelWidth)}
              label={t('networkNotificationsSuspension.suspendFor')}
              hasBeenValidated={false}
              value={suspendHours}
              error={suspendForError && 'nssHoursError'}
              onChange={e => handleSuspendHoursChange(e.target.value)}
            />
            <div className={style.hoursLabel}>{'hours'}</div>
          </div>
        )}

        {serverError && <div className={style.serverError}>{serverError}</div>}
        <div className={style.btnDiv}>
          {isLoadingModal && <Loader />}
          <Button className={style.sendInviteBtn} type="submit" disabled={submitDisabled}>
            {t('btn.save')}
          </Button>
        </div>
      </form>
    </div>
  )
})
