import { SecondaryAction } from '../common/secondary-action'
import * as React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { LoginLayout } from '../common/login-layout'
import { UnlockCompleteStore } from './unlock-complete-store'
import { inject, observer } from 'mobx-react'
import { RouterStore } from 'mobx-react-router'
import { LOGIN_ROUTE } from 'constants/routes'
import { parse } from 'query-string'
import { Loader } from 'components/loader'
import style from './unlock-complete.css'
import { withTranslation, WithTranslation } from 'react-i18next'
import { AppStore } from 'stores/app-store'

interface PasswordCompleteProps extends RouteComponentProps, WithTranslation {
  token?: string
  routerStore?: RouterStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  routerStore: appStore.routerStore,
}))
@observer
export class UnlockCompleteComponent extends React.Component<PasswordCompleteProps> {
  private store = new UnlockCompleteStore(this.props.routerStore, parse(this.props.location.search))

  componentDidMount() {
    const options = parse(this.props.location.search)
    if (!options.recoveryToken) {
      this.props.routerStore.replace('/login')
    }
  }

  render() {
    const { t } = this.props
    const {
      store: { error, isLoading },
    } = this

    return (
      <LoginLayout title={t('auth.unlockAccount')}>
        {isLoading && <Loader theme="small" />}
        {error && <div className={style.error}>{t(`errors.${error}`)}</div>}
        <SecondaryAction>
          <Link to={LOGIN_ROUTE}>{t('auth.cancelResetPassowrd')}</Link>
        </SecondaryAction>
      </LoginLayout>
    )
  }
}

export const UnlockComplete = withTranslation()(UnlockCompleteComponent)
