import { FieldState, FormState } from 'formstate'
import { password, required } from 'utils/form/validators'
import { action, computed, observable } from 'mobx'
import { UserApi } from 'api/portal/user-api'
import { AuthTransactionStore } from '../auth-transaction-store'
import { RouterStore } from 'mobx-react-router'
import { errorsMap } from 'constants/errors'
import { applications } from 'constants/user/applications'

interface AddPasswordStoreOptions {
  activationToken?: string
  login?: string
}

export class AddPasswordStore {
  userApi = new UserApi()

  public password = new FieldState('').validators(required('New Password'), password)
  public passwordConfirm = new FieldState('').validators(required('Confirm New Password'))

  public portalPrivacyPolicy = new FieldState<boolean>(false).validators(
    val => !val && 'pleaseAgreeToPortalPrivacyPolicy',
  )
  public portalTerms = new FieldState<boolean>(false).validators(
    val => !val && 'pleaseAgreeToPortalTerms',
  )
  public enterpriseTerms = new FieldState<boolean>(false).validators(
    val => !val && 'pleaseAgreeToEnterpriseTerms',
  )

  public formState = new FormState({
    password: this.password,
    passwordConfirm: this.passwordConfirm,
  }).validators($ => {
    if ($.password.value !== $.passwordConfirm.value) {
      return 'passwordsMustMatch'
    }
  })

  @observable
  public serverError = ''

  public firstName = ''

  @observable
  public isLoading = false

  @observable
  public isLoadingActiveCheck = true

  @observable
  public externalOriginAppId: string = null

  private token = ''

  constructor(
    private readonly options: AddPasswordStoreOptions,
    private authTransactionStore: AuthTransactionStore,
    private routerStore: RouterStore,
  ) {}

  @computed
  public get error() {
    return this.formState.error || this.serverError
  }

  @action
  public async checkIsUserActive(email: string) {
    const isActive = await this.userApi.checkIsUserActive(email)
    if (isActive) {
      this.routerStore.replace('/login')
    }
    this.setIsLoadingActiveCheck(false)
  }

  @action
  initSetPassword(email: string) {
    this.getUserCreationOrigin(email)
    this.checkIsUserActive(email)
  }

  @action
  private setExternalOriginAppId(value: string) {
    this.externalOriginAppId = value
  }

  @action
  public async getUserCreationOrigin(email: string) {
    const externalOriginAppId = await this.userApi.getUserCreationOrigin(email)
    if (externalOriginAppId) {
      this.setExternalOriginAppId(externalOriginAppId)
    } else {
      this.setExternalOriginAppId('')
    }
  }

  @action
  public async validateAndSubmit() {
    await this.formState.validate()
    await this.enterpriseTerms.validate()
    await this.portalPrivacyPolicy.validate()
    await this.portalTerms.validate()

    if (
      !this.formState.hasError &&
      !this.enterpriseTerms.hasError &&
      !this.portalPrivacyPolicy.hasError &&
      !this.portalTerms.hasError
    ) {
      this.clearServerError()
      await this.submit()
    }
  }

  @action
  private async submit() {
    this.isLoading = true
    try {
      if (!this.token) {
        const token = await this.userApi.getToken(this.options.activationToken)
        this.token = token
      }
      const data = await this.userApi.setPassword(this.token, {
        password: this.password.value,
        email: this.options.login,
      })

      if (data.isPortalUser || this.externalOriginAppId !== '') {
        // createdByAppUrl will be undefined if portal and will not affect setCookieAndRedirect
        const createdByAppUrl = applications.find(a => a.id === this.externalOriginAppId)?.url
        const transaction = await this.authTransactionStore.authClient.signInWithCredentials({
          username: data.email,
          password: this.password.value,
        })
        if (transaction.status === 'SUCCESS') {
          this.handleSuccess()
          this.authTransactionStore.authClient.session.setCookieAndRedirect(
            transaction.sessionToken,
            createdByAppUrl,
          )
        }
      } else {
        this.routerStore.replace('/set-password-success')
        this.handleSuccess()
      }
    } catch (error) {
      this.handleServerError(error)
    }
  }

  @action
  private clearServerError() {
    this.serverError = ''
  }

  @action.bound
  private handleSuccess() {
    this.token = ''
    this.isLoading = false
  }

  @action.bound
  private handleServerError(error: string) {
    this.serverError = errorsMap[error]
    this.isLoading = false
  }

  @action
  private setIsLoadingActiveCheck(value: boolean) {
    this.isLoadingActiveCheck = value
  }
}
