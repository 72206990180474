import * as React from 'react'
import style from './add-edit-team.css'
import { Close } from 'components/icons/index'
import { AccountStore } from '../account-store'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { SetTeam } from './add-edit-team'
import { Loader } from 'components/loader'

export const AddTeam = observer(
  ({ accountStore, onClose }: { accountStore: AccountStore; onClose: () => Promise<void> }) => {
    const { t } = useTranslation()
    return (
      <div className={style.addTeamRoot}>
        <div className={style.subTitle}>{t('teams.addTeam')}</div>
        <div className={style.closeButton}>
          <Close onClick={onClose} />
        </div>
        {accountStore.isLoadingModal ? (
          <Loader />
        ) : (
          <SetTeam accountStore={accountStore} onClose={onClose} action="add" />
        )}
      </div>
    )
  },
)
