import * as React from 'react'
import style from './m2m-api-token-generation.css'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { AccountStore } from '../account-store'
import { Close, CopyToClipboard } from 'components/icons'
import { Button } from 'components/button'
import { IM2MResponse } from 'interfaces/api/portal/company-api'
import { Loader } from 'components/loader'
import { Tooltip } from 'components/tooltip'
import { Input } from 'components/input'
import { showAlert } from 'utils/show-alert'
import { useStores } from 'utils/hooks/useStores'
import { Dropdown, DropdownItem, IconButton, Icons, Table, Tabs } from 'plume-ui'
import classNames from 'classnames'
import { DropdownStyles } from 'plume-ui/dist/components/Dropdown/Dropdown'
import { Tab } from 'plume-ui/dist/components/Tabs/Tabs'
import { SecondaryCloud } from 'enums/secondary-cloud.enum'
import { DataRow } from 'plume-ui/dist/components/Table/Table'
import { SalesforceCloud } from 'constants/salesforce.constants'
import { getCompanyAllowedApplications } from 'helpers/company-helpers'
import { canAccess } from 'modules/auth/auth-action-permission'

interface M2MApiTokenGenerationProps {
  onClose: () => Promise<void>
  accountStore: AccountStore
}

const partnerLabel = 'Partner Admin'
const partnerRole = 'partnerIdAdmin'
const upriseLabel = 'Uprise'
const upriseRole = 'uprise:oktaM2M'

export const M2MApiTokenGeneration = observer((props: M2MApiTokenGenerationProps) => {
  const { t } = useTranslation()
  const { onClose, accountStore } = props
  const [m2MResponse, setM2MResponse] = React.useState<IM2MResponse>()
  const [copiedToClipboard, setCopiedToClipboard] = React.useState(false)
  const [appName, setAppName] = React.useState('')
  const [tokenTtl, setTokenTtl] = React.useState('10')
  const { isLoading, serverError, m2MProvisionedApps } = props.accountStore
  const appStore = useStores()
  const { authStore } = appStore
  if (!authStore.currentUser?.assignedApplications) {
    authStore.getUserAssignedApplications()
  }
  const { currentUser } = authStore

  const canEditM2MSettings = canAccess('editM2MTokens', currentUser)

  if (!currentUser || !currentUser.company) {
    return <Loader />
  }

  const { assignedApplications } = currentUser
  const { cloudEnvironment } = currentUser.company
  const [selectedCloud, setSelectedCloud] = React.useState<SalesforceCloud>(cloudEnvironment)
  const [data, setData] = React.useState<DataRow[]>()
  const [tokenRole, setTokenRole] = React.useState(partnerRole)
  const [tokenLabel, setTokenLabel] = React.useState(partnerLabel)

  React.useEffect(() => {
    const appData = getAppData(selectedCloud)
    const tableFormattedAppData = Array.isArray(appData)
      ? appData?.map(app => ({
          id: app.id,
          appName: app.name,
          scope: `${app.scope}`,
          duration: `${app.tokenTtlMinutes}`,
          authurl: app.authorizationTokenUrl,
        }))
      : null
    tableFormattedAppData ? setData(tableFormattedAppData) : setData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [m2MProvisionedApps, selectedCloud])

  const companyAllowedApplications = getCompanyAllowedApplications(currentUser.company)

  const hasUpriseApp = !!assignedApplications?.find(
    userApp =>
      companyAllowedApplications?.find(a => a?.id === userApp?.id)?.sfProductName === 'Uprise',
    // no need for sfToolName because they're always the same
  )

  const handleResetToken = async (appId: string, appName: string) => {
    const payload = { cloudName: selectedCloud }
    showAlert({
      title: t('m2mApiTokenGeneration.refreshToken', { appName }),
      message: t('m2mApiTokenGeneration.refreshTokenMessage'),
      buttonText: t('btn.refresh'),
      onAcknowledge: async () => {
        const resetTkn = await accountStore.resetM2MSecret(appId, payload)
        setM2MResponse(resetTkn)
      },
      onClose: () => undefined,
    })
  }

  const handleDeleteApp = async (appId: string, appName: string) => {
    const payload = { cloudName: selectedCloud }
    showAlert({
      title: t('m2mApiTokenGeneration.deleteApp', { appName }),
      message: t('m2mApiTokenGeneration.deleteAppMessage'),
      buttonText: t('btn.delete'),
      onAcknowledge: async () => {
        await accountStore.deleteM2MProvisionedApp(appId, payload)
      },
      onClose: () => undefined,
    })
  }

  const handleCopyToClipboard = async (
    field: 'scope' | 'authorizationTokenUrl' | 'authorizationHeader',
  ) => {
    await navigator.clipboard.writeText(m2MResponse[field])
    setCopiedToClipboard(true)
  }

  const handleDurationChange = (value: string) => {
    let parsedValue: number

    if (value.length) {
      parsedValue = Number.parseInt(value)
      if (!isNaN(parsedValue) && parsedValue >= 5 && parsedValue <= 1440) {
        setTokenTtl(`${parsedValue}`)
      }
    }

    setTokenTtl(value)
  }

  const handleNewAppSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    const payload = {
      name: appName,
      tokenTtlMinutes: parseInt(tokenTtl),
      role: tokenRole,
      cloud: selectedCloud,
    }

    const newApp = await accountStore.addM2MProvisionedApp(payload)
    setM2MResponse(newApp)

    if (serverError) {
      showAlert({
        title: 'errors.errorUpdatingAppCustomization',
        message: (
          <span className={style.showLineBreaks}>
            {`${accountStore.serverError}\n\n${t('errors.pleaseReload')}`}
          </span>
        ),
        buttonText: 'btn.close',
        onAcknowledge: onClose,
      })
    }

    setAppName('')
  }

  const appNameErrorMessage = (): string => {
    if (!appName.length) {
      return 'm2mApiNewAppMissingName'
    } else if (appName.length > 64) {
      return 'lessThan64Chars'
    } else if (appNameIsDuplicate(appName)) {
      return 'm2mAppNameIsDuplicate'
    } else {
      return ''
    }
  }

  const appNameIsDuplicate = (name: string): boolean => {
    for (const element of data) {
      if (element.appName === name) {
        return true
      }
    }
    return false
  }

  const tokenTtlErrorMessage = (): string => {
    const parsedToken = Number(tokenTtl)
    if (!tokenTtl.length || isNaN(parsedToken) || parsedToken < 5 || parsedToken > 1440) {
      return 'm2mApiNewAppInvalidDuration'
    } else {
      return ''
    }
  }

  const renderResponse = () => {
    return (
      <>
        <div className={style.disclaimer}>{t('m2mApiTokenGeneration.disclaimer')}</div>
        <div className={style.inputWrapper}>
          <Input
            className={style.input}
            inputStyle={{ paddingRight: 24, textOverflow: 'ellipsis' }}
            label={t('m2mApiTokenGeneration.authorizationHeader')}
            value={m2MResponse.authorizationHeader}
            readOnly
          />
          <Tooltip
            placement="top"
            onVisibleChange={visible => {
              if (!visible) {
                setCopiedToClipboard(false)
              }
            }}
            overlay={
              <span className={style.tooltipText}>
                {copiedToClipboard ? t('tooltip.copied') : t('tooltip.copyToClipboard')}
              </span>
            }
          >
            <span
              className={style.copyToClipboard}
              onClick={() => handleCopyToClipboard('authorizationHeader')}
            >
              <CopyToClipboard />
            </span>
          </Tooltip>
        </div>
      </>
    )
  }

  const getAppData = (cloud: SalesforceCloud) => {
    return m2MProvisionedApps?.[cloud]
  }

  const dropdownClasses = (curr: DropdownStyles) => ({
    ...curr,
    list: classNames(curr.list, style.override, style.dropdownBackground),
  })

  const renderDropDown = (d: DataRow) => {
    return (
      <Dropdown
        closeOnItemClick
        listPosition="right"
        classes={dropdownClasses}
        button={
          <IconButton>
            <Icons.DotsVerticalIcon />
          </IconButton>
        }
      >
        <DropdownItem onClick={() => handleResetToken(d.id, d.appName)}>
          {t('btn.refresh')}
        </DropdownItem>
        <DropdownItem onClick={() => handleDeleteApp(d.id, d.appName)}>
          {t('btn.delete')}
        </DropdownItem>
      </Dropdown>
    )
  }

  const copyDataToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)
  }

  const renderCopyableData = (text: string) => {
    return (
      <div onClick={() => copyDataToClipboard(text)} className={style.copyableData}>
        {text.length > 14 ? `${text.substring(0, 14)}...` : text} <Icons.CopyIcon />
      </div>
    )
  }

  const renderAppForm = () => {
    const appCount = data ? data.length : 0

    const tableHeaders = [
      { fieldName: 'appName', name: t('m2mApiTokenGeneration.appName') },
      {
        fieldName: 'scope',
        name: t('m2mApiTokenGeneration.scope'),
        render: (data: DataRow) => renderCopyableData(data.scope),
      },
      { fieldName: 'duration', name: t('m2mApiTokenGeneration.bearerHeader') },
      {
        fieldName: 'authurl',
        name: t('m2mApiTokenGeneration.authHeader'),
        render: (data: DataRow) => renderCopyableData(data.authurl),
      },
    ]

    if (canEditM2MSettings) {
      tableHeaders.push({
        name: '',
        fieldName: '',
        render: (data: DataRow) => renderDropDown(data),
      })
    }

    return (
      <div className={style.tableContainer}>
        {data && appCount > 0 && (
          <div>
            <Table headerRow={tableHeaders} dataRows={data} truncateCellContent />
            <br />
          </div>
        )}
        {m2MResponse && (
          <span className={style.m2mSuccess}>{t('m2mApiTokenGeneration.success')}</span>
        )}
        {canEditM2MSettings && appCount < 10 && (
          <div>
            <form onSubmit={handleNewAppSubmit}>
              <Input
                label={t('m2mApiTokenGeneration.appName')}
                value={appName}
                onChange={e => setAppName(e.target.value)}
                error={appNameErrorMessage()}
              />
              {hasUpriseApp && selectedCloud === cloudEnvironment && (
                <div className={style.input}>
                  <div className={style.label}>
                    {t('m2mApiTokenGeneration.selectRole').toUpperCase()}
                  </div>
                  <Dropdown classes={dropdownClasses} label={tokenLabel} closeOnItemClick>
                    <DropdownItem
                      onClick={() => {
                        setTokenRole(partnerRole)
                        setTokenLabel(partnerLabel)
                      }}
                    >
                      {partnerLabel}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setTokenRole(upriseRole)
                        setTokenLabel(upriseLabel)
                      }}
                    >
                      {upriseLabel}
                    </DropdownItem>
                  </Dropdown>
                </div>
              )}
              <Input
                label={t('m2mApiTokenGeneration.tokenValidDuration')}
                value={tokenTtl}
                onChange={e => handleDurationChange(e.target.value)}
                error={tokenTtlErrorMessage()}
                infoButtonText={t('m2mApiTokenGeneration.tokenTtlTooltip')}
              />
              <div className={style.actionBtnContainer}>
                <Button
                  className={style.actionBtn}
                  type="submit"
                  disabled={!!appNameErrorMessage() || !!tokenTtlErrorMessage()}
                >
                  {t('btn.save')}
                </Button>
              </div>
            </form>
          </div>
        )}

        {!canEditM2MSettings && !appCount && (
          <div>{t('m2mApiTokenGeneration.noTokensCreated')}</div>
        )}
      </div>
    )
  }

  const tabOptions = Object.values(SecondaryCloud).reduce(
    (arr, c) => {
      if (Array.isArray(m2MProvisionedApps?.[c])) {
        return [...arr, { id: c, label: c, content: renderAppForm() }]
      }
      return arr
    },
    [
      {
        id: cloudEnvironment,
        label: cloudEnvironment,
        content: renderAppForm(),
      },
    ],
  )

  const tabclick = async (tab: Tab) => {
    setM2MResponse(null)
    setSelectedCloud(tab.id as SalesforceCloud)
    setTokenRole(partnerRole)
    setTokenLabel(partnerLabel)
  }

  return (
    <div className={style.root}>
      <div className={style.subTitle}>{t('m2mApiTokenGeneration.tokenGeneration')}</div>
      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>

      {assignedApplications === null ? (
        <Loader theme="small" rootClassName={style.loaderRoot} />
      ) : (
        <div>
          <Tabs active={0} options={tabOptions} onSelectTab={tabclick} tabType="flatTabs" />
          {isLoading && <Loader theme="small" />}
          {!isLoading && m2MResponse && renderResponse()}
        </div>
      )}
    </div>
  )
})
