export interface IReportJobPayload {
  name: string
  nodeIds: string[]
}

export interface IUpdateJobPayload {
  name: string
  csvString: string
}

export const CSVNodeColumns = [
  'POD_SN',
  'PARTNER_ID',
  'GROUP_IDS',
  'NODE_MODEL',
  'PO_NO',
  'PART_NO',
  'BOX_SN',
  'SHIP_DATE',
  'ETH0_MAC',
  'ETH1_MAC',
  'WIFI24_MAC',
  'WIFI5_MAC',
  'WIFI5_MAC_L',
  'WIFI5_MAC_U',
  'BT_MAC',
  'WIFI6_MAC',
  'WIFI6_MAC_L',
  'WIFI6_MAC_U',
  'ULTRA_WIDEBAND',
  'FIRMWARE_VERSION',
  'RESIDENTIAL_GATEWAY',
] as const

export type NodeCSVColumn = (typeof CSVNodeColumns)[number]
export type NodeCSVFormat = Record<NodeCSVColumn, string>

export interface IInventoryNode {
  id: string
  partnerId: string
  residentialGateway: boolean
  groupIds: string[]
  radioMac24?: string
  ethernetMac?: string
  ethernet1Mac?: string
  radioMac50?: string
  radioMac50L?: string
  radioMac50U?: string
  radioMac60?: string
  radioMac60L?: string
  radioMac60U?: string
  bluetoothMac?: string
  ultraWideband?: any
  boxSerialNumber: string
  model: string
  purchaseOrderNumber: string
  partNumber: string
  shipDate: string
  firmwareVersion: string
}

export interface IGetInventoryNodeInfoResponse {
  errorKey?: string
  nodeInfo?: IInventoryNode
}

export interface INodeInfo {
  nodeId: string
  partnerId: string
  status?: 'SUCCESS' | 'FAIL' | 'IN_PROGRESS'
  createdAt?: string
  error?: string

  residentialGateway: boolean
  groupIds: string[]
  radioMac24?: string
  ethernetMac?: string
  ethernet1Mac?: string
  radioMac50?: string
  radioMac50L?: string
  radioMac50U?: string
  radioMac60?: string
  radioMac60L?: string
  radioMac60U?: string
  bluetoothMac?: string
  ultraWideband?: any
  boxSerialNumber: string
  model: string
  purchaseOrderNumber: string
  partNumber: string
  shipDate: string
  firmwareVersion: string
}

export interface ManageInventoryJob {
  partnerId: string
  name: string
  createdAt: string
  submittedByEmail: string
  nodes?: INodeInfo[]
  status?: 'IN_PROGRESS' | 'SUCCESS'
  jobType: 'REPORT' | 'UPDATE' | 'IMPORT'
  nodesFailed: number
  nodesSucceded: number
  nodesStarted: number
}

export interface IPortalInventoryApi {
  addReportJob(partnerId: string, payload: IReportJobPayload): Promise<void>
  addUpdateJob(partnerId: string, payload: IUpdateJobPayload): Promise<void>
  addImportJob(partnerId: string, payload: IUpdateJobPayload): Promise<void>
  listInventoryJobs(partnerId: string): Promise<ManageInventoryJob[]>
  getJobNodes(partnerId: string, jobName: string): Promise<INodeInfo[]>
  getInventoryNodeInfo(nodeId: string): Promise<IGetInventoryNodeInfoResponse>
}
