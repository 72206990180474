import { action, observable } from 'mobx'
import { showAlert } from 'utils/show-alert'
import { RouterStore } from 'mobx-react-router'
import { OktaAuth } from '@okta/okta-auth-js'
import { LOGIN_ROUTE } from 'constants/routes'
import { errorsMap } from 'constants/errors'

interface ResetCompleteStoreOptions {
  recoveryToken?: string
}

export class UnlockCompleteStore {
  private authClient: OktaAuth

  @observable
  public error = ''

  @observable
  public isLoading = false

  constructor(private readonly routerStore: RouterStore, options: ResetCompleteStoreOptions) {
    if (!!options.recoveryToken) {
      this.authClient = new OktaAuth({
        issuer: process.env.OKTA_ISSUER,
        clientId: process.env.OKTA_CLIENT_ID,
      })
      this.initActivation(options.recoveryToken)
    }
  }

  @action
  private initActivation(recoveryToken: string) {
    this.isLoading = true
    this.authClient
      .verifyRecoveryToken({
        recoveryToken,
      })
      .then((transaction: any) => {
        if (transaction.status === 'SUCCESS') {
          this.handleSuccess()
        } else {
          throw `We cannot handle the ${transaction.status} status`
        }
      })
      .catch((error: any) => {
        this.handleServerError(error.message)
      })
  }

  @action.bound
  private async handleSuccess() {
    this.isLoading = false
    await showAlert({
      title: 'auth.accountUnlockedTitle',
      message: 'auth.accountUnlockedMsg',
      buttonText: 'btn.gotIt',
      isCloseShowing: true,
    })
    this.routerStore.replace(LOGIN_ROUTE)
  }

  @action.bound
  private handleServerError(error: string) {
    this.error = errorsMap[error] || error
    this.isLoading = false
  }
}
