import React, { Component, createRef, RefObject } from 'react'
import style from './bar-chart.css'
import { DailyItem } from 'models/home/dashboard-data'
import moment from 'moment'
import classNames from 'classnames'
import { getMonth, getNumOfDaysInCurrentMonth } from 'helpers/bar-chart.helpers'

interface IBarChartProps {
  data: { online: DailyItem[]; offline: DailyItem[] }
  label: string
  formatNumber?: (val: number) => any
  type?: string
  t: (...props: any) => any
}

interface IBarChartState {
  width: number
  height: number
  titleHeight: number
}

export class BarChart extends Component<IBarChartProps, IBarChartState> {
  private container = createRef<HTMLDivElement>()
  private titleElement = createRef<HTMLDivElement>()
  private readonly OFFSET_Y = 15
  private readonly LABELS_SIZE = 20
  private readonly AXIS_STROKE_WIDTH = 2
  private readonly DIVIDER_HEIGHT = 5
  private readonly CURR_MONTH_NUM_OF_DAYS = getNumOfDaysInCurrentMonth()

  private hhToolTop = createRef<HTMLDivElement>()

  private barRefs: RefObject<SVGRectElement>[] = []

  constructor(props: IBarChartProps) {
    super(props)

    this.state = {
      width: 0,
      height: 0,
      titleHeight: 0,
    }
  }

  componentDidMount() {
    this.updateDimensions()
  }

  updateDimensions = () => {
    const { clientWidth: width, clientHeight: height } = this.container.current
    const { clientHeight: titleHeight } = this.titleElement.current

    this.setState({ titleHeight, width, height: height - titleHeight })
  }

  getDataBounds() {
    const { data } = this.props
    const values = data.online.map(obj => obj.val)
    const { minY, maxY } = this.getMinMaxY(values, values)

    return { minY, maxY, minX: 0, maxX: this.CURR_MONTH_NUM_OF_DAYS - 1 }
  }

  getGraphHeight = () => {
    const { height } = this.state
    return height - this.LABELS_SIZE
  }

  getMinMaxY = (values1: number[], values2: number[]) => {
    const minY1 = Math.min.apply(null, values1)
    const minY2 = Math.min.apply(null, values2)
    const maxY1 = Math.max.apply(null, values1)
    const maxY2 = Math.max.apply(null, values2)

    const minY = minY1 < minY2 ? minY1 : minY2
    const maxY = maxY1 > maxY2 ? maxY1 : maxY2

    return { minY, maxY }
  }

  getSvgX(x: number) {
    const { width } = this.state
    return (x / this.CURR_MONTH_NUM_OF_DAYS) * width
  }

  getSvgY(y: number) {
    const height = this.getGraphHeight()
    const { maxY } = this.getDataBounds()
    const val = height - (y / maxY) * height

    return val >= 0 ? val : 0
  }

  makeAxis() {
    const { width } = this.state
    const axisHeight = this.getGraphHeight() + 1

    return (
      <g className={style.barChartAxis}>
        {/* X axis */}
        <line x1={0} y1={axisHeight} x2={width} y2={axisHeight} />
        {/* Left divider */}
        <line
          x1={this.AXIS_STROKE_WIDTH / 2}
          y1={axisHeight - this.AXIS_STROKE_WIDTH / 2}
          x2={this.AXIS_STROKE_WIDTH / 2}
          y2={axisHeight + this.DIVIDER_HEIGHT}
        />
        {/* Middle divider */}
        <line
          x1={width / 2}
          y1={axisHeight - this.AXIS_STROKE_WIDTH / 2}
          x2={width / 2}
          y2={axisHeight + this.DIVIDER_HEIGHT}
        />
        {/* Right divider */}
        <line
          x1={width - this.AXIS_STROKE_WIDTH / 2}
          y1={axisHeight - this.AXIS_STROKE_WIDTH / 2}
          x2={width - this.AXIS_STROKE_WIDTH / 2}
          y2={axisHeight + this.DIVIDER_HEIGHT}
        />
      </g>
    )
  }

  handleOnHover = (val: number, elIndex: number): any => {
    this.hhToolTop.current.innerHTML = this.props.formatNumber(val)
    const currentBar: any = this.barRefs[elIndex]

    this.hhToolTop.current.style.left = `${Math.ceil(currentBar.current.x.baseVal.value) - 3}px`
    this.hhToolTop.current.style.top = `${Math.ceil(currentBar.current.y.baseVal.value) - 10}px`

    this.hhToolTop.current.className = classNames(style.toolTip, style.displayInline)
  }

  handleOnHoverOut = (): any => {
    this.hhToolTop.current.className = classNames(style.toolTip, style.displayHidden)
  }

  makeBars = () => {
    const height = this.getGraphHeight()
    const { data } = this.props
    const { width } = this.state
    const spaceBetween = width / this.CURR_MONTH_NUM_OF_DAYS
    const offset = spaceBetween / 10
    const barWidth = spaceBetween / 2 - offset + 2
    this.barRefs = []

    const lastEl = data.online.length && data.online[data.online.length - 1]

    const bars = Array.from(Array(this.CURR_MONTH_NUM_OF_DAYS).keys()).map((_d, i) => {
      const dayOfMonth = data.online.find(item => {
        return moment(item.ts).utc().date() === i + 1
      })

      const rectRef = React.createRef<SVGRectElement>()
      this.barRefs.push(rectRef)

      const render = dayOfMonth
      return (
        <React.Fragment key={i}>
          {render ? (
            <rect
              ref={rectRef}
              onMouseEnter={() => this.handleOnHover(dayOfMonth.val, i)}
              onMouseLeave={() => this.handleOnHoverOut()}
              className={classNames(
                style.firstChartBar,
                lastEl === dayOfMonth && style.barChartSelected,
              )}
              width={barWidth}
              height={height - this.getSvgY(dayOfMonth.val)}
              x={this.getSvgX(i) + offset}
              y={this.getSvgY(dayOfMonth.val) - this.AXIS_STROKE_WIDTH / 2 + 1}
              rx={3}
              ry={3}
            />
          ) : (
            <rect
              className={style.firstChartBarHidden}
              width={barWidth}
              height={0}
              x={this.getSvgX(i) + offset}
              y={this.getSvgY(100) - this.AXIS_STROKE_WIDTH / 2}
              rx={3}
              ry={3}
            />
          )}
        </React.Fragment>
      )
    })

    return bars
  }

  makeLabels() {
    const { height } = this.state
    const texts = Array.from(Array(this.CURR_MONTH_NUM_OF_DAYS).keys()).map((point, i) => {
      const shouldDisplayText =
        point === 0 || point === 15 || point === this.CURR_MONTH_NUM_OF_DAYS - 1
      return (
        <text
          key={i}
          className={style.axisLabel}
          x={this.getSvgX(i) + 3}
          y={height + this.OFFSET_Y - this.LABELS_SIZE}
          style={{
            transform: `rotate(-45deg)`,
            transformOrigin: `${this.getSvgX(i) + 6}px ${
              height + this.OFFSET_Y - this.LABELS_SIZE - 2
            }px`,
          }}
        >
          {shouldDisplayText && point + 1}
        </text>
      )
    })

    return texts
  }

  render() {
    const { height, width } = this.state
    const { label, t } = this.props

    return (
      <div ref={this.container} className={style.root}>
        <div ref={this.titleElement} className={style.title}>
          {label}
          {': '}
          {t(`common.months.${getMonth()}`)}
        </div>
        <div ref={this.hhToolTop} className={classNames(style.displayNone, style.toolTip)} />
        <svg width={width} height={height + 1}>
          <defs>
            <linearGradient id="selectedGradient" x1="0.5" y1="0" x2="0.5" y2="1">
              <stop offset="0%" stopColor="#1fdc90" />
              <stop offset="33.33%" stopColor="#17c6be" />
              <stop offset="100%" stopColor="#8884ff" />
            </linearGradient>
          </defs>
          {this.makeAxis()}
          {width !== 0 && this.makeBars()}
          {this.makeLabels()}
        </svg>
      </div>
    )
  }
}
