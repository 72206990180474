import * as routes from '../routes'
import { AppsIconFilled, KnowledgeCenter, KnowledgeCenterFilled } from 'components/icons'
import { ACCESS_RIGHTS } from 'constants/access-rights'
import { Icons } from 'plume-ui'

export const DOCEBO_TRAINING_URL = `${process.env.OKTA_ORG_URL}/app/plumeexternal_docebo_1/${process.env.OKTA_DOCEBO_APP_ID}/sso/saml`
export const PUBLIC_RESOURCES_URL = 'https://www.plume.com/serviceproviders/resources/'
export const GLOSSARY_URL = 'https://glossary.plume.com/hc/en-us'
export const OPENSYNC_CPE_URL = 'https://www.plume.com/serviceproviders/cpe/'

export const navItems = [
  {
    text: 'nav.home',
    path: routes.HOME_ROUTE,
    icon: Icons.AppsIcon,
    iconSelected: AppsIconFilled,
    accessRightAlias: ACCESS_RIGHTS.HOME_DASHBOARD,
  },
  {
    text: 'nav.configuration',
    path: routes.ACCOUNT_ROUTE,
    icon: Icons.SettingsIcon,
    iconSelected: Icons.SettingsFillIcon,
    accessRightAlias: ACCESS_RIGHTS.ACCOUNT,
  },
  {
    text: 'nav.applications',
    path: routes.APPLICATIONS_ROUTE,
    icon: Icons.DotCenterIcon,
    iconSelected: Icons.DotCenterFillIcon,
    accessRightAlias: ACCESS_RIGHTS.APPLICATIONS,
  },
  {
    text: 'nav.users',
    path: routes.DASHBOARD_USERS_ROUTE,
    icon: Icons.GroupIcon,
    iconSelected: Icons.GroupFillIcon,
    accessRightAlias: ACCESS_RIGHTS.USERS,
  },
  {
    text: 'nav.knowledgeCenter',
    path: routes.KNOWLEDGE_CENTER_ROUTE,
    icon: KnowledgeCenter,
    iconSelected: KnowledgeCenterFilled,
    accessRightAlias: ACCESS_RIGHTS.KNOWLEDGE_CENTER,
  },
] as const
