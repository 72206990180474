import * as React from 'react'
import style from './terms-and-conditions.css'
import { Footer } from 'components/footer'
import { TermsAndConditionsContent } from './enterprise-terms-and-conditions-content'
import { PortalPrivacyPolicyContent } from './portal-privacy-policy-content'
import { PortalTermsContent } from './portal-terms-content'

const Header = () => <header className={style.header} />

const GetTermsAndConditionsComponent = (
  title: string,
  ContentComponent: React.ReactNode,
  isRaw = false,
) => {
  const TermsAndConditions = () => (
    <div className={style.root}>
      <Header />
      <div className={style.wrapper}>
        <div className={isRaw ? style.rawTcWrapper : style.tcWrapper}>
          <div className={style.tcTitle}>{title}</div>
          {ContentComponent}
        </div>
      </div>
      <Footer />
    </div>
  )
  return TermsAndConditions
}

export const termsUpdatedDate = '2023-12-18T00:00:00.000Z'

export const EnterpriseTermsAndConditions = GetTermsAndConditionsComponent(
  'TERMS OF USE & PRIVACY POLICY FOR ENTERPRISE SERVICES',
  <TermsAndConditionsContent />,
)

export const PortalPrivayPolicy = GetTermsAndConditionsComponent(
  'PLUME PORTAL PRIVACY POLICY',
  <PortalPrivacyPolicyContent />,
  true,
)

export const PortalTerms = GetTermsAndConditionsComponent(
  'PLUME PORTAL TERMS',
  <PortalTermsContent />,
  true,
)
