interface IStatusPageUrls {
  [key: string]: string
}

export const statusPageUrls: IStatusPageUrls = {
  Beta: `${process.env.PORTAL_API_BASE_URL}/statuspage/Beta`,
  Gamma: `${process.env.PORTAL_API_BASE_URL}/statuspage/Gamma`,
  Kappa: `${process.env.PORTAL_API_BASE_URL}/statuspage/Kappa`,
  Iota: `${process.env.PORTAL_API_BASE_URL}/statuspage/Iota`,
}
