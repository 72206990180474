import * as React from 'react'
import style from './show-portal-companies.css'
import { Close } from 'components/icons/index'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import { Loader } from 'components/loader'
import classNames from 'classnames'
import { IUser } from 'interfaces/api/portal/user-api'

interface ShowPortalsProps {
  onClose: () => Promise<void>
  user: IUser
}

export const ShowPortalCompanies = observer((props: ShowPortalsProps) => {
  const appStore = useStores()
  const { t } = useTranslation()

  const usersStore = appStore.usersStore
  const { isLoadingModal, serverError, accessiblePortals } = usersStore
  const { onClose } = props

  return (
    <div className={style.root}>
      <div className={style.subTitle}>
        {t('users.showPortals', { email: `${props.user.firstName} ${props.user.lastName}` })}
      </div>

      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>
      <div className={style.labelText}>{t('users.synchronizationMayBeInProgress')}</div>
      {accessiblePortals.map((company, index) => (
        <div
          key={company.partnerId}
          className={classNames(
            style.companyWrapper,
            index % 2 === 0 ? style.companyWrapperEven : style.companyWrapperOdd,
          )}
        >
          <div className={style.companyInfoWrapper}>
            <div className={style.companyInfoColumn}>
              <div className={style.name}>{company.name}</div>
              <div className={style.userTitle}>{company.partnerId}</div>
            </div>
          </div>
        </div>
      ))}
      {serverError && (
        <div className={style.rncServerError}>{t(`errors.${serverError}`, serverError)}</div>
      )}
      {isLoadingModal && <Loader />}
    </div>
  )
})
