import { UiStore } from 'stores/ui-store'

export interface IAddUserProps {
  onClose: () => Promise<void>
  uiStore?: UiStore
}

export enum AddUserReturnValue {
  CloseButton,
}
