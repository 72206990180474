import * as React from 'react'
import style from '../user.css'
import { observer, inject } from 'mobx-react'
import { Loader } from 'components/loader'
import { DashboardUsersStore } from '../user-store'
import classNames from 'classnames'
import { withTranslation, WithTranslation } from 'react-i18next'
import { UsersTab } from '../users-tab'
import { AppStore } from 'stores/app-store'

interface UserSearchResultProps extends WithTranslation {
  handleEditUser(user: any): void
  isUserItemLoading?: boolean
  handleReactivateUser?(userId: string): Promise<void>
  usersStore?: DashboardUsersStore
  handleResetPassword?(user: any): void
  handleUnlockUser?(userId: string): Promise<void>
  handleCloneUser?(user: any): void
  handleUserInfo?(user: any): void
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  usersStore: appStore.usersStore,
}))
@observer
export class UserSearchResultComponent extends React.Component<UserSearchResultProps> {
  render() {
    const {
      isUserItemLoading,
      invitedSearchedUsers,
      activeSearchedUsers,
      suspendedSearchedUsers,
      deactivatedSearchedUsers,
      lockedOutSearchUsers,
      expiredPasswordSearchUsers,
      expiredPasswordUsers,
      lockedUsers,
    } = this.props.usersStore

    const {
      handleEditUser,
      handleReactivateUser,
      t,
      handleResetPassword,
      handleUnlockUser,
      handleCloneUser,
      handleUserInfo,
    } = this.props

    return (
      <div>
        <div className={style.userSectionGap} />

        <div className={classNames(style.usersTypeSubTitle, style.zeroResultsSubtitle)}>
          {t('users.active').toUpperCase()}
        </div>
        {activeSearchedUsers.length > 0 && (
          <div className={style.userListWrapper}>
            <UsersTab
              users={activeSearchedUsers}
              type={'active'}
              handleEditUser={handleEditUser}
              handleResetPassword={handleResetPassword}
              handleCloneUser={handleCloneUser}
              handleUserInfo={handleUserInfo}
            />
          </div>
        )}
        <div className={style.zeroResultsMsg}>
          {t('users.searchMatchActive', {
            count: activeSearchedUsers.length,
          })}
        </div>
        <div className={style.userSectionGap} />

        <div className={classNames(style.usersTypeSubTitle, style.zeroResultsSubtitle)}>
          {t('users.invited').toUpperCase()}
        </div>
        {invitedSearchedUsers.length > 0 && (
          <div className={style.userListWrapper}>
            {isUserItemLoading && <Loader fit />}
            <UsersTab
              users={invitedSearchedUsers}
              type={'invited'}
              handleEditUser={handleEditUser}
              handleReactivateUser={handleReactivateUser}
              handleResetPassword={handleResetPassword}
              handleUserInfo={handleUserInfo}
            />
          </div>
        )}
        <div className={style.zeroResultsMsg}>
          {t('users.searchMatchInvited', {
            count: invitedSearchedUsers.length,
          })}
        </div>
        <div className={style.userSectionGap} />

        <div className={classNames(style.usersTypeSubTitle, style.zeroResultsSubtitle)}>
          {t('users.suspended').toUpperCase()}
        </div>
        {suspendedSearchedUsers.length > 0 && (
          <div className={style.userListWrapper}>
            <UsersTab
              users={suspendedSearchedUsers}
              type={'suspended'}
              handleEditUser={handleEditUser}
              handleUserInfo={handleUserInfo}
            />
          </div>
        )}
        <div>
          <div className={style.zeroResultsMsg}>
            {t('users.searchMatchSuspended', {
              count: suspendedSearchedUsers.length,
            })}
          </div>
        </div>
        <div className={style.userSectionGap} />

        {deactivatedSearchedUsers.length > 0 && (
          <>
            <div className={classNames(style.usersTypeSubTitle, style.zeroResultsSubtitle)}>
              {t('users.deactivated').toUpperCase()}
            </div>
            <div className={style.userListWrapper}>
              <UsersTab
                users={deactivatedSearchedUsers}
                type={'deactivated'}
                handleEditUser={handleEditUser}
                handleUserInfo={handleUserInfo}
              />
            </div>
            <div>
              <div className={style.zeroResultsMsg}>
                {t('users.searchMatchDeactivated', {
                  count: deactivatedSearchedUsers.length,
                })}
              </div>
            </div>
            <div className={style.userSectionGap} />
          </>
        )}

        {lockedOutSearchUsers.length > 0 && (
          <div className={style.userListWrapper}>
            <UsersTab
              users={lockedOutSearchUsers}
              type={'locked'}
              handleEditUser={handleEditUser}
              handleUnlockUser={handleUnlockUser}
              handleUserInfo={handleUserInfo}
            />
          </div>
        )}
        {lockedUsers.length > 0 && lockedOutSearchUsers.length === 0 && (
          <div className={classNames(style.usersTypeSubTitle, style.zeroResultsSubtitle)}>
            {t('users.locked').toUpperCase()}
          </div>
        )}
        {lockedUsers.length > 0 && (
          <div className={style.zeroResultsMsg}>
            {t('users.searchMatchLocked', {
              count: lockedOutSearchUsers.length,
            })}
          </div>
        )}
        {lockedUsers.length > 0 && <div className={style.userSectionGap} />}

        <div className={classNames(style.usersTypeSubTitle, style.zeroResultsSubtitle)}>
          {t('users.expired').toUpperCase()}
        </div>
        {expiredPasswordSearchUsers.length > 0 && (
          <div className={style.userListWrapper}>
            <UsersTab
              users={expiredPasswordSearchUsers}
              type={'expired'}
              handleEditUser={handleEditUser}
              handleResetPassword={handleResetPassword}
              handleUserInfo={handleUserInfo}
            />
          </div>
        )}
        {expiredPasswordUsers.length > 0 && (
          <div className={style.zeroResultsMsg}>
            {t('users.searchMatchExpired', {
              count: expiredPasswordSearchUsers.length,
            })}
          </div>
        )}
      </div>
    )
  }
}

export const UserSearchResult = withTranslation()(UserSearchResultComponent)
