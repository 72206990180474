import * as React from 'react'
import { AppStore } from 'stores/app-store'
import { StoreContext } from 'index'

export const useStores = (): AppStore => {
  const store = React.useContext(StoreContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.')
  }

  return store
}
