import * as React from 'react'
import { default as RcTooltip } from 'rc-tooltip' // eslint-disable-line
import { TooltipProps as RcTooltipProps } from 'rc-tooltip/lib/Tooltip'
import './tooltip.css'
import './tooltip-plume-ui.css'

/**
 * To get old Tooltip look use `prefixCls='plume-tooltip'`
 */
const Tooltip: React.FC<RcTooltipProps> = ({
  prefixCls = 'plume-ui-tooltip',
  children,
  ...props
}) => {
  return (
    <RcTooltip prefixCls={prefixCls} {...props}>
      {children}
    </RcTooltip>
  )
}

export { Tooltip }
