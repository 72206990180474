import * as React from 'react'
import style from './nda.css'
import { Loader } from 'components/loader'
import { inject, observer } from 'mobx-react'
import { AuthStore } from 'stores/auth-store'
import { AppStore } from 'stores/app-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import { DashboardLayout } from 'modules/dashboard/common/dashboard-layout'
import { TrialExpiredOrTerminated } from 'modules/account-setup/trial-expired-or-terminated/trial-expired-or-terminated'
import { Redirect } from 'react-router-dom'
import { isTerminated } from 'helpers/company-helpers'
import { Box } from 'plume-ui'
import classNames from 'classnames'

interface INdaProps extends WithTranslation {
  appStore?: AppStore
  authStore?: AuthStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  appStore,
  authStore: appStore.authStore,
}))
@observer
class NdaComponent extends React.Component<INdaProps> {
  constructor(props: INdaProps) {
    super(props)
  }

  componentDidMount() {
    this.props.authStore.update()
  }

  render() {
    const { currentUser } = this.props.authStore
    const { t } = this.props

    if (!currentUser) {
      return <Loader />
    }

    if (currentUser.company.signedNdaTimestamp) {
      return <Redirect to="/" />
    }

    const isTrialExpiredOrTerminated =
      !currentUser?.isEmployee && isTerminated(currentUser?.company)

    return (
      <React.Fragment>
        {isTrialExpiredOrTerminated && <TrialExpiredOrTerminated />}
        <DashboardLayout>
          <div className={style.root}>
            <Box classes={curr => ({ root: classNames(curr.root, style.ndaBoxStyle) })}>
              {t('nda.message')}
            </Box>
          </div>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export const Nda = withTranslation()(NdaComponent)
