import * as React from 'react'
import style from './account.css'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { observer } from 'mobx-react-lite'
import { useStores } from 'utils/hooks/useStores'
import { useTranslation } from 'react-i18next'
import { AuthStore } from 'stores/auth-store'
import { DashboardLayout } from '../common/dashboard-layout'
import classNames from 'classnames'
import { AccountStore } from './account-store'
import { AddUserReturnValue } from 'interfaces/utils/add-user'
import { AppStore } from 'stores/app-store'
import { EditBillingContact } from './edit-billing-contact'
import { EditPortalConfiguration } from './edit-portal-configuration/edit-portal-configuration'
import { Loader } from 'components/loader'
import { NavLink, Redirect } from 'react-router-dom'
import { TrialExpiredOrTerminated } from 'modules/account-setup/trial-expired-or-terminated/trial-expired-or-terminated'
import { AppCustomization } from './app-customization/app-customization'
import { when } from 'mobx'
import { ConfirmDelete } from '../user/confirm-delete/confirm-delete'
import { RouterStore } from 'mobx-react-router'
import { EditReleaseNotesContact } from './edit-release-notes-contact/edit-release-notes-contact'
import { AppLogoUploadModal } from './logo-upload/logo-upload-modal'
import {
  getCompanyAllowedApplications,
  getFormattedApplications,
  isActive,
  isProductActive,
  isTerminated,
} from 'helpers/company-helpers'
import { SupportContacts } from './support-contacts/support-contacts'
import { M2MApiTokenGeneration } from './m2m-api-token-generation/m2m-api-token-generation'
import { ManageTeams } from './team/manage-teams'
import { SecondaryCloud } from 'enums/secondary-cloud.enum'
import { mixpanelActions } from 'utils/mixpanelActions'
import { NetworkStatusSuspension } from 'modules/dashboard/account/network-status-suspension/network-status-suspension'
import { TimeExpiryTicker } from './network-status-suspension/time-expiry-ticker'
import { SingleNodeInventoryCheck } from './single-node-inventory-check/single-node-inventory-check'
import { IDP_SETTINGS_ROUTE } from 'constants/routes'
import { Heading, Button, StaticCard } from 'plume-ui'
import {
  ConfigurationCard,
  renderActionButton,
} from 'components/configuration-card/configuration-card'
import { StaticCardStyles } from 'plume-ui/dist/components/Card/StaticCard'
import { GeneralReturnValue } from 'interfaces/utils/modal'
import { SecondaryCloudManagement } from 'modules/account-manager/secondary-cloud-management/secondary-cloud-management'
import { CRMNotification } from 'modules/account-manager/crm-notification/crm-notification'
import { PlumeCloudAPIAccmgrAccess } from './plume-cloud-api-accmgr-access/plume-cloud-api-accmgr-access'
import { NodesClaimableByLocationsWithSharedParent } from './nodes-claimable-by-locations-with-shared-parent/nodes-claimable-by-locations-with-shared-parent'
import { canAccess } from 'modules/auth/auth-action-permission'
import { GlobalAuth } from './global-auth/global-auth'
import { getTypedObjectKeys } from '../../../helpers/general-helpers'
import { PlumeProduct } from '../../../enums/plume-product.enum'
import { isGlobalAuthProductEnabledOnCloud } from './global-auth/global-auth.helpers'
import { ViewPartnerConfigGetCalls } from './view-partner-config-get-calls/view-partner-config-get-calls'

export enum ConfirmDeleteReturnValue {
  CloseButton,
  ConfirmButton,
}

const showEditBCModal = async (actionModalStore: ModalLayerStore, accountStore: AccountStore) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      accountStore.clearFormState()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <EditBillingContact
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        accountStore={accountStore}
      />,
    )
  })
}

const showEditPCModal = async (
  actionModalStore: ModalLayerStore,
  accountStore: AccountStore,
  authStore: AuthStore,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      accountStore.clearFormState()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <EditPortalConfiguration
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        accountStore={accountStore}
        authStore={authStore}
      />,
    )
  })
}

const showEditRNCModal = async (actionModalStore: ModalLayerStore, accountStore: AccountStore) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      accountStore.clearRNCFormState()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <EditReleaseNotesContact
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        accountStore={accountStore}
      />,
    )
  })
}

const showAppCustomizationDialog = async (
  actionModalStore: ModalLayerStore,
  accountStore: AccountStore,
  appStore: AppStore,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      accountStore.clearMobileConfigFormState()
      accountStore.setIsMobileConfigEdited(false)
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <AppCustomization
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        appStore={appStore}
        accountStore={accountStore}
      />,
    )
  })
}

const showNSS = async (actionModalStore: ModalLayerStore, accountStore: AccountStore) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      actionModalStore.removeModal()
      accountStore.clearServerError()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <NetworkStatusSuspension
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        accountStore={accountStore}
      />,
    )
  })
}

const showNCLSP = async (actionModalStore: ModalLayerStore, accountStore: AccountStore) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      actionModalStore.removeModal()
      accountStore.clearServerError()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <NodesClaimableByLocationsWithSharedParent
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        accountStore={accountStore}
      />,
    )
  })
}

const showGlobalAuth = async (actionModalStore: ModalLayerStore, accountStore: AccountStore) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      actionModalStore.removeModal()
      accountStore.clearServerError()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <GlobalAuth
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
      />,
    )
  })
}

const showUploadLogoDialog = async (actionModalStore: ModalLayerStore) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <AppLogoUploadModal
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
      />,
    )
  })
}

const showSupportContactsModal = async (
  actionModalStore: ModalLayerStore,
  accountStore: AccountStore,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      accountStore.clearServerError()
      accountStore.updateSupportContactsPhones = null
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <SupportContacts
        accountStore={accountStore}
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
      />,
    )
  })
}

const showM2MApiTokenGenerationModal = async (
  actionModalStore: ModalLayerStore,
  accountStore: AccountStore,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      accountStore.clearServerError()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <M2MApiTokenGeneration
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        accountStore={accountStore}
      />,
    )
  })
}

// disabled per extmice 1248
// const showMobileAppSSOModal = async (
//   actionModalStore: ModalLayerStore,
//   accountStore: AccountStore,
// ) => {
//   return new Promise<AddUserReturnValue>(res => {
//     const onClose = async (closeButton: AddUserReturnValue) => {
//       accountStore.clearServerError()
//       actionModalStore.removeModal()
//       return res(closeButton)
//     }

//     actionModalStore.addModal(
//       <MobileAppSSOModal
//         key={actionModalStore.getKey()}
//         onClose={() => onClose(AddUserReturnValue.CloseButton)}
//         accountStore={accountStore}
//       />,
//     )
//   })
// }

// export const displayConfirmDeleteMobileAppSSOModal = async (
//   actionModalStore: ModalLayerStore,
//   accountStore: AccountStore,
// ) => {
//   return new Promise<ConfirmDeleteReturnValue>(res => {
//     const onClose = async (closeButton: ConfirmDeleteReturnValue) => {
//       actionModalStore.removeModal()
//       return res(closeButton)
//     }

//     const onConfirmDelete = async () => {
//       await accountStore.deleteMobileAppSSOConfig()
//       await accountStore.getSSOConfig()
//       onClose(ConfirmDeleteReturnValue.CloseButton)
//     }

//     actionModalStore.addModal(
//       <ConfirmDelete
//         key={actionModalStore.getKey()}
//         confirmQuestion={'account.deleteMobileAppSSOConfirmQuestion'}
//         confirmText={'account.deleteMobileAppSSOConfirmText'}
//         onClose={() => onClose(ConfirmDeleteReturnValue.CloseButton)}
//         onConfirm={() => onConfirmDelete()}
//         accountStore={accountStore}
//       />,
//     )
//   })
// }

export const displayConfirmDeleteModal = async (
  actionModalStore: ModalLayerStore,
  accountStore: AccountStore,
  routerStore: RouterStore,
) => {
  return new Promise<ConfirmDeleteReturnValue>(res => {
    const onClose = async (closeButton: ConfirmDeleteReturnValue) => {
      actionModalStore.removeModal()
      return res(closeButton)
    }

    const onConfirmDelete = async () => {
      await accountStore.forceDeleteAccount()
      onClose(ConfirmDeleteReturnValue.CloseButton)
      routerStore.replace('/select-partner')
    }

    actionModalStore.addModal(
      <ConfirmDelete
        key={actionModalStore.getKey()}
        confirmQuestion={'account.deleteAccountConfirmQuestion'}
        confirmText={'account.deleteAccountConfirmText'}
        onClose={() => onClose(ConfirmDeleteReturnValue.CloseButton)}
        onConfirm={() => onConfirmDelete()}
        accountStore={accountStore}
      />,
    )
  })
}

export const displayManageTeamsModal = async (
  actionModalStore: ModalLayerStore,
  accountStore: AccountStore,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      accountStore.clearManageTeamsFormState()
      return res(closeButton)
    }
    accountStore.initManageTeams()
    actionModalStore.addModal(
      <ManageTeams
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        accountStore={accountStore}
      />,
    )
  })
}

const displaySNICModal = async (actionModalStore: ModalLayerStore, accountStore: AccountStore) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      accountStore.clearNodeInfoFormState()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <SingleNodeInventoryCheck
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        accountStore={accountStore}
      />,
    )
  })
}

const showPCAAMA = async (actionModalStore: ModalLayerStore, accountStore: AccountStore) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      actionModalStore.removeModal()
      accountStore.clearServerError()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <PlumeCloudAPIAccmgrAccess
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        accountStore={accountStore}
      />,
    )
  })
}

const { Beta, OpenSync, Dogfood, Osync } = SecondaryCloud

const allSecondaryClouds = [Beta, OpenSync, Dogfood, Osync] as const

const showManageSecondaryCloudModal = async (
  actionModalStore: ModalLayerStore,
  accountStore: AccountStore,
  secondaryCloud: SecondaryCloud,
) => {
  return new Promise<GeneralReturnValue>(res => {
    const onClose = async (closeButton: GeneralReturnValue) => {
      accountStore.clearSCMFormState(secondaryCloud)
      return res(closeButton)
    }

    accountStore.clearServerError()
    accountStore.initSecondaryCloudManagement(secondaryCloud)

    actionModalStore.addModal(
      <SecondaryCloudManagement
        key={actionModalStore.getKey()}
        onClose={() => onClose(GeneralReturnValue.CloseButton)}
        secondaryCloud={secondaryCloud}
      />,
    )
  })
}

const showEditCRMNotificationConfigModal = async (
  actionModalStore: ModalLayerStore,
  accountStore: AccountStore,
) => {
  return new Promise<GeneralReturnValue>(res => {
    const onClose = async (closeButton: GeneralReturnValue) => {
      accountStore.clearServerError()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <CRMNotification
        key={actionModalStore.getKey()}
        onClose={() => onClose(GeneralReturnValue.CloseButton)}
      />,
    )
  })
}

const viewPartnerConfigGetCalls = async (actionModalStore: ModalLayerStore) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <ViewPartnerConfigGetCalls
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
      />,
    )
  })
}

export const DashboardConfiguration = observer(() => {
  const { t } = useTranslation()
  const appStore = useStores()

  const { accountStore: store, authStore, actionModalStore } = appStore
  const currentUser = authStore?.currentUser
  const partnerId = currentUser?.company?.partnerId
  const parentPartnerIdResponse = store?.parentPartnerIdResponse
  const notificationConfig = store?.crmNotificationConfig
  const notificationConfigError = store?.crmNotificationConfigError

  React.useEffect(() => {
    appStore.authStore.update()

    when(
      () =>
        !!appStore.authStore.currentUser &&
        !!appStore.authStore.currentUser.company &&
        !!appStore.accountStore,
      () => {
        appStore.accountStore.getNetworkStatusSuspension()
        appStore.accountStore.getM2MProvisionedApps()
        appStore.accountStore.getNodesClaimableByLocationsWithSharedParentInfo()
        appStore.accountStore.getPartnerConfigFeatureFlags()

        const partnerId = appStore.authStore?.currentUser?.company?.partnerId

        mixpanelActions.track('Pageview - Account', {
          'Page name': 'Account',
          'Partner Id': partnerId,
        })
        // store.getSSOConfig()
      },
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const refreshPPID = store && !store.parentPartnerIdResponse
    if (refreshPPID && currentUser?.isEmployee) {
      store.getParentPartnerId()
    }
  }, [store, currentUser?.isEmployee])

  React.useEffect(() => {
    if (currentUser?.isEmployee && store && !notificationConfig) {
      store.getCRMNotificationConfig()
    }
  }, [store, currentUser?.isEmployee, notificationConfig])

  React.useEffect(() => {
    if (currentUser?.isEmployee && !store.plumeCloudAPIAccmgrAccessInfo) {
      store.getPlumeCloudAPIAccmgrAccessInfo()
    }
  }, [currentUser?.isEmployee, store, store?.plumeCloudAPIAccmgrAccessInfo])

  const handleEditBC = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - Edit Billing Contact', {
      form: 'Billing Contact',
      'Partner Id': partnerId,
    })
    store.prepareEditBC()
    showEditBCModal(appStore.actionModalStore, store)
  }

  const handleEditPC = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - Edit Portal Configuration', {
      form: 'Portal Configuration',
      'Partner Id': partnerId,
    })
    store.prepareEditPL()
    store.prepareEditPN()
    showEditPCModal(appStore.actionModalStore, store, appStore.authStore)
  }

  const handleEditRNC = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - Edit Release Notes Contact', {
      form: 'Release Notes Contact',
      'Partner Id': partnerId,
    })
    store.prepareEditRNC()
    showEditRNCModal(appStore.actionModalStore, store)
  }

  const handleAppCustomization = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - App Customization', {
      form: 'App Customization',
      'Partner Id': partnerId,
    })
    showAppCustomizationDialog(appStore.actionModalStore, store, appStore)
  }

  // const handleDeleteAccount = () => {
  //   displayConfirmDeleteModal(appStore.actionModalStore, store, appStore.routerStore)
  // }

  const handleAppLogoUpload = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - Logo Upload', {
      form: 'Logo Upload',
      'Partner Id': partnerId,
    })
    showUploadLogoDialog(appStore.actionModalStore)
  }

  const handleManageTeams = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - Team Management', {
      form: 'Team Management',
      'Partner Id': partnerId,
    })
    displayManageTeamsModal(appStore.actionModalStore, store)
  }

  const handleNSS = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - NSS', {
      form: 'NSS',
      'Partner Id': partnerId,
    })
    showNSS(appStore.actionModalStore, store)
  }

  const handleNCLSP = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - NCLSP', {
      form: 'NCLSP',
      'Partner Id': partnerId,
    })
    showNCLSP(appStore.actionModalStore, store)
  }

  const handleGlobalAuth = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - Global Auth', {
      form: 'Global Auth',
      'Partner Id': partnerId,
    })
    showGlobalAuth(appStore.actionModalStore, store)
  }

  const handlePCAAMA = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - PCAAMA', {
      form: 'PCAAMA',
      'Partner Id': partnerId,
    })
    showPCAAMA(appStore.actionModalStore, store)
  }

  const handleSupportContacts = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - Support Contacts', {
      form: 'Support Contacts',
      'Partner Id': partnerId,
    })
    showSupportContactsModal(appStore.actionModalStore, store)
  }

  const handleM2MApiTokenGeneration = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - M2M API Token', {
      form: 'M2M API Token',
      'Partner Id': partnerId,
    })
    showM2MApiTokenGenerationModal(appStore.actionModalStore, store)
  }

  const handleOpenSNIC = () => {
    const partnerId = appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Account - Single Node Inventory check', {
      form: 'Single Node Inventory check',
      'Partner Id': partnerId,
    })
    displaySNICModal(appStore.actionModalStore, store)
  }

  // disabled per extmice1248
  // handleMobileAppSSO = () => {
  //   showMobileAppSSOModal(appStore.actionModalStore, store)
  // }

  // handleDeleteMobileAppSSO = () => {
  //   const partnerId = appStore.authStore?.currentUser?.company?.partnerId

  //   mixpanelActions.track('Account - Delete Mobile App SSO', {
  //     'Partner Id': partnerId,
  //   })
  //   displayConfirmDeleteMobileAppSSOModal(appStore.actionModalStore, store)
  // }

  // launchLink = (email: string) => {
  //   const partnerId = appStore.authStore?.currentUser?.company?.partnerId

  //   mixpanelActions.track('Account - Send Email to AM', {
  //     'Partner Id': partnerId,
  //   })

  //   const mail = `mailto:${email}`
  //   const a = document.createElement('a')
  //   a.href = mail
  //   a.click()
  // }

  const handleManageSecondaryCloud = (secondaryCloud: SecondaryCloud) => {
    mixpanelActions.track('Account Manager - Secondary Cloud', {
      form: 'Secondary Cloud',
      'Partner Id': partnerId,
    })
    showManageSecondaryCloudModal(actionModalStore, store, secondaryCloud)
  }

  const handleEditNotificationConfig = () => {
    mixpanelActions.track('Account Manager - Edit Notification Config', {
      form: 'Edit Notification Config',
      'Partner Id': partnerId,
    })
    showEditCRMNotificationConfigModal(actionModalStore, store)
  }

  const handleViewPartnerConfigGetCalls = () => {
    mixpanelActions.track('Account - View Partner Config GET calls', {
      form: 'View Partner Config GET calls',
      'Partner Id': partnerId,
    })
    viewPartnerConfigGetCalls(actionModalStore)
  }

  if (!appStore.authStore.currentUser || !appStore.authStore.currentUser.company) {
    return <Loader />
  }

  if (
    !appStore.authStore.currentUser.featureAccessRightsArr.includes('ACCOUNT') ||
    !canAccess('viewSettings', currentUser)
  ) {
    return <Redirect to={`/missing-access`} />
  }

  if (!appStore.authStore.currentUser.company.signedNdaTimestamp) {
    return <Redirect to="/nda" />
  }

  const { isSetupCompleted } = store
  const { company } = currentUser
  const {
    m2MProvisionedApps,
    nssLoadingError,
    networkStatusSuspensionConfig,
    plumeCloudAPIAccmgrAccessInfo,
    pcaamaLoadingError,
    m2mError,
    nodesClaimableByLocationsWithSharedParentInfo,
    nclspLoadingError,
    partnerConfigFeatureFlagsAllClouds,
    partnerConfigFeatureFlagsError,
  } = store

  const subscriberCanCustomizeApp =
    currentUser.isSubscriber &&
    canAccess('appCustomization', currentUser) &&
    isActive(company) &&
    company.partnerOfferedType !== 'Turnkey' &&
    !company.isDTCCompany

  const employeeCanCustomizeApp =
    currentUser.isEmployee && canAccess('appCustomization', currentUser)

  const canCustomizeApp =
    (company.profiles?.includes('HomePass') || company.profiles?.includes('WorkPass')) &&
    (subscriberCanCustomizeApp || employeeCanCustomizeApp)

  const hasPortalManagementPermission = canAccess('portalManagementCard', currentUser)
  const canUploadLogo =
    isActive(currentUser.company) &&
    (company.profiles?.includes('HomePass') || company.profiles?.includes('WorkPass')) &&
    canAccess('appCustomization', currentUser)
  const isBillingContactEmpty =
    !company.billingContactName && !company.billingContactEmail && !company.billingContactPhone
  const isReleaseNotesContactEmailEmpty = !company.releaseNotesContactEmail

  // disabled per extmice1248
  // const canEditMobileSSO =
  //   currentUser.portalRole === 'accountmgr' || currentUser.portalRole === PortalRole.admin

  const isTrialExpiredOrTerminated = !currentUser?.isEmployee && isTerminated(currentUser?.company)
  const canEditM2MApiToken = canAccess('editM2MTokens', currentUser)
  const companyHasApplications = !!getCompanyAllowedApplications(company)?.length
  // Disabled per extmice 1248
  // const hasSSOConfig = ssoConfig !== null && Object.keys(ssoConfig).length > 0

  const { cloudEnvironment } = appStore.authStore.currentUser.company
  const secondaryCloudCompanyFields = store?.secondaryCloudCompanyFields

  const availableClouds = Object.values(SecondaryCloud).reduce(
    (arr, c) => {
      if (Array.isArray(m2MProvisionedApps?.[c])) {
        return [...arr, c]
      }
      return arr
    },
    [cloudEnvironment],
  )

  const nssEndDate = networkStatusSuspensionConfig?.schedule?.endsAt
    ? new Date(networkStatusSuspensionConfig?.schedule?.endsAt)
    : null

  const isNssActive = nssEndDate > new Date()
  const hasSamlProduct =
    isProductActive(currentUser?.company?.productsInfo?.['SAML']) ||
    currentUser?.company?.samlEnabled

  const longCardClasses = (curr: StaticCardStyles) => ({
    ...curr,
    root: classNames(curr.root, style.override, style.longCardRoot),
    content: classNames(curr.content, style.override, style.longCardContent),
    action: classNames(curr.action, style.override, style.alignLongCardButton),
    header: classNames(curr.header, style.override, style.longCardHeader),
  })

  const globalAuthHomePassEnabledClouds = getTypedObjectKeys(
    partnerConfigFeatureFlagsAllClouds,
  )?.filter(sfCloud =>
    isGlobalAuthProductEnabledOnCloud(partnerConfigFeatureFlagsAllClouds, sfCloud, 'HomePass'),
  )

  const globalAuthWorkPassEnabledClouds = getTypedObjectKeys(
    partnerConfigFeatureFlagsAllClouds,
  )?.filter(sfCloud =>
    isGlobalAuthProductEnabledOnCloud(partnerConfigFeatureFlagsAllClouds, sfCloud, 'WorkPass'),
  )

  return (
    <React.Fragment>
      {isTrialExpiredOrTerminated && <TrialExpiredOrTerminated />}
      <DashboardLayout currentUser={appStore.authStore.currentUser}>
        <div className={style.root}>
          <div className={style.headerTitle}> {t('account.title')}</div>
          {companyHasApplications && canAccess('teamManagement', currentUser) && (
            <StaticCard
              classes={longCardClasses}
              title={
                <>
                  <Heading size="xl">{t('account.manageTeamsTitle')}</Heading>
                  <div className={style.text}>{t('account.manageTeamsDescription')}</div>
                </>
              }
              actions={[renderActionButton(true, 'account.manageTeamsAction', handleManageTeams)]}
            ></StaticCard>
          )}

          {canCustomizeApp && (
            <StaticCard
              classes={longCardClasses}
              title={
                <>
                  <Heading size="xl">{t('appCustomization.title')}</Heading>
                  <div className={style.text}>{t('appCustomization.description')}</div>
                </>
              }
              actions={[
                <Button
                  key="appCustomization"
                  classes={curr => ({
                    ...curr,
                    root: classNames(curr.root, style.cardButtonWidth),
                  })}
                  notifications={!isSetupCompleted ? 1 : undefined}
                  styleVariant="superprimary"
                  onClick={handleAppCustomization}
                >
                  {t('btn.customize')}
                </Button>,
              ]}
            ></StaticCard>
          )}

          {canUploadLogo && (
            <StaticCard
              classes={longCardClasses}
              title={
                <>
                  <Heading size="xl">{t('logoUpload.dashboardTitle')}</Heading>
                  <div className={style.text}>{t('logoUpload.description')}</div>
                </>
              }
              actions={[renderActionButton(true, 'btn.upload', handleAppLogoUpload)]}
            ></StaticCard>
          )}

          <div className={style.content}>
            <div className={style.cardsContainer}>
              {hasPortalManagementPermission && (
                <>
                  <ConfigurationCard
                    title={t('account.portalConfiguration')}
                    actionButton={renderActionButton(true, 'btn.edit', handleEditPC)}
                  >
                    <div className={style.text}>
                      {`${t('account.portalLanguage')}: `}
                      {company.portalLanguage
                        ? t(`common.portalLanguages.${company.portalLanguage}`)
                        : t('account.notEntered')}
                    </div>
                    <div className={style.text}>
                      {`${t('account.portalName')}: `}
                      {company.portalNickname ? company.portalNickname : t('account.notEntered')}
                    </div>
                  </ConfigurationCard>

                  <ConfigurationCard
                    title={t('account.releaseNotes')}
                    actionButton={renderActionButton(true, 'btn.edit', handleEditRNC)}
                  >
                    {!isReleaseNotesContactEmailEmpty ? (
                      <div>
                        <div className={style.text}>{company.releaseNotesContactEmail}</div>
                      </div>
                    ) : (
                      <div className={style.text}>{t('account.notSpecified')}</div>
                    )}
                  </ConfigurationCard>

                  <ConfigurationCard
                    title={t('account.nssTitle')}
                    isLoading={!nssLoadingError && networkStatusSuspensionConfig === null}
                    actionButton={renderActionButton(true, 'common.manage', handleNSS)}
                  >
                    {nssLoadingError ? (
                      <div>{t('account.nssLoadingError')}</div>
                    ) : networkStatusSuspensionConfig?.enabled && isNssActive ? (
                      <div>
                        {`${t(
                          'account.nssExpiresAt',
                        )} ${nssEndDate?.toLocaleDateString()} ${nssEndDate?.toLocaleTimeString()} `}
                        <TimeExpiryTicker time={networkStatusSuspensionConfig?.schedule?.endsAt} />
                      </div>
                    ) : networkStatusSuspensionConfig?.enabled && !isNssActive ? (
                      <div>{t('account.nssExpired')}</div>
                    ) : (
                      <div>{t('account.noNssScheduleSetup')}</div>
                    )}
                  </ConfigurationCard>

                  <ConfigurationCard
                    title={t('account.billingContact')}
                    actionButton={renderActionButton(true, 'btn.edit', handleEditBC)}
                  >
                    {!isBillingContactEmpty ? (
                      <div>
                        <div className={style.text}>
                          {company.billingContactName
                            ? company.billingContactName
                            : t('account.nameNotEntered')}
                        </div>
                        <div className={style.text}>
                          {company.billingContactEmail
                            ? company.billingContactEmail
                            : t('account.emailNotEntered')}
                        </div>
                        <div className={style.text}>
                          {company.billingContactPhone
                            ? company.billingContactPhone
                            : t('account.phoneCodeNotEntered')}
                        </div>
                      </div>
                    ) : (
                      <div className={style.text}>{t('account.notEntered')}</div>
                    )}
                  </ConfigurationCard>

                  <ConfigurationCard
                    title={t('account.supportContacts')}
                    actionButton={renderActionButton(true, 'btn.edit', handleSupportContacts)}
                  >
                    <div>
                      <div className={style.text}>
                        {`${t('supportContacts.firstLevelEscalation')}: ${
                          company.firstEscalationEmail || t('account.notEntered')
                        }`}
                      </div>
                      <div className={style.text}>
                        {`${t('supportContacts.secondLevelEscalation')}: ${
                          company.secondEscalationEmail || t('account.notEntered')
                        }`}
                      </div>
                      <div className={style.text}>
                        {`${t('supportContacts.managementLevelEscalation')}: ${
                          company.managementEscalationEmail || t('account.notEntered')
                        }`}
                      </div>
                    </div>
                  </ConfigurationCard>
                </>
              )}

              {canAccess('viewInventory', currentUser) && (
                <ConfigurationCard
                  title={t('account.singleNodeInventoryCheck')}
                  actionButton={renderActionButton(true, 'btn.launch', handleOpenSNIC)}
                >
                  <div className={style.text}>
                    {t('account.singleNodeInventoryCheckDescription')}
                  </div>
                </ConfigurationCard>
              )}

              {canAccess('viewM2MTokens', currentUser) && (
                <ConfigurationCard
                  title={t('m2mApiTokenGeneration.tokenGeneration')}
                  isLoading={m2MProvisionedApps === null && !m2mError}
                  actionButton={renderActionButton(
                    !m2mError,
                    canEditM2MApiToken ? 'btn.edit' : 'btn.view',
                    handleM2MApiTokenGeneration,
                  )}
                >
                  <div>
                    {m2mError
                      ? t(`errors.${m2mError}`)
                      : `${t('m2mApiTokenGeneration.availableClouds')}: ${availableClouds.join(
                          ', ',
                        )}`}
                  </div>
                </ConfigurationCard>
              )}

              {canAccess('idpSettings', currentUser) && hasSamlProduct && (
                <ConfigurationCard
                  title={t('idpSettings.title')}
                  actionButton={
                    <NavLink to={IDP_SETTINGS_ROUTE} exact={true}>
                      <Button
                        classes={curr => ({
                          ...curr,
                          root: classNames(curr.root, style.cardButtonWidth),
                        })}
                        styleVariant="superprimary"
                      >
                        {t('common.manage')}
                      </Button>
                    </NavLink>
                  }
                >
                  <div className={style.text}>{t('idpSettings.cardDescription')}</div>
                </ConfigurationCard>
              )}

              {canAccess('settingsApplicationsCard', currentUser) && (
                <ConfigurationCard accountManagerOnly title={t('account.applications')}>
                  <div className={classNames(style.text, style.showNewlines)}>
                    {getFormattedApplications(currentUser?.company)}
                  </div>
                </ConfigurationCard>
              )}

              {canAccess('crmNotification', currentUser) && (
                <ConfigurationCard
                  accountManagerOnly
                  title={t('accountManager.crmNotificationConfig')}
                  isLoading={notificationConfig === null && !notificationConfigError}
                  actionButton={renderActionButton(true, 'btn.edit', handleEditNotificationConfig)}
                >
                  {notificationConfigError ? (
                    <div>{t('errors.partnerConfigurationSettingsNotAvailable')}</div>
                  ) : (
                    <div className={classNames(style.text, style.strongInNewline)}>
                      {t('accountManager.crmNotificationBraze')}:{' '}
                      <strong>
                        {notificationConfig?.partnerFeatureFlags?.brazeUpdateCustomAttributes
                          ? t('accountManager.enabled')
                          : t('accountManager.disabled')}
                      </strong>
                      {t('accountManager.crmNotificationQualtrics')}:{' '}
                      <strong>
                        {notificationConfig?.partnerFeatureFlags?.qualtricsUpdateCustomAttributes
                          ? t('accountManager.enabled')
                          : t('accountManager.disabled')}
                      </strong>
                    </div>
                  )}
                </ConfigurationCard>
              )}

              {canAccess('staffCloudSettings', currentUser) && (
                <>
                  <ConfigurationCard
                    accountManagerOnly
                    title={t('accountManager.parentPartnerId')}
                    isLoading={!parentPartnerIdResponse}
                  >
                    {parentPartnerIdResponse?.parentPartnerId ? (
                      <>
                        <div className={classNames(style.text, style.strongInNewline)}>
                          {t('accountManager.partnerId')}:{' '}
                          <strong>{parentPartnerIdResponse.parentPartnerId}</strong>
                        </div>
                        <div className={classNames(style.text, style.strongInNewline)}>
                          {t('accountManager.name')}:{' '}
                          <strong>{parentPartnerIdResponse.name}</strong>
                        </div>
                        <div className={classNames(style.text, style.strongInNewline)}>
                          {t('accountManager.description')}:{' '}
                          <strong>{parentPartnerIdResponse.description}</strong>
                        </div>
                      </>
                    ) : (
                      t('accountManager.noParentPartnerId')
                    )}
                  </ConfigurationCard>

                  {allSecondaryClouds.map(secondaryCloud => (
                    <ConfigurationCard
                      key={secondaryCloud}
                      accountManagerOnly
                      title={t('accountManager.secondaryCloudSettingsTitle', {
                        secondaryCloud,
                      })}
                      isLoading={!nssLoadingError && networkStatusSuspensionConfig === null}
                      actionButton={renderActionButton(true, 'btn.edit', () =>
                        handleManageSecondaryCloud(secondaryCloud),
                      )}
                    >
                      <div className={classNames(style.text, style.strongInNewline)}>
                        {t('accountManager.enabled')}:{' '}
                        <strong>
                          {secondaryCloudCompanyFields[secondaryCloud]
                            ? t('common.yes')
                            : t('common.no')}
                        </strong>
                      </div>
                    </ConfigurationCard>
                  ))}

                  <ConfigurationCard
                    accountManagerOnly
                    title={t('plumeCloudAPIAccessConfig.title')}
                    isLoading={!pcaamaLoadingError && plumeCloudAPIAccmgrAccessInfo === null}
                    actionButton={renderActionButton(true, 'common.manage', handlePCAAMA)}
                  >
                    {pcaamaLoadingError
                      ? t('plumeCloudAPIAccessConfig.loadingError')
                      : plumeCloudAPIAccmgrAccessInfo?.userAssignedClouds?.length > 0
                      ? `${t('plumeCloudAPIAccessConfig.cardDescription', {
                          email: currentUser.email,
                          partnerId,
                        })}:${plumeCloudAPIAccmgrAccessInfo?.userAssignedClouds?.join(', ')}`
                      : t('plumeCloudAPIAccessConfig.cardDescriptionNoAccess', {
                          email: currentUser.email,
                          partnerId,
                        })}
                  </ConfigurationCard>

                  {!!company.parentPartnerId && (
                    <ConfigurationCard
                      accountManagerOnly
                      title={t('account.nodesClaimableByLocationsWithSharedParent')}
                      isLoading={
                        !nclspLoadingError && nodesClaimableByLocationsWithSharedParentInfo === null
                      }
                      actionButton={renderActionButton(
                        !!nodesClaimableByLocationsWithSharedParentInfo?.elligible,
                        'common.manage',
                        handleNCLSP,
                      )}
                    >
                      {nclspLoadingError ? (
                        <div>{t('account.nclspLoadingError')}</div>
                      ) : !nodesClaimableByLocationsWithSharedParentInfo?.elligible ? (
                        <div>{t('account.nclspNotElligible', { partnerId })}</div>
                      ) : !!nodesClaimableByLocationsWithSharedParentInfo?.claimableByLocationsWithSharedParent ? (
                        <div>
                          {t('account.nclspClaimable')}
                          <div className={style.marigold}>
                            {t('nodesClaimableByParent.description')}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {t('account.nclspNotClaimable')}
                          <div className={style.marigold}>
                            {t('nodesClaimableByParent.description')}
                          </div>
                        </div>
                      )}
                    </ConfigurationCard>
                  )}

                  <ConfigurationCard
                    accountManagerOnly
                    title={t('globalAuth.cardTitle')}
                    isLoading={
                      !partnerConfigFeatureFlagsError && partnerConfigFeatureFlagsAllClouds === null
                    }
                    actionButton={renderActionButton(
                      !partnerConfigFeatureFlagsError,
                      'common.manage',
                      handleGlobalAuth,
                    )}
                  >
                    <div className={classNames(style.text, style.showNewlines)}>
                      {partnerConfigFeatureFlagsError
                        ? t('globalAuth.loadingError')
                        : `${
                            globalAuthHomePassEnabledClouds?.length
                              ? t('globalAuth.enabledOn', {
                                  clouds: globalAuthHomePassEnabledClouds.join(', '),
                                  product: PlumeProduct.HomePass,
                                })
                              : t('globalAuth.notEnabledOn', { product: PlumeProduct.HomePass })
                          }\n${
                            globalAuthWorkPassEnabledClouds?.length
                              ? t('globalAuth.enabledOn', {
                                  clouds: globalAuthWorkPassEnabledClouds.join(', '),
                                  product: PlumeProduct.WorkPass,
                                })
                              : t('globalAuth.notEnabledOn', { product: PlumeProduct.WorkPass })
                          }`}
                    </div>
                  </ConfigurationCard>

                  <ConfigurationCard
                    accountManagerOnly
                    title={t('partnerConfigGetCalls.title')}
                    isLoading={false}
                    actionButton={renderActionButton(
                      true,
                      'btn.view',
                      handleViewPartnerConfigGetCalls,
                    )}
                  >
                    {t('partnerConfigGetCalls.description')}
                  </ConfigurationCard>
                </>
              )}
            </div>
            {/* <div className={style.col}>
              
              Temporary disabled until access is defined 
            {currentUser.isEmployee && (
                  <div className={style.container}>
                    <div className={style.title}>
                      {t('account.deleteAccount')}
                    </div>
                    <div className={style.text}>
                      {t('account.deleteAccountNotification')}
                    </div>
                    <div className={style.deleteAccount}>
                      <Button onClick={this.handleDeleteAccount}>
                        {t('btn.deleteAccount')}
                      </Button>
                    </div>
                  </div>
                )} 
            </div>*/}
          </div>

          {/* Disabled until proper client exposure decision is made extmice1248 */}
          {/* {canEditMobileSSO && (
              <div className={style.mobileSSO}>
                {ssoConfig === null ? (
                  <Loader fit />
                ) : (
                  <>
                    <div className={style.mobileSSODesc}>
                      <div className={classNames(style.title)}>
                        {t('account.mobileAppSSOTitle')}
                      </div>
                      <div className={classNames(style.text)}>
                        {hasSSOConfig
                          ? t('account.changeMobileAppSSOConfig')
                          : t('account.noMobileAppSSOConfig')}
                      </div>
                    </div>
                    <div className={style.appCustomizationBtnWrapper}>
                      {hasSSOConfig && (
                        <span className={style.deleteMobileAppSSO}>
                          <Button onClick={this.handleDeleteMobileAppSSO}>
                            {t('btn.delete')}
                          </Button>
                        </span>
                      )}
                      <Button onClick={this.handleMobileAppSSO}>
                        {!hasSSOConfig ? t('btn.add') : t('btn.edit')}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )} */}
        </div>
      </DashboardLayout>
    </React.Fragment>
  )
})
// export const DashboardConfiguration = withTranslation()(DashboardAccountComponent)
