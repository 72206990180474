import * as React from 'react'

const rawPrivacyPolicyDocument = `<html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style>
    <!--
     /* Font Definitions */
     @font-face
        {font-family:Wingdings;
        panose-1:5 0 0 0 0 0 0 0 0 0;}
    @font-face
        {font-family:"Cambria Math";
        panose-1:2 4 5 3 5 4 6 3 2 4;}
    @font-face
        {font-family:Calibri;
        panose-1:2 15 5 2 2 2 4 3 2 4;}
    @font-face
        {font-family:Cambria;
        panose-1:2 4 5 3 5 4 6 3 2 4;}

      @font-face {
        font-family: Wigrum;
        font-weight: 300;
        src: url('../assets/fonts/wigrumweb-regular.woff') format('woff');
      }

      @font-face {
        font-family: Wigrum;
        font-weight: 500;
        src: url('../assets/fonts/wigrumweb-medium.woff') format('woff');
      }

      @font-face {
        font-family: Wigrum;
        font-style: normal;
        font-weight: 700;
        src: url('../assets/fonts/wigrumweb-bold.woff') format('woff');
      }


    @font-face
        {font-family:"Helvetica Neue";
        panose-1:2 0 5 3 0 0 0 2 0 4;}
    @font-face
        {font-family:Garamond;
        panose-1:2 2 4 4 3 3 1 1 8 3;}
    @font-face
        {font-family:"Segoe UI";
        panose-1:2 11 5 2 4 2 4 2 2 3;}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
        {margin:0in;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    p.MsoFooter, li.MsoFooter, div.MsoFooter
        {mso-style-link:"Footer Char";
        margin:0in;
        font-size:8.0pt;
        font-family:"Calibri",sans-serif;}
    a:link, span.MsoHyperlink
        {color:blue;
        text-decoration:underline;}
    span.FooterChar
        {mso-style-name:"Footer Char";
        mso-style-link:Footer;}
    .MsoChpDefault
        {font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
     /* Page Definitions */
     @page WordSection1
        {size:8.5in 11.0in;
        margin:1.0in 1.0in 1.0in 1.0in;}
    div.WordSection1
        {page:WordSection1;}
     /* List Definitions */
     ol
        {margin-bottom:0in;}
    ul
        {margin-bottom:0in;}
    -->
  </style>
</head>

<body
  lang="EN-US"
  link="blue"
  vlink="#954F72"
  style="word-wrap: break-word; background-color: white"
>
  <div class="WordSection1">
    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >&nbsp;</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Last Updated: December 18, 2023</span
      >
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Plume Design, Inc. and its subsidiaries and affiliates (collectively,</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><b
        ><i
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
            >Plume</span
          ></i
        ></b
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >or</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><b
        ><i
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
            >we</span
          ></i
        ></b
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >) offers so</span
      ><span
        style="
          font-size: 9.5pt;
          line-height: 115%;
          font-family: Wigrum;
          color: black;
          background: white;
        "
        >ftware, mobile applications, hardware, Plume’s user experience management Portal and
        other related services that deliver smart home and enterprise-grade small business
        services built on self-optimizing WiFi technology (<strong
          ><i><span style="font-family: Wigrum">Plume Services</span></i></strong
        >).  This Plume Portal Privacy Policy <b><i>(Portal Privacy Policy)</i></b> applies
        to</span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black">
        the website that Plume makes available for its customers and prospective customers (<b
          ><i>Portal</i></b
        >). Plume provides this Portal Privacy Policy to explain the personal information that
        Plume processes through the Portal and how and why Plume processes it.  This Portal
        Privacy Policy also describes the rights and choices that you may have with respect to
        your personal information and how to exercise those rights.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >In this Portal Privacy Policy, the following terms have the following meanings:</span
      >
    </p>

    <ul type="disc">
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <b
          ><i
            ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
              >personal information</span
            ></i
          ></b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif"
          >&nbsp;</span
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >means information that identifies or can be used to identify an individual person and,
          under some privacy laws that may apply to Plume, information that identifies a specific
          entity</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <b
          ><i
            ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
              >processing</span
            ></i
          ></b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif"
          >&nbsp;</span
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >means any operation (or set if operations), such as collecting, combining and storing,
          performed on personal information</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <b
          ><i
            ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
              >controller</span
            ></i
          ></b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif"
          >&nbsp;</span
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >means the person or entity that determines why and how personal information is
          processed, including a “business” as defined in the California Consumer Privacy Act (<b
            ><i>CCPA</i></b
          >)</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <b
          ><i
            ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
              >processor</span
            ></i
          ></b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif"
          >&nbsp;</span
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >means the person or entity processing personal information on behalf of a controller,
          including a “service provider”</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <b
          ><i
            ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum">user</span></i
          ></b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif"
          >&nbsp;</span
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >means you and other individuals who access and use the Portal</span
        >
      </li>
    </ul>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Plume's processing of personal information is subject to the laws in the jurisdictions in
        which Plume operates. Privacy rights also may vary depending on each user's place of
        habitual residence.
      </span>
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >The controller for personal information collected through the Portal is Plume Design,
        Inc., 325 Lytton Ave., Palo Alto, CA 94301.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >CALIFORNIA RESIDENTS:  FOR OUR NOTICE AT COLLECTION, PLEASE CLICK
        </span></b
      ><span style="color: black"
        ><a target="_blank" href="https://www.plume.com/legal/privacy-rights-notice/"
          ><b
            ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum">HERE</span></b
          ></a
        ></span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >For more information, please see Section 7 below.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >IF YOU HAVE QUESTIONS REGARDING OUR PERSONAL INFORMATION PROCESSING PRACTICES, PLEASE
        CONTACT US AT</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><span style="color: black"
        ><a href="mailto:PRIVACY@PLUME.COM"
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
            >PRIVACY@PLUME.COM</span
          ></a
        ></span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >1. WHEN THIS PLUME PRIVACY POLICY APPLIES</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >This Privacy Policy applies to the Portal and otherwise when you specifically acknowledge
        it. If a particular website or service links to a different privacy policy, then that
        privacy policy - not this Portal Privacy Policy - applies. Please make sure to check those
        other privacy policies to learn how your personal information is processed.</span
      >
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >2. PERSONAL INFORMATION COLLECTION</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Plume collects personal information to provide and promote the Portal and for the other
        purposes listed below. The specific types of personal information that Plume collects from
        or about you depends on how you use the Portal.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >a.</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >Information users choose to give us</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >We collect the personal information that users choose to share with us, which typically
        includes the following types of personal information:</span
      >
    </p>

    <table
      class="MsoNormalTable"
      border="0"
      cellspacing="0"
      cellpadding="0"
      style="background: white; border-collapse: collapse"
    >
      <thead>
        <tr>
          <td
            valign="top"
            style="
              border: solid windowtext 1.5pt;
              background: #4472c4;
              padding: 7.5pt 7.5pt 7.5pt 7.5pt;
            "
          >
            <p class="MsoNormal" align="center" style="text-align: center; line-height: 115%">
              <b
                ><span
                  style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
                  >Types of Personal information</span
                ></b
              >
            </p>
          </td>
          <td
            valign="top"
            style="
              border: solid windowtext 1.5pt;
              border-left: none;
              background: #4472c4;
              padding: 7.5pt 7.5pt 7.5pt 7.5pt;
            "
          >
            <p class="MsoNormal" align="center" style="text-align: center; line-height: 115%">
              <b
                ><span
                  style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
                  >Why this Personal Information is Collected</span
                ></b
              >
            </p>
          </td>
        </tr>
      </thead>
      <tr>
        <td
          valign="top"
          style="
            border: solid windowtext 1.5pt;
            border-top: none;
            padding: 7.5pt 7.5pt 7.5pt 7.5pt;
          "
        >
          <p class="MsoNormal" style="line-height: 115%">
            <b
              ><span
                style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
                >Contact and account information</span
              ></b
            ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
              ><br />
              <i
                >Name, email address or other username, password, telephone number and similar
                contact information and purchase related contact information (e.g., billing and
                delivery address, payment information and similar information necessary to
                complete a purchase); profile nicknames and profile photos.</i
              ></span
            >
          </p>
        </td>
        <td
          valign="top"
          style="
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1.5pt;
            border-right: solid windowtext 1.5pt;
            padding: 7.5pt 7.5pt 7.5pt 7.5pt;
          "
        >
          <ul type="disc">
            <li class="MsoNormal" style="color: black; line-height: 115%">
              <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
                >To create and maintain an account to use the Portal</span
              >
            </li>
            <li class="MsoNormal" style="color: black; line-height: 115%">
              <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
                >For customer service</span
              >
            </li>
            <li class="MsoNormal" style="color: black; line-height: 115%">
              <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
                >To provide information that you request, such as when you participate in an event
                or sign up to receive emails about Plume</span
              >
            </li>
            <li class="MsoNormal" style="color: black; line-height: 115%">
              <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
                >For marketing, such as to send information that we think will interest users,
                which is sometimes personalized based on the information associated that Plume
                has</span
              >
            </li>
            <li class="MsoNormal" style="color: black; line-height: 115%">
              <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
                >To request feedback, such as through a survey</span
              >
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td
          valign="top"
          style="
            border: solid windowtext 1.5pt;
            border-top: none;
            padding: 7.5pt 7.5pt 7.5pt 7.5pt;
          "
        >
          <p class="MsoNormal" style="line-height: 115%">
            <b
              ><span
                style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
                >Personal information you share through the Portal</span
              ></b
            >
          </p>
        </td>
        <td
          valign="top"
          style="
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1.5pt;
            border-right: solid windowtext 1.5pt;
            padding: 7.5pt 7.5pt 7.5pt 7.5pt;
          "
        >
          <ul type="disc">
            <li class="MsoNormal" style="color: black; line-height: 115%">
              <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
                >To manage promotions and other features of the Portal that allow you to share
                your personal information</span
              >
            </li>
            <li class="MsoNormal" style="color: black; line-height: 115%">
              <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
                >When you receive customer support</span
              >
            </li>
            <li class="MsoNormal" style="color: black; line-height: 115%">
              <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
                >To respond to your request in a form filled out on Plume's website to download
                content or attend an event</span
              >
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >b.</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >Information automatically collected by the Portal
        </span></b
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >The Portal automatically collects certain information from and about users of the Portal.
        Some automatically collected information is personal information under certain laws.
        Information is automatically collected using cookies, pixel, web beacons and similar data
        collection technology and includes:</span
      >
    </p>

    <ul type="disc">
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >browser type, operating system version, domains, IP address, browser type, internet
          service provider and mobile network of visitors to Plume's websites</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >how a computer or mobile device or interacts with Plume's websites, including the date
          and time accessed, search requests and results, mouse clicks and movements, specific
          webpages accessed and links clicked</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >data about the third-party sites or services accessed before interacting with Plume's
          websites, which is used to make advertising more relevant for users</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >interactions with Plume's marketing communications, such as whether and when a Plume
          email is opened, to help Plume measure the success of email marketing campaigns</span
        >
      </li>
    </ul>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Please see Plume's
      </span>
      <span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;
      </span>
      <span style="color: black">
        <a target="_blank" href="https://plume.com/legal/cookie-policy">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
            >Cookie Policy</span
          >
        </a>
      </span>
      <span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;
      </span>

      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black">
        for more information.
      </span>
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >c.</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >Information collected from third parties</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >From time to time, Plume receives personal information from third parties that help Plume
        learn more about users and how to promote and improve the Portal more effectively.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >The types of personal information that we receive from third parties are:</span
      >
    </p>

    <ul type="disc">
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Personal information from marketing services providers to help identify individuals who
          may be interested in learning more about the Portal and to supplement personal
          information we already have</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Personal information from the third-party advertising partners that use cookies to
          collect information about browsing activities over time and across websites. Through a
          process called “retargeting,” each advertising partner places cookie(s) in a user's
          browser when the user visits the Portal so that they can identify the user and serve ads
          on other websites around the web based on browsing activity.</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Personal information from publicly available sources</span
        >
      </li>
    </ul>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >When Plume combines personal information from third-party data sources to enhance the
        personal information that Plume holds, Plume requires that each third party confirm the
        lawfulness of its sharing of personal information with Plume for the purposes described in
        this Portal Privacy Policy.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >d.</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >Other information collected with consent</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >We may ask users for consent to collect specific types of personal information, such to
        participate in events, request exclusive content or participate in testing new features or
        products related to the Portal.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >3. PERSONAL INFORMATION USE</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Plume uses personal information to provide and improve the Portal, manage our business,
        protect users and enforce our legal rights. Plume processes personal information as a
        controller to:</span
      >
    </p>

    <ul type="disc">
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <b
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >For Plume's lawful business purposes</span
          ></b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum">:</span>
      </li>
      <ul type="circle">
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >monitor use of and protect the Portal and information processed through the
            Portal</span
          >
        </li>
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >track customer support</span
          >
        </li>
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >analyze, measure the effectiveness of and improve the Portal</span
          >
        </li>
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >identify user needs and develop new products and services to meet them</span
          >
        </li>
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >conduct marketing about the Portal or related third-party products and services
            (where permitted)</span
          >
        </li>
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >invite users and others to participate in market research and testing of current and
            new features</span
          >
        </li>
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >create statistical analyses and segment and combine data sets to identify
            trends</span
          >
        </li>
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >detect and prevent fraud (e.g., if you provide a credit or debit card, we may use
            third parties to check the validity of the sort code, account number and card number
            you submit)</span
          >
        </li>
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >enforce the </span
          ><u
            ><a
              style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
              href="/portal-terms"
              target="_blank"
              >Portal Terms</a
            ></u
          >
        </li>
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >prevent, investigate and/or report security incidents</span
          >
        </li>
      </ul>
    </ul>

    <p class="MsoNormal" style="margin-left: 1in; line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >&nbsp;</span
      >
    </p>

    <ul style="margin-top: 0in" type="disc">
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <b
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >To comply with applicable laws and protect legal rights:</span
          ></b
        >
      </li>
      <ul style="margin-top: 0in" type="circle">
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >enforce and investigate actual or suspected violations of our agreements</span
          >
        </li>
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >protect the safety, security and legal rights of users</span
          >
        </li>
        <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
          <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >detect, prevent and remediate fraud or other unlawful behavior and similar issues
            related to the Portal</span
          >
        </li>
      </ul>
    </ul>

    <p class="MsoNormal" style="margin-left: 0.5in; line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >&nbsp;</span
      >
    </p>

    <ul style="margin-top: 0in" type="disc">
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <b
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >With consent</span
          ></b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >. Plume processes personal information as a controller based on consent for certain
          marketing activities and advertising practices.</span
        >
      </li>
    </ul>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Plume also anonymizes personal information and uses the anonymized data as permitted by
        applicable law and contracts. (Once personal information is anonymized in compliance with
        applicable law, it is no longer personal information and not subject to this Portal
        Privacy Policy.)</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >4. PERSONAL INFORMATION SHARING</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >How Plume shares personal information depends on the Portal usage. Generally, Plume
        shares personal information with people and businesses that help operate the Portal and
        otherwise when Plume is legally permitted or required to do so.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Plume shares personal information with the following categories of recipients:</span
      >
    </p>

    <ul type="disc">
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Professional advisors, such as lawyers, accountants, insurers and information security
          and forensics experts.</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Processors to enable them to perform services on our behalf, including data analytics,
          data security, cloud storage providers, surveys and otherwise to help us carry out our
          business.</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Marketing vendors that help promote the Portal (such as by email marketing) and
          supplement personal information that we already have</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Potential or actual acquirers or investors and their professional advisers in
          connection with any actual or proposed merger, acquisition or investment in or of all or
          any part of our business. We will use our best efforts to ensure that the terms of this
          Portal Privacy Policy apply to personal information after the transaction or that users
          receive advance notice of changes to personal information processing.</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Competent law enforcement, government regulators and courts when we believe disclosure
          is necessary (i) to comply with the law, (ii) to exercise, establish or defend legal
          rights, or (iii) to protect the vital interests of users, business partners, service
          providers or another third party.</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Our affiliates and subsidiaries, such as Plume's entities in Poland, Slovenia,
          Switzerland and Taiwan.</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Other third parties with consent.</span
        >
      </li>
    </ul>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >When Plume shares anonymized data, Plume takes administrative measures to prohibit
        efforts by recipients to re-identify anonymized data.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >5. PERSONAL INFORMATION PROTECTION</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Plume uses technical, physical, and administrative safeguards intended to protect the
        personal information that we process from unauthorized access and use.</span
      >
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Our safeguards are designed to provide a level of security appropriate to the risk of
        processing your personal information and include (as applicable) measures to ensure the
        ongoing confidentiality, integrity, availability, and resilience of processing systems and
        a procedure for regularly testing, assessing and evaluating the effectiveness of technical
        and organizational measures for ensuring the security of the processing of personal
        information. Like any other organization, Plume cannot fully eliminate security risks
        associated with the processing of personal information.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >You are responsible for maintaining the security of your account credentials. Plume will
        treat access to the Portal through your account credentials as authorized by you.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >We may suspend your use of all or part of the Portal without notice if we suspect or
        detect any breach of security. If you believe that information you provided to Plume or
        your account is no longer secure, please notify us immediately at</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><span style="color: black"
        ><a href="mailto:privacy@plume.com"
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
            >privacy@plume.com</span
          ></a
        ></span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >If we become aware of a breach that affects the security of your personal information, we
        will provide you with notice as required by applicable law. When permitted by applicable
        law, Plume will provide this notice to you through the email address associated with your
        account or other permitted method associated with your account.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >UNAUTHORIZED ACCESS TO PERSONAL INFORMATION AND THE PLUME SERVICES - INCLUDING SCRAPING -
        IS PROHIBITED AND MAY LEAD TO CRIMINAL PROSECUTION.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >6. PERSONAL INFORMATION RETENTION</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Plume retains personal information for as long as necessary to carry out the purposes
        described above. Plume also retains personal information for as long as Plume believes
        necessary to comply with our legal obligations, resolve disputes and enforce our legal
        agreements.</span
      >
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >When determining the relevant retention period, Plume considers a user's purpose for
        using the Portal, retention periods in Plume's data deletion procedures and requirements
        of data protection laws.</span
      >
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >At the end of the relevant retention period, Plume either deletes or anonymizes personal
        information or, if Plume cannot delete or anonymize the personal information, then Plume
        segregates and securely stores personal information until deletion or anonymization is
        possible. Plume uses anonymized data subject to applicable law.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >7. CHOICES AVAILABLE FOR PERSONAL INFORMATION</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Choices about personal information depend on a user's place of habitual residence and
        applicable law.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >For residents of the</span
        ></b
      ><b
        ><span
          style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
          >&nbsp;</span
        ></b
      ><b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >US</span
        ></b
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >: 
      </span>
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >To Opt-out of Selling/Sharing (as defined in our </span
      ><span style="color: black"
        ><a target="_blank" href="https://www.plume.com/legal/privacy-rights-notice/"
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
            >Notice at Collection</span
          ></a
        ></span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >)
      </span>
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black">
        or to make another privacy rights request, please complete this</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><span style="color: black"
        ><a target="_blank" href="https://discover.plume.com/US-Privacy-Rights-Request-Form.html"
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum">form</span></a
        ></span
      >
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >. You also can send us an email to</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><span style="color: black"
        ><a href="mailto:privacy@plume.com"
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
            >privacy@plume.com</span
          ></a
        ></span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >for such requests.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >For residents</span
        ></b
      ><b
        ><span
          style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
          >&nbsp;</span
        ></b
      ><b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >of EEA, Switzerland and UK</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <u
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >Data Controller</span
        ></u
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >The controller for the personal information collected in connection with use of the
        Portal in the European Economic Area, Switzerland and United Kingdom is Plume Design,
        Inc. 
      </span>
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <u
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >Processing</span
        ></u
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Plume's processing of your personal information is described above in this Privacy
        Policy, including:</span
      >
    </p>

    <ul type="disc">
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Personal information collection (Section 2)</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Personal information use and sharing (Sections 3-4)</span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Personal information protection and retention (Sections 5-6)</span
        >
      </li>
    </ul>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <u
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >Legal Bases for Plume's Processing</span
        ></u
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >The legal bases for Plume's processing of your personal information depend on the context
        in which the personal information is collected and processed. Generally, we only collect
        personal information when:</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >(i) Plume needs the personal information to perform a contract (such as the </span
      ><u
        ><a
          style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
          href="/portal-terms"
          target="_blank"
          >Portal Terms</a
        ></u
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >) with you or a customer;   
      </span>
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >(ii) when we have your consent to do so (which consent you can withdraw at any time using
        the contact information below); or</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >(iii) when the processing is in our legitimate business interests and not overridden by
        the privacy or other fundamental rights and freedoms of users (such as when we process
        personal information to prevent fraudulent use of the Portal). When we collect and use
        personal information in reliance on our legitimate interests (or those of any third
        party), this interest is to provide and communicate with you about the Portal, for
        responding to queries, improving the Portal, advising users about new features or
        maintenance or similar commercial interests to make the Portal available for you. We may
        have other legitimate interests and if appropriate we will make clear our legitimate
        interests at the relevant time.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >In some cases, we also may have a legal obligation to collect personal information from
        users. If we ask you to provide personal information to comply with a legal requirement,
        we will make this clear at the relevant time and advise you whether providing your
        personal information is mandatory and the possible consequences if you do not provide your
        personal information.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >If you have questions about or need further information concerning the legal basis on
        which we collect and use your personal information, please contact us at</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><span style="color: black"
        ><a href="mailto:privacy@plume.com"
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
            >privacy@plume.com</span
          ></a
        ></span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <u
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >Data Subjects Rights</span
        ></u
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >To the extent provided under GDPR and the similar laws of</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Switzerland and UK, you have the following rights with respect to personal information
        concerning you:</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >- Right of access your personal information</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >- Right to rectification (i.e., correction, update)</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >- Right to erasure</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >- Right to restrict processing</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >- Right to data portability (i.e., receive an electronic copy of your personal
        information for purposes of transmitting it to another organization)</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >- Right to withdraw consent at any time</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >If you would like to access, correct, update, suppress, restrict, object to or delete
        personal information that you have previously provided to Plume as or if you would like to
        receive an electronic copy of your personal information for purposes of transmitting it to
        another company (where this right to portability is provided to you by law), please submit
        your request to us as follows:</span
      >
    </p>

    <ul type="disc">
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >To make a privacy rights request, please see </span
        ><span style="color: black"
          ><a target="_blank" href="https://www.plume.com/legal/data-subject-rights-request/"
            ><span
              style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
              >HERE</span
            ></a
          ></span
        >
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black">
        </span
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >You also can send us an email to</span
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif"
          >&nbsp;</span
        ><span style="color: black"
          ><a href="mailto:privacy@plume.com"
            ><span
              style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
              >privacy@plume.com</span
            ></a
          ></span
        ><span
          style="
            font-size: 9.5pt;
            line-height: 115%;
            font-family: 'Cambria', serif;
            color: #4472c4;
          "
          >&nbsp;</span
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >for such requests.</span
        >
      </li>
    </ul>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >In your request, please make clear what personal information you would like to have
        changed or other limitations you would like to put on our use of your personal
        information. For your protection, we verify your identity and geographic residency before
        fulfilling your request. We will comply with your request as soon as reasonably
        practicable and within the time periods required by applicable law.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Please note that we often need to retain certain personal information for record keeping
        purposes and/or to complete any transaction that you began prior to submitting your
        request. We also may not delete certain personal information for legal reasons.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <u
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >How to Contact Data Protection Authorities</span
        ></u
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <i
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >EU Data Protection Authorities</span
        ></i
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >You have a right to lodge a complaint about how we process your personal information with
        the appropriate EU data protection authority. Please click</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><span style="color: black"
        ><a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/reform/what-are-data-protection-authorities-dpas_en"
          target="_blank"
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
            >here</span
          ></a
        ></span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      >
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black">
        for more information.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <i
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >Switzerland's Data Protection Regulator</span
        ></i
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Office of the Federal Data Protection and Information Commissioner (FDPIC)<br />
        Feldeggweg 1<br />
        CH - 3003 Berne<br />
        Tel: 41 (0)58 462 43 95 (mon.-fri., 10-12 am)<br />
        Telefax: 41 (0)58 465 99 96<br /> </span
      ><span style="color: black"
        ><a href="https://www.edoeb.admin.ch/edoeb/en/home/deredoeb/kontakt.html" target="_blank"
          ><span
            style="
              font-size: 9pt;
              line-height: 115%;
              font-family: 'Helvetica Neue';
              color: #1155cc;
              background: white;
            "
            >https://www.edoeb.admin.ch/edoeb/en/home/deredoeb/kontakt.html</span
          ></a
        ></span
      ><span
        style="
          font-size: 9pt;
          line-height: 115%;
          font-family: 'Helvetica Neue';
          color: #222222;
          background: white;
        "
        >&nbsp;</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <i
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >United Kingdom's Data Protection Regulator</span
        ></i
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Information Commissioner's Office (ICO)<br />
        Wycliffe House, Water Lane, Wilmslow Cheshire SK9 5AF<br />
        Telephone: 0303 123 1113<br />
        Fax: 01625 524510<br /> </span
      ><span style="color: black"
        ><a href="https://ico.org.uk/global/contact-us/" target="_blank"
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
            >https://ico.org.uk/global/contact-us/</span
          ></a
        ></span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >To stop receiving promotional emails from Plume, please click the “Unsubscribe” link at
        the bottom of the email. Your account settings also may allow you to change your
        notification preferences. After you opt out, we may still send you non-promotional
        communications, such as receipts for purchases or administrative information about your
        account.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >IF YOU RESIDE IN A JURISDICTION WITH PRIVACY LAWS THAT OFFER YOU PRIVACY RIGHTS NOT
          DESCRIBED IN THIS PRIVACY POLICY, PLEASE CONTACT PLUME AT</span
        ></b
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><span style="color: black"
        ><a href="mailto:privacy@plume.com"
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
            >PRIVACY@PLUME.COM</span
          ></a
        ></span
      ><b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >. PLUME RESPECTS YOUR PRIVACY RIGHTS AND WILL DO OUR BEST TO ACCOMMODATE YOUR
          REQUESTS.</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >8. CROSS-BORDER TRANSFERS OF PERSONAL INFORMATION</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Plume may transfer personal information across borders to any of the places where Plume
        and its suppliers and partners do business. Other jurisdictions may have data protection
        laws that are different from (and, in some cases, less protective) than the laws where you
        reside.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >If your personal information is transferred across borders by Plume or on Plume's behalf,
        Plume uses appropriate safeguards to protect personal information in accordance with this
        Portal Privacy Policy. These safeguards include agreeing to standard contractual clauses
        and similar model contracts for transfers of personal information among the Plume
        affiliates and among Plume's suppliers and partners. When in place, these contracts
        require the affiliates, suppliers and partners to protect personal information in
        accordance with applicable privacy laws.  To request information about Plume's standard
        contractual clauses or model contracts for cross-border personal information transfers,
        please contact</span
      ><span
        style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif; color: black"
        >&nbsp;</span
      ><span style="color: black"
        ><a href="mailto:privacy@plume.com"
          ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
            >privacy@plume.com</span
          ></a
        ></span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >.
      </span>
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Plume also has committed to comply with the EU-U.S. </span
      ><a target="_blank" href="https://www.dataprivacyframework.gov/"
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >Data Privacy Framework</span
        ></a
      >
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. Data Privacy Framework, as set
        forth by the U.S. Department of Commerce (collectively, the <b><i>DPF Framework</i></b
        >).  The DPF Framework </span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >applies to personal information that Plume </span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >receives in the U.S. from the EU, UK and Switzerland. 
      </span>
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >&nbsp;</span
      >
    </p>

    <p class="MsoNormal" style="text-align: justify; line-height: 115%">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >For more information about how Plume complies with the DPF Framework, please read the
        Plume DPF Privacy Notice.    (Note: If the terms in this Portal Privacy Policy and the DPF
        Privacy Notice conflict, then the DPF Privacy Notice governs the </span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >personal information that Plume </span
      ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >receives in the U.S. in reliance on the DPF Framework.  To view our certification, please
        visit </span
      ><a href="https://www.dataprivacyframework.gov/" target="_blank"
        ><span
          style="font-size: 9pt; line-height: 115%; font-family: 'Helvetica Neue'; color: #1155cc"
          >https://www.dataprivacyframework.gov/</span
        ></a
      ><span
        style="font-size: 9pt; line-height: 115%; font-family: 'Helvetica Neue'; color: white"
        >.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >9. CHANGES TO THIS PORTAL PRIVACY POLICY</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >Plume may update this Portal Privacy Policy from time to time in response to changing
        legal, technical or business developments. When Plume updates the Portal Privacy Policy,
        Plume will post the updated version and change the “Last Updated” date above. Plume also
        will take appropriate measures to inform you in advance of significant changes that we
        believe affect your privacy rights so that you have an opportunity to review the revised
        Portal Privacy Policy before it is effective. If your consent is required by applicable
        privacy laws, Plume will obtain your consent to changes before the revised Portal Privacy
        Policy applies to you.</span
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <b
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
          >10. CONTACT INFORMATION</span
        ></b
      >
    </p>

    <p class="MsoNormal" style="line-height: 115%; background: white">
      <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: black"
        >If you have questions or comments about the Portal Privacy Policy or Plume's privacy
        practices, please contact us:</span
      >
    </p>

    <ul type="disc">
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum">by email at</span
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: 'Cambria', serif"
          >&nbsp;</span
        ><span style="color: black"
          ><a href="mailto:privacy@plume.com"
            ><span
              style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum; color: #4472c4"
              >privacy@plume.com</span
            ></a
          ></span
        >
      </li>
      <li class="MsoNormal" style="color: black; line-height: 115%; background: white">
        <span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >by mail at:<br />
          Plume Design, Inc.<br />
          Attn: Portal Privacy<br /> </span
        ><span style="font-size: 9.5pt; line-height: 115%; font-family: Wigrum"
          >325 Lytton Avenue<br />
          Palo Alto, CA 94301</span
        >
      </li>
    </ul>
  </div>
</body>
</html>
`

export class PortalPrivacyPolicyContent extends React.Component {
  render() {
    return (
      <iframe
        title="PLUME PORTAL PRIVACY POLICY"
        width="100%"
        height="100%"
        srcDoc={rawPrivacyPolicyDocument}
      />
    )
  }
}
