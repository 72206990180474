import * as React from 'react'
import { Button } from 'components/button'
import { AppStore } from 'stores/app-store'
import { observer } from 'mobx-react'
import { withTranslation, WithTranslation } from 'react-i18next'
import universalCookie from 'universal-cookie'
import style from './release-notes-modal.css'
import { DashboardUsersStore } from 'modules/dashboard/user/user-store'
// import { supportEmails } from 'constants/support-emails'
import { SALESFORCE_SAML_RELEASE_NOTES_URL } from 'constants/applications/urls'

interface ReleaseNotesModalComponentProps extends WithTranslation {
  appStore?: AppStore
  onClose: () => void
  usersStore?: DashboardUsersStore
}

@observer
export class ReleaseNotesComponent extends React.Component<ReleaseNotesModalComponentProps> {
  handleGotIt = () => {
    const cookies = new universalCookie()
    const { onClose } = this.props

    cookies.set('releaseNotesPopup1.51.0', true, {
      path: '/',
      expires: new Date('2024-02-25T23:59:00.000Z'),
    })

    if (onClose) {
      onClose()
      onClose()
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className={style.root}>
        <div className={style.banner} />
        <div className={style.title}>{t(`releaseNotes1510.title`)}</div>
        <ul className={style.list}>
          <li>{t(`releaseNotes1510.listItem1`)}</li>
          <li>
            {t(`releaseNotes1510.listItem2a`)}
            <a href={SALESFORCE_SAML_RELEASE_NOTES_URL} target="_blank">
              {t('releaseNotes1510.listItem2b')}
            </a>
            {t(`releaseNotes1510.listItem2c`)}
          </li>
          <li>{t(`releaseNotes1510.listItem3`)}</li>
          <li>{t(`releaseNotes1510.listItem4`)}</li>
        </ul>

        <div className={style.buttonWrapper}>
          <Button
            type="submit"
            className={style.actionButton}
            autoFocus
            fullWidth
            onClick={this.handleGotIt}
          >
            {t('btn.gotIt')}
          </Button>
        </div>
      </div>
    )
  }
}

export const ReleaseNotesModal = withTranslation()(ReleaseNotesComponent)
