import { action, observable } from 'mobx'
import * as React from 'react'

export class ModalLayerStore {
  modals = observable.array<React.ReactElement<any>>([])

  private id = 0

  public getKey() {
    return (this.id += 1)
  }

  @action
  public addModal(element: React.ReactElement<any>) {
    const bodyElement = document.getElementsByTagName('body')[0]
    bodyElement.style.overflow = 'hidden'
    this.modals.push(element)
  }

  @action
  public removeModal() {
    const bodyElement = document.getElementsByTagName('body')[0]
    if (this.modals.length === 1) bodyElement.style.overflow = ''
    this.modals.pop()
  }
}
