import { Expander } from 'components/expander'
import * as React from 'react'

import style from './paper.css'
import { UpArrow, DownArrow } from 'components/icons'

interface IPaperProps {
  isMobile: boolean
  isExpanded: boolean
  title: string
  name?: string
  icon: any
  toggle(name: string): void
}

export class Paper extends React.Component<IPaperProps> {
  toggleHandler = (name: string) => {
    this.props.toggle(name)
  }

  render() {
    const { isMobile, isExpanded, title, name, icon } = this.props

    return (
      <div className={style.paper}>
        <div className={style.paperTitleWrapper}>
          <div className={style.flex}>
            {icon}
            <span className={style.paperTitle}>{title}</span>
          </div>
          {isMobile &&
            (isExpanded ? (
              <UpArrow onClick={() => this.toggleHandler(name)} />
            ) : (
              <DownArrow onClick={() => this.toggleHandler(name)} />
            ))}
        </div>
        <Expander maxHeight={500} expanded={isExpanded} transitionDuration={0.4}>
          <div className={style.content}>{this.props.children}</div>
        </Expander>
      </div>
    )
  }
}
