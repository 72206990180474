import * as React from 'react'
import style from './dashboard-layout.css'
import { Header } from './header'
import { Navigation } from './navigation'
import { Footer } from 'components/footer'
import { AuthStore } from 'stores/auth-store'
import { inject, observer } from 'mobx-react'
import universalCookie from 'universal-cookie'
import { when } from 'mobx'
import { Loader } from 'components/loader'
import { ICompanyApi } from 'interfaces/api/portal/company-api'
import { IUser } from 'interfaces/api/portal/user-api'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { AddUserReturnValue } from 'interfaces/utils/add-user'
import { AppStore } from 'stores/app-store'
import { DashboardUsersStore } from 'modules/dashboard/user/user-store'
import classNames from 'classnames'
import { withTranslation, WithTranslation } from 'react-i18next'
import { flattenSubordinateCompanies, isTerminated } from 'helpers/company-helpers'
import { MANAGE_INVENTORY_ROUTE, MANAGE_PARTNERS_ROUTE } from 'constants/routes'
import { ReleaseNotesModal } from 'modules/dashboard/common/dashboard-layout/release-notes-modal'

const showReleaseNotesModal = async (
  actionModalStore: ModalLayerStore,
  usersStore: DashboardUsersStore,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <ReleaseNotesModal
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        usersStore={usersStore}
      />,
    )
  })
}

// Account suspension is not used yet

// const suspendedText =
//   'This account has been suspended due to a billing issue. Please update your billing information to re-activate account.'
// const suspendedButtonText = 'Update billing info'

interface IDashboardLayoutProps extends WithTranslation {
  children: React.ReactNode
  authStore?: AuthStore
  companyApi?: ICompanyApi
  currentUser?: IUser
  appStore?: AppStore
  errorMode?: boolean
  selectCompany?: boolean
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  appStore,
  authStore: appStore.authStore,
  companyApi: appStore.companyApi,
}))
@observer
class DashboardLayoutComponent extends React.Component<IDashboardLayoutProps> {
  componentDidMount() {
    if (!this.props.errorMode) {
      when(
        () => this.props.appStore.usersStore && !this.props.appStore.usersStore.isLoading,
        () => {
          this.props.appStore.usersStore.updateUserLocaleIfNeeded()
        },
      )

      const cookies = new universalCookie()
      const showReleaseNotes = cookies.get('releaseNotesPopup1.51.0')

      if (
        !showReleaseNotes &&
        new Date('2024-02-25T23:59:00.000Z').getTime() > new Date().getTime()
      ) {
        showReleaseNotesModal(this.props.appStore.actionModalStore, this.props.appStore.usersStore)
      }
    }
  }

  render() {
    const { children, errorMode, selectCompany, authStore } = this.props
    const { currentUser } = authStore

    if (!currentUser && !errorMode) {
      return <Loader />
    }

    const isTrialExpiredOrTerminated =
      !currentUser?.isEmployee && isTerminated(currentUser?.company)

    const isMangeTrialsOpen =
      window.location.pathname === MANAGE_PARTNERS_ROUTE ||
      window.location.pathname === MANAGE_INVENTORY_ROUTE

    const rootCls = classNames(style.root, {
      [style.rootError]: errorMode,
    })

    if (errorMode) {
      return (
        <React.Fragment>
          <div className={rootCls}>
            <Header errorMode={errorMode} />
            <div className={style.container}>
              <Navigation
                handleLogoutClick={this.handleLogoutClickWithReload}
                selectOtherPartnerCompany={this.selectOtherPartnerCompany}
                featureAccessRights={[]}
                hasMoreCompanies={false}
                errorMode
              />
              <section className={classNames(style.content, isMangeTrialsOpen && style.overflowX)}>
                {children}
              </section>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      )
    }

    if (selectCompany) {
      return (
        <>
          <div className={rootCls}>
            <Header />
            <div className={style.container}>{children}</div>
          </div>
          <Footer />
        </>
      )
    }

    // const isSupportModalVisible = this.props.appStore.usersStore?.isSupportModalVisible

    const hasMoreCompanies =
      this.props.authStore.currentUser.companies &&
      this.props.authStore.currentUser.hasMoreCompanies

    const subordinateCompanies =
      this.props.authStore.currentUser.companies &&
      flattenSubordinateCompanies(this.props.authStore.currentUser.companies)
    const hasSubordinateCompanies = subordinateCompanies?.length > 0

    return (
      <React.Fragment>
        <div className={style.root}>
          <Header />
          <div className={style.container}>
            {!isTrialExpiredOrTerminated && (
              <React.Fragment>
                <Navigation
                  handleLogoutClick={this.handleLogoutClick}
                  selectOtherPartnerCompany={this.selectOtherPartnerCompany}
                  featureAccessRights={this.props.authStore.currentUser.featureAccessRightsArr}
                  hasMoreCompanies={hasMoreCompanies}
                  hasSubordinateCompanies={hasSubordinateCompanies}
                />
                <section
                  className={classNames(style.content, isMangeTrialsOpen && style.overflowX)}
                >
                  {children}
                </section>
              </React.Fragment>
            )}
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
  handleLogoutClick = () => {
    this.props.authStore.logout()
  }
  handleLogoutClickWithReload = () => {
    this.props.authStore.logout()
    window.location.reload()
  }
  selectOtherPartnerCompany = () => {
    this.props.authStore.selectOtherPartnerCompany()
  }
}

export const DashboardLayout = withTranslation()(DashboardLayoutComponent)
