import * as React from 'react'
import { Close } from 'components/icons/index'
import { Button } from 'components/button'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import style from './plume-cloud-api-accmgr-access.css'
import { AccountStore } from 'modules/dashboard/account/account-store'
import { mixpanelActions } from 'utils/mixpanelActions'
import { TagsSelection } from 'plume-ui'
import { SalesforceCloud } from 'constants/salesforce.constants'
import { convertOptionsArrayToRecord, convertOptionsRecordToArray } from 'helpers/general-helpers'
import classNames from 'classnames'

interface PCAAMAProps {
  onClose: () => Promise<void>
  accountStore: AccountStore
}

export const PlumeCloudAPIAccmgrAccess = observer((props: PCAAMAProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const company = appStore?.authStore?.currentUser?.company
  const { onClose } = props

  const { serverError, isLoadingModal, plumeCloudAPIAccmgrAccessInfo } = props.accountStore

  const [selectedClouds, setSelectedClouds] = React.useState(
    convertOptionsArrayToRecord(plumeCloudAPIAccmgrAccessInfo?.userAssignedClouds),
  )

  const TagsOptions = plumeCloudAPIAccmgrAccessInfo?.partnerAvailableClouds.map(c => ({
    id: c,
    value: c,
  }))

  plumeCloudAPIAccmgrAccessInfo

  React.useEffect(() => {
    mixpanelActions.track('Plume Cloud API Account Manager Access - Modal Open', {
      'Partner Id': company?.partnerId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const oc = style.override
  const submitDisabled = isLoadingModal

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (submitDisabled) {
      return
    }

    props.accountStore.submitPlumeCloudAPIAccmgrAccess({
      userAssignedClouds: convertOptionsRecordToArray(selectedClouds),
    })
  }

  return (
    <div className={style.root}>
      <div className={style.subTitle}>{t('plumeCloudAPIAccessConfig.title')}</div>
      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>

      <form onSubmit={handleFormSubmit}>
        <div className={style.labelText}>
          {t('plumeCloudAPIAccessConfig.description', {
            email: appStore?.authStore?.currentUser?.email,
            partnerId: company?.partnerId,
          })}
        </div>

        <TagsSelection
          classes={curr => ({ root: classNames(curr.root, oc, style.tagSelectionWidth) })}
          items={TagsOptions}
          selectionStrategy="multiple"
          onToggleItem={cloud => {
            setSelectedClouds(clouds => ({
              ...clouds,
              [cloud.id]: !clouds[cloud.id as SalesforceCloud],
            }))
          }}
          initiallySelectedItems={plumeCloudAPIAccmgrAccessInfo?.userAssignedClouds}
        />

        {serverError && <div className={style.serverError}>{serverError}</div>}
        <div className={style.btnDiv}>
          {isLoadingModal && <Loader />}
          <Button className={style.sendInviteBtn} type="submit" disabled={submitDisabled}>
            {t('btn.save')}
          </Button>
        </div>
      </form>
    </div>
  )
})
