import * as React from 'react'
import style from './accept-updated-terms-conditions.css'
import { Button } from 'components/button'
import { useTranslation } from 'react-i18next'
import {
  PORTAL_PRIVACY_POLICY_ROUTE,
  PORTAL_TERMS_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
} from 'constants/routes'
import { AddUserReturnValue } from 'interfaces/utils/add-user'
import { appStoreContainer } from 'index'
import { useStores } from 'utils/hooks/useStores'
import { observer } from 'mobx-react-lite'

interface IUpdatedTermsAndConditionsProps {
  onClose: () => Promise<void>
}

export const UpdatedTermsAndConditions = observer((props: IUpdatedTermsAndConditionsProps) => {
  const { t } = useTranslation()
  const appStore = useStores()
  const { onClose } = props

  const handleAccept = async () => {
    onClose()
    await appStore.authStore.updateUserTermsAndConditions()
    window.location.reload()
  }

  return (
    <div className={style.root}>
      <div className={style.title}>{t('termsAndConditions.acceptTitle')}</div>
      <div className={style.content}>
        <div className={style.tcText}>
          {t('termsAndConditions.acceptDescription')}{' '}
          <a target="_blank" href={TERMS_AND_CONDITIONS_ROUTE}>
            {t('auth.enterpriseTerms')}
          </a>
          {',\n'}
          <a target="_blank" href={PORTAL_PRIVACY_POLICY_ROUTE}>
            {t('auth.portalPrivacyPolicy')}
          </a>
          {', '}
          <a target="_blank" href={PORTAL_TERMS_ROUTE}>
            {t('auth.portalTerms')}
          </a>{' '}
          .
        </div>
      </div>
      <div className={style.buttonWrapper}>
        <Button className={style.actionButton} autoFocus fullWidth onClick={handleAccept}>
          {t('common.accept')}
        </Button>
      </div>
    </div>
  )
})

export const showAcceptUpdatedTermsAndConditions = async () => {
  const actionModalStore = appStoreContainer.appStore.actionModalStore
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <UpdatedTermsAndConditions
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
      />,
    )
  })
}
