import * as React from 'react'
import style from './yesno-radio-button.css'
import { RadioButton } from '../radio-button'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

interface IYesNoRadioButtonProps {
  title?: string
  id: string
  name: string
  checkedValue: boolean
  handleOnFieldChange: (name: string) => void
  yesLabel?: string
  noLabel?: string
  className?: string
  disabled?: boolean
}

export const YesNoRadioButton = (props: IYesNoRadioButtonProps) => {
  const { t } = useTranslation()
  const {
    title,
    id,
    name,
    checkedValue,
    disabled = false,
    handleOnFieldChange,
    yesLabel,
    noLabel,
    className,
  } = props

  return (
    <div className={classNames(style.root, className)}>
      {title && <div className={style.title}>{title}</div>}
      <div className={style.formInputRadio}>
        <RadioButton
          id={`${id}Yes`}
          name={name}
          checked={checkedValue}
          handleOnFieldChange={!disabled ? handleOnFieldChange : () => undefined}
          label={t(yesLabel || 'common.yes')}
          disabled={disabled}
        />
      </div>
      <div className={style.formInputRadio}>
        <RadioButton
          id={`${id}No`}
          name={name}
          checked={!checkedValue}
          handleOnFieldChange={!disabled ? handleOnFieldChange : () => undefined}
          label={t(noLabel || 'common.no')}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default YesNoRadioButton
