import * as React from 'react'
import style from './missing-company.css'
import { AuthStore } from 'stores/auth-store'
import { inject, observer } from 'mobx-react'
import { SignOut } from 'components/icons'
import classNames from 'classnames'
import { withTranslation, WithTranslation } from 'react-i18next'
import { AppStore } from 'stores/app-store'

interface IMissingCompanyComponentProps extends WithTranslation {
  authStore?: AuthStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  authStore: appStore.authStore,
}))
@observer
class MissingCompanyComponent extends React.Component<IMissingCompanyComponentProps> {
  render() {
    const { t } = this.props

    return (
      <React.Fragment>
        <div className={style.root}>
          <div className={style.text}>{t('missingCompany.message')}</div>
          <div
            className={classNames(style.signOutButton, style.navItemWrapper)}
            onClick={this.handleLogoutClick}
          >
            <span className={style.icon}>
              <SignOut />
            </span>
            <span className={style.navItemText}>{t('auth.signOut')}</span>
          </div>
        </div>
      </React.Fragment>
    )
  }
  handleLogoutClick = () => {
    this.props.authStore.logout()
  }
}

export const MissingCompany = withTranslation()(MissingCompanyComponent)
