export const parseJsonSafe = (stringifiedJson: string) => {
  try {
    return JSON.parse(stringifiedJson)
  } catch (e) {
    return null
  }
}

export const stringifyError = (e: any) => {
  try {
    const errStr = JSON.stringify(
      e,
      Object.getOwnPropertyNames(e).filter(p => p !== 'stack'),
    )
    return errStr
  } catch (e) {
    return null
  }
}

export const capitalizeFirstLetter = (s: string) => {
  return `${s.charAt(0).toUpperCase()}${s.slice(1)}`
}

export const convertSecondsToHMS = (s: string | number) => {
  const initialSeconds = Number.parseInt(`${s}`, 10)
  const hours = Math.floor(initialSeconds / 3600)
  const minutes = Math.floor((initialSeconds - hours * 3600) / 60)
  const seconds = initialSeconds - hours * 3600 - minutes * 60
  return { hours, minutes, seconds }
}

export const handleNumberInputFieldChange = (
  value: string,
  setField: React.Dispatch<React.SetStateAction<string>>,
  limit?: number,
) => {
  const parsedValue = value ? Number.parseInt(value) : 0
  if (!isNaN(parsedValue) && parsedValue >= 0 && (!limit || parsedValue <= limit)) {
    setField(`${value ? parsedValue : ''}`)
  }
}

export const subtractTimeSpeedTest = (subtractFrom: string, subtractAmount: string) => {
  if (!subtractFrom || !subtractAmount) {
    return
  }
  const subFromArray = subtractFrom.split(':')
  const subAmountArray = subtractAmount.split(':')
  const subFromSeconds =
    Number.parseInt(subFromArray[0], 10) * 3600 + Number.parseInt(subFromArray[1], 10) * 60
  const subAmountSeconds =
    Number.parseInt(subAmountArray[0], 10) * 3600 + Number.parseInt(subAmountArray[1], 10) * 60
  return subFromSeconds - subAmountSeconds
}

export const getDataUrl = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const fr = new FileReader()
    fr.onload = () => resolve(fr.result as string)
    fr.onerror = e => reject(e)
    fr.readAsDataURL(file)
  })

function fallbackCopyTextToClipboard(text: string) {
  const ta = document.createElement('textarea')
  ta.value = text
  ta.style.display = 'none'

  document.body.appendChild(ta)
  ta.focus()
  ta.select()
  ta.setSelectionRange(0, 99999)

  try {
    document.execCommand('copy')
  } catch (e) {}

  document.body.removeChild(ta)
}
export function copyTextToClipboard(text: string, callback?: Function) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(res => callback?.(res))
}

export const downloadFile = (file: any, fileName: string, type: string) => {
  const blob = new Blob([file], { type })
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)

  link.setAttribute('href', url)
  link.setAttribute('download', fileName)
  link.style.visibility = 'hidden'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const setSafariScalingStyle = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  if (isSafari) {
    document.documentElement.style.setProperty('--vpUnitsFactor', '1')
  }
}

export function convertSfPicklistStringToArray<T extends string>(sfString: string): T[] {
  return sfString ? (sfString.split(';') as T[]) : []
}

export function splitWithEmpty<T extends string>(string: string, separator: string): T[] {
  return string ? (string.split(separator) as T[]) : []
}

export function convertOptionsArrayToRecord<T extends string>(array: Array<T>) {
  return array.reduce(
    (record, currValue) => ({ ...record, [currValue]: true }),
    {} as Partial<Record<T, boolean>>,
  )
}

export function convertOptionsRecordToArray<T extends string>(record: Partial<Record<T, boolean>>) {
  return Object.keys(record).reduce((arr, currValue: T) => {
    if (record[currValue]) {
      arr.push(currValue)
    }
    return arr
  }, [] as Array<T>)
}

// export function convertRecordToArrayWithId<
//   T extends string,
//   B extends Record<any, any>,
//   C extends string,
// >(record: Partial<Record<T, B>>, idString: C) {
//   return Object.keys(record).reduce((arr, currValue: T) => {
//     arr.push({ [idString]: currValue, ...record[currValue] })
//     return arr
//   }, [] as Array<Partial<Record<T, B> & Record<C, string>>>)
// }

export function isDateAfterNow(dateString: string) {
  if (!dateString) {
    return null
  }
  const date = new Date(dateString)
  return date > new Date()
}

export function matchesCaseInsensitive(source: string, submatch: string) {
  return source?.toLowerCase()?.includes(submatch?.toLowerCase())
}

export function getDuplicateInArray(array: string[]) {
  let duplicated: string
  const valueExistsInArrayRecord: Record<string, boolean> = {}
  for (const value of array) {
    if (valueExistsInArrayRecord[value]) {
      duplicated = value
      break
    }
    valueExistsInArrayRecord[value] = true
  }
  return duplicated
}

/** Returns duplicated value in lower-case*/
export function getCaseInsensitiveDuplicateInArray(array: string[]) {
  let duplicatedLowerCase: string
  const valueExistsInArrayRecord: Record<string, boolean> = {}
  for (const valueOriginal of array) {
    const valueLowerCase = valueOriginal?.toLowerCase()
    if (valueExistsInArrayRecord[valueLowerCase]) {
      duplicatedLowerCase = valueLowerCase
      break
    }
    valueExistsInArrayRecord[valueLowerCase] = true
  }
  return duplicatedLowerCase
}

const objectIncludesKey = (obj: any, key: string) => {
  try {
    return !!Object.keys(obj)?.includes(key)
  } catch (e) {
    return false
  }
}

export function getPropertiesInObject(o: any, property: string, arr: any[] = []) {
  if (objectIncludesKey(o, property)) {
    arr.push(o?.[property])
  } else {
    if (o !== null && o !== undefined && !(typeof o === 'string' || o instanceof String)) {
      for (const key of Object.keys(o)) {
        getPropertiesInObject(o?.[key], property, arr)
      }
    }
  }
  return arr
}

export function containsAllMembers<T extends string>(array: Array<T>, target: Array<T>) {
  return target.every(m => array.includes(m))
}

export type DeepReadonly<T> = T extends (infer R)[]
  ? DeepReadonlyArray<R>
  : T extends Function
  ? T
  : T extends object
  ? DeepReadonlyObject<T>
  : T

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DeepReadonlyArray<T> extends ReadonlyArray<DeepReadonly<T>> {}

type DeepReadonlyObject<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>
}

export function getTypedObjectKeys<T extends object>(obj: T) {
  try {
    return Object.keys(obj) as Array<keyof T>
  } catch {
    return null
  }
}
