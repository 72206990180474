import * as React from 'react'
import style from './unsupported-browser.css'

const Header = () => <header className={style.header} />

export const UnsupportedBrowser = () => {
  return (
    <div className={style.root}>
      <Header />
      <div className={style.title}>Internet Explorer is not supported.</div>
      <div>Use Chrome, Firefox or Safari to access the Plume Portal.</div>
    </div>
  )
}
