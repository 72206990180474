import { useEffect } from 'react'
import { useStores } from './useStores'

export function useGetUserAppAssignmentData() {
  const appStore = useStores()
  useEffect(() => {
    if (!appStore.authStore.currentUser) {
      appStore.authStore.update().then(() => appStore.authStore.getUserAssignedApplications())
    } else {
      appStore.authStore.getUserAssignedApplications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
