import { IApplication } from 'constants/user/applications'
import {
  getHarvestFeaturesFromOpportunityProducts,
  getHarvestFeaturesValue,
} from 'helpers/harvest-helpers'
import { ICompany } from 'interfaces/api/portal/company-api'

// no need for sfToolName because they're always the same

export const getDefaultAppFeatures = (app: IApplication, company: ICompany) => {
  const appSfName = app.sfProductName
  if (appSfName === 'Harvest') {
    return getHarvestFeaturesFromOpportunityProducts(company.productsInfo)
  }
  return []
}

export const getAppFeaturesValue = (app: IApplication, features: string[], itemValue: string) => {
  const appSfName = app.sfProductName
  let featuresValue: string[] = []
  if (appSfName === 'Harvest') {
    featuresValue = getHarvestFeaturesValue(features, itemValue)
  } else {
    featuresValue = features?.includes(itemValue)
      ? features.filter(f => f !== itemValue)
      : [...features, itemValue]
  }
  return featuresValue
}

export const isAppFeatureAvailable = (app: IApplication, itemValue: string, company: ICompany) => {
  const appSfName = app.sfProductName
  if (appSfName === 'Harvest') {
    const availableHarvestFeatures = getHarvestFeaturesFromOpportunityProducts(company.productsInfo)
    return availableHarvestFeatures.includes(itemValue)
  }
  return true
}
