import { SecondaryAction } from '../common/secondary-action'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { LoginLayout } from '../common/login-layout'
import { Input } from 'components/input/input'
import { Button } from 'components/button/index'
import { PasswordResetRequestStore } from './password-reset-request-store'
import { inject, observer } from 'mobx-react'
import { AppStore } from 'stores/app-store'
import { LOGIN_ROUTE } from 'constants/routes'
import { withTranslation, WithTranslation } from 'react-i18next'

interface PasswordResetRequestProps extends WithTranslation {
  appStore?: AppStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  appStore,
}))
@observer
class PasswordResetRequestComponent extends React.Component<PasswordResetRequestProps> {
  private store = new PasswordResetRequestStore(this.props.appStore)

  private handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    this.store.validateAndSubmit()
  }

  render() {
    const { t } = this.props
    const {
      store: { email, isLoading, error },
    } = this

    return (
      <LoginLayout title={t('auth.passwordReset')} description={t('auth.passwordResetDesc')}>
        <form onSubmit={this.handleFormSubmit}>
          <Input
            autoFocus
            label={t('common.emailAddress')}
            hasBeenValidated={email.hasBeenValidated}
            value={email.value}
            error={email.error || error}
            onChange={e => email.onChange(e.target.value)}
            onBlur={e => email.onChange(e.target.value.trim())}
          />
          <Button type="submit" fullWidth disabled={isLoading}>
            {t('btn.sendResetLink')}
          </Button>
        </form>
        <SecondaryAction>
          <Link to={LOGIN_ROUTE}>{t('auth.cancelResetPassowrd')}</Link>
        </SecondaryAction>
      </LoginLayout>
    )
  }
}

export const PasswordResetRequest = withTranslation()(PasswordResetRequestComponent)
