import { SalesforceCloud } from '../../../../constants/salesforce.constants'
import { PlumeServiceProductType } from '../../../../enums/plume-product.enum'
import { GetPartnerConfigFeatureFlagsResponse } from '../../../../interfaces/api/portal/company-api'

export const isGlobalAuthProductEnabledOnCloud = (
  partnerConfigFeatureFlagsAllClouds: GetPartnerConfigFeatureFlagsResponse,
  cloud: SalesforceCloud,
  product: PlumeServiceProductType,
) => {
  return product === 'HomePass'
    ? !!(
        partnerConfigFeatureFlagsAllClouds?.[cloud]?.globalAuth &&
        partnerConfigFeatureFlagsAllClouds?.[cloud]?.globalAuthHomePass
      )
    : !!(
        partnerConfigFeatureFlagsAllClouds?.[cloud]?.globalAuth &&
        partnerConfigFeatureFlagsAllClouds?.[cloud]?.globalAuthWorkPass
      )
}
