import { action, observable } from 'mobx'
import { OktaAuth } from '@okta/okta-auth-js'
import { History } from 'history'
import { LOGIN_ROUTE } from 'constants/routes'

interface AppAuthOptions {
  redirectUri?: string
  clientId?: string
  scopes?: string[]
}

interface AuthTransactionStoreOptions {
  restoreTransaction: boolean
  restoreSession?: boolean
  history: History
  appOptions?: AppAuthOptions
}

export class AuthTransactionStore {
  @observable
  loading = true

  get userPhoneNumber() {
    return this.transaction?.factor?.profile?.phoneNumber
  }

  authClient: OktaAuth
  transaction: any
  private history: History
  private appAuthOptions: AppAuthOptions = {}
  factors: any

  constructor(private options: AuthTransactionStoreOptions) {
    this.authClient = new OktaAuth({
      issuer: process.env.OKTA_ISSUER,
    })

    this.history = options.history

    const appOptions = this.options.appOptions
    if (appOptions) {
      this.appAuthOptions.clientId = appOptions.clientId || process.env.OKTA_CLIENT_ID
      this.appAuthOptions.redirectUri =
        appOptions.redirectUri || `${window.location.origin}/implicit/callback`
      this.appAuthOptions.scopes = appOptions.scopes || ['email', 'openid', 'portal']
    }

    this.init()
  }

  async skip() {
    const transaction = await this.transaction.skip()

    this.handleTransaction(transaction)
  }

  @action
  private setLoading(value: boolean) {
    this.loading = value
  }

  private async init() {
    this.setLoading(true)
    if (this.options.restoreSession) {
      const redirecting = await this.restoreSession()
      if (redirecting) {
        return
      }
    }
    if (this.options.restoreTransaction) {
      await this.restoreTransaction()
    }
    this.setLoading(false)
  }

  private async restoreSession() {
    const sessionExists = await this.authClient.session.exists()

    if (sessionExists) {
      try {
        await this.authClient.token.getWithRedirect({
          ...this.appAuthOptions,
        })
        return true
      } catch (err) {
        console.error(err)
      }
    }

    return false
  }

  private restoreTransaction() {
    return new Promise<void>(resolve => {
      const exists = this.authClient.tx.exists()
      if (exists) {
        this.authClient.tx
          .resume()
          .then((transaction: any) => {
            this.transaction = transaction
            this.redirectToTransactionRoute()
            resolve(transaction)
          })
          .catch((err: any) => {
            console.error(err)
            this.redirectToLogin()
            resolve()
          })
      } else {
        this.redirectToLogin()
        resolve()
      }
    })
  }

  async handleTransaction(transaction: any) {
    this.transaction = transaction
    await this.redirectToTransactionRoute()
  }

  // PASSWORD_RESET, RECOVERY and RECOVERY_CHALLENGE are handled in Password and Account lock stores
  async redirectToTransactionRoute() {
    const transaction = this.transaction
    switch (transaction.status) {
      case 'REJECTED':
        this.history.replace(LOGIN_ROUTE)
      case 'SUCCESS':
        await this.authClient.token.getWithRedirect({
          sessionToken: transaction.sessionToken,
          ...this.appAuthOptions,
        })
        break
      default:
        throw `We cannot handle the ${transaction.status} status`
    }
  }

  async prevTransaction() {
    const prevTransaction = await this.transaction.prev()
    this.transaction = prevTransaction
  }

  private redirectToLogin() {
    this.history.replace('/login')
  }
}
