interface ILocalStorage {
  get: (key: string) => string
  getJson: (key: string) => object
  set: (key: string, value: string) => void
  setJson: (key: string, value: object) => void
  remove: (key: string) => void
  clearAll: () => void
}

export class LocalStorage implements ILocalStorage {
  get(key: string) {
    return window.localStorage.getItem(key)
  }
  getJson(key: string) {
    return JSON.parse(this.get(key))
  }
  set(key: string, value: string) {
    window.localStorage.setItem(key, value)
  }
  setJson(key: string, value: object) {
    this.set(key, JSON.stringify(value))
  }
  remove(key: string) {
    window.localStorage.removeItem(key)
  }
  clearAll() {
    window.localStorage.clear()
  }
}
