import * as React from 'react'
import style from './manage-teams.css'
import { Close } from 'components/icons/index'
import { AccountStore } from '../account-store'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import { Loader } from 'components/loader'
import { SetTeam } from './add-edit-team'
import { Card, CardHeader, IconButton, Icons } from 'plume-ui'
import classNames from 'classnames'
import { Button } from 'components/button'
import alertStyle from 'components/alert/alert.css'
import { AddUserReturnValue } from 'interfaces/utils/add-user'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { AddTeam } from './add-team'
import { ConfirmDeleteReturnValue } from '../account'
import { ConfirmDelete } from 'modules/dashboard/user/confirm-delete/confirm-delete'
import { Team } from 'interfaces/api/portal/company-api'
import { Tooltip } from 'components/tooltip'

interface SupportContactsProps {
  onClose: () => Promise<void>
  accountStore: AccountStore
}

const launchAddTeam = async (actionModalStore: ModalLayerStore, accountStore: AccountStore) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      accountStore.clearFormState()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <div key={actionModalStore.getKey()} className={alertStyle.darkenBackground}>
        <AddTeam
          accountStore={accountStore}
          onClose={() => onClose(AddUserReturnValue.CloseButton)}
        />
      </div>,
    )
  })
}

export const displayConfirmDeleteTeam = async (
  actionModalStore: ModalLayerStore,
  accountStore: AccountStore,
  teamName: string,
  teamGroupId: string,
  t: (...args: any) => string,
) => {
  return new Promise<ConfirmDeleteReturnValue>(res => {
    const onClose = async (closeButton: ConfirmDeleteReturnValue) => {
      actionModalStore.removeModal()
      return res(closeButton)
    }

    const onConfirmDelete = async () => {
      onClose(ConfirmDeleteReturnValue.CloseButton)
      accountStore.deleteTeam(teamGroupId, teamName)
    }

    actionModalStore.addModal(
      <div key={actionModalStore.getKey()} className={alertStyle.darkenBackground}>
        {accountStore.isLoadingModal ? (
          <Loader />
        ) : (
          <ConfirmDelete
            confirmQuestion={'teams.confirmDelete'}
            confirmText={t('teams.confirmDeleteTeam', { teamName })}
            onClose={() => onClose(ConfirmDeleteReturnValue.CloseButton)}
            onConfirm={() => onConfirmDelete()}
          />
        )}
      </div>,
    )
  })
}

export const ManageTeams = observer((props: SupportContactsProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const rootScrollRef = React.useRef<HTMLDivElement>(null)

  const { teams, isLoadingModal, manageTeamCardsOpenArray, isWaitingTooLong } = props.accountStore

  const loadTeamApps = (team: Team) => {
    props.accountStore.getTeamApps(team.groupId, team.name)
  }

  const { onClose } = props

  return (
    <div
      className={classNames(style.root, isLoadingModal && style.disableScrolling)}
      ref={rootScrollRef}
    >
      <div className={style.subTitle}>{t('teams.manageTeams')}</div>

      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>
      {teams === null ? (
        <Loader additionalStyle={{ zIndex: 1000 }} />
      ) : (
        teams.map((team, index) => {
          return (
            <Card
              key={team.name}
              classes={current => ({
                ...current,
                root: classNames(current.root, style.cardMargins),
                content: classNames(current.content, 'CollapseCard__content', {
                  'CollapseCard__content--open': manageTeamCardsOpenArray[index],
                }),
              })}
              header={
                <CardHeader
                  classes={current => ({
                    ...current,
                    header: classNames(current.header, style.cardHeaderHeight),
                  })}
                  title={
                    <div className={style.cardHeaderTitleWrapper}>
                      {team.isLocked && (
                        <Tooltip
                          placement="top"
                          overlay={<span>{t('teams.samlTeam')}</span>}
                          align={{
                            offset: [0, -5],
                          }}
                        >
                          <Icons.KeySolidIcon className={style.lockedIcon} />
                        </Tooltip>
                      )}
                      <div>
                        {team.name}
                        <div className={classNames(style.teamDescription)}>{team.description}</div>
                      </div>
                    </div>
                  }
                  open={manageTeamCardsOpenArray[index]}
                  iconRight={<Icons.EditIcon />}
                  isOpenCallback={() => loadTeamApps(team)}
                  actions={
                    !team.isLocked
                      ? [
                          <IconButton
                            key={'delete'}
                            small
                            onClick={e => {
                              e.stopPropagation()
                              displayConfirmDeleteTeam(
                                appStore.actionModalStore,
                                props.accountStore,
                                team.name,
                                team.groupId,
                                t,
                              )
                            }}
                          >
                            <Icons.TrashIcon color="#fa1478" />
                          </IconButton>,
                        ]
                      : []
                  }
                />
              }
            >
              <div className={'CollapseCard__content__inner'}>
                <SetTeam action="edit" team={team} accountStore={props.accountStore} />
              </div>
            </Card>
          )
        })
      )}
      {isWaitingTooLong && (
        <div className={style.longLoadingMessage}>{t('teams.waitingTooLong')}</div>
      )}
      {isLoadingModal && (
        <Loader
          additionalStyle={
            rootScrollRef?.current
              ? { top: rootScrollRef.current.scrollTop, zIndex: 1000 }
              : { zIndex: 1000 }
          }
        />
      )}
      <div className={style.btnDiv}>
        <Button
          className={style.sendInviteBtn}
          onClick={() => launchAddTeam(appStore.actionModalStore, props.accountStore)}
        >
          {t('teams.addTeam')}
        </Button>
      </div>
    </div>
  )
})
