import * as React from 'react'
import style from './secondary-action.css'

interface ISecondaryActionProps {
  children: React.ReactNode
}

export const SecondaryAction = ({ children }: ISecondaryActionProps) => (
  <p className={style.description}>{children}</p>
)
