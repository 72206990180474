import * as React from 'react'
import style from './alert.css'
import { Button } from 'components/button'
import { Close } from 'components/icons'
import { IAlertProps } from 'interfaces/utils/alert'
import { useTranslation } from 'react-i18next'

export const Alert = (props: IAlertProps) => {
  const { t } = useTranslation()
  const { title, message, skipI18n, buttonText, isCloseShowing, onClose, onButtonClick } = props
  return (
    <div className={style.root}>
      <div className={style.closeButton}>
        {isCloseShowing ? <Close onClick={onClose} /> : '\u00A0'}
      </div>
      <div className={style.title}>{t(title)}</div>
      <div className={style.content}>
        {typeof message === 'string' && !skipI18n ? t(message) : message}
      </div>
      <div className={style.buttonWrapper}>
        <Button className={style.actionButton} autoFocus fullWidth onClick={onButtonClick}>
          {t(buttonText)}
        </Button>
      </div>
    </div>
  )
}
