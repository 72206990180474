import { AddPasswordStore } from './add-password-store'
import * as React from 'react'
import { Input } from 'components/input/input'
import { Button } from 'components/button/index'
import { inject, observer } from 'mobx-react'
import { parse } from 'query-string'
import { RouterStore } from 'mobx-react-router'
import { RouteComponentProps } from 'react-router-dom'
import { AppStore } from 'stores/app-store'
import { Loader } from 'components/loader'
import { Checkbox } from 'components/checkbox'
import style from './add-password.css'
import classNames from 'classnames'
import { Footer } from 'components/footer'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  PORTAL_PRIVACY_POLICY_ROUTE,
  PORTAL_TERMS_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
} from 'constants/routes'

const Header = () => <header className={style.header} />

interface AddPasswordState {
  confirmPassFieldFocused: boolean
}

interface AddPasswordProps extends RouteComponentProps, WithTranslation {
  routerStore?: RouterStore
  appStore?: AppStore
}

interface QueryOptions {
  activationToken?: string
  login?: string
  firstName?: string
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  appStore,
  routerStore: appStore.routerStore,
}))
@observer
class AddPasswordComponent extends React.Component<AddPasswordProps, AddPasswordState> {
  private options: QueryOptions
  private store = new AddPasswordStore(
    parse(this.props.location.search),
    this.props.appStore.getAuthTransactionStore(false, true, parse(this.props.location.search)),
    this.props.routerStore,
  )

  constructor(props: AddPasswordProps) {
    super(props)
    this.state = {
      confirmPassFieldFocused: false,
    }
  }

  componentDidMount() {
    const options = parse(this.props.location.search, { decode: false }) as QueryOptions
    if (!options.activationToken || !options.login) {
      this.props.routerStore.replace('/login')
    }
    const email = options.login
    this.store.initSetPassword(encodeURIComponent(email))
    this.setQueryParams(options)
  }

  private handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    this.store.validateAndSubmit()
  }

  private setQueryParams(options: QueryOptions) {
    this.options = options
  }

  handlePasswordConfirmOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      store: { passwordConfirm, password, formState },
    } = this

    passwordConfirm.onChange(e.target.value)
    if (password.value === passwordConfirm.value) {
      formState.validate()
      this.setState({ confirmPassFieldFocused: false })
    }
  }

  handlePasswordConfirmOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const {
      store: { formState },
    } = this
    formState.validate()
    this.setState({ confirmPassFieldFocused: !!e.target.value })
  }

  render() {
    const { t } = this.props
    const { confirmPassFieldFocused } = this.state
    const {
      store: {
        password,
        passwordConfirm,
        isLoading,
        error,
        isLoadingActiveCheck,
        enterpriseTerms,
        portalPrivacyPolicy,
        portalTerms,
        externalOriginAppId,
      },
    } = this

    if (isLoadingActiveCheck || externalOriginAppId === null) {
      return <Loader />
    }

    const firstName = this.options.firstName ? ` ${this.options.firstName}` : ''

    return (
      <div className={style.root}>
        <Header />
        <section className={style.content}>
          <div className={style.subTitle}>{t('general.plumePartner')}</div>
          <div className={classNames(style.title, style.titleWithDescription)}>
            {t('auth.setPassword')}
          </div>
          <div className={style.contentWrapper}>
            <div className={style.description}>
              {t('auth.setPasswordDesc', { user: firstName })}
            </div>
            <form onSubmit={this.handleFormSubmit}>
              <Input
                autoFocus
                label={t('common.enterPassword')}
                type="password"
                hasBeenValidated={password.hasBeenValidated}
                value={password.value}
                error={!!password.error}
                onChange={e => password.onChange(e.target.value)}
              />
              <Input
                label={t('common.reenterPassword')}
                type="password"
                hasBeenValidated={confirmPassFieldFocused && passwordConfirm.hasBeenValidated}
                value={passwordConfirm.value}
                error={
                  (confirmPassFieldFocused && (password.error || passwordConfirm.error)) || error
                }
                onChange={this.handlePasswordConfirmOnChange}
                onBlur={this.handlePasswordConfirmOnBlur}
              />

              <div className={style.tc}>
                <Checkbox
                  theme="default"
                  name="portalPrivacyPolicy"
                  checked={portalPrivacyPolicy.value}
                  onChange={e => portalPrivacyPolicy.onChange(e.target.checked)}
                  error={portalPrivacyPolicy.error}
                />
                <div className={style.tcText}>
                  {t('auth.tcAgreement')}{' '}
                  <a target="_blank" href={PORTAL_PRIVACY_POLICY_ROUTE}>
                    {t('auth.portalPrivacyPolicy')}
                  </a>
                  .
                </div>
              </div>

              <div className={style.tc}>
                <Checkbox
                  theme="default"
                  name="portalTerms"
                  checked={portalTerms.value}
                  onChange={e => portalTerms.onChange(e.target.checked)}
                  error={portalTerms.error}
                />
                <div className={style.tcText}>
                  {t('auth.tcAgreement')}{' '}
                  <a target="_blank" href={PORTAL_TERMS_ROUTE}>
                    {t('auth.portalTerms')}
                  </a>
                  .
                </div>
              </div>

              <div className={style.tc}>
                <Checkbox
                  theme="default"
                  name="enterpriseTerms"
                  checked={enterpriseTerms.value}
                  onChange={e => enterpriseTerms.onChange(e.target.checked)}
                  error={enterpriseTerms.error}
                />
                <div className={style.tcText}>
                  {t('auth.tcAgreement')}{' '}
                  <a target="_blank" href={TERMS_AND_CONDITIONS_ROUTE}>
                    {t('auth.enterpriseTerms')}
                  </a>
                  .
                </div>
              </div>
              <Button type="submit" fullWidth disabled={isLoading}>
                {t('auth.login')}
              </Button>
            </form>
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}

export const AddPassword = withTranslation()(AddPasswordComponent)
