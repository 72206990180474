import * as React from 'react'
import { ProgressBar } from './progress-bar'
import style from './progress-bar.css'

interface ProgressBarLoaderProps {
  width: number
  title?: string
}

export const ProgressBarLoader = ({ width, title }: ProgressBarLoaderProps) => (
  <div className={style.progressLoaderOverlay}>
    <div className={style.subTitle}>{title}</div>
    <ProgressBar width={width}></ProgressBar>
  </div>
)
