import * as React from 'react'
import { Close } from 'components/icons/index'
import { Button } from 'components/button'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import style from './nodes-claimable-by-locations-with-shared-parent.css'
import { AccountStore } from 'modules/dashboard/account/account-store'
import { mixpanelActions } from 'utils/mixpanelActions'
import classNames from 'classnames'
import { TextToggler } from 'plume-ui'

interface NCLSPProps {
  onClose: () => Promise<void>
  accountStore: AccountStore
}

export const NodesClaimableByLocationsWithSharedParent = observer((props: NCLSPProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const company = appStore?.authStore?.currentUser?.company
  const { onClose } = props

  const { serverError, isLoadingModal, nodesClaimableByLocationsWithSharedParentInfo } =
    props.accountStore

  const [nclspEnabled, setNclspEnabled] = React.useState(
    !!nodesClaimableByLocationsWithSharedParentInfo?.claimableByLocationsWithSharedParent,
  )

  React.useEffect(() => {
    mixpanelActions.track('Nodes Claimable By Locations With Shared Parent - Modal Open', {
      'Partner Id': company?.partnerId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const oc = style.override
  const submitDisabled = isLoadingModal

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (submitDisabled) {
      return
    }
    props.accountStore.submitNodesClaimableByLocationsWithSharedParent({
      claimableByLocationsWithSharedParent: nclspEnabled,
    })
  }

  return (
    <div className={style.root}>
      <div className={style.subTitle}>{t('account.nodesClaimableByLocationsWithSharedParent')}</div>
      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>

      <form onSubmit={handleFormSubmit}>
        <div className={style.labelText}>{t('nodesClaimableByParent.description')}</div>
        <TextToggler
          classes={current => ({
            ...current,
            root: classNames(current.root, oc, style.toggleContainer),
            toggleContainer: classNames(current.toggleContainer, oc, style.toggleAdjustment),
          })}
          title={t('nodesClaimableByParent.shouldBeClaimable')}
          selected={nclspEnabled}
          onToggle={() => setNclspEnabled(!nclspEnabled)}
        />

        {serverError && <div className={style.serverError}>{serverError}</div>}
        <div className={style.btnDiv}>
          {isLoadingModal && <Loader />}
          <Button className={style.sendInviteBtn} type="submit" disabled={submitDisabled}>
            {t('btn.save')}
          </Button>
        </div>
      </form>
    </div>
  )
})
