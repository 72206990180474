import { SecondaryAction } from '../common/secondary-action'
import { PasswordDescription } from './password-description'
import * as React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { LoginLayout } from '../common/login-layout'
import { Input } from 'components/input/input'
import { Button } from 'components/button/index'
import { PasswordResetCompleteStore } from './password-reset-complete-store'
import { inject, observer } from 'mobx-react'
import { RouterStore } from 'mobx-react-router'
import { LOGIN_ROUTE } from 'constants/routes'
import { parse } from 'query-string'
import { withTranslation, WithTranslation } from 'react-i18next'
import { AppStore } from 'stores/app-store'

interface PasswordCompleteProps extends RouteComponentProps, WithTranslation {
  token?: string
  routerStore?: RouterStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  routerStore: appStore.routerStore,
}))
@observer
export class PasswordResetCompleteComponent extends React.Component<PasswordCompleteProps> {
  private store = new PasswordResetCompleteStore(
    this.props.routerStore,
    parse(this.props.location.search),
  )

  componentDidMount() {
    const options = parse(this.props.location.search)
    if (!options.recoveryToken) {
      this.props.routerStore.replace('/login')
    }
  }

  private handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    this.store.validateAndSubmit()
  }

  render() {
    const {
      store: { password, passwordConfirm, isLoading, error, isInvalidToken },
      props: { t },
    } = this

    return (
      <LoginLayout title={t('auth.passwordReset')} description={t('auth.passwordResetMs')}>
        <form onSubmit={this.handleFormSubmit}>
          <Input
            autoFocus
            label={t('common.newPassword')}
            type="password"
            hasBeenValidated={password.hasBeenValidated}
            value={password.value}
            error={!!password.error}
            onChange={e => password.onChange(e.target.value)}
            disabled={isInvalidToken}
          />
          <Input
            label={t('common.confirmNewPassword')}
            type="password"
            hasBeenValidated={passwordConfirm.hasBeenValidated}
            value={passwordConfirm.value}
            error={password.error || passwordConfirm.error || error}
            onChange={e => passwordConfirm.onChange(e.target.value)}
            disabled={isInvalidToken}
          />
          {!error && <PasswordDescription />}
          <Button type="submit" fullWidth disabled={isLoading || isInvalidToken}>
            {t('btn.saveNewPassword')}
          </Button>
        </form>
        <SecondaryAction>
          <Link to={LOGIN_ROUTE}>{t('auth.cancelResetPassowrd')}</Link>
        </SecondaryAction>
      </LoginLayout>
    )
  }
}

export const PasswordResetComplete = withTranslation()(PasswordResetCompleteComponent)
