import classNames from 'classnames'
import * as React from 'react'
import style from './button.css'

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean
}

export const Button = (props: IButtonProps) => {
  const { className, fullWidth, ...otherProps } = props
  const computedClassName = classNames(style.root, fullWidth && style.fullWidth, className)

  return <button className={computedClassName} {...otherProps} />
}
