import * as React from 'react'
import style from '../app-customization.css'
import { LeftArrow, RightArrow } from 'components/icons/index'
import { Button } from 'components/button'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Document, Page } from 'react-pdf'
import classNames from 'classnames'
import { AccountStore } from '../../account-store'
import hpIosHomeScreen from '../../../../../assets/png/partner_logo_ios_empty.png'
import hpAndroidHomeScreen from '../../../../../assets/png/partner_logo_android_empty.png'
import wpHomeScreen from '../../../../../assets/png/workpass_home_screen.png'
import hpV2AndroidHsMain from '../../../../../assets/png/homepass_v2_android_home_screen_main.png'
import hpV2AndroidHsScrolled from '../../../../../assets/png/homepass_v2_android_home_screen_scrolled.png'
import hpV2IosHsMain from '../../../../../assets/png/homepass_v2_ios_home_screen_main.png'
import hpV2IosHsScrolled from '../../../../../assets/png/homepass_v2_ios_home_screen_scrolled.png'
import { PlumeProduct } from 'enums/plume-product.enum'
import SVG from 'react-inlinesvg'

interface LogoPreviewProps {
  accountStore: AccountStore
  fromAccMgrLogoUpload?: boolean
}

export const LogoPreview = observer((props: LogoPreviewProps) => {
  const handlePrevStep = () => {
    props.accountStore.setPrevStep()
  }

  const handleNextStep = () => {
    props.accountStore.setNextStep()
  }

  const handleOSPreviewChange = (os: string) => {
    props.accountStore.setOsPreview(os)
  }

  const {
    accountStore: {
      isLoadingModal,
      svgFile,
      pdfFile,
      isPdfFileValid,
      svgUrl,
      pdfUrl,
      svgFileError,
      pdfFileError,
      isSvgEdited,
      isPdfEdited,
      currentStep,
      previewOS,
      isAppCustomizationSubmitDisabled,
      isImageUploadRequired,
      selectedProduct,
      svgBase64DataUrl,
    },
  } = props
  const { t } = useTranslation()

  const isHomePassV2Enabled = process.env.HOMEPASS_V2_ENABLED === 'true'

  const computedClassName =
    (svgFile && classNames(style.displayBlock, style.fileLogoPreview)) || style.displayNone

  const renderPdf = (pdfFile && isPdfFileValid) || (pdfUrl && !isPdfEdited)
  const renderSvgImage = !svgFile && svgUrl && !isSvgEdited

  const computedClassNamePdf =
    (renderPdf && classNames(style.displayBlock, style.fileLogoPreview)) || style.displayNone

  const isMCLogoUploadDisabled = props.fromAccMgrLogoUpload
    ? isImageUploadRequired || !svgFile || !pdfFile || !!svgFileError || !!pdfFileError
    : isAppCustomizationSubmitDisabled

  const isHomePassSelected = selectedProduct === PlumeProduct.HomePass
  const previewCssVars = isHomePassSelected
    ? isHomePassV2Enabled
      ? previewOS === 'ios'
        ? style.iosHsHomePassV2
        : style.androidHsHomePassV2
      : previewOS === 'ios'
      ? style.iosPreviewHomePass
      : style.androidPreviewHomePass
    : previewOS === 'ios'
    ? style.iosPreviewWorkPass
    : style.androidPreviewWorkPass

  const iosBg = isHomePassSelected
    ? isHomePassV2Enabled
      ? hpV2IosHsMain
      : hpIosHomeScreen
    : wpHomeScreen
  const androidBg = isHomePassSelected
    ? isHomePassV2Enabled
      ? hpV2AndroidHsMain
      : hpAndroidHomeScreen
    : wpHomeScreen

  return (
    <div
      className={classNames(
        previewCssVars,
        (currentStep !== 1 || isLoadingModal) && style.displayNone,
      )}
    >
      <div className={style.setupCompletedWrapper}>{t('appCustomization.formMsg')}</div>
      <div className={style.previewContent}>
        <div
          className={classNames(style.arrowLeft, currentStep === 1 && style.hidden)}
          onClick={handlePrevStep}
        >
          <LeftArrow />
        </div>
        <div>
          <div className={style.previewHeader}>
            <span
              className={classNames(
                style.previewItem,
                previewOS === 'ios' && style.previewItemActive,
              )}
              onClick={() => handleOSPreviewChange('ios')}
            >
              {t('appCustomization.iOS')}
            </span>
            <span
              className={classNames(
                style.previewItem,
                previewOS === 'android' && style.previewItemActive,
              )}
              onClick={() => handleOSPreviewChange('android')}
            >
              {t('appCustomization.android')}
            </span>
          </div>
          {previewOS === 'ios' && (
            <div className={style.homeScreenshotsContainer}>
              <div
                className={classNames(
                  style.imagePreview,
                  isHomePassSelected && !isHomePassV2Enabled && style.imagePreviewIosHome,
                )}
              >
                <img src={iosBg} alt="iOS Home page" />
                <div className={style.ioslogo}>
                  <Document
                    file={pdfFile || pdfUrl}
                    className={computedClassNamePdf}
                    renderMode="svg"
                  >
                    <Page pageNumber={1} className={style.reactPdfPage} />
                  </Document>
                </div>
              </div>
              {isHomePassV2Enabled && isHomePassSelected && (
                <div className={classNames(style.imagePreview, style.iosHsScrolledHomePassV2)}>
                  <img src={hpV2IosHsScrolled} alt="iOS Home page scrolled" />
                  <div className={style.ioslogo}>
                    <Document
                      file={pdfFile || pdfUrl}
                      className={computedClassNamePdf}
                      renderMode="svg"
                    >
                      <Page pageNumber={1} className={style.reactPdfPage} />
                    </Document>
                  </div>
                </div>
              )}
            </div>
          )}
          {previewOS === 'android' && (
            <div className={style.homeScreenshotsContainer}>
              <div className={style.imagePreview}>
                <img src={androidBg} alt="Android Home page" />
                <div className={style.androidLogo}>
                  <div className={classNames(computedClassName)} />
                  {svgFile && <SVG src={svgBase64DataUrl} title="AndroidLogo" />}
                  {renderSvgImage && <SVG src={svgUrl} title="AndroidLogo" />}
                </div>
              </div>
              {isHomePassV2Enabled && isHomePassSelected && (
                <div className={classNames(style.imagePreview, style.androidHsScrolledHomePassV2)}>
                  <img src={hpV2AndroidHsScrolled} alt="Android Home page scrolled" />
                  <div className={style.androidLogo}>
                    <div className={classNames(computedClassName)} />
                    {svgFile && <SVG src={svgBase64DataUrl} title="AndroidLogo" />}
                    {renderSvgImage && <SVG src={svgUrl} title="AndroidLogo" />}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {!props.fromAccMgrLogoUpload && (
          <div
            onClick={handleNextStep}
            className={classNames(style.arrowRight, currentStep === 3 && style.hidden)}
          >
            <RightArrow />
          </div>
        )}
      </div>

      <div className={style.btnDiv}>
        <Button className={style.sendInviteBtn} type="submit" disabled={isMCLogoUploadDisabled}>
          {props.fromAccMgrLogoUpload
            ? t('appCustomization.uploadLogo', {
                product: t(`common.${selectedProduct}`),
              })
            : t('appCustomization.submitCustomization', {
                product: t(`common.${selectedProduct}`),
              })}
        </Button>
      </div>
    </div>
  )
})
