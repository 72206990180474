import * as React from 'react'
import style from './missing-access-rights.css'
import { AuthStore } from 'stores/auth-store'
import { RouteComponentProps } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { SignOut } from 'components/icons'
import classNames from 'classnames'
import { withTranslation, WithTranslation } from 'react-i18next'
import { parse } from 'query-string'
import { AppStore } from 'stores/app-store'

interface IMissingAccessRightsProps extends RouteComponentProps, WithTranslation {
  authStore?: AuthStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  authStore: appStore.authStore,
}))
@observer
class MissingAccessRightsComponent extends React.Component<IMissingAccessRightsProps> {
  render() {
    const { t } = this.props
    const options = parse(this.props.location.search) as Record<string, any>
    let missingAccessMessage = t('missingAccess.message')
    if (options?.['account-manager'] === 'restricted') {
      missingAccessMessage = t('missingAccess.accountManagerRestrictedMessage')
    }
    if (!!options?.['idp-user-error-message']) {
      missingAccessMessage = options?.['idp-user-error-message']
    }

    return (
      <React.Fragment>
        <div className={style.root}>
          <div className={style.messageText}>{missingAccessMessage}</div>
          <div
            className={classNames(style.signOutButton, style.navItemWrapper)}
            onClick={this.handleLogoutClick}
          >
            <span className={style.icon}>
              <SignOut />
            </span>
            <span className={style.navItemText}>{t('auth.signOut')}</span>
          </div>
        </div>
      </React.Fragment>
    )
  }
  handleLogoutClick = () => {
    this.props.authStore.logout()
  }
}

export const MissingAccessRights = withTranslation()(MissingAccessRightsComponent)
