import { action, observable, when } from 'mobx'
import { AuthStore } from 'stores/auth-store'
import { ICompanyApi } from 'interfaces/api/portal/company-api'

export class SelectPartnerStore {
  @observable
  isLoading = false

  constructor(
    private readonly companyApi: ICompanyApi,
    private readonly authStore: AuthStore,
    partnerId?: string,
  ) {
    when(
      () => !!authStore.idToken && !!authStore.currentUser,
      () => {
        partnerId && this.setCompany(partnerId)
      },
    )
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  async setCompany(partnerId: string) {
    this.setIsLoading(true)

    const asyncData = await Promise.all([
      this.companyApi.getCompanyByPartnerId(partnerId),
      this.companyApi.getAdditionalPartnerInfo(partnerId),
    ])

    const company = asyncData[0]
    const additionalPartnerInfo = asyncData[1]
    this.authStore.setSelectedEmployeeCompanyData(company, additionalPartnerInfo)

    this.setIsLoading(false)
  }
}
