// Needs to be changed to sf error messages
export const errorsMap: any = {
  'Authentication failed': 'authenticationFailed',
  'Error creating contact in hubspot.': 'errorCreatingContactInHubspot',
  'Error fetching contact.': 'errorFetchingContact',
  'Error fetching contacts batch from hubspot.': 'errorFetchingContactsBatchFromHubspot',
  'Error while updating contact properties on Hubspot.':
    'errorWhileUpdatingContactPropertiesOnHubspot',
  'Error while adding contact to company on Hubspot.': 'errorWhileAddingContactToCompanyOnHubspot',
  'Error while deleting contact on Hubspot.': 'errorWhileDeletingContactOnHubspot',
  'Error fetching company by id.': 'errorFetchingCompanyById',
  'Error fetching companies.': 'errorFetchingCompanies',
  'Error fetching company owner.': 'errorFetchingCompanyOwner',
  'Error uploading NDA to hubspot.': 'errorUploadingNda',
  'Error while updating properties on Hubspot.': 'errorUpdatingPropsOnHubspot',
  'Error creating zendesk organization.': 'errorCreatingZendeskOrg',
  'Error while sending email.': 'errorSendingEmail',
  'Error while sending NDA signed email.': 'errorSendingNDAEmail',
  'Error while sending support request email.': 'errorSendingSupportRequestEmail',
  'Error fetching remote config template.': 'errorFetchingRemoteConfig',
  'Error publishing remote config template.': 'errorPublishingRemoteConfig',
  'Error creating envelope.': 'errorCreatingEnvelope',
  'Error creating view.': 'errorCreatingView',
  'Error fetching document from envelope.': 'ErrorFetchingDocFromEnvelope',
  'Error fetching dashboard data.': 'errorFetchingDashboardData',
  'User with this email address already exists in the current organization.':
    'userWitthThisEmailAlreadyExistsInCurrentOrg',
  'User already exists, associated with given company.': 'userExistsAssociatedWithGivenCompany',
  'This user already exists in Okta with ACTIVE status and is not assigned to any company. Please contact Plume Support for further details.':
    'userExistsWithActiveStatus',
  'Something went wrong.': 'somethingWentWrong',
  'Update of credentials failed': 'errorUpdateOfCredentialsFailed',
  'The password does not meet the complexity requirements of the current password policy.':
    'errorResetPasswordComplexity',
  'You have accessed an account recovery link that has expired or been previously used.':
    'errorRecoveryLink',
  'Contact already exists': 'errorContactExists',
  'Error updating contact in Salesforce.': 'errorUpdatingPropsOnHubspot',
  'User already exists.': 'errorUserAlreadyExists',
  'An SMS message was recently sent. Please wait 30 seconds before trying again.':
    'errorWait30Seconds',
  'Email not allowed for distribution list.': 'emailNotAllowedDistributionList',
}
