import {
  IGetInventoryNodeInfoResponse,
  INodeInfo,
  IPortalInventoryApi,
  IUpdateJobPayload,
  ManageInventoryJob,
} from 'interfaces/api/portal/i.inventory-api'
import { PortalBaseApi } from './portal-base-api'

export class PortalInventoryApi extends PortalBaseApi implements IPortalInventoryApi {
  public async addReportJob(
    partnerId: string,
    payload: {
      name: string
      nodeIds: string[]
    },
  ) {
    return this.post<void>(`/inventory/${partnerId}/report-job`, payload)
  }

  public async addUpdateJob(partnerId: string, payload: IUpdateJobPayload) {
    return this.post<void>(`/inventory/${partnerId}/update-job`, payload)
  }

  public async addImportJob(partnerId: string, payload: IUpdateJobPayload) {
    return this.post<void>(`/inventory/${partnerId}/import-job`, payload)
  }

  public async listInventoryJobs(partnerId: string) {
    return this.get<ManageInventoryJob[]>(`/inventory/${partnerId}/list-jobs`)
  }

  public async getJobNodes(partnerId: string, jobName: string) {
    return this.get<INodeInfo[]>(`/inventory/${partnerId}/job/${jobName}/nodes`)
  }

  public async getInventoryNodeInfo(nodeId: string) {
    return this.get<IGetInventoryNodeInfoResponse>(`/inventory/nodes/${nodeId}`)
  }
}
