import { action, observable, toJS } from 'mobx'
import { AppStore } from 'stores/app-store'
import { ExportStore, HeaderColumn } from 'modules/export/export-store'
import {
  IReportJobPayload,
  IUpdateJobPayload,
  ManageInventoryJob,
} from 'interfaces/api/portal/i.inventory-api'
import { notify } from 'plume-ui'
import i18next from 'i18next'

export class ManageInventoryStore {
  constructor(private readonly appStore: AppStore) {}

  @observable
  isLoading = false

  @observable
  serverError = ''

  @observable
  jobs: ManageInventoryJob[] = null

  @observable
  subordinatePartnerIds: string[] = null

  @action
  setSubordinatePartnerIds(partnerIds: string[]) {
    this.subordinatePartnerIds = partnerIds
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  @action
  setJobs(jobs: ManageInventoryJob[]) {
    this.jobs = jobs
  }

  @action
  exportCSV(headers: HeaderColumn[], data: any, fileName: string) {
    const exportStore = new ExportStore()

    exportStore.exportCSVFile(headers, data, fileName)
  }

  @action
  async getSubordinatePartnerIds() {
    const { company } = this.appStore.authStore.currentUser
    const subordinatePartnerIds = await this.appStore.companyApi.getSubordinatePartnersOnAllClouds(
      company.partnerId,
    )
    this.setSubordinatePartnerIds(subordinatePartnerIds)
  }

  @action
  async listJobs() {
    this.setIsLoading(true)
    const { company } = this.appStore.authStore.currentUser
    const allJobs = await this.appStore.portalInventoryApi.listInventoryJobs(company.partnerId)
    this.setJobs(allJobs)
    this.setIsLoading(false)
  }

  @action
  async initManageInventory() {
    this.setIsLoading(true)
    await this.getSubordinatePartnerIds()
    await this.listJobs()
    this.setIsLoading(false)
  }

  @action
  async getJobNodes(jobName: string) {
    try {
      this.setIsLoading(true)
      const { company } = this.appStore.authStore.currentUser
      const nodes = await this.appStore.portalInventoryApi.getJobNodes(company.partnerId, jobName)
      const jobsExtended = toJS(this.jobs)
      const jobIndex = jobsExtended.findIndex(j => j.name === jobName)
      jobsExtended[jobIndex].nodes = nodes
      this.setJobs(jobsExtended)
    } catch (e) {
      this.handleServerError(e)
    }
    this.setIsLoading(false)
  }

  @action
  handleServerError(error: string) {
    this.serverError = error
  }

  @action
  clearServerError() {
    this.serverError = ''
  }

  @action.bound
  private removeModal() {
    this.appStore.actionModalStore.removeModal()
    this.clearServerError()
    this.setIsLoading(false)
  }

  @action
  async addReportJob(partnerId: string, payload: IReportJobPayload) {
    this.setIsLoading(true)
    this.clearServerError()
    try {
      await this.appStore.portalInventoryApi.addReportJob(partnerId, payload)
      await this.listJobs()
      this.removeModal()
      this.setIsLoading(false)
    } catch (e) {
      this.handleServerError(e)
      notify({
        title: i18next.t('errors.somethingWentWrong'),
        body: e,
        type: 'error',
      })
      this.setIsLoading(false)
    }
  }

  @action
  async addUpdateJob(partnerId: string, payload: IUpdateJobPayload) {
    this.setIsLoading(true)
    this.clearServerError()
    try {
      await this.appStore.portalInventoryApi.addUpdateJob(partnerId, payload)
      await this.listJobs()
      this.removeModal()
      this.setIsLoading(false)
    } catch (e) {
      this.handleServerError(e)
      notify({
        title: i18next.t('errors.somethingWentWrong'),
        body: e,
        type: 'error',
      })
      this.setIsLoading(false)
    }
  }

  @action
  async addImportJob(partnerId: string, payload: IUpdateJobPayload) {
    this.setIsLoading(true)
    this.clearServerError()
    try {
      await this.appStore.portalInventoryApi.addImportJob(partnerId, payload)
      await this.listJobs()
      this.removeModal()
      this.setIsLoading(false)
    } catch (e) {
      this.handleServerError(e)
      notify({
        title: i18next.t('errors.somethingWentWrong'),
        body: e,
        type: 'error',
      })
      this.setIsLoading(false)
    }
  }
}
