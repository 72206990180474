import * as React from 'react'
import { SignOut } from 'components/icons'
import { navItems } from 'constants/dashboard/navigation'
import style from './navigation.css'
import { useTranslation } from 'react-i18next'
import { Icons, Menu } from 'plume-ui'
import { CUSTOMER_SUCCESS_JIRA } from 'constants/applications/urls'
import { mixpanelActions } from 'utils/mixpanelActions'
import { MenuItems } from 'plume-ui/dist/components/Menu/Menu'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useStores } from 'utils/hooks/useStores'
import { MANAGE_INVENTORY_ROUTE, MANAGE_PARTNERS_ROUTE } from 'constants/routes'
import { canAccess } from 'modules/auth/auth-action-permission'

interface IDashboardNavProps {
  handleLogoutClick: () => void
  selectOtherPartnerCompany?: () => void
  featureAccessRights: string[]
  hasMoreCompanies: boolean
  hasSubordinateCompanies?: boolean
  errorMode?: boolean
}

export const Navigation = observer((props: IDashboardNavProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const history = useHistory()
  const currentUser = appStore?.authStore?.currentUser
  const uiStore = appStore.uiStore

  const {
    handleLogoutClick,
    selectOtherPartnerCompany,
    hasMoreCompanies,
    errorMode,
    hasSubordinateCompanies,
  } = props

  const accessibleNavItems = navItems.filter(
    nI =>
      nI.accessRightAlias === 'KNOWLEDGE_CENTER' ||
      props.featureAccessRights.includes(nI.accessRightAlias),
  )

  const company = currentUser?.company

  const handleRequestMeetingClick = () => {
    const partnerId = currentUser?.company?.partnerId
    mixpanelActions.track('Navigation - Request Meeting', {
      'Partner Id': partnerId,
    })

    window.open(CUSTOMER_SUCCESS_JIRA, '_blank')
  }

  const getPath = (navItem: { accessRightAlias?: string; path: string }) => {
    if (navItem.accessRightAlias === 'USERS') {
      return `/${company.partnerId}${navItem.path}`
    }
    return navItem.path
  }

  const redirectTo = (path: string) => {
    history.push(path)
  }

  const navMenuItems: MenuItems = accessibleNavItems.map(ni => {
    const path = getPath(ni)
    const isSelected = path === window.location.pathname
    return {
      name: t(ni.text),
      isSelected,
      iconLeft: <ni.icon width="24px" />,
      iconLeftSelected: <ni.iconSelected width="24px" />,
      onItemClick: () => redirectTo(path),
    }
  })

  if (hasMoreCompanies || hasSubordinateCompanies) {
    navMenuItems.push({
      name: t('nav.companySelection'),
      isSelected: false,
      onItemClick: selectOtherPartnerCompany,
      iconLeft: <Icons.CompanyIcon width="24px" />,
    })
  }

  if (canAccess('viewInventory', currentUser)) {
    navMenuItems.push({
      name: t('nav.manageInventory'),
      isSelected: window.location.pathname === MANAGE_INVENTORY_ROUTE,
      iconLeft: <Icons.HaystackIcon width="24px" />,
      iconLeftSelected: <Icons.PodHaystackFillIcon width="24px" />,
      onItemClick: () => redirectTo(MANAGE_INVENTORY_ROUTE),
    })
  }

  const menuBottomItems: MenuItems = []

  if (
    canAccess('viewManageTrials', currentUser) &&
    currentUser.companies[0].allowTrialManagement &&
    !!currentUser.companies[0]?.subordinateCompanies?.length
  ) {
    menuBottomItems.push({
      name: t('manageTrials.title'),
      isSelected: window.location.pathname === MANAGE_PARTNERS_ROUTE,
      iconLeftSelected: <Icons.CompanyFillIcon width="24px" />,
      onItemClick: () => {
        history.replace(MANAGE_PARTNERS_ROUTE)
      },
      iconLeft: <Icons.CompanyIcon width="24px" />,
    })
  }

  if (currentUser && Object.keys(currentUser).length > 0) {
    menuBottomItems.push(
      {
        name: t('nav.requestMeeting'),
        isSelected: false,
        onItemClick: handleRequestMeetingClick,
        iconLeft: <Icons.CommentsIcon width="24px" />,
      },
      {
        name: t('auth.signOut'),
        isSelected: false,
        onItemClick: handleLogoutClick,
        iconLeft: <SignOut />,
      },
    )
  }

  const grouped = [
    {
      items: navMenuItems,
      fullName: '',
      shortName: '',
    },
    {
      items: menuBottomItems,
      fullName: '',
      shortName: '',
    },
  ]

  return (
    <>
      {!errorMode && (!uiStore.isMobile || uiStore.menuEnabled) && (
        <section className={style.drawer}>
          <Menu
            groupedItems={grouped}
            preventSwitchingGroupName={false}
            variant={uiStore.isMobile ? 'collapsed' : 'expanded'}
          />
        </section>
      )}
    </>
  )
})
