import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import style from './manage-trial.css'
import { mixpanelActions } from 'utils/mixpanelActions'
import classNames from 'classnames'
import {
  Dropdown,
  DropdownItem,
  Button,
  Table,
  Status,
  Modal,
  ModalHeader,
  ModalBody,
} from 'plume-ui'
import { Heading as TableHeader } from 'plume-ui/dist/components/Table/Table'
import { SalesforceProductStatus } from 'interfaces/api/portal/company-api'
import { howManyDaysLeft } from 'helpers/company-helpers'
import { ManageTrialsStore } from './manage-trials-store'
import { isDateAfterNow } from 'helpers/general-helpers'

interface ManageTrialProps {
  editPartnerId: string
  onClose: () => Promise<void>
  manageTrialsStore: ManageTrialsStore
}

export const ManageTrial = observer((props: ManageTrialProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const { onClose, editPartnerId } = props
  const { allSuites, serverError, isLoading } = props.manageTrialsStore

  const editCompany = appStore?.authStore?.currentUser?.companies?.[0]?.subordinateCompanies?.find(
    sc => sc.partnerId === editPartnerId,
  )
  React.useEffect(() => {
    mixpanelActions.track('Manage partner modal - Open', {
      'Partner Id': editPartnerId,
    })
    document.body.classList.add(style.modifyPortalZIndex)
    return () => document.body.classList.remove(style.modifyPortalZIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [dropdownLabel, setDropdownLabel] = React.useState('')

  const oc = style.override

  const handleExtendTrial = async (partnerId: string, suiteLabel: string) => {
    await props.manageTrialsStore.startOrExtendTrial(partnerId, { suiteLabel })
  }

  const handleStartTrial = async (partnerId: string, suiteLabel: string) => {
    await props.manageTrialsStore.startOrExtendTrial(partnerId, { suiteLabel })
    setDropdownLabel('')
  }

  const tableHeaders: TableHeader[] = [
    {
      fieldName: 'label',
      name: t('common.product'),
    },
    {
      fieldName: 'status',
      name: t('common.status'),
      render: (row: Partial<{ productStatus: SalesforceProductStatus }>) => {
        const productStatus = row?.productStatus
        const isPurchased = productStatus?.isPurchased

        const trialDuration = productStatus?.trialEndDate
          ? howManyDaysLeft(productStatus?.trialEndDate)
          : null
        return (
          <>
            {(isPurchased || trialDuration !== null) && (
              <Status
                color={
                  isPurchased
                    ? 'ok'
                    : isDateAfterNow(productStatus?.trialEndDate)
                    ? 'warning'
                    : 'error'
                }
                label={
                  isPurchased
                    ? t('common.active')
                    : isDateAfterNow(productStatus?.trialEndDate)
                    ? t('manageTrials.expiresIn', {
                        dayOrDays: t('common.day', { count: trialDuration }),
                        numOfDays: trialDuration,
                      })
                    : t('home.trialExpired')
                }
              />
            )}
          </>
        )
      },
    },
    {
      fieldName: 'extend',
      name: t('common.action'),
      render: (row: Partial<{ productStatus: SalesforceProductStatus; label: string }>) => {
        const trialDuration = row?.productStatus?.trialEndDate
          ? howManyDaysLeft(row?.productStatus?.trialEndDate)
          : null
        return !row?.productStatus?.isPurchased && trialDuration > 0 ? (
          <Button
            classes={curr => ({
              ...curr,
              root: classNames(curr.root, oc, style.extendTrialButtonCustomization),
            })}
            styleVariant="tertiary"
            onClick={() => handleExtendTrial(editPartnerId, row.label)}
          >
            {t('manageTrials.extendTrial')}
          </Button>
        ) : undefined
      },
    },
  ]

  const dataRows = allSuites
    .map(s =>
      editCompany.productsInfo?.[s.label]
        ? {
            label: s.label,
            productStatus: editCompany.productsInfo?.[s.label],
          }
        : null,
    )
    .filter(r => !!r)

  const notStartedSuites = allSuites?.filter(s => !editCompany.productsInfo?.[s.label])

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <ModalHeader
        classes={curr => ({
          ...curr,
          textWrapper: classNames(curr.textWrapper, style.headerTextMargin),
        })}
        title={t('manageTrials.manageTrialFor', {
          companyName: `${editCompany?.name} - ${editPartnerId}`,
        })}
      />
      <ModalBody classes={curr => ({ ...curr, root: classNames(curr.root, style.modalBottom) })}>
        <Table
          classes={curr => ({
            ...curr,
            root: classNames(curr.root, oc, style.tableRoot, style.tableMargin),
            dataRow: classNames(curr.dataRow, style.override, style.rowHover),
          })}
          headerRow={tableHeaders}
          dataRows={dataRows as any}
          noResultsMessage={t('manageTrials.noProductsOnCompany')}
        />

        {!!notStartedSuites?.length && (
          <>
            <div className={style.startNewTrial}>{t('manageTrials.startNewTrial')}</div>
            <div className={style.startTrialWrapper}>
              <Dropdown
                openInPortal
                label={dropdownLabel || t('manageTrials.selectProduct')}
                closeOnItemClick
              >
                {notStartedSuites.map(s => (
                  <DropdownItem
                    key={s.label}
                    selected={dropdownLabel === s.label}
                    onClick={() => setDropdownLabel(s.label)}
                  >
                    {s.label}
                  </DropdownItem>
                ))}
              </Dropdown>
              {dropdownLabel && (
                <Button
                  styleVariant="tertiary"
                  disabled={!dropdownLabel}
                  onClick={() => handleStartTrial(editPartnerId, dropdownLabel)}
                >
                  {t('manageTrials.startTrial')}
                </Button>
              )}
            </div>
          </>
        )}

        {serverError && <div className={style.serverError}>{serverError}</div>}
      </ModalBody>
      {isLoading && <Loader />}
    </Modal>
  )
})
