import { action, observable } from 'mobx'
import { IStartExtendTrial, ISuite } from 'interfaces/api/portal/company-api'
import { AppStore } from 'stores/app-store'

export class ManageTrialsStore {
  @observable
  isLoading = false

  @observable
  serverError = ''

  @observable
  allSuites: ISuite[] = null

  constructor(private readonly appStore: AppStore) {}

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  @action
  setAllSuites(allSuites: ISuite[]) {
    this.allSuites = allSuites
  }

  @action
  async listSuites() {
    const allSuites = await this.appStore.companyApi.listSuites()
    this.setAllSuites(allSuites)
  }

  @action
  handleServerError(error: string) {
    this.serverError = error
  }

  @action
  clearServerError() {
    this.serverError = ''
  }

  @action
  async startOrExtendTrial(partnerId: string, payload: IStartExtendTrial) {
    this.setIsLoading(true)
    try {
      await this.appStore.companyApi.startOrExtendSuiteTrial(partnerId, payload)
      await this.appStore.authStore.update(null, true)
      this.setIsLoading(false)
    } catch (e) {
      this.handleServerError(e)
      this.setIsLoading(false)
    }
  }
}
