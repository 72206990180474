import * as React from 'react'
import style from './header.css'
import { inject, observer } from 'mobx-react'
import { UiStore } from 'stores/ui-store'
import { AuthStore } from 'stores/auth-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import { CloudStatus } from './cloud-status'
import * as companyHelpers from 'helpers/company-helpers'
import { when } from 'mobx'
import { AccountStatus } from './account-status'
import { Icons, IconButton, Dropdown, DropdownItem, Spinner } from 'plume-ui'
import classNames from 'classnames'
import { AppStore } from 'stores/app-store'

interface HeaderProps extends WithTranslation {
  uiStore?: UiStore
  authStore?: AuthStore
  errorMode?: boolean
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  uiStore: appStore.uiStore,
  authStore: appStore.authStore,
}))
@observer
class HeaderComponent extends React.Component<HeaderProps> {
  componentDidMount() {
    if (!this.props.errorMode) {
      when(
        () => !!this.props.authStore.currentUser && !!this.props.authStore.currentUser.company,
        () => {
          const { company } = this.props.authStore.currentUser

          if (!!company?.parentPartnerId && !this.props.uiStore.parentCompany) {
            this.props.uiStore.getParentPartnerCompany(company)
          }
        },
      )
    }
  }

  render() {
    const { uiStore, authStore, errorMode, t } = this.props

    const currentUser = errorMode ? null : authStore.currentUser
    const companyName = !errorMode && currentUser?.company && currentUser?.company?.name

    const parentCompany = !errorMode && uiStore?.parentCompany
    const parentCompanyLoading = !errorMode && uiStore?.parentCompanyLoading

    const selectedEmployeeCompany = window.localStorage.getItem('selectedEmployeeCompany')
    const accMgrSelectedCompanyLabel = selectedEmployeeCompany
      ? authStore?.allActiveCompanies?.find(ac => ac.partnerId === selectedEmployeeCompany)?.name
      : ''

    const chooseCompanyDropdown = (
      <Dropdown
        openInPortal
        listPosition={uiStore.isMobile ? 'left' : 'right'}
        label={accMgrSelectedCompanyLabel || t('selectCompany.title')}
        searchBar={true}
        classes={curr => ({
          ...curr,
          root: classNames(curr.root, style.override, style.dropdownMargins),
          list: classNames(curr.list, style.override, style.dropdownList),
        })}
        noSearchMatchMessage={t('header.noResults')}
      >
        {authStore?.allActiveCompanies?.map(ac => {
          return (
            <DropdownItem
              key={ac.partnerId}
              onClick={() => authStore.setSelectedEmployeeCompany(ac.partnerId)}
              // // add when Salesforce Portal state migration is finished
              // classes={curr => ({
              //   ...curr,
              //   root: classNames(
              //     curr.root,
              //     companyHelpers.isTerminated(ac) && style.terminatedTrialTextModifications,
              //   ),
              // })}
            >
              {`${ac.name} - ${ac.partnerId}`}
            </DropdownItem>
          )
        })}
      </Dropdown>
    )
    return (
      <React.Fragment>
        {!uiStore.isMobile && (
          <section className={style.headerWrapper}>
            <div className={style.logoInputWrapper}>
              <div className={style.logo} />
            </div>

            {currentUser?.isEmployee && chooseCompanyDropdown}

            <div className={style.accountWrapper}>
              <div className={style.cartAccountWrapper}>
                <div>
                  <div className={style.accountName}>
                    {currentUser?.firstName} {currentUser?.lastName}
                  </div>
                  <AccountStatus />
                  <CloudStatus />
                  {(parentCompany || parentCompanyLoading) && (
                    <div className={style.company}>
                      {t('common.channelPartner')}:{' '}
                      {parentCompanyLoading ? (
                        <Spinner
                          visible
                          classes={curr => ({
                            ...curr,
                            root: classNames(curr.root, style.spinnerModifications),
                          })}
                        />
                      ) : (
                        companyHelpers.getPortalOrCompanyName(parentCompany)
                      )}
                    </div>
                  )}
                  {companyName && (
                    <div className={style.company}>
                      {companyHelpers.getPortalOrCompanyName(currentUser.company)}
                    </div>
                  )}
                </div>
                {currentUser?.initials && (
                  <div className={style.avatar}>
                    <span>{currentUser?.initials}</span>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
        {uiStore.isMobile && (
          <section className={style.headerWrapper}>
            <IconButton
              classes={curr => ({
                ...curr,
                root: classNames(curr.root, style.override, style.iconSize),
              })}
              onClick={() => uiStore.toggleMenuEnabled()}
            >
              <Icons.HamburgerIcon width={24} />
            </IconButton>

            {currentUser?.isEmployee && chooseCompanyDropdown}

            <div className={style.logo} />
          </section>
        )}
      </React.Fragment>
    )
  }
}

export const Header = withTranslation()(HeaderComponent)
