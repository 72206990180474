import { Button } from 'components/button/index'
import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { AppStore } from 'stores/app-store'
import { Location } from 'history'
import { withTranslation, WithTranslation } from 'react-i18next'
import { AuthStore } from 'stores/auth-store'
import { showAlert } from 'utils/show-alert'
import style from './login.css'
import { parse } from 'query-string'
import { RouteComponentProps } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader, notify, Button as UiButton } from 'plume-ui'
import classNames from 'classnames'
import { idpDeepLinkSuffixRecord } from 'constants/idp.constants'

interface LoginProps extends WithTranslation, RouteComponentProps {
  appStore?: AppStore
  location: Location
  authStore: AuthStore
}

interface LoginState {
  idpCountdown: number
  idpTimerId: number
  idpModalOpen: boolean
}

// Don't convert to functional component due to complex state and setInterval interactions
@inject(({ appStore }: { appStore: AppStore }) => ({
  appStore,
  authStore: appStore.authStore,
}))
@observer
class LoginComponent extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props)
    this.state = {
      idpCountdown: 5,
      idpTimerId: null,
      idpModalOpen: false,
    }
  }

  componentDidMount() {
    this.props.appStore.getAuthTransactionStore(false, true, {})
    const { t } = this.props
    const options = parse(location.search) as { idpValue?: string }
    if (options?.idpValue) {
      window.localStorage.setItem('idpValue', options.idpValue)
    }
    const idpValue = window.localStorage.getItem('idpValue')
    if (idpValue && !window.localStorage.getItem('logoutWhileSaml')) {
      this.props.authStore.checkIsIdpValid(idpValue)

      // Should be left as class component because of state and setInterval interaction
      const _idpTimerId = window.setInterval(() => {
        if (this.props.authStore.isIdpIdValid === false) {
          notify({
            title: t('idpSettings.invalidIdp'),
            body: t('idpSettings.invalidIdpDescription'),
            type: 'error',
          })
          this.closeIdpModal()
        } else if (this.state.idpCountdown > 1) {
          this.setState(oldState => ({ idpCountdown: oldState.idpCountdown - 1 }))
        } else {
          clearInterval(_idpTimerId)
          const portalEnv = process.env.PORTAL_ENV
          window.location.href = `${process.env.OKTA_ORG_URL}/sso/saml2/${idpValue}${idpDeepLinkSuffixRecord[portalEnv]}`
        }
      }, 1000)
      this.setState({ idpTimerId: _idpTimerId, idpModalOpen: true })
    } else if (window.localStorage.getItem('logoutWhileSaml')) {
      window.localStorage.removeItem('logoutWhileSaml')
    }

    const expired = location.search === '?expired=true'
    if (expired) {
      showAlert({
        title: 'auth.expiredSessionTitle',
        message: 'auth.expiredSessionMessage',
        buttonText: 'btn.gotIt',
        isCloseShowing: true,
        onAcknowledge: async () => this.props.appStore.routerStore.replace('/login'),
      })
    }
  }

  private handleLogin = () => {
    this.props.authStore.removeSelectedEmployeeCompany()
    this.props.authStore.auth.signInWithRedirect({
      redirectUri: `${window.location.origin}/implicit/callback`,
    })
  }

  private closeIdpModal = () => {
    clearInterval(this.state.idpTimerId)
    window.localStorage.removeItem('idpValue')
    this.setState({ idpModalOpen: false })
    this.props.history.push('/login')
  }

  public render() {
    const { t } = this.props

    return (
      <div className={style.root}>
        <Modal isOpen={this.state.idpModalOpen}>
          <ModalHeader title={t('idpSettings.redirectingToPartnerIdp')} />
          <ModalBody classes={curr => ({ root: classNames(curr.root, style.idpModalContent) })}>
            {t('idpSettings.redirectIn', { seconds: this.state.idpCountdown })}
          </ModalBody>
          <ModalFooter>
            <UiButton onClick={this.closeIdpModal} styleVariant="tertiary-grey">
              {t('btn.cancel')}
            </UiButton>
          </ModalFooter>
        </Modal>
        <header className={style.header} />
        <div className={style.content}>
          <div className={style.contentWrapper}>
            <div className={style.login}>
              <h1 className={style.loginTitle}>Sign In</h1>
              <h2 className={style.loginSubtitle}>Plume Portal</h2>
              <Button onClick={this.handleLogin} fullWidth className={style.loginButton}>
                {t('auth.loginWithOkta')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const Login = withTranslation()(LoginComponent)
