import * as React from 'react'
import * as Sentry from '@sentry/react'
import { DashboardLayout } from 'modules/dashboard/common/dashboard-layout'
import { UiStore } from 'stores/ui-store'
import { AuthStore } from 'stores/auth-store'
import { History } from 'history'

import style from './exception.css'
import { useTranslation } from 'react-i18next'

interface Exception429Props {
  uiStore?: UiStore
  authStore?: AuthStore
  location?: any
  history?: History
  statusCode?: number
}

export const Exception429: React.FC<Exception429Props> = ({ statusCode = 429 }) => {
  const { t } = useTranslation()

  React.useEffect(() => {
    Sentry.captureEvent({
      message: '429 Too many Requests',
    })
  }, [])

  return (
    <DashboardLayout errorMode>
      <div className={style.root}>
        <div className={style.exceptionContent}>
          <div className={style.title}>{statusCode ? `${statusCode} ERROR` : <>&nbsp;</>}</div>
          <div className={style.text}>{t('errors.error429Title')}</div>
          <div className={style.separator} />
        </div>
        <div className={style.listWrapper}>
          <ul className={style.list}>
            <li>
              <span className={style.errorKey}>{t('errors.description')}</span>
              <span className={style.errorValue}>{t('errors.error429Description')}</span>
            </li>
          </ul>
        </div>
      </div>
    </DashboardLayout>
  )
}
