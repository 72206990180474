import * as React from 'react'
import style from './select.css'
import formStyle from './select-form-theme.css'
import headerStyle from './select-header-theme.css'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'components/tooltip'

interface SelectOption {
  value: any
  text: string
  hidden?: boolean
}

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectOption[]
  tooltipText?: string
  tooltipOffset?: any[]
  theme?: 'form' | 'header'
}

export const Select = (props: SelectProps) => {
  const { t } = useTranslation()
  const { options, disabled, tooltipText, tooltipOffset, ...otherProps } = props
  const isFormTheme = props.theme === 'form'
  const isHeaderTheme = props.theme === 'header'

  const isDisabled = disabled || options?.length === 1

  const className = classNames(
    style.select,
    {
      [formStyle.select]: isFormTheme,
      [headerStyle.select]: isHeaderTheme,
    },
    props.className,
    isDisabled && style.selectDisabled,
  )

  const arrowClassName = classNames(style.arrow, {
    [formStyle.arrow]: isFormTheme,
    [headerStyle.arrow]: isHeaderTheme,
  })

  const arrowDownClassName = classNames(style.arrowDown, {
    [formStyle.arrowDown]: isFormTheme,
    [headerStyle.arrowDown]: isHeaderTheme,
  })

  return props.tooltipText ? (
    <Tooltip
      placement="top"
      overlay={<span className={style.tooltipText}>{t(tooltipText)}</span>}
      align={{
        offset: tooltipOffset || [0, -5],
      }}
      mouseLeaveDelay={0}
    >
      <div className={className}>
        <select {...otherProps} disabled={isDisabled}>
          {options &&
            options.map(({ value, text }) => (
              <option key={value} value={value}>
                {t(text)}
              </option>
            ))}
        </select>
        {!isDisabled && (
          <div className={arrowClassName}>
            <div className={arrowDownClassName} />
          </div>
        )}
      </div>
    </Tooltip>
  ) : (
    <div className={className}>
      <select {...otherProps} disabled={isDisabled}>
        {options &&
          options.map(({ value, text, hidden = false }) => {
            return (
              <option key={value} value={value} hidden={hidden}>
                {t(text)}
              </option>
            )
          })}
      </select>
      {!isDisabled && (
        <div className={arrowClassName}>
          <div className={arrowDownClassName} />
        </div>
      )}
    </div>
  )
}
