export type IAlertProps = {
  title: string
  message: React.ReactNode
  skipI18n?: boolean
  buttonText: string
  isCloseShowing: boolean
  onClose: () => Promise<void>
  onButtonClick: () => Promise<void>
}

export enum AlertReturnValue {
  ActionButton,
  CloseButton,
}
