import * as Sentry from '@sentry/react'
import { IAuthStore } from 'interfaces/stores/auth-store'
import { action, observable } from 'mobx'
import { User } from 'models'
import { OktaAuth } from '@okta/okta-auth-js'
import { History } from 'history'
import { deserialize } from 'serializr'
import { ICompanyApi, ICompany, IGetCompaniesResponse } from 'interfaces/api/portal/company-api'
import {
  IAssignedApplication,
  IIdpUserGetCompanyResponse,
  IUserApi,
} from 'interfaces/api/portal/user-api'
import { flattenCompanies } from 'helpers/company-helpers'
import { io, Socket } from 'socket.io-client'
import { connectSocketAsync } from 'helpers/websocket-helpers'
import { mixpanelActions } from 'utils/mixpanelActions'
import {
  idpUserGetCompaniesErrorEvent,
  idpUserGetCompaniesEvent,
} from 'constants/websocket-event-identifiers'
import { parseJsonSafe } from 'helpers/general-helpers'
import { showAlert } from 'utils/show-alert'
import { showAcceptUpdatedTermsAndConditions } from 'modules/account-setup/terms-and-conditions/accept-updated-terms-conditions'
import { termsUpdatedDate } from 'modules/account-setup/terms-and-conditions/terms-and-conditions'
import { canAccess } from 'modules/auth/auth-action-permission'

export class AuthStore implements IAuthStore {
  auth: OktaAuth

  @observable
  public currentUser?: User

  @observable
  public idToken?: string

  @observable
  public group?: string

  @observable
  public selectedCompany?: string

  @observable
  public selectedEmployeeCompany?: string

  @observable
  allActiveCompanies: Partial<ICompany>[]

  constructor(
    private readonly history: History,
    private readonly companyApi: ICompanyApi,
    private readonly userApi: IUserApi,
    private readonly getAppWebSocket: () => Socket,
    private readonly setAppWebSocket: (s: Socket) => void,
  ) {
    this.auth = new OktaAuth({
      issuer: process.env.OKTA_ISSUER,
      clientId: process.env.OKTA_CLIENT_ID,
      redirectUri: `${window.location.origin}/implicit/callback`,
      postLogoutRedirectUri: `${window.location.origin}/implicit/callback`,
      scopes: ['openid', 'email', 'portal'],
    })
  }

  @action
  async update(partnerId: string = null, forceUpdateCompanies = false) {
    this.setToken(await this.auth.getIdToken())
    if (this.idToken) {
      this.restoreSelectedGroup()
      const rawUser = await this.auth.getUser()
      const user = deserialize(User, rawUser)

      const userSentryPayload = {
        id: user.id,
        salesforceId: user.sfId,
        email: user.email,
      }

      if (user) {
        Sentry.setUser(userSentryPayload)
        mixpanelActions.identify(user.id)
        mixpanelActions.people.set({
          $first_name: user.firstName,
          $last_name: user.lastName,
          $email: user.email,
        })
      }

      if (!this.getAppWebSocket()?.connected) {
        const webSocket = io(`${process.env.PORTAL_API_BASE_URL}`, {
          path: '/websocket/',
          auth: { token: this.idToken },
        })
        await connectSocketAsync(webSocket)
        this.setAppWebSocket(webSocket)
      }

      if (user.isEmployee) {
        await this.listActiveCompanies()
        const empPartnerId = partnerId || window.localStorage.getItem('selectedEmployeeCompany')

        if (empPartnerId) {
          try {
            const asyncData = await Promise.all([
              this.companyApi.getCompanyByPartnerId(empPartnerId),
              this.companyApi.getAdditionalPartnerInfo(empPartnerId),
            ])

            const company = asyncData[0]
            const additionalPartnerInfo = asyncData[1]
            window.localStorage.setItem('selectedEmployeeCompany', company.partnerId)

            this.setCurrentEmployeeUser(user, company, additionalPartnerInfo)
            return
          } catch (e) {
            this.removeSelectedEmployeeCompany()
            if (e === 'No account manager access to this partner.') {
              this.history.replace('/missing-access?account-manager=restricted')
            } else {
              this.history.replace('/missing-access')
            }
          }
        }
        this.setCurrentEmployeeUser(user, null)
      } else if (!!user.idp_role) {
        if (!this.getAppWebSocket()?.listeners(idpUserGetCompaniesEvent)?.length) {
          this.getAppWebSocket()?.on(idpUserGetCompaniesEvent, async (message: string) => {
            const idpUserGetCompanyResponse: IIdpUserGetCompanyResponse = parseJsonSafe(message)
            const additionalPartnerInfo = await this.companyApi.getAdditionalPartnerInfo(
              idpUserGetCompanyResponse.company.partnerId,
            )
            this.setIdpUser(user, idpUserGetCompanyResponse, additionalPartnerInfo)
          })

          this.getAppWebSocket()?.on(idpUserGetCompaniesErrorEvent, (errorMessage: string) => {
            this.handleIdpGetCompanyError(errorMessage)
          })
        }

        if (!this.currentUser || !this.currentUser.companies.length) {
          try {
            await this.userApi.getOrSetupIdpUserCompany(this.getAppWebSocket()?.id)
          } catch {
            this.history.replace('/missing-access')
          }
        }
      } else {
        if (!this.currentUser || !this.currentUser.companies.length || forceUpdateCompanies) {
          const companyData = await this.companyApi.getCompanies()
          await this.setCurrentPartnerUser(user, companyData, null, partnerId)
        }
      }

      /**
       * some cases the user will not pass through the above code
       * hence why there is another .setUser to collect potential sfId.
       */
      if (user) {
        Sentry.setUser(userSentryPayload)
      }
    }
  }

  @action
  setIdpUser(
    user: User,
    payload: IIdpUserGetCompanyResponse,
    additionalPartnerInfo: Partial<ICompany>,
  ) {
    const company = payload?.company
    window.localStorage.setItem('selectedCompany', company?.partnerId)
    this.currentUser = user
    this.currentUser.assignedApplications = null

    this.setCurrentCompany(company, additionalPartnerInfo)
    mixpanelActions.people.set({
      $partner_id: company?.partnerId,
    })

    this.currentUser.canClone = false
    this.currentUser.cloned = payload?.cloned
    this.currentUser.companies = [company]
    this.fetchAndSetOwner()
    if (payload.tableauProvisionFailure) {
      this.handleTableauProvisionError(payload.tableauProvisionAction)
    }
  }

  @action
  private handleTableauProvisionError(action: string) {
    // adding provision failure
    if (action === 'add') {
      showAlert({
        title: 'errors.editUserAddTableauProvisioningTitle',
        message: 'errors.editUserAddTableauProvisioningMessage',
        buttonText: 'btn.ok',
        isCloseShowing: false,
      })
      // remove provision failure
    } else {
      showAlert({
        title: 'errors.editUserRemoveTableauProvisioningTitle',
        message: 'errors.editUserRemoveTableauProvisioningMessage',
        buttonText: 'btn.ok',
        isCloseShowing: false,
      })
    }
  }

  @action
  handleIdpGetCompanyError(errorMessage: string) {
    this.history.replace(
      `/missing-access?idp-user-error-message=${encodeURIComponent(errorMessage)}`,
    )
  }

  @observable
  isIdpIdValid: boolean = null

  @action.bound
  setIsIdpIdValid(isIdpIdValid: boolean) {
    this.isIdpIdValid = isIdpIdValid
  }

  @action
  async checkIsIdpValid(idpId: string) {
    try {
      const isIdpIdValid = await this.companyApi.checkIsIdpValid(idpId)
      this.setIsIdpIdValid(isIdpIdValid)
    } catch (e) {}
  }

  @action
  public async getUserAssignedApplications() {
    const userApps = await this.userApi.getUserAssignedApplications()
    this.setAssignedApplications(userApps)
  }

  @action
  private setAssignedApplications(assignedApplications: IAssignedApplication[]) {
    this.currentUser.assignedApplications = assignedApplications
  }

  async logout() {
    this.removeSelectedCompany()
    this.removeSelectedEmployeeCompany()
    try {
      Sentry.configureScope(scope => scope.setUser(null))
      if (window.localStorage.getItem('idpValue')) {
        window.localStorage.setItem('logoutWhileSaml', 'true')
      }
      await this.auth.signOut({
        postLogoutRedirectUri: `${window.location.origin}/login`,
      })
    } catch (err) {
      console.log(err)
      throw new Error(err)
    }
  }

  @action
  private setToken(token: string) {
    this.idToken = token
  }

  @action.bound
  public async setCurrentPartnerUser(
    user: User,
    companyData: IGetCompaniesResponse,
    assignedApplications: IAssignedApplication[],
    partnerId?: string,
  ) {
    if (
      !user.idp_role &&
      !user.isEmployee &&
      companyData?.sfContact &&
      (!companyData?.sfContact?.termsAccepted ||
        new Date(termsUpdatedDate).getTime() >
          new Date(companyData?.sfContact?.termsAccepted).getTime())
    ) {
      showAcceptUpdatedTermsAndConditions()
      return
    }

    const selectedCompany = window.localStorage.getItem('selectedCompany')

    const flatCompanies = flattenCompanies(companyData.companies)

    let company: ICompany
    let additionalPartnerInfo: Partial<ICompany>
    if (partnerId) {
      company = flatCompanies.find(co => co.partnerId === partnerId)
      if (!company) {
        this.history.replace('/missing-access')
      }
      additionalPartnerInfo = await this.companyApi.getAdditionalPartnerInfo(company.partnerId)
    } else {
      company = selectedCompany
        ? flatCompanies.find(co => co.partnerId === selectedCompany)
        : flatCompanies[0]

      additionalPartnerInfo = await this.companyApi.getAdditionalPartnerInfo(company.partnerId)
    }
    this.setCurrentPartnerUserData(
      user,
      companyData,
      assignedApplications,
      company,
      additionalPartnerInfo,
    )
    mixpanelActions.people.set({
      $partner_id: partnerId,
    })
  }

  // all currentUser related assignments should be done in one mobx tick
  @action
  public setCurrentPartnerUserData(
    user: User,
    companyData: IGetCompaniesResponse,
    assignedApplications: IAssignedApplication[],
    company: ICompany,
    additionalPartnerInfo: Partial<ICompany>,
  ) {
    this.currentUser = user
    this.setCurrentCompany(company, additionalPartnerInfo)
    this.currentUser.canClone = companyData?.sfContact?.canClone
    this.currentUser.cloned = companyData?.sfContact?.cloned
    this.currentUser.sfId = companyData?.sfContact?.id
    this.currentUser.companies = companyData?.companies
    this.currentUser.subordinateCompanies = companyData?.subordinateCompanies
    this.currentUser.hideTableauWelcomeModal =
      companyData?.sfContact?.hideTableauWelcomeModal === 'true'
    this.currentUser.assignedApplications = assignedApplications
    this.currentUser.phoneNumber = companyData?.sfContact?.phoneNumber
    this.fetchAndSetOwner()
  }

  @action
  private setCurrentEmployeeUser(
    user: User,
    company: ICompany,
    additionalPartnerInfo?: Partial<ICompany>,
  ) {
    if (!this.currentUser) {
      this.currentUser = user
    }
    if (!this.currentUser.assignedApplications) {
      this.currentUser.assignedApplications = null
    }

    this.setCurrentCompany(company, additionalPartnerInfo)

    if (company) {
      mixpanelActions.people.set({
        $partner_id: company?.partnerId,
      })
    }

    this.fetchAndSetOwner()
  }

  @action
  public setSelectedGroup(group?: string) {
    this.group = group
    window.localStorage.setItem('group', group)
    this.history.replace('/')
    this.update()
  }

  private fetchAndSetOwner() {
    if (!this.currentUser.company) {
      return
      // return (window.location.href = `${window.location.origin}/missing-company`)
    }

    const { ownerEmail, ownerFirstName, ownerLastName } = this.currentUser.company

    this.setOwner({
      email: ownerEmail,
      firstName: ownerFirstName,
      lastName: ownerLastName,
    })
  }

  @action
  private setOwner(owner: any) {
    this.patchCurrentCompanyProperties({ owner })
  }

  @action
  public async setSelectedCompany(partnerId?: string) {
    this.selectedCompany = partnerId

    const flatCompanies = flattenCompanies(this.currentUser.companies)
    const company = flatCompanies.find(co => co.partnerId === partnerId)

    const additionalPartnerInfo = await this.companyApi.getAdditionalPartnerInfo(partnerId)
    this.setCurrentCompany(company, additionalPartnerInfo)

    this.fetchAndSetOwner()
    window.localStorage.setItem('selectedCompany', partnerId)
    const featureAccessRightsArray = this.currentUser.company.featureAccessRights?.split(';') || []
    this.history.replace(
      featureAccessRightsArray.includes('ACCOUNT') && canAccess('viewSettings', this.currentUser)
        ? '/account'
        : '/knowledge-center',
    )
    this.update()
  }

  @action
  public async setSelectedEmployeeCompany(partnerId: string) {
    this.removeSelectedEmployeeCompany()
    window.localStorage.setItem('selectedEmployeeCompany', partnerId)
    window.location.assign(`/${partnerId}/users`)
  }

  @action
  public setSelectedEmployeeCompanyData(
    company: ICompany,
    additionalPartnerInfo?: Partial<ICompany>,
  ) {
    this.setCurrentCompany(company, additionalPartnerInfo)
    this.removeSelectedEmployeeCompany()
    window.localStorage.setItem('selectedEmployeeCompany', company.partnerId)
    this.selectedEmployeeCompany = company.partnerId
    this.history.replace(`/${company.partnerId}/users`)
    this.update()
  }

  @action setCurrentCompany(company: ICompany, additionalInfo: Partial<ICompany>) {
    if (company) {
      this.currentUser.company = Object.assign(company, additionalInfo)
    }
  }

  @action patchCurrentCompanyProperties(companyProperties: Omit<Partial<ICompany>, 'partnerId'>) {
    Object.assign(this.currentUser.company, companyProperties)
    const companyInTopArrayInd = this.currentUser.companies?.findIndex(
      c => c.partnerId === this.currentUser.company.partnerId,
    )
    if (companyInTopArrayInd > -1) {
      Object.assign(this.currentUser.companies[companyInTopArrayInd], companyProperties)
    }
    for (let i = 0; i < this.currentUser.companies?.length; i += 1) {
      const companyInSubArrayInd = this.currentUser.companies[i].subordinateCompanies?.findIndex(
        sc => sc.partnerId === this.currentUser.company.partnerId,
      )
      if (companyInSubArrayInd > -1) {
        Object.assign(
          this.currentUser.companies[i].subordinateCompanies[companyInSubArrayInd],
          companyProperties,
        )
      }
    }
  }

  @action
  public restoreSelectedGroup() {
    this.group = window.localStorage.getItem('group')
  }

  @action
  public removeSelectedCompany() {
    window.localStorage.removeItem('selectedCompany')
    this.selectedCompany = ''
  }

  @action
  public removeSelectedEmployeeCompany() {
    window.localStorage.removeItem('selectedEmployeeCompany')
    this.selectedEmployeeCompany = ''
  }

  @action
  selectOtherPartnerCompany() {
    this.removeSelectedCompany()
    this.history.replace('/select-company')
  }

  @action
  setAllActiveCompanies(allActiveCompanies: Partial<ICompany>[]) {
    this.allActiveCompanies = allActiveCompanies
  }

  @action
  async listActiveCompanies() {
    const allActiveCompanies = await this.companyApi.listActiveCompanies()
    this.setAllActiveCompanies(allActiveCompanies)
  }

  @action
  async updateUserTermsAndConditions() {
    await this.userApi.acceptTermsAndConditions()
  }
}
