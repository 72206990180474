import * as React from 'react'
import style from './select-company.css'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { SignOut } from 'components/icons'
import { DashboardLayout } from 'modules/dashboard/common/dashboard-layout'
import { flattenCompanies, isActive, isTerminated } from 'helpers/company-helpers'
import { useTranslation } from 'react-i18next'
import { useUpdateCurrentUser } from 'utils/hooks/use-update-current-user'
import { useStores } from 'utils/hooks/useStores'
import { ICompany } from 'interfaces/api/portal/company-api'
import { Checkbox } from 'components/checkbox'
import classNames from 'classnames'
import { getPortalOrCompanyName } from 'helpers/company-helpers'
import { mixpanelActions } from 'utils/mixpanelActions'

type CompanyCardProps = {
  onClick: () => void
  company: ICompany
  styles?: React.CSSProperties
  isLoading?: boolean
}

const CompanyCard = ({ onClick, company, styles, isLoading = false }: CompanyCardProps) => {
  return (
    <div className={classNames(style.companyCol)} onClick={onClick} style={styles}>
      <div className={style.company}>
        <div className={style.companyLogo}>
          <div
            className={classNames(
              style.companyLogoText,
              isTerminated(company) && style.companyTrialOver,
              isActive(company) && style.companyContractSigned,
            )}
          >
            {company.name[0]}
          </div>
        </div>
        <div className={style.companyText}>
          <div className={style.companyName}>{getPortalOrCompanyName(company)}</div>
          <div className={style.companyProducts}>{company.profiles.join(', ')}</div>
          <div className={style.companyInfo}>{company.partnerId}</div>
        </div>
        {isLoading && <Loader fit />}
      </div>
    </div>
  )
}

export const SelectCompany = observer(() => {
  const { t } = useTranslation()
  const appStore = useStores()
  const currentUser = useUpdateCurrentUser()
  const [terminated, setTerminated] = React.useState(false)
  const [active, setActive] = React.useState(true)
  const [homepass, setHomepass] = React.useState(true)
  const [workpass, setWorkpass] = React.useState(true)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [loadingPartnerId, setLoadingPartnerId] = React.useState<string>(null)

  React.useEffect(() => {
    appStore.uiStore.resetParentPartner()
  }, [appStore.uiStore])

  const filterCompanies = (company: ICompany) => {
    const portalStateCondition =
      (active && isActive(company)) || (terminated && isTerminated(company))

    const portalProductsCondition =
      (homepass && (company.profiles.includes('HomePass') || !company.profiles?.length)) ||
      (workpass && (company.profiles.includes('WorkPass') || !company.profiles?.length))

    const searchCondition = searchTerm
      ? company.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        company.partnerId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        company?.portalNickname?.toLowerCase()?.includes(searchTerm.toLowerCase())
      : true

    if (portalStateCondition && portalProductsCondition && searchCondition) {
      return true
    }
  }

  if (!currentUser) {
    return <Loader />
  }

  const flatCompanies = flattenCompanies(currentUser.companies)

  const parentCompanies = flatCompanies
    .filter(company => company.isParent && filterCompanies(company))
    .sort((a, b) => a.name.localeCompare(b.name))

  const subordinateCompanies = flatCompanies
    .filter(company => company.isSubordinatePartner && filterCompanies(company))
    .sort((a, b) => a.name.localeCompare(b.name))

  const handleLogoutClick = () => {
    appStore.authStore.logout()
  }

  const handleCompanyClick = async (partnerId: string) => {
    if (loadingPartnerId) {
      return
    }
    mixpanelActions.track('Select Company', { 'Partner Id': partnerId })
    setLoadingPartnerId(partnerId)
    appStore.resetAccountStore()
    await appStore.authStore.setSelectedCompany(partnerId)
    setLoadingPartnerId(null)
    appStore.usersStore.resetUsers()
    await appStore.usersStore.fetchUsers()
    await appStore.homeStore.getDashboardData()
  }

  return (
    <DashboardLayout currentUser={currentUser} selectCompany>
      <div className={style.root}>
        <div className={style.titleSearch}>
          <input
            className={style.searchInput}
            value={searchTerm}
            placeholder={t('selectCompany.searchFor')}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <div className={style.filterBy}>
          <div className={style.filterGroup}>
            <div className={style.filterLabel}>{t('selectCompany.status')}</div>
            <div className={style.checkboxesWrapper}>
              <Checkbox
                className={classNames(style.checkBox, style.checkBoxSigned)}
                theme="default"
                name="active"
                checked={active}
                onChange={e => setActive(e.target.checked)}
                error=""
              />
              <div className={style.btnLabel}>{t('common.active')}</div>
              <Checkbox
                className={classNames(style.checkBox, style.checkBoxTrialOver)}
                theme="default"
                name="terminated"
                checked={terminated}
                onChange={e => setTerminated(e.target.checked)}
                error=""
              />
              <div className={style.btnLabel}>{t('common.terminated')}</div>
            </div>
          </div>
          <div className={style.filterGroupSeparator} />
          <div className={style.filterGroup}>
            <div className={style.filterLabel}>{t('account.products')}</div>
            <div className={style.checkboxesWrapper}>
              <Checkbox
                className={classNames(style.checkBox, style.checkBoxHomepass)}
                theme="default"
                name="homepass"
                checked={homepass}
                onChange={e => setHomepass(e.target.checked)}
                error=""
              />
              <div className={style.btnLabel}>{t('selectCompany.homepass')}</div>
              <Checkbox
                className={classNames(style.checkBox, style.checkBoxHomepass)}
                theme="default"
                name="workpass"
                checked={workpass}
                onChange={e => setWorkpass(e.target.checked)}
                error=""
              />
              <div className={style.btnLabel}>{t('selectCompany.workpass')}</div>
            </div>
          </div>
        </div>
        {parentCompanies?.length > 0 && (
          <>
            <h3 className={style.title}>{t('selectCompany.channelPartners')}</h3>
            <div className={style.companyWrapper}>
              {parentCompanies.map(company => (
                <CompanyCard
                  key={company.partnerId}
                  onClick={() => handleCompanyClick(company.partnerId)}
                  company={company}
                  isLoading={company.partnerId === loadingPartnerId}
                />
              ))}
            </div>
          </>
        )}
        {subordinateCompanies?.length > 0 && (
          <>
            <div className={style.companyWrapper}>
              <h3 className={style.title} style={{ textAlign: 'center' }}>
                {t('selectCompany.affiliatePartners')}:
              </h3>
              {subordinateCompanies.map(subordinateCompany => (
                <CompanyCard
                  key={subordinateCompany.partnerId}
                  onClick={() => handleCompanyClick(subordinateCompany.partnerId)}
                  company={subordinateCompany}
                  isLoading={subordinateCompany.partnerId === loadingPartnerId}
                />
              ))}
            </div>
          </>
        )}
        <div className={style.logout} onClick={handleLogoutClick}>
          <span className={style.logoutIcon}>
            <SignOut />
          </span>
          <span className={style.logoutText}>{t('auth.signOut')}</span>
        </div>
      </div>
    </DashboardLayout>
  )
})
