import * as React from 'react'

import style from './line-chart.css'
import { MonthlyItem } from 'models/home/dashboard-data'
import classNames from 'classnames'
import { renderToStaticMarkup } from 'react-dom/server'
import { Status } from 'plume-ui'

interface ILineChartProps {
  data: { online: MonthlyItem[]; offline: MonthlyItem[] }
  formatNumber?: (value: number) => any
  t: (...props: any) => any
}

interface ILineChartState {
  width: number
  height: number
  titleHeight: number
}

const POINT_RADIUS = '7'

export class LineChart extends React.Component<ILineChartProps, ILineChartState> {
  private container = React.createRef<HTMLDivElement>()
  private titleElement = React.createRef<HTMLDivElement>()
  private readonly OFFSET_Y = 15
  private readonly LABELS_SIZE = 20
  private readonly DIVIDER_HEIGHT = 5
  // private readonly AXIS_STROKE_WIDTH = 2

  private MONTHS_DURATION: number

  private toolTip = React.createRef<HTMLDivElement>()

  private circleRefsActive: React.RefObject<SVGCircleElement>[] = []
  private circleRefsOffline: React.RefObject<SVGCircleElement>[] = []

  constructor(props: ILineChartProps) {
    super(props)

    this.MONTHS_DURATION = this.props.data.online.length
    this.state = {
      width: 0,
      height: 0,
      titleHeight: 0,
    }
  }

  componentDidMount() {
    this.updateDimensions()
  }

  public updateDimensions() {
    const { clientWidth: width, clientHeight: height } = this.container.current
    const { clientHeight: titleHeight } = this.titleElement.current

    this.setState({ titleHeight, width, height: height - titleHeight })
  }

  private getDataBounds() {
    const { data } = this.props

    const values = data.online.map(obj => obj.value)

    const minY = Math.min.apply(null, values)
    const maxY = Math.max.apply(null, values)

    return { minY, maxY, minX: 0, maxX: this.MONTHS_DURATION - 1 }
  }

  private getGraphHeight() {
    const { height } = this.state
    return height - this.LABELS_SIZE
  }

  private getSvgX(x: number) {
    const { width } = this.state
    return (x / this.MONTHS_DURATION) * width
  }

  private getSvgY(y: number) {
    const height = this.getGraphHeight()
    const { maxY } = this.getDataBounds()

    return maxY === 0 ? 0 : height - (y / maxY) * height
  }

  handleOnHoverToolTip = (
    elIndex: number,
    onlineValue: number,
    offlineValue: number,
    circle: any,
  ): any => {
    const { t } = this.props
    const currentCircle: any = circle

    const toolTip = (
      <div>
        <div className={style.displayFlex}>
          <Status
            color="ok"
            label={t('home.online')}
            classes={styles => {
              return {
                ...styles,
                label: classNames(styles.label, style.override, style.onlineStatus),
              }
            }}
          />
          <div className={style.toolTipValue}>{this.props.formatNumber(onlineValue)}</div>
        </div>
        {this.props.data.offline.length !== 0 && (
          <div className={style.displayFlex}>
            <Status
              color="#999eff"
              label={t('home.offline')}
              classes={styles => {
                return {
                  ...styles,
                  label: classNames(styles.label, style.override, style.offlineStatus),
                }
              }}
            />
            <div className={style.toolTipValue}>{this.props.formatNumber(offlineValue)}</div>
          </div>
        )}
      </div>
    )

    this.toolTip.current.innerHTML = renderToStaticMarkup(toolTip)

    const leftDirectionAdjustment =
      elIndex === this.MONTHS_DURATION - 1 ? 90 : elIndex === this.MONTHS_DURATION - 2 ? 50 : 20
    this.toolTip.current.style.left = `${
      Math.ceil(currentCircle.current.cx.baseVal.value) - leftDirectionAdjustment
    }px`

    const topDirectionAdjustment = this.props.data.offline.length !== 0 ? 35 : 15
    this.toolTip.current.style.top = `${
      Math.ceil(currentCircle.current.cy.baseVal.value) - topDirectionAdjustment
    }px`
    this.toolTip.current.className = classNames(style.toolTip, style.displayInline)
  }

  handleOnHoverOutToolTip = (): any => {
    this.toolTip.current.className = classNames(style.toolTip, style.displayHidden)
  }

  private areMonthsRelated(i: number) {
    return i > 0
  }

  private makePath() {
    const { data } = this.props
    const { width } = this.state

    this.circleRefsActive = []
    this.circleRefsOffline = []
    const spaceStartSection = width / this.MONTHS_DURATION / 2 - 3

    const hasNoActiveValues = data.online.every(point => point.value === 0)
    let pathsActive = []

    pathsActive = data.online.map((point, i) => {
      const areMonthsRelated = this.areMonthsRelated(i)
      if (areMonthsRelated) {
        return `M ${this.getSvgX(i - 1) + spaceStartSection} ${
          this.getSvgY(data.online[i - 1].value) + this.DIVIDER_HEIGHT
        } L ${this.getSvgX(i) + spaceStartSection} ${
          this.getSvgY(point.value) + this.DIVIDER_HEIGHT
        } `
      }

      return `M ${this.getSvgX(i) + spaceStartSection} ${
        this.getSvgY(data.online[i].value) + this.DIVIDER_HEIGHT
      } L ${this.getSvgX(i) + spaceStartSection + 1} ${
        this.getSvgY(point.value) + this.DIVIDER_HEIGHT
      } `
    })

    // TODO: wait for final design related to floating points - then remove commented code
    // let pathDActive = `M 20 ${this.getSvgY(data.online[0].value) +
    //   this.DIVIDER_HEIGHT}`
    // pathDActive += data.online.map(
    //   (point, i) =>
    //     `L ${this.getSvgX(i) + 20} ${this.getSvgY(point.value) +
    //       this.DIVIDER_HEIGHT} `,
    // )
    // let pathDActive = `M ${spaceStartSection} ${this.getSvgY(
    //   data.online[0].value,
    // ) + this.DIVIDER_HEIGHT}`
    // pathDActive += data.online.map(
    //   (point, i) =>
    //     `L ${this.getSvgX(i) + spaceStartSection} ${this.getSvgY(point.value) +
    //       this.DIVIDER_HEIGHT} `,
    // )

    const dotsActive = data.online.map((point, i) => {
      const circleRefActive = React.createRef<SVGCircleElement>()
      this.circleRefsActive.push(circleRefActive)
      return (
        <circle
          key={`online${i}`}
          ref={circleRefActive}
          onMouseEnter={() =>
            this.handleOnHoverToolTip(
              i,
              point.value || 0,
              data.offline && data.offline.length > 0 ? data.offline[i].value : 0,
              this.circleRefsActive[i],
            )
          }
          onMouseLeave={() => this.handleOnHoverOutToolTip()}
          cx={this.getSvgX(i) + spaceStartSection}
          cy={this.getSvgY(point.value || 0) + this.DIVIDER_HEIGHT}
          r={POINT_RADIUS}
          fill="white"
          stroke="#c7c7c7"
          strokeWidth="1"
          className={style.circle}
        />
      )
    })

    const hasNoOfflineValues = data.offline.every(point => point.value === 0)
    let pathsOffline = []

    pathsOffline = data.offline.map((point, i) => {
      const areMonthsRelated = this.areMonthsRelated(i)
      if (areMonthsRelated) {
        return `M ${this.getSvgX(i - 1) + spaceStartSection} ${
          this.getSvgY(data.offline[i - 1].value) + this.DIVIDER_HEIGHT
        } L ${this.getSvgX(i) + spaceStartSection} ${
          this.getSvgY(point.value) + this.DIVIDER_HEIGHT
        } `
      }

      return `M ${this.getSvgX(i) + spaceStartSection} ${
        this.getSvgY(data.offline[i].value) + this.DIVIDER_HEIGHT
      } L ${this.getSvgX(i) + spaceStartSection + 1} ${
        this.getSvgY(point.value) + this.DIVIDER_HEIGHT
      } `
    })

    // TODO: wait for final design related to floating points - then remove commented code
    // let pathDOffline = `M 20 ${this.getSvgY(data.offline[0].value) +
    //   this.DIVIDER_HEIGHT}`
    // pathDOffline += data.offline.map(
    //   (point, i) =>
    //     `L ${this.getSvgX(i) + 20} ${this.getSvgY(point.value) +
    //       this.DIVIDER_HEIGHT} `,
    // )
    // let pathDOffline = `M ${spaceStartSection} ${this.getSvgY(
    //   data.offline[0].value,
    // ) + this.DIVIDER_HEIGHT}`
    // pathDOffline += data.offline.map(
    //   (point, i) =>
    //     `L ${this.getSvgX(i) + spaceStartSection} ${this.getSvgY(point.value) +
    //       this.DIVIDER_HEIGHT} `,
    // )

    const dotsOffline = data.offline.map((point, i) => {
      const circleRefOffline = React.createRef<SVGCircleElement>()
      this.circleRefsOffline.push(circleRefOffline)
      return (
        <circle
          key={`offline${i}`}
          ref={circleRefOffline}
          onMouseEnter={() =>
            this.handleOnHoverToolTip(
              i,
              data.online && data.online.length > 0 ? data.online[i].value : 0,
              point.value || 0,
              this.circleRefsOffline[i],
            )
          }
          onMouseLeave={() => this.handleOnHoverOutToolTip()}
          cx={this.getSvgX(i) + spaceStartSection}
          cy={this.getSvgY(point.value || 0) + this.DIVIDER_HEIGHT}
          r={POINT_RADIUS}
          fill="white"
          stroke="#c7c7c7"
          strokeWidth="1"
          className={style.circle}
        />
      )
    })

    return (
      <React.Fragment>
        {!hasNoActiveValues &&
          pathsActive.map((path, i) => <path key={i} d={path} className={style.pathLineActive} />)}
        {dotsActive.map(dot => dot)}
        {!hasNoOfflineValues &&
          pathsOffline.map((path, i) => (
            <path key={i} d={path} className={style.pathLineOffline} />
          ))}
        {dotsOffline.map(dot => dot)}
      </React.Fragment>
    )
  }

  private makeAxis() {
    const { width } = this.state
    const axisHeight = this.getGraphHeight()

    return (
      <g className={style.linechartAxis}>
        {/* X axis */}
        <line
          x1={0}
          y1={axisHeight + this.DIVIDER_HEIGHT}
          x2={width}
          y2={axisHeight + this.DIVIDER_HEIGHT}
        />
      </g>
    )
  }

  private makeLabels() {
    const { data, t } = this.props
    const { height, width } = this.state
    const spaceBetween = width / this.MONTHS_DURATION / 2

    const texts = data.online.map((point, i) => (
      <text
        key={i}
        className={style.axisLabel}
        x={this.getSvgX(i) + spaceBetween - 10}
        y={height + this.OFFSET_Y - this.LABELS_SIZE + this.DIVIDER_HEIGHT - 4}
        textAnchor={'start'}
      >
        {t(`common.months.${point.month}_short`)}
      </text>
    ))

    return texts
  }

  render() {
    const { height, width } = this.state
    const { t } = this.props

    return (
      <div ref={this.container} className={style.root}>
        <div ref={this.titleElement} className={style.title}>
          {t('home.graphPastNMonths', { count: this.props.data.online.length })}
        </div>
        <div ref={this.toolTip} className={classNames(style.displayNone, style.toolTip)} />
        <svg width={width} height={height + 3} viewBox={`0 0 ${width} ${height + 3}`}>
          {this.makePath()}
          {this.makeAxis()}
          {this.makeLabels()}
        </svg>
      </div>
    )
  }
}
