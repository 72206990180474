import * as React from 'react'
import { Close } from 'components/icons'

import { AppStore } from 'stores/app-store'
import { UiStore } from 'stores/ui-store'
import { DashboardUsersStore } from '../user-store'
import { inject, observer } from 'mobx-react'
import { IUserApi } from 'interfaces/api/portal/user-api'
import { Loader } from 'components/loader'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { AuthStore } from 'stores/auth-store'
import { withTranslation, WithTranslation } from 'react-i18next'

import style from './clone-user-progress-modal.css'
import { ProgressBar } from 'components/progress-bar/progress-bar'

interface CloneUserProps extends WithTranslation {
  appStore?: AppStore
  onClose: () => Promise<void>
  uiStore?: UiStore
  userApi?: IUserApi
  usersStore?: DashboardUsersStore
  modalLayerStore?: ModalLayerStore
  authStore?: AuthStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  appStore,
  uiStore: appStore.uiStore,
  usersStore: appStore.usersStore,
  modalLayerStore: appStore.actionModalStore,
  authStore: appStore.authStore,
}))
@observer
class CloneUserProgressComponent extends React.Component<CloneUserProps> {
  private store: DashboardUsersStore
  timer: any

  constructor(props: CloneUserProps) {
    super(props)
    this.store = props.usersStore
  }

  componentDidMount() {
    this.timer = setInterval(() => this.store.fetchClonedUsersQueue(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const { onClose, t } = this.props
    const {
      store: { cloneQueue, clonedUserId },
    } = this

    const userQueue = cloneQueue && cloneQueue[clonedUserId]

    const progress = userQueue ? userQueue.current / userQueue.totalGroups : 0

    return (
      <div className={style.root}>
        <div className={style.subTitle}>{t('users.cloneUserTitle')}</div>
        <div className={style.closeButton}>
          <Close onClick={onClose} />
        </div>
        {userQueue ? (
          <div className={style.wrapper}>
            <div className={style.resetPasswordDesc}>
              {`Copying Okta Groups in progress. ${userQueue.current} out of ${userQueue.totalGroups} groups are copied ...`}
            </div>

            <ProgressBar width={progress} />
          </div>
        ) : (
          <Loader theme="small" />
        )}
      </div>
    )
  }
}

export const CloneUserProgressModal = withTranslation()(CloneUserProgressComponent)
