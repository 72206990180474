import * as React from 'react'
import style from './input.css'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'components/tooltip'
import { InfoButton } from 'components/icons'

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  error?: string | boolean | { text: string; options?: { [option: string]: string } }
  hasBeenValidated?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  inputStyle?: React.CSSProperties
  infoButtonText?: string
  infoButtonSize?: 'default' | 'small'
}

export const Input = (props: IInputProps) => {
  const { t } = useTranslation()
  const {
    className,
    label,
    error,
    hasBeenValidated,
    inputRef,
    infoButtonText,
    inputStyle,
    infoButtonSize,
    ...otherProps
  } = props
  const invalid = hasBeenValidated && !!error
  const valid = hasBeenValidated && !error
  return (
    <div
      className={classNames(style.root, valid && style.valid, invalid && style.invalid, className)}
    >
      <label className={style.label} htmlFor={label}>
        {label}
      </label>
      {infoButtonText && (
        <Tooltip
          placement="right"
          overlay={<div className={style.tooltipInfoText}>{t(infoButtonText)}</div>}
          align={{
            offset: [0, -5],
          }}
          trigger={['click']}
          mouseLeaveDelay={0}
        >
          <div className={style.infoTooltipContainter}>
            <InfoButton
              className={classNames(style.infoButton, {
                [style.infoButtonSmall]: infoButtonSize === 'small',
              })}
            />
          </div>
        </Tooltip>
      )}
      <input
        id={label}
        type="text"
        className={style.input}
        style={inputStyle}
        ref={inputRef}
        {...otherProps}
      />
      {typeof error === 'string' && error !== '' && (
        <div className={style.error}>{t([`errors.${error}`, 'errors.somethingWentWrong'])}</div>
      )}
      {typeof error !== 'string' && typeof error !== 'boolean' && error && (
        <div className={style.error}>{t(error?.text, error?.options)}</div>
      )}
    </div>
  )
}
