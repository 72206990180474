import { autorun, when } from 'mobx'
import { AuthStore } from './auth-store'
import { PortalBaseApi } from 'api/portal'
import { ModalLayerStore } from './modal-layer-store'
import * as history from 'history'
import { RouterStore, syncHistoryWithStore, SynchronizedHistory } from 'mobx-react-router'
import { DashboardUsersStore } from 'modules/dashboard/user/user-store'
import { UiStore } from './ui-store'
import { CompanyApi } from 'api/portal/company-api'
import { UserApi } from 'api/portal/user-api'
import { AuthTransactionStore } from 'modules/auth/auth-transaction-store'
import { AccountSetupStore } from 'modules/account-setup/account-setup-store'
import { DashboardHomeStore } from 'modules/dashboard/home/dashboard-home-store'
import { navItems } from 'constants/dashboard/navigation'
import { ApplicationsStore } from 'modules/dashboard/applications/applications-store'
import { Socket } from 'socket.io-client'
import { PortalInventoryApi } from 'api/portal/inventory-apy'
import { AccountStore } from 'modules/dashboard/account/account-store'

export class AppStore {
  // utils
  history: SynchronizedHistory

  // api
  companyApi = new CompanyApi()
  userApi = new UserApi()
  portalInventoryApi = new PortalInventoryApi()

  appWebSocket: Socket = null

  // stores
  routerStore = new RouterStore()
  authStore: AuthStore
  actionModalStore = new ModalLayerStore()
  uiStore = new UiStore(this.companyApi)
  accountStore?: AccountStore
  homeStore?: DashboardHomeStore
  usersStore?: DashboardUsersStore
  applicationsStore?: ApplicationsStore

  authTransactionStore?: AuthTransactionStore
  accountSetupStore?: AccountSetupStore

  constructor() {
    const browserHistory = history.createBrowserHistory()
    this.history = syncHistoryWithStore(browserHistory, this.routerStore)

    this.authStore = new AuthStore(
      this.history,
      this.companyApi,
      this.userApi,
      () => this.appWebSocket,
      s => (this.appWebSocket = s),
    )

    when(
      () => !!this.authStore.currentUser && !!this.authStore.currentUser.company,
      () => {
        const accountStore = new AccountStore(this)
        this.accountStore = accountStore

        const usersStore = new DashboardUsersStore(this)
        this.usersStore = usersStore

        const accessibleNavItems = navItems.filter(nI =>
          this.authStore.currentUser.featureAccessRightsArr.includes(nI.accessRightAlias),
        )
        const isHomeAccessible = accessibleNavItems.find(
          nI => nI.accessRightAlias === 'HOME_DASHBOARD',
        )
        if (isHomeAccessible) {
          const homeStore = new DashboardHomeStore(this.authStore)
          this.homeStore = homeStore
        }

        this.accountSetupStore = new AccountSetupStore({
          history: this.history,
        })
      },
    )

    // TODO: align hotfix
    when(
      () => !!this.authStore.currentUser && !!this.authStore.currentUser.isEmployee,
      () => {
        const accountStore = new AccountStore(this)
        this.accountStore = accountStore

        const usersStore = new DashboardUsersStore(this)
        this.usersStore = usersStore

        this.accountSetupStore = new AccountSetupStore({
          history: this.history,
        })
      },
    )

    autorun(() => PortalBaseApi.setDefaultAuthorizationHeader(this.authStore.idToken))

    autorun(() => PortalBaseApi.setDefaultPartnerGroupHeader(this.authStore.group))

    autorun(() =>
      PortalBaseApi.setHistoryAndUiStore({
        history: this.history,
        uiStore: this.uiStore,
      }),
    )
  }

  getAuthTransactionStore(restoreTransaction: boolean, restoreSession?: boolean, appOptions?: any) {
    if (!this.authTransactionStore) {
      this.authTransactionStore = new AuthTransactionStore({
        appOptions,
        restoreTransaction,
        restoreSession,
        history: this.history,
      })
    }

    return this.authTransactionStore
  }

  resetAccountStore() {
    this.accountStore = new AccountStore(this)
  }
}
