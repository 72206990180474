import { FieldState, FormState } from 'formstate'
import { observable, action, computed, runInAction, toJS } from 'mobx'
import { AppStore } from 'stores/app-store'
import {
  required,
  url,
  // domain,
  email,
  phoneNumberHelpDesc,
  notDomainOrUrl,
  pdf,
  // min3chars,
  min7chars,
  max15chars,
  max80chars,
  max50chars,
  max10chars,
  makeOptional,
  max20chars,
} from 'utils/form/validators'
import {
  IAppCustomizationUpdateResponse,
  ICompany,
  IMobileConfig,
  ProductCustomization,
  ISupportContactsPayload,
  IM2MPayload,
  Team,
  TeamAppAssignmentProblem,
  AppProgressWrapper,
  IM2MProvisionedAppsConfigResult,
  IM2MCloudPayload,
  INetworkStatusSuspension,
  INetworkStatusSuspensionPayload,
  IPlumeCloudAPIAccmgrAccessInfo,
  IPlumeCloudAPIAccmgrAccessPayload,
  INodesClaimableBySharedParentResponse,
  INodesClaimableBySharedParentPayload,
  GetPartnerConfigFeatureFlagsResponse,
  GlobalAuthFeatureFlagsRequest,
  PartnerConfigFeatureFlagsResponse,
  RetrievePartnerConfigGetCallsResponse,
} from 'interfaces/api/portal/company-api'
import { errorsMap } from 'constants/errors'
import { showAlert } from 'utils/show-alert'
import { PlumeProduct } from 'enums/plume-product.enum'
import i18next from 'i18next'
import { getDataUrl, parseJsonSafe } from 'helpers/general-helpers'
import { TeamApplications } from './team/team-constants'
import { applications } from 'constants/user/applications'
import {
  addTeamSuccessEvent,
  editTeamSuccessEvent,
  teamAppsEvent,
  teamErrorEvent,
} from 'constants/websocket-event-identifiers'
import {
  ICRMNotificationConfig,
  ICRMNotificationConfigPayload,
  IIdpInfo,
  ISecondaryCloudSettingsResponse,
  IUpdateIdpPayload,
} from 'interfaces/api/portal/company-api'
import { SecondaryCloud } from 'enums/secondary-cloud.enum'
import { downloadFile } from 'helpers/general-helpers'
import {
  idpErrorEvent,
  idpSettingsSetTeamsSuccessEvent,
} from 'constants/websocket-event-identifiers'
import { SalesforceCloud } from 'constants/salesforce.constants'
import { canAccess } from 'modules/auth/auth-action-permission'

const { Beta, OpenSync, Dogfood, Osync } = SecondaryCloud

type SecondaryCloudUsersProgress = { count: number; total: number }
type SecondaryCloudProgress = Partial<Record<SecondaryCloud, SecondaryCloudUsersProgress>>

export type PlumeCloudGroupsUser = Partial<Record<SalesforceCloud, string>> & {
  email: string
}

const { WorkPass, HomePass } = PlumeProduct

interface EditBCSuccessPayload {
  billingContactName: string
  billingContactEmail: string
  billingContactPhone: string
}

// interface EditGISuccessPayload {
//   companyName: string
//   phoneNumber: string
//   domain: string
// }

const getEmptyCustomization = (): ProductCustomization => {
  return {
    urlPrivacyPolicy: '',
    urlTemsOfUse: '',
    faqEnabled: false,
    urlFaq: '',
    contactUsEnabled: false,
    contactUsEmail: '',
    callUsNumber: '',
    callUsDisplayNumber: '',
    buyPodEnabled: false,
    urlPartnerBuyPod: '',
    addPodEnabled: false,
    removePodEnabled: false,
    multilocationEnabled: false,
    callUsEnabled: false,
    removeGatewayEnabled: false,
    manageYourDataEnabled: false,
    manageYourDataUrl: '',
    autorunSpeedtestToggleEnabled: false,
    manageAccountEnabled: false,
    getPartnerAccountUrl: '',
    usePartnerAccountUrl: true,
    chatSupportEnabled: false,
    chatSupportUrl: '',
    hideIspNameEnabled: false,
    captivePortalDefaultLoginOptions: 'email',
  }
}

export type AccountStoreFields<T> = {
  [K in keyof AccountStore]: T extends AccountStore[K] ? K : never
}[keyof AccountStore]

type KeysOfTypeOmit<T, P, O extends string> = {
  [K in keyof Omit<T, O>]: P extends Omit<T, O>[K] ? K : never
}[keyof Omit<T, O>]
type ASFieldsBooleanOmit = KeysOfTypeOmit<AccountStore, FieldState<boolean>, 'toggleFieldChanged'>

export class AccountStore {
  // if necessary uncomment
  // [index: string]: any

  APP_CUSTOMIZATION_PREVIEW_STEPS = 3
  APP_CUSTOMIZATION_PHONE_NUMBER_MAX = 25

  /* Account company data */

  // public domain = new FieldState('').validators(domain)
  // public companyName = new FieldState('').validators(min3chars, max80chars)
  // public phoneNumber = new FieldState('').validators(phoneNumberHelpDesc, min7chars, max15chars)

  // private companyInfoForm = new FormState({
  //   domain: this.domain,
  //   companyName: this.companyName,
  //   phone: this.phoneNumber,
  // })

  public address = new FieldState('').validators(max50chars)
  public city = new FieldState('').validators(max50chars)
  public state = new FieldState('').validators(max50chars)
  public zip = new FieldState('').validators(max10chars)

  public billingContactName = new FieldState('').validators(required('This field'), max80chars)
  public billingContactEmail = new FieldState('').validators(email, max80chars)
  public billingContactPhone = new FieldState('').validators(
    phoneNumberHelpDesc,
    min7chars,
    max15chars,
  )

  private billingContactForm = new FormState({
    name: this.billingContactName,
    email: this.billingContactEmail,
    phone: this.billingContactPhone,
  })

  public portalLanguage = new FieldState('').validators()

  public releaseNotesContactEmail = new FieldState('').validators(email, max80chars)

  public releaseNotesContactEnabled = new FieldState<boolean>(false).validators()

  public portalNickname = new FieldState('').validators(max20chars)

  /* App customization data */

  @observable
  public selectedProduct: PlumeProduct = null

  public homepassCustomization: ProductCustomization = getEmptyCustomization()
  public workpassCustomization: ProductCustomization = getEmptyCustomization()

  public urlPrivacyPolicy = new FieldState('').validators(required('This field'), url, pdf)
  public urlTemsOfUse = new FieldState('').validators(required('This field'), url, pdf)

  public faqEnabled = new FieldState<boolean>(false).validators()
  public urlFaq = new FieldState('').validators(required('This field'), url, pdf)

  @observable
  public buyPodEnabled = new FieldState<boolean>(false).validators()

  @observable
  public urlPartnerBuyPod = new FieldState('').validators(required('This field'), url, pdf)

  @observable
  public addPodEnabled = new FieldState<boolean>(false).validators()
  public removePodEnabled = new FieldState<boolean>(false).validators()

  public contactUsEnabled = new FieldState<boolean>(false).validators()
  public contactUsEmail = new FieldState('').validators(required('This field'), email)

  public callUsEnabled = new FieldState<boolean>(false).validators()
  public callUsNumber = new FieldState('').validators(
    required('This field'),
    phoneNumberHelpDesc,
    min7chars,
    max15chars,
  )
  public callUsDisplayNumber = new FieldState('').validators(
    required('This field'),
    notDomainOrUrl,
    min7chars,
    max15chars,
  )

  public multilocationEnabled = new FieldState<boolean>(false).validators()
  public privacyEnabled = new FieldState<boolean>(false).validators()
  public termsEnabled = new FieldState<boolean>(false).validators()

  @observable
  public removeGatewayEnabled = new FieldState<boolean>(false).validators()

  @observable
  public manageYourDataEnabled = new FieldState<boolean>(false).validators()

  @observable
  public manageYourDataUrl = new FieldState('').validators(required('This field'), url, pdf)

  @observable
  public autorunSpeedtestToggleEnabled = new FieldState<boolean>(false).validators()

  @observable
  public manageAccountEnabled = new FieldState<boolean>(false).validators()

  @observable
  public getPartnerAccountUrl = new FieldState('').validators(required('This field'), url, pdf)

  @observable
  public usePartnerAccountUrl = new FieldState<boolean>(true).validators()

  @observable
  public chatSupportEnabled = new FieldState<boolean>(false).validators()

  @observable
  public chatSupportUrl = new FieldState('').validators(required('This field'), url, pdf)

  @observable
  public hideIspNameEnabled = new FieldState<boolean>(false).validators()

  @observable
  public captivePortalDefaultLoginOptions = new FieldState('').validators(required('This field'))

  public mobileConfigFormState = new FormState({
    urlPrivacyPolicy: this.urlPrivacyPolicy,
    urlTemsOfUse: this.urlTemsOfUse,
    faqEnabled: this.faqEnabled,
    urlFaq: this.urlFaq,
    buyPodEnabled: this.buyPodEnabled,
    urlPartnerBuyPod: this.urlPartnerBuyPod,
    addPodEnabled: this.addPodEnabled,
    removePodEnabled: this.removePodEnabled,
    contactUsEnabled: this.contactUsEnabled,
    contactUsEmail: this.contactUsEmail,
    callUsEnabled: this.callUsEnabled,
    callUsNumber: this.callUsNumber,
    callUsDisplayNumber: this.callUsDisplayNumber,
    multilocationEnabled: this.multilocationEnabled,
    removeGatewayEnabled: this.removeGatewayEnabled,
    manageYourDataEnabled: this.manageYourDataEnabled,
    manageYourDataUrl: this.manageYourDataUrl,
    autorunSpeedtestToggleEnabled: this.autorunSpeedtestToggleEnabled,
    manageAccountEnabled: this.manageAccountEnabled,
    getPartnerAccountUrl: this.getPartnerAccountUrl,
    usePartnerAccountUrl: this.usePartnerAccountUrl,
    chatSupportEnabled: this.chatSupportEnabled,
    chatSupportUrl: this.chatSupportUrl,
    hideIspNameEnabled: this.hideIspNameEnabled,
    captivePortalDefaultLoginOptions: this.captivePortalDefaultLoginOptions,
  })

  @observable
  public svgFile: File

  @observable
  public pdfFile: File

  @observable
  public isPdfFileValid = true

  @observable
  public svgUrl: string

  @observable
  public pdfUrl: string

  @observable
  error = false

  @observable
  isLoading = false

  @observable
  isLoadingModal = false

  @observable
  public serverError = ''

  @observable
  public isEditModalVisible = false

  @observable
  public svgFileError = ''

  @observable
  public pdfFileError = ''

  @observable
  public isSvgEdited: boolean

  @observable
  public isPdfEdited: boolean

  @observable
  public isSvgDeleted: boolean

  @observable
  public isPdfDeleted: boolean

  @observable
  public isSetupCompleted = true

  private etag: string

  private initialConfigs: { [product: string]: IMobileConfig }

  @observable
  isMobileConfigEdited = false

  @observable
  currentStep = 0

  @observable
  previewOS = 'ios'

  @observable
  isCallUsHovering = false

  @observable
  isDeletingAccount = false

  @observable
  isDeletingMobileAppSSOConfig = false

  @observable
  firstEscalation = new FieldState('').validators(email, max80chars)

  @observable
  secondEscalation = new FieldState('').validators(email, max80chars)

  @observable
  managementEscalation = new FieldState('').validators(email, max80chars)

  @observable
  firstEscalationPhone = new FieldState('').validators(
    phoneNumberHelpDesc,
    makeOptional(min7chars),
    max15chars,
  )

  @observable
  secondEscalationPhone = new FieldState('').validators(
    phoneNumberHelpDesc,
    makeOptional(min7chars),
    max15chars,
  )

  @observable
  managementEscalationPhone = new FieldState('').validators(
    phoneNumberHelpDesc,
    makeOptional(min7chars),
    max15chars,
  )

  @observable
  m2MProvisionedApps: IM2MProvisionedAppsConfigResult

  @observable
  m2mError = ''

  // @observable
  // ssoConfig: any = null

  public updateSupportContactsPhones: () => void = null

  constructor(private readonly appStore: AppStore) {}

  public async initAppCustomization() {
    this.setIsLoadingModal(true)
    this.setError(false)

    try {
      const { partnerId } = this.appStore.authStore.currentUser.company
      const [homepassConfig, workpassConfig] = await this.appStore.companyApi.getMobileConfig(
        partnerId,
      )

      this.setInitalConfigsForVerification(homepassConfig, workpassConfig)
      this.setInitialCustomization(homepassConfig, HomePass)
      this.setInitialCustomization(workpassConfig, WorkPass)
      this.setInitialProduct()
      this.setUrlPartnerBuyPodValidators()

      await this.validateMobileConfigFormState()
      this.setError(false)
    } catch (e) {
      this.setError(true)
      this.removeModal()

      showAlert({
        title: 'errors.somethingWentWrong',
        message: 'errors.errorFetchingMobileConfig',
        buttonText: 'btn.gotIt',
        isCloseShowing: true,
        onAcknowledge: () => this.removeModal(),
      })
    }

    this.setIsLoadingModal(false)
  }

  @action.bound
  setUrlPartnerBuyPodValidators(isURLRequired?: boolean) {
    const { isDTCCompany, partnerOfferedType } = this.appStore.authStore.currentUser.company
    const isPartnerBuyPodUrlOptional = partnerOfferedType === 'Turnkey' || isDTCCompany

    if (isURLRequired || !isPartnerBuyPodUrlOptional || this.urlPartnerBuyPod.value) {
      this.urlPartnerBuyPod.validators(required('This field'), url, pdf)
    } else if (isPartnerBuyPodUrlOptional) {
      this.urlPartnerBuyPod.validators(makeOptional(url), makeOptional(pdf))
    }
  }

  @action.bound
  private setInitalConfigsForVerification(hpConfig: IMobileConfig, wpConfig: IMobileConfig) {
    this.initialConfigs = { [HomePass]: hpConfig, [WorkPass]: wpConfig }
  }

  @action.bound
  private applyProductCustomization(product: PlumeProduct) {
    const selectedCustomization =
      product === WorkPass ? this.workpassCustomization : this.homepassCustomization
    this.urlPrivacyPolicy.onChange(selectedCustomization.urlPrivacyPolicy)
    this.urlTemsOfUse.onChange(selectedCustomization.urlTemsOfUse)
    this.faqEnabled.onChange(selectedCustomization.faqEnabled)
    this.urlFaq.onChange(selectedCustomization.urlFaq)
    this.contactUsEnabled.onChange(selectedCustomization.contactUsEnabled)
    this.contactUsEmail.onChange(selectedCustomization.contactUsEmail)
    this.callUsNumber.onChange(selectedCustomization.callUsNumber)
    this.callUsDisplayNumber.onChange(selectedCustomization.callUsDisplayNumber)
    this.buyPodEnabled.onChange(selectedCustomization.buyPodEnabled)
    this.urlPartnerBuyPod.onChange(selectedCustomization.urlPartnerBuyPod)
    this.addPodEnabled.onChange(selectedCustomization.addPodEnabled)
    this.removePodEnabled.onChange(selectedCustomization.removePodEnabled)
    this.multilocationEnabled.onChange(selectedCustomization.multilocationEnabled)
    this.callUsEnabled.onChange(selectedCustomization.callUsEnabled)

    this.removeGatewayEnabled.onChange(selectedCustomization.removeGatewayEnabled)
    this.manageYourDataEnabled.onChange(selectedCustomization.manageYourDataEnabled)
    this.manageYourDataUrl.onChange(selectedCustomization.manageYourDataUrl)
    this.autorunSpeedtestToggleEnabled.onChange(selectedCustomization.autorunSpeedtestToggleEnabled)
    this.manageAccountEnabled.onChange(selectedCustomization.manageAccountEnabled)
    this.getPartnerAccountUrl.onChange(selectedCustomization.getPartnerAccountUrl)
    this.usePartnerAccountUrl.onChange(selectedCustomization.usePartnerAccountUrl)
    this.chatSupportEnabled.onChange(selectedCustomization.chatSupportEnabled)
    this.chatSupportUrl.onChange(selectedCustomization.chatSupportUrl)
    this.hideIspNameEnabled.onChange(selectedCustomization.hideIspNameEnabled)
    this.captivePortalDefaultLoginOptions.onChange(
      selectedCustomization.captivePortalDefaultLoginOptions,
    )

    this.svgUrl = selectedCustomization.svgUrl
    this.pdfUrl = selectedCustomization.pdfUrl
    this.isSvgEdited = selectedCustomization.isSvgEdited
    this.isPdfEdited = selectedCustomization.isPdfEdited
    this.isSetupCompleted = selectedCustomization.isSetupCompleted
    this.etag = selectedCustomization.etag
    this.svgFile = selectedCustomization.svgFile || null
    this.pdfFile = selectedCustomization.pdfFile || null
    this.validateMobileConfigFormState()
  }

  @action.bound
  switchProduct(product: PlumeProduct) {
    if (product !== this.selectedProduct) {
      this.selectedProduct = product
      this.svgFileError = ''
      this.pdfFileError = ''
      this.applyProductCustomization(product)

      // add svg/pdf base64 handling of switching
      // add pdf handling after cors headers are properly set up and when logo dimensions align
      if (this.customizations[this.selectedProduct].svgFile)
        this.setsvgBase64DataUrl(this.customizations[this.selectedProduct].svgFile)
    }
  }

  @action.bound
  modifyProductCustomization(field: string, value: string | boolean) {
    if (this.selectedProduct === HomePass) {
      this.homepassCustomization = { ...this.homepassCustomization, [field]: value }
    } else if (this.selectedProduct === WorkPass) {
      this.workpassCustomization = { ...this.workpassCustomization, [field]: value }
    }
  }

  @action.bound
  copyCustomization() {
    if (this.selectedProduct === HomePass) {
      const { etag, svgUrl, svgFile, isSvgEdited, pdfFile, pdfUrl, isPdfEdited, ...customization } =
        this.workpassCustomization
      Object.assign(this.homepassCustomization, customization)
    } else {
      const { etag, svgUrl, svgFile, isSvgEdited, pdfFile, pdfUrl, isPdfEdited, ...customization } =
        this.homepassCustomization
      Object.assign(this.workpassCustomization, customization)
    }
    this.setIsMobileConfigEdited(true)
    this.applyProductCustomization(this.selectedProduct)
  }

  @observable
  svgBase64DataUrl: string

  @action.bound
  async setsvgBase64DataUrl(file: File) {
    const base64Image = await getDataUrl(file)
    runInAction(() => (this.svgBase64DataUrl = base64Image))
  }

  @action.bound
  async copyLogo() {
    const source =
      this.selectedProduct === HomePass ? this.workpassCustomization : this.homepassCustomization
    const target =
      this.selectedProduct === HomePass ? this.homepassCustomization : this.workpassCustomization
    if (source.svgUrl && !source.svgFile) {
      const fetchedSvg = source.svgUrl ? await fetch(source.svgUrl) : null
      target.svgFile = new File([await fetchedSvg.blob()], 'svgFile.svg', { type: 'image/svg+xml' })
    } else {
      target.svgFile = source.svgFile || null
    }
    await this.setsvgBase64DataUrl(target.svgFile)
    target.svgUrl = target.svgFile ? '' : source.svgUrl
    target.isSvgEdited = true
    target.pdfFile = source.pdfFile || null
    target.pdfUrl = source.pdfUrl
    target.isPdfEdited = true
    this.applyProductCustomization(this.selectedProduct)
  }

  @action
  private setInitialProduct() {
    const { profiles } = this.appStore.authStore.currentUser.company
    if (profiles?.includes('HomePass')) {
      this.selectedProduct = HomePass
    } else if (profiles?.includes('WorkPass')) {
      this.selectedProduct = WorkPass
    }
    this.applyProductCustomization(this.selectedProduct)
  }

  @action setInitialCustomization(mobileConfig: IMobileConfig, product: PlumeProduct) {
    const customization =
      product === WorkPass ? this.workpassCustomization : this.homepassCustomization

    customization.urlPrivacyPolicy = mobileConfig.privacyLink
    customization.urlTemsOfUse = mobileConfig.termsLink
    customization.faqEnabled = mobileConfig.faqEnabled
    customization.urlFaq = mobileConfig.faqLink
    customization.contactUsEnabled = mobileConfig.contactUsEnabled
    customization.contactUsEmail = mobileConfig.contactUsEmail
    customization.callUsEnabled = mobileConfig.callUsEnabled
    customization.callUsNumber = mobileConfig.callUsNumber
    customization.callUsDisplayNumber = mobileConfig.callUsDisplayNumber
    customization.buyPodEnabled = mobileConfig.buyPodEnabled
    customization.urlPartnerBuyPod = mobileConfig.getPartnerBuyPodUrl
    customization.addPodEnabled = mobileConfig.addPodEnabled
    customization.removePodEnabled = mobileConfig.removePodEnabled
    customization.removeGatewayEnabled = mobileConfig.removeGatewayEnabled
    customization.manageYourDataEnabled = mobileConfig.manageYourDataEnabled
    customization.manageYourDataUrl = mobileConfig.manageYourDataUrl
    customization.hideIspNameEnabled = mobileConfig.hideIspNameEnabled

    if (product === HomePass) {
      customization.multilocationEnabled = mobileConfig.multilocationEnabled
      customization.autorunSpeedtestToggleEnabled = mobileConfig.autorunSpeedtestToggleEnabled
      customization.manageAccountEnabled = mobileConfig.manageAccountEnabled
      customization.getPartnerAccountUrl = mobileConfig.getPartnerAccountUrl
      customization.usePartnerAccountUrl = mobileConfig.usePartnerAccountUrl
      customization.chatSupportEnabled = mobileConfig.chatSupportEnabled
      customization.chatSupportUrl = mobileConfig.chatSupportUrl
    } else if (product === WorkPass) {
      customization.captivePortalDefaultLoginOptions = mobileConfig.captivePortalDefaultLoginOptions
    }

    customization.svgUrl = mobileConfig.files.svgUrl || ''
    customization.pdfUrl = mobileConfig.files.pdfUrl || ''
    customization.isSvgEdited = !!mobileConfig.files.isSvgEdited
    customization.isPdfEdited = !!mobileConfig.files.isPdfEdited

    customization.isSetupCompleted = mobileConfig.isSetupCompleted
    customization.etag = mobileConfig.etag
  }

  @computed
  get customizations() {
    return {
      [HomePass]: this.homepassCustomization,
      [WorkPass]: this.workpassCustomization,
    }
  }

  @action
  public clearFormState() {
    // this.companyName.reset()
    // this.phoneNumber.reset()
    // this.domain.reset()
    this.address.reset()
    this.city.reset()
    this.state.reset()
    this.zip.reset()

    this.clearServerError()
  }

  @action
  public clearMobileConfigFormState() {
    this.pdfFile = null
    this.svgFile = null
    this.urlPrivacyPolicy.reset()
    this.urlTemsOfUse.reset()
    this.faqEnabled.reset()
    this.urlFaq.reset()
    this.buyPodEnabled.reset()
    this.urlPartnerBuyPod.reset()
    this.addPodEnabled.reset()
    this.removePodEnabled.reset()
    this.contactUsEnabled.reset()
    this.contactUsEmail.reset()
    this.callUsEnabled.reset()
    this.callUsNumber.reset()
    this.callUsDisplayNumber.reset()
    this.multilocationEnabled.reset()
    this.removeGatewayEnabled.reset()
    this.manageYourDataEnabled.reset()
    this.manageYourDataUrl.reset()
    this.autorunSpeedtestToggleEnabled.reset()
    this.manageAccountEnabled.reset()
    this.getPartnerAccountUrl.reset()
    this.usePartnerAccountUrl.reset()
    this.chatSupportEnabled.reset()
    this.chatSupportUrl.reset()
    this.hideIspNameEnabled.reset()
    this.captivePortalDefaultLoginOptions.reset()
    this.svgUrl = ''
    this.pdfUrl = ''
    this.svgFileError = ''
    this.pdfFileError = ''
    this.isSvgEdited = false
    this.isPdfEdited = false
    this.setCurrentStep(0)
    this.homepassCustomization = getEmptyCustomization()
    this.workpassCustomization = getEmptyCustomization()

    this.clearServerError()
  }

  @action
  removeAllManageTeamsListeners() {
    this.appStore.appWebSocket.removeAllListeners(teamAppsEvent)
    this.appStore.appWebSocket.removeAllListeners(addTeamSuccessEvent)
    this.appStore.appWebSocket.removeAllListeners(editTeamSuccessEvent)
    this.appStore.appWebSocket.removeAllListeners(teamErrorEvent)
  }

  @action
  public clearManageTeamsFormState() {
    this.clearServerError()
    this.setIsLoadingModal(false)
    this.setIsWaitingTooLong(false)
    this.setLoadingReason('')
    this.removeAllManageTeamsListeners()
    this.removeModal()
  }

  @observable
  teams: Team[] = null

  @action.bound
  setTeams(teams: Team[]) {
    this.setManageTeamCardsOpenArray(Array<boolean>(teams?.length || 0).fill(false))
    this.teams = teams
  }

  @observable
  loadingReason = ''

  @action.bound
  setLoadingReason(reason: string) {
    this.loadingReason = reason
  }

  @observable
  isWaitingTooLong = false

  @action.bound
  setIsWaitingTooLong(isWaitingTooLong: boolean) {
    this.isWaitingTooLong = isWaitingTooLong
  }

  @action
  setupLoadingTooLong(isLoading = true) {
    if (isLoading) {
      const timestamp = `${Date.now()}`
      this.setLoadingReason(timestamp)
      this.setIsLoadingModal(true)
      setTimeout(() => {
        if (this.loadingReason === timestamp && this.isLoadingModal) {
          this.setIsWaitingTooLong(true)
        }
      }, 20 * 1000)
    } else {
      this.setIsLoadingModal(false)
      this.setIsWaitingTooLong(false)
    }
  }

  @action
  initManageTeams() {
    this.removeAllManageTeamsListeners()

    this.appStore.appWebSocket.on(teamAppsEvent, (message: string) => {
      const teamAppWrapper: AppProgressWrapper = parseJsonSafe(message)
      this.handleAddTeamApp(teamAppWrapper)
    })

    this.appStore.appWebSocket.on(addTeamSuccessEvent, (message: string) => {
      const addTeamRes: {
        assignmentDetails: TeamAppAssignmentProblem[]
        team: Team
      } = parseJsonSafe(message)
      this.handleAddTeamSuccess(addTeamRes.assignmentDetails, addTeamRes.team)
    })

    this.appStore.appWebSocket.on(editTeamSuccessEvent, (message: string) => {
      const editTeamRes: {
        assignmentDetails: TeamAppAssignmentProblem[]
        team: Team & { oldName: string }
      } = parseJsonSafe(message)
      this.handleEditTeamSuccess(editTeamRes.assignmentDetails, editTeamRes.team)
    })

    this.appStore.appWebSocket.on(teamErrorEvent, (errorMessage: string) => {
      this.handleServerError(errorMessage)
      showAlert({
        title: 'errors.error',
        message: errorMessage,
      })
    })
    this.getTeams()
  }

  @action
  public async getTeams() {
    this.setIsLoadingModal(true)

    const teams = await this.appStore.companyApi.getTeams(
      this.appStore.authStore.currentUser.company.partnerId,
    )
    this.setTeams(teams)
    this.setIsLoadingModal(false)
  }

  @observable
  manageTeamCardsOpenArray: boolean[] = []

  @action.bound
  setManageTeamCardsOpenArray(cardsOpen: boolean[]) {
    this.manageTeamCardsOpenArray = cardsOpen
  }

  @action
  public async getTeamApps(teamGroupId: string, teamName: string) {
    const teamsVar = toJS(this.teams)
    const selectedTeam = teamsVar.findIndex(t => t.name === teamName)
    if (this.manageTeamCardsOpenArray[selectedTeam]) {
      const teamCardArray = Array<boolean>(teamsVar?.length || 0).fill(false)
      this.setManageTeamCardsOpenArray(teamCardArray)
    } else {
      this.setupLoadingTooLong()

      teamsVar[selectedTeam].apps = []
      this.setTeams(teamsVar)

      await this.appStore.companyApi.getTeamApps(
        this.appStore.authStore.currentUser.company.partnerId,
        teamGroupId,
        this.appStore.appWebSocket?.id,
      )
    }
  }

  handleAddTeamApp(teamAppWrapper: AppProgressWrapper) {
    const progressArray = teamAppWrapper.progress.split('/')
    const progress = {
      count: Number.parseInt(progressArray[0]),
      total: Number.parseInt(progressArray[1]),
    }

    const teamsVar = toJS(this.teams)
    const selectedTeamIndex = teamsVar.findIndex(t => t.name === teamAppWrapper.teamName)
    if (selectedTeamIndex === -1) {
      this.clearManageTeamsFormState()
      return showAlert({
        title: i18next.t('teams.teamNameInvalid'),
        message: i18next.t('teams.mayHaveBeenRenamed'),
      })
    }
    if (progress.total !== 0) {
      teamsVar[selectedTeamIndex].apps.push(teamAppWrapper.app)
    }
    this.setTeams(teamsVar)
    if (progress.count === progress.total) {
      const teamCardArray = Array<boolean>(teamsVar?.length || 0).fill(false)
      teamCardArray[selectedTeamIndex] = true
      this.setManageTeamCardsOpenArray(teamCardArray)
      this.setupLoadingTooLong(false)
    }
  }

  @action
  public async validateAndSubmitAddTeam(
    teamApps: TeamApplications,
    teamName: string,
    teamDescription: string,
  ) {
    this.setupLoadingTooLong()
    const filtered: TeamApplications = {}
    for (const appId in teamApps) {
      if (teamApps[appId].selected) {
        filtered[appId] = teamApps[appId]
      }
    }
    await this.appStore.companyApi.addTeam(
      this.appStore.authStore.currentUser.company.partnerId,
      teamName,
      filtered,
      teamDescription,
      this.appStore.appWebSocket?.id,
    )
  }

  @action
  handleAddTeamSuccess(assignmentResult: TeamAppAssignmentProblem[], team: Team) {
    if (assignmentResult?.length) {
      showAlert({
        title: 'teams.appAssignmentDetails',
        message: assignmentResult
          .map(r => `${applications.find(a => a.id === r.appId)?.name} - ${r.reason}`)
          .join('\n'),
        onAcknowledge: async () => {
          this.clearManageTeamsFormState()
          this.clearManageTeamsFormState()
        },
      })
    } else {
      const teamsVar = toJS(this.teams)
      teamsVar.push(team)
      this.setTeams(teamsVar)
      this.removeModal()
      this.setIsWaitingTooLong(false)
    }
  }

  @action
  public async validateAndSubmitEditTeam(
    teamApps: TeamApplications,
    newTeamName: string,
    originalTeamName: string,
    teamGroupId: string,
    teamDescription: string,
  ) {
    this.setupLoadingTooLong()
    const filtered: TeamApplications = {}
    for (const appId in teamApps) {
      if (teamApps[appId].selected === true || teamApps[appId].selected === false) {
        filtered[appId] = teamApps[appId]
      }
    }

    await this.appStore.companyApi.editTeam(
      this.appStore.authStore.currentUser.company.partnerId,
      newTeamName,
      filtered,
      originalTeamName,
      teamGroupId,
      teamDescription,
      this.appStore.appWebSocket?.id,
    )
  }

  @action
  handleEditTeamSuccess(
    assignmentResult: TeamAppAssignmentProblem[],
    team: Team & { oldName: string },
  ) {
    for (let i = 0; i < this.appStore.usersStore.users.length; i += 1) {
      if (this.appStore.usersStore.users[i].team === team.oldName) {
        this.appStore.usersStore.users[i].team = team.name
      }
    }
    if (assignmentResult?.length) {
      showAlert({
        title: 'teams.appAssignmentDetails',
        message: assignmentResult
          .map(r => `${applications.find(a => a.id === r.appId)?.name} - ${r.reason}`)
          .join('\n'),
        onAcknowledge: async () => this.clearManageTeamsFormState(),
      })
    } else {
      const teamsVar = toJS(this.teams)
      const updatedTeamIndex = teamsVar.findIndex(t => t.groupId === team.groupId)
      teamsVar[updatedTeamIndex] = team
      this.setTeams(teamsVar)
      this.setupLoadingTooLong(false)
    }
  }

  @action
  public async deleteTeam(teamGroupId: string, teamName: string) {
    this.isLoadingModal = true
    try {
      await this.appStore.companyApi.deleteTeam(
        this.appStore.authStore.currentUser.company.partnerId,
        teamGroupId,
      )
      this.handleDeleteTeamSuccess(teamName)
    } catch (e) {
      this.handleServerError(e)
      showAlert({
        title: 'errors.error',
        message: e,
      })
    }
  }

  @action
  handleDeleteTeamSuccess(teamName: string) {
    for (let i = 0; i < this.appStore.usersStore.users.length; i += 1) {
      if (this.appStore.usersStore.users[i].team === teamName) {
        this.appStore.usersStore.users[i].team = undefined
      }
    }
    showAlert({
      title: i18next.t('teams.deleteTeamSuccessfulMessage', { teamName }),
      message: '',
    })

    const teamsVar = toJS(this.teams)
    const teamIndex = teamsVar?.findIndex(d => d.name === teamName)
    if (teamIndex !== -1) {
      teamsVar?.splice(teamIndex, 1)
    }
    this.setTeams(teamsVar)
    this.setIsLoadingModal(false)
  }

  // @action
  // public async prepareEditGI() {
  //   this.companyName.value = this.appStore.authStore.currentUser.company.name
  //   this.phoneNumber.value = this.appStore.authStore.currentUser.company.phone
  //   this.domain.value = this.appStore.authStore.currentUser.company.domain
  // }

  // @action
  // public async validateAndSubmitGeneralInfo() {
  //   await this.companyInfoForm.validate()

  //   if (!this.companyInfoForm.hasError) {
  //     this.clearServerError()
  //     await this.submitGeneralInfo()
  //   }
  // }

  // @action
  // private async submitGeneralInfo() {
  //   this.isLoadingModal = true
  //   const payload = {
  //     companyName: this.companyName.value,
  //     phoneNumber: this.phoneNumber.value,
  //     domain: this.domain.value,
  //   }

  //   try {
  //     await this.appStore.companyApi.updateGeneralInfo(
  //       this.appStore.authStore.currentUser.company.partnerId,
  //       payload,
  //     )
  //     this.handleEditGISuccess(payload)
  //   } catch (e) {
  //     this.handleServerError(e.message)
  //   }
  // }

  // @action
  // async handleEditGISuccess(payload: EditGISuccessPayload) {
  //   await this.appStore.authStore.update(this.appStore.authStore.currentUser.company.partnerId)
  //   this.setGeneralInfo(payload)
  //   this.removeModal()
  // }

  // @action
  // private setGeneralInfo(payload: EditGISuccessPayload) {
  //   this.appStore.authStore.patchCurrentCompanyProperties({
  //     name: payload.companyName,
  //     phone: payload.phoneNumber,
  //     domain: payload.domain,
  //   })
  // }

  @action
  public async prepareEditBC() {
    const { billingContactName, billingContactEmail, billingContactPhone } =
      this.appStore.authStore.currentUser.company

    this.billingContactName.value = billingContactName
    this.billingContactEmail.value = billingContactEmail
    this.billingContactPhone.value = billingContactPhone

    this.billingContactName.validate()
    this.billingContactEmail.validate()
    this.billingContactPhone.validate()
  }

  @action
  public async validateAndSubmitBillingContact() {
    await this.billingContactForm.validate()

    if (!this.billingContactForm.hasError) {
      this.clearServerError()
      await this.submitBillingContact()
    }
  }

  @action
  private async submitBillingContact() {
    this.isLoadingModal = true
    const payload = {
      billingContactName: this.billingContactName.value,
      billingContactEmail: this.billingContactEmail.value,
      billingContactPhone: this.billingContactPhone.value,
    }

    try {
      await this.appStore.companyApi.updateBillingContact(
        this.appStore.authStore.currentUser.company.partnerId,
        payload,
      )
      this.handleEditBCSuccess(payload)
    } catch (e) {
      this.handleServerError(e.message)
    }
  }

  @action
  async handleEditBCSuccess(payload: EditBCSuccessPayload) {
    await this.appStore.authStore.update(this.appStore.authStore.currentUser.company.partnerId)
    this.setBillingContact(payload)
    this.removeModal()
  }

  @action
  private setBillingContact(payload: EditBCSuccessPayload) {
    this.appStore.authStore.patchCurrentCompanyProperties({
      billingContactName: payload.billingContactName,
      billingContactEmail: payload.billingContactEmail,
      billingContactPhone: payload.billingContactPhone,
    })
  }

  @action
  public async prepareEditPL() {
    const { company } = this.appStore.authStore.currentUser
    this.portalLanguage.value = company.portalLanguage || 'English'
  }

  @action
  public async validateAndSubmitPortalLanguage() {
    await this.portalLanguage.validate()

    if (!this.portalLanguage.hasError) {
      this.clearServerError()
      await this.submitPortalLanguage()
    }
  }

  @action
  public async validateAndSubmitPortalConfiguration() {
    this.validateAndSubmitPortalLanguage()
    this.validateAndSubmitPortalNickname()
  }

  @action
  private async submitPortalLanguage() {
    this.isLoadingModal = true
    const payload = {
      portalLanguage: this.portalLanguage.value,
    }

    try {
      await this.appStore.companyApi.updatePortalLanguage(
        this.appStore.authStore.currentUser.company.partnerId,
        payload,
      )
      this.handleEditPLSuccess(payload.portalLanguage)
    } catch (e) {
      this.handleServerError(e.message)
    }
  }

  @action
  public async handleEditPLSuccess(portalLanguage: string) {
    await this.appStore.authStore.update(this.appStore.authStore.currentUser.company.partnerId)
    this.setPortalLanguage(portalLanguage)
    this.removeModal()
  }

  @action
  private setPortalLanguage(portalLanguage: string) {
    this.appStore.authStore.patchCurrentCompanyProperties({ portalLanguage })
  }

  @action
  forceDeleteAccount = async () => {
    this.setIsDeletingAccount(true)
    const { partnerId } = this.appStore.authStore.currentUser.company
    await this.appStore.companyApi.forceDeleteAccount(partnerId)
    this.appStore.authStore.removeSelectedEmployeeCompany()
    this.setIsDeletingAccount(false)
  }

  @action
  private setIsDeletingAccount = (value: boolean) => {
    this.isDeletingAccount = value
  }

  @action
  deleteMobileAppSSOConfig = async () => {
    this.setIsDeletingMobileAppSSOConfig(true)
    const { partnerId } = this.appStore.authStore.currentUser.company
    await this.appStore.companyApi.deleteSSOConfig(partnerId)
    this.setIsDeletingMobileAppSSOConfig(false)
  }

  @action
  private setIsDeletingMobileAppSSOConfig = (value: boolean) => {
    this.isDeletingMobileAppSSOConfig = value
  }

  @action
  public async prepareEditRNC() {
    this.clearServerError()
    const { company } = this.appStore.authStore.currentUser
    this.releaseNotesContactEmail.value = company.releaseNotesContactEmail || ''
    this.releaseNotesContactEnabled.value = company.releaseNotesContactEnabled
  }

  @action
  async submitReleaseNotesContactEmail() {
    this.isLoadingModal = true
    const payload = {
      releaseNotesContactEmail: this.releaseNotesContactEmail.value,
      releaseNotesContactEnabled: this.releaseNotesContactEnabled.value,
    }
    try {
      await this.appStore.companyApi.updateReleaseNotesContactEmail(
        this.appStore.authStore.currentUser.company.partnerId,
        payload,
      )
      this.handleEditRNCSuccess()
    } catch (e) {
      const error = errorsMap[e] || e.message || e
      this.handleServerError(error)
    }
  }

  @action
  public async handleEditRNCSuccess() {
    const { company } = this.appStore.authStore.currentUser
    const refreshedCompany = await this.appStore.companyApi.getCompanyByPartnerId(company.partnerId)
    this.setReleaseNotesContactEmail(refreshedCompany)
    this.setIsLoadingModal(false)
    this.removeModal()
  }

  @action
  private setReleaseNotesContactEmail(refreshedCompany: ICompany) {
    this.appStore.authStore.patchCurrentCompanyProperties({
      releaseNotesContactEmail: refreshedCompany.releaseNotesContactEmail,
      releaseNotesContactEnabled: refreshedCompany.releaseNotesContactEnabled,
      releaseNotesContactLastName: refreshedCompany.releaseNotesContactLastName,
    })
  }

  @action
  clearRNCFormState() {
    this.releaseNotesContactEmail.reset()
    this.releaseNotesContactEnabled.reset()
    this.clearServerError()
  }

  @action
  initSupportContacts() {
    const { company } = this.appStore.authStore.currentUser
    this.firstEscalation.reset(company.firstEscalationEmail)
    this.secondEscalation.reset(company.secondEscalationEmail)
    this.managementEscalation.reset(company.managementEscalationEmail)
    this.firstEscalationPhone.reset(company.firstEscalationPhone)
    this.secondEscalationPhone.reset(company.secondEscalationPhone)
    this.managementEscalationPhone.reset(company.managementEscalationPhone)
  }

  @action
  async submitSupportContacts() {
    this.isLoadingModal = true
    try {
      const payload: ISupportContactsPayload = {
        firstEscalationEmail: this.firstEscalation.value,
        firstEscalationPhone: this.firstEscalationPhone.value,
        secondEscalationEmail: this.secondEscalation.value,
        secondEscalationPhone: this.secondEscalationPhone.value,
        managementEscalationEmail: this.managementEscalation.value,
        managementEscalationPhone: this.managementEscalationPhone.value,
      }
      await this.appStore.companyApi.setSupportContacts(
        this.appStore.authStore.currentUser.company.partnerId,
        payload,
      )
      this.handleEditSCSuccess(payload)
    } catch (e) {
      const error = e.message || e
      this.handleServerError(error)
    }
  }

  @action
  public async handleEditSCSuccess(payload: ISupportContactsPayload) {
    this.appStore.authStore.patchCurrentCompanyProperties({
      firstEscalationEmail: payload.firstEscalationEmail,
      firstEscalationPhone: payload.firstEscalationPhone,
      secondEscalationEmail: payload.secondEscalationEmail,
      secondEscalationPhone: payload.secondEscalationPhone,
      managementEscalationEmail: payload.managementEscalationEmail,
      managementEscalationPhone: payload.managementEscalationPhone,
    })
    this.updateSupportContactsPhones && this.updateSupportContactsPhones()
    this.setIsLoadingModal(false)
    this.removeModal()
  }

  @action
  public clearServerError() {
    this.serverError = ''
  }

  @action.bound
  private async removeModal() {
    this.appStore.actionModalStore.removeModal()
    this.clearFormState()
    this.clearMobileConfigFormState()
    this.updateSupportContactsPhones = null
    this.setIsLoadingModal(false)
  }

  @action.bound
  private handleServerError(error: string) {
    this.serverError = errorsMap[error] || error
    this.isLoadingModal = false
    this.isLoading = false
    this.setCurrentStep(0)
    this.setIsMobileConfigEdited(false)
  }

  @action
  private setIsLoadingModal = (value: boolean) => {
    this.isLoadingModal = value
  }

  @action
  private setError = (value: boolean) => {
    this.error = value
  }

  @action
  public toggleFieldChanged = (field: ASFieldsBooleanOmit) => {
    this[field].onChange(!this[field].value)
  }

  @action
  public async validateMobileConfigFormState() {
    await this.mobileConfigFormState.$.urlPrivacyPolicy.validate()
    await this.mobileConfigFormState.$.urlTemsOfUse.validate()

    if (this.faqEnabled.value) await this.mobileConfigFormState.$.urlFaq.validate()
    else {
      this.urlFaq.reset()
      this.modifyProductCustomization('urlFaq', '')
    }

    if (this.buyPodEnabled.value) await this.mobileConfigFormState.$.urlPartnerBuyPod.validate()
    else {
      this.urlPartnerBuyPod.reset()
      this.modifyProductCustomization('urlPartnerBuyPod', '')
    }

    if (this.contactUsEnabled.value) await this.mobileConfigFormState.$.contactUsEmail.validate()
    else {
      this.contactUsEmail.reset()
      this.modifyProductCustomization('contactUsEmail', '')
    }

    if (this.callUsEnabled.value) {
      await this.mobileConfigFormState.$.callUsNumber.validate()
      await this.mobileConfigFormState.$.callUsDisplayNumber.validate()
    } else {
      this.callUsNumber.reset()
      this.callUsDisplayNumber.reset()
      this.modifyProductCustomization('callUsNumber', '')
      this.modifyProductCustomization('callUsDisplayNumber', '')
    }

    if (this.manageYourDataEnabled.value)
      await this.mobileConfigFormState.$.manageYourDataUrl.validate()
    else {
      this.manageYourDataUrl.reset()
      this.modifyProductCustomization('manageYourDataUrl', '')
    }

    if (this.manageAccountEnabled.value && this.usePartnerAccountUrl.value)
      await this.mobileConfigFormState.$.getPartnerAccountUrl.validate()
    else {
      this.getPartnerAccountUrl.reset()
      this.modifyProductCustomization('getPartnerAccountUrl', '')
    }

    if (this.chatSupportEnabled.value) await this.mobileConfigFormState.$.chatSupportUrl.validate()
    else {
      this.chatSupportUrl.reset()
      this.modifyProductCustomization('chatSupportUrl', '')
    }
  }

  @action
  public async validateAndSubmitAppCustomization() {
    await this.validateMobileConfigFormState()
    if (!this.mobileConfigFormState.hasError) {
      this.clearServerError()
      await this.submitAppCustomization()
    } else {
      this.setCurrentStep(0)
    }
  }

  @action
  private async submitAppCustomization() {
    const isPartnerBuyPodUrlOptional =
      this.appStore.authStore.currentUser.company.partnerOfferedType === 'Turnkey' ||
      this.appStore.authStore.currentUser.company.isDTCCompany
    this.isLoadingModal = true
    let payload: Record<string, string | boolean | IMobileConfig> = {
      privacyLink: this.urlPrivacyPolicy.value,
      termsLink: this.urlTemsOfUse.value,
      faqEnabled: this.faqEnabled.value,
      faqLink: this.urlFaq.value,
      contactUsEnabled: this.contactUsEnabled.value,
      contactUsEmail: this.contactUsEmail.value,
      callUsEnabled: this.callUsEnabled.value,
      callUsNumber: this.callUsNumber.value,
      callUsDisplayNumber: this.callUsDisplayNumber.value,
      buyPodEnabled: this.buyPodEnabled.value,
      getPartnerBuyPodUrl:
        this.urlPartnerBuyPod.value ||
        (isPartnerBuyPodUrlOptional ? null : this.urlPartnerBuyPod.value),
      usePartnerBuyPodUrl: !!this.urlPartnerBuyPod.value,
      addPodEnabled: this.addPodEnabled.value,
      removePodEnabled: this.removePodEnabled.value,
      removeGatewayEnabled: this.removeGatewayEnabled.value,
      manageYourDataEnabled: this.manageYourDataEnabled.value,
      manageYourDataUrl: this.manageYourDataUrl.value,
      hideIspNameEnabled: this.hideIspNameEnabled.value,

      etag: this.etag,
      initialMobileConfig: this.initialConfigs[this.selectedProduct],
      product: this.selectedProduct,
    }
    if (this.selectedProduct === HomePass) {
      payload = {
        ...payload,
        multilocationEnabled: this.multilocationEnabled.value,
        autorunSpeedtestToggleEnabled: this.autorunSpeedtestToggleEnabled.value,
        manageAccountEnabled: this.manageAccountEnabled.value,
        getPartnerAccountUrl: this.getPartnerAccountUrl.value || null,
        usePartnerAccountUrl: this.usePartnerAccountUrl.value,
        chatSupportEnabled: this.chatSupportEnabled.value,
        chatSupportUrl: this.chatSupportUrl.value,
      }
    } else if (this.selectedProduct === WorkPass) {
      payload = {
        ...payload,
        captivePortalDefaultLoginOptions: this.captivePortalDefaultLoginOptions.value,
      }
    }

    try {
      const mcResult = await this.appStore.companyApi.updateMobileConfig(
        this.appStore.authStore.currentUser.company.partnerId,
        payload,
      )

      const profiles = this.appStore.authStore.currentUser.company.profiles
      if (profiles.length === 2) {
        showAlert({
          title: i18next.t('appCustomization.submissionSuccessful', {
            product: i18next.t(`common.${mcResult.product}`),
          }),
          message: 'appCustomization.returnToAppCustomization',
          buttonText: 'btn.return',
          isCloseShowing: true,
          onAcknowledge: () => this.returnToAppCustomization(mcResult),
          onClose: () => this.handleAppCustomizationSuccess(),
        })
      } else {
        this.handleAppCustomizationSuccess(mcResult.product)
      }
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action
  async returnToAppCustomization(mcResult?: IAppCustomizationUpdateResponse) {
    this.initialConfigs = { ...this.initialConfigs, [mcResult.product]: mcResult.mobileConfig }
    this.customizations[this.selectedProduct].etag = mcResult.mobileConfig.etag
    this.etag = mcResult.mobileConfig.etag
    this.currentStep = 0
    this.isLoadingModal = false
  }

  @action
  async handleAppCustomizationSuccess(_product?: PlumeProduct) {
    this.isSetupCompleted = true
    this.currentStep = 0
    this.removeModal()
    this.setIsMobileConfigEdited(false)
  }

  @action
  async submitAccMgrLogoUpload() {
    this.isLoadingModal = true
    const payload = {
      isSvgEdited: this.isSvgEdited,
      isPdfEdited: this.isPdfEdited,
      filesDeleted: this.isPdfDeleted && this.isSvgDeleted,
    }

    try {
      const formData = new FormData()
      formData.append('svg', this.svgFile)
      formData.append('pdf', this.pdfFile)
      formData.append('data', JSON.stringify(payload))
      formData.append('product', this.selectedProduct)

      await this.appStore.companyApi.uploadAccMgrAppLogo(
        this.appStore.authStore.currentUser.company.partnerId,
        formData,
      )
      this.handleAppCustomizationSuccess()
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action
  async setSvgFile(file: File) {
    this.clearSvgFileError()
    this.svgFile = file
    this.customizations[this.selectedProduct].svgFile = file
    this.isSvgEdited = true
    this.customizations[this.selectedProduct].isSvgEdited = true
  }

  @action
  async setPdfFile(file: File) {
    this.clearPdfFileError()
    this.pdfFile = file
    this.customizations[this.selectedProduct].pdfFile = file
    this.isPdfEdited = true
    this.customizations[this.selectedProduct].isPdfEdited = true
  }

  @action
  async setIsPdfFileValid(value: boolean) {
    this.isPdfFileValid = value
    if (value) this.clearPdfFileError()
  }

  @action
  removePdf() {
    this.pdfFile = null
    this.customizations[this.selectedProduct].pdfFile = null
    this.clearPdfFileError()
    this.pdfUrl = ''
    this.isPdfEdited = true
  }

  @action
  removeSvg() {
    this.svgFile = null
    this.customizations[this.selectedProduct].svgFile = null
    this.clearSvgFileError()
    this.svgUrl = ''
    this.isSvgEdited = true
  }

  @action
  public setSvgFileValidationError(error: string) {
    this.svgFileError = error
  }

  @action
  public setPdfFileValidationError(error: string) {
    this.pdfFileError = error
  }

  @action
  private clearSvgFileError() {
    this.svgFileError = ''
  }

  @action
  private clearPdfFileError() {
    this.pdfFileError = ''
  }

  @action
  setCurrentStep(newStep: number) {
    this.currentStep = newStep
  }

  @action
  setPrevStep() {
    this.currentStep = this.currentStep - 1
  }
  @action
  setNextStep() {
    if (this.currentStep < this.APP_CUSTOMIZATION_PREVIEW_STEPS) {
      this.currentStep = this.currentStep + 1
    }
  }

  @action
  setOsPreview(os: string) {
    this.previewOS = os
  }

  @action
  toggleCallUsHover() {
    this.isCallUsHovering = !this.isCallUsHovering
  }

  @action
  setIsMobileConfigEdited(value: boolean) {
    this.isMobileConfigEdited = value
  }

  @action
  setIsSVGDeleted(value: boolean) {
    this.isSvgDeleted = value
  }

  @action
  setIsPDFDeleted(value: boolean) {
    this.isPdfDeleted = value
  }

  @action
  public async getM2MProvisionedApps() {
    this.m2MProvisionedApps = null
    const { company } = this.appStore.authStore.currentUser

    if (canAccess('viewM2MTokens', this.appStore.authStore.currentUser)) {
      try {
        const provisionedApps = await this.appStore.companyApi.getM2MProvisionedApps(
          company.partnerId,
        )

        runInAction(() => {
          this.m2MProvisionedApps = provisionedApps
        })
      } catch (e) {
        if (e === 'No m2m group access') {
          runInAction(() => {
            this.m2mError = 'noM2MGroupAccess'
          })
        }
      }
    }
  }

  @action
  async addM2MProvisionedApp(payload: IM2MPayload) {
    this.isLoading = true
    const { company } = this.appStore.authStore.currentUser

    try {
      const newApp = await this.appStore.companyApi.addM2MProvisionedApp(company.partnerId, payload)
      await this.getM2MProvisionedApps()
      this.handleM2MSuccess()
      return newApp
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action
  async resetM2MSecret(appId: string, payload: IM2MCloudPayload) {
    this.isLoading = true
    const { company } = this.appStore.authStore.currentUser

    try {
      const resetApp = await this.appStore.companyApi.resetM2MSecret(
        company.partnerId,
        appId,
        payload,
      )
      this.handleM2MSuccess()
      return resetApp
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action
  async deleteM2MProvisionedApp(appId: string, payload: IM2MCloudPayload) {
    this.isLoading = true
    const { company } = this.appStore.authStore.currentUser

    try {
      await this.appStore.companyApi.deleteM2MProvisionedApp(company.partnerId, appId, payload)
      this.getM2MProvisionedApps()
      this.handleM2MDeleteSuccess()
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action
  private handleM2MSuccess() {
    this.isLoading = false
  }

  @action
  private handleM2MDeleteSuccess() {
    this.isLoading = false
    this.removeModal()
  }

  // @action
  // public async getSSOConfig() {
  //   this.ssoConfig = null
  //   const { company, portalRole } = this.appStore.authStore.currentUser

  //   if (['accountmgr', 'superadmin'].includes(portalRole)) {
  //     const ssoConfig = await this.appStore.companyApi.getSSOConfig(company.partnerId)

  //     runInAction(() => {
  //       this.ssoConfig = ssoConfig
  //     })
  //   }
  // }

  @computed
  get isAppCustomizationSubmitDisabled() {
    return (
      this.isLoadingModal ||
      !this.isMobileConfigEdited ||
      !!this.svgFileError ||
      !!this.pdfFileError ||
      this.isImageUploadRequired
    )
  }

  @computed
  get svgFileExists() {
    return !!this.svgFile || !!this.svgUrl
  }

  @computed
  get pdfFileExists() {
    return !!this.pdfFile || !!this.pdfUrl
  }

  @computed
  get isImageUploadRequired() {
    return (
      (this.svgFileExists && !this.pdfFileExists) || (this.pdfFileExists && !this.svgFileExists)
    )
  }

  @action
  public async prepareEditPN() {
    const { company } = this.appStore.authStore.currentUser
    this.portalNickname.value = company.portalNickname
  }

  @action
  public async validateAndSubmitPortalNickname() {
    await this.portalNickname.validate()

    if (!this.portalNickname.hasError) {
      this.clearServerError()
      await this.submitPortalNickname()
    }
  }

  @action
  private async submitPortalNickname() {
    this.isLoadingModal = true
    const payload = {
      portalNickname: this.portalNickname.value,
    }

    try {
      await this.appStore.companyApi.updatePortalNickname(
        this.appStore.authStore.currentUser.company.partnerId,
        payload,
      )
      this.handleEditPNSuccess(payload.portalNickname)
    } catch (e) {
      this.handleServerError(e.message)
    }
  }

  @action
  public async handleEditPNSuccess(portalNickname: string) {
    await this.appStore.authStore.update(this.appStore.authStore.currentUser.company.partnerId)
    this.setPortalNickname(portalNickname)
    this.removeModal()
  }

  @action
  private setPortalNickname(portalNickname: string) {
    this.appStore.authStore.patchCurrentCompanyProperties({ portalNickname })
    this.appStore.usersStore.refreshCurrentUserApps()
  }

  @observable
  public networkStatusSuspensionConfig: INetworkStatusSuspension = null

  @action
  setNetworkStatusSuspensionConfig(nssc: INetworkStatusSuspension) {
    this.networkStatusSuspensionConfig = nssc
  }

  @observable
  public nssLoadingError = false

  @action
  setNssLoadingError(nssle: boolean) {
    this.nssLoadingError = nssle
  }

  @action
  async getNetworkStatusSuspension() {
    this.networkStatusSuspensionConfig = null
    const { company } = this.appStore.authStore.currentUser
    try {
      const nssc = await this.appStore.companyApi.getNetworkStatusSuspension(company.partnerId)
      this.setNetworkStatusSuspensionConfig(nssc)
    } catch (e) {
      this.setNssLoadingError(true)
    }
  }

  @action
  async submitNetworkStatusSuspension(payload: INetworkStatusSuspensionPayload) {
    this.isLoadingModal = true

    try {
      const updatedNssc = await this.appStore.companyApi.updateNetworkStatusSuspension(
        this.appStore.authStore.currentUser.company.partnerId,
        payload,
      )
      this.handleEditNSSSuccess(updatedNssc)
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action
  public async handleEditNSSSuccess(nssc: INetworkStatusSuspension) {
    this.setNetworkStatusSuspensionConfig(nssc)
    this.removeModal()
  }

  @observable
  nodeInfo: { id: string; partnerId: string } = null

  @action.bound
  setNodeInfo(nodeInfo: { id: string; partnerId: string }) {
    this.nodeInfo = nodeInfo
  }

  @observable
  nodeError: string = null

  @action.bound
  setNodeError(nodeError: string) {
    this.nodeError = nodeError
  }

  @action
  async getNodeInfo(nodeId: string) {
    const { company, isEmployee } = this.appStore.authStore.currentUser
    this.clearNodeInfoFormState()
    this.setIsLoadingModal(true)
    const nodeInfoResponse = isEmployee
      ? await this.appStore.portalInventoryApi.getInventoryNodeInfo(nodeId)
      : await this.appStore.companyApi.getNodeInfo(company.partnerId, nodeId)
    if (nodeInfoResponse.nodeInfo) {
      this.setNodeInfo(nodeInfoResponse.nodeInfo)
    } else {
      this.setNodeError(nodeInfoResponse.errorKey)
    }
    this.setIsLoadingModal(false)
  }

  @action
  clearNodeInfoFormState() {
    this.setIsLoadingModal(false)
    this.clearServerError()
    this.setNodeError('')
    this.setNodeInfo(null)
  }

  @observable
  plumeCloudAPIAccmgrAccessInfo: IPlumeCloudAPIAccmgrAccessInfo = null

  @action
  setPlumeCloudAPIAccmgrAccessInfo(plumeCloudAPIAccmgrAccessInfo: IPlumeCloudAPIAccmgrAccessInfo) {
    this.plumeCloudAPIAccmgrAccessInfo = plumeCloudAPIAccmgrAccessInfo
  }

  @observable
  public pcaamaLoadingError = false

  @action
  setPcaamaLoadingError(pcaama: boolean) {
    this.pcaamaLoadingError = pcaama
  }

  @action
  async getPlumeCloudAPIAccmgrAccessInfo() {
    const { company } = this.appStore.authStore.currentUser
    try {
      const plumeCloudAPIAccmgrAccessInfo =
        await this.appStore.companyApi.getPlumeCloudAPIAccmgrAccessInfo(company.partnerId)
      this.setPlumeCloudAPIAccmgrAccessInfo(plumeCloudAPIAccmgrAccessInfo)
    } catch (e) {
      this.setPcaamaLoadingError(true)
    }
  }

  @action
  async submitPlumeCloudAPIAccmgrAccess(payload: IPlumeCloudAPIAccmgrAccessPayload) {
    this.isLoadingModal = true

    try {
      const updatedPCAAMA = await this.appStore.companyApi.updatePlumeCloudAPIAccmgrAccess(
        this.appStore.authStore.currentUser.company.partnerId,
        payload,
      )
      this.handleEditPCAAMASuccess(updatedPCAAMA)
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action
  public async handleEditPCAAMASuccess(pcaama: IPlumeCloudAPIAccmgrAccessInfo) {
    this.setPlumeCloudAPIAccmgrAccessInfo(pcaama)
    this.removeModal()
  }

  @observable
  nodesClaimableByLocationsWithSharedParentInfo: INodesClaimableBySharedParentResponse = null

  @action
  setNodesClaimableByLocationsWithSharedParentInfo(nclsp: INodesClaimableBySharedParentResponse) {
    this.nodesClaimableByLocationsWithSharedParentInfo = nclsp
  }

  @observable
  public nclspLoadingError = false

  @action
  setNclspLoadingError(nclspLoadingError: boolean) {
    this.nclspLoadingError = nclspLoadingError
  }

  @action
  public async getNodesClaimableByLocationsWithSharedParentInfo() {
    if (!this.appStore.authStore.currentUser.isEmployee) {
      return
    }
    const { company } = this.appStore.authStore.currentUser
    try {
      const nodesClaimableByParentInfo =
        await this.appStore.companyApi.getNodesClaimableByLocationsSharedParentInfo(
          company.partnerId,
        )
      this.setNodesClaimableByLocationsWithSharedParentInfo(nodesClaimableByParentInfo)
    } catch (e) {
      this.setNclspLoadingError(true)
    }
  }

  @action
  async submitNodesClaimableByLocationsWithSharedParent(
    payload: INodesClaimableBySharedParentPayload,
  ) {
    this.isLoadingModal = true

    try {
      const updatedNCLSP =
        await this.appStore.companyApi.updateNodesClaimableByLocationsSharedParentInfo(
          this.appStore.authStore.currentUser.company.partnerId,
          payload,
        )
      this.handleEditNCLSPSuccess(updatedNCLSP)
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action
  public async handleEditNCLSPSuccess(nclsp: INodesClaimableBySharedParentResponse) {
    this.setNodesClaimableByLocationsWithSharedParentInfo(nclsp)
    this.removeModal()
  }

  // Previously in account manager store

  @observable
  public parentPartnerIdResponse: any

  @observable
  crmNotificationConfig: ICRMNotificationConfig

  @observable
  crmNotificationConfigError = false

  @computed
  get secondaryCloudCompanyFields() {
    return {
      [Beta]: this.appStore.authStore.currentUser.company.allDeployments.includes('Beta'),
      [OpenSync]: this.appStore.authStore.currentUser.company.allDeployments.includes('OpenSync'),
      [Dogfood]: this.appStore.authStore.currentUser.company.allDeployments.includes('Dogfood'),
      [Osync]: this.appStore.authStore.currentUser.company.allDeployments.includes('Osync'),
    }
  }

  @observable
  public progressBarValue = 0

  @observable
  public secondaryCloudProgress: SecondaryCloudProgress = {}

  @action.bound
  setSecondaryCloudProgress(secondaryCloudProgress: SecondaryCloudProgress) {
    this.secondaryCloudProgress = secondaryCloudProgress
  }

  @action.bound
  setProgressBarValue(percentageTest: number) {
    this.progressBarValue = percentageTest
  }

  @action
  submitSecondaryCloudSettings(secondaryCloud: SecondaryCloud) {
    this.isLoadingModal = true
    this.changeSecondaryCloudSettings(secondaryCloud, this.appStore.appWebSocket.id)
  }

  @action
  initSecondaryCloudManagement(secondaryCloud: SecondaryCloud) {
    this.removeAllSecondaryCloudSocketListeners()

    Object.values(SecondaryCloud).forEach((sc: SecondaryCloud) => {
      this.appStore.appWebSocket.on(`usersProgress${sc}`, (message: string) => {
        const messageArray = message.split('/')
        const progress = {
          count: Number.parseInt(messageArray[0]),
          total: Number.parseInt(messageArray[1]),
        }
        this.setSecondaryCloudProgress({ ...this.secondaryCloudProgress, [sc]: progress })
        if (progress.count === progress.total) {
          showAlert({
            title: i18next.t('accountManager.secondaryCloudSuccess', {
              secondaryCloud: sc,
            }),
            message: '',
            onAcknowledge: async () => this.clearSCMFormState(secondaryCloud),
          })
        }
      })
    })
  }

  @action
  async changeSecondaryCloudSettings(secondaryCloud: SecondaryCloud, clientId: string) {
    const payload = { isEnabled: true, secondaryCloud, clientId }

    try {
      const scSettingsResponse = await this.appStore.companyApi.updateSecondaryCloudSettings(
        this.appStore.authStore.currentUser.company.partnerId,
        payload,
      )
      await this.appStore.authStore.update()
      this.handleEditSecondaryCloudSuccess(secondaryCloud, scSettingsResponse)
    } catch (e) {
      if (e === 'secondaryCloudAlreadyConfigured') {
        this.handleSecondaryCloudAlreadyConfiguredPopup(
          i18next.t('accountManager.secondaryCloudSettingsTitle', { secondaryCloud }),
          i18next.t('errors.secondaryCloudAlreadyConfigured', { secondaryCloud }),
        )
      }
      this.handleServerError(e)
    }
  }

  @action
  handleEditSecondaryCloudSuccess(
    secondaryCloud: SecondaryCloud,
    response: ISecondaryCloudSettingsResponse,
  ) {
    if (!response.keepModalOpen) {
      this.clearSCMFormState(secondaryCloud)
    }
  }

  @action
  async handleSecondaryCloudAlreadyConfiguredPopup(title: string, message: string) {
    showAlert({
      title: title,
      message: message,
      skipI18n: true,
      onAcknowledge: () => this.removeModal(),
    })
    this.setIsLoadingModal(false)
  }

  @action
  removeAllSecondaryCloudSocketListeners() {
    Object.values(SecondaryCloud).forEach((sc: SecondaryCloud) => {
      this.appStore.appWebSocket.removeAllListeners(`usersProgress${sc}`)
    })
  }

  @action
  public clearSCMFormState(secondaryCloud: SecondaryCloud) {
    delete this.secondaryCloudProgress[secondaryCloud]
    this.removeAllSecondaryCloudSocketListeners()
    this.clearServerError()
    this.removeModal()
  }

  @action.bound
  public async getParentPartnerId() {
    try {
      const { partnerId } = this.appStore.authStore.currentUser.company
      const provisioningData = await this.appStore.companyApi.getParentPartnerId(partnerId)
      this.setParentPartnerIdResponse(provisioningData)
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action.bound
  private async setParentPartnerIdResponse(provisioningData: any) {
    this.parentPartnerIdResponse = provisioningData
  }

  @action
  public async getCRMNotificationConfig() {
    this.crmNotificationConfig = null
    try {
      const { company } = this.appStore.authStore.currentUser
      const notificationConfiguration =
        await this.appStore.companyApi.getCRMNotificationConfiguration(company.partnerId)

      if (!notificationConfiguration) {
        runInAction(() => {
          this.crmNotificationConfigError = true
        })
      }

      runInAction(() => {
        this.crmNotificationConfig = notificationConfiguration
      })
    } catch (e) {
      runInAction(() => {
        this.crmNotificationConfigError = true
      })
    }
  }

  @action
  public async submitCRMNotificationConfig(payload: ICRMNotificationConfigPayload) {
    this.isLoadingModal = true
    const { company } = this.appStore.authStore.currentUser

    try {
      await this.appStore.companyApi.updateCRMNotificationConfiguration(company.partnerId, payload)
      this.handleCRMNotificationConfigSuccess(payload)
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action handleCRMNotificationConfigSuccess(payload: ICRMNotificationConfigPayload) {
    const { company } = this.appStore.authStore.currentUser

    this.crmNotificationConfig.id = company.partnerId
    this.crmNotificationConfig.partnerFeatureFlags = payload.partnerFeatureFlags
    this.isLoadingModal = false
    this.clearServerError()
    this.removeModal()
  }

  @observable
  idpStep: number = null

  @action
  setIdpStep(step: number) {
    this.idpStep = step
  }

  @observable
  deepLink: string = null

  @action
  setDeepLink(deepLink: string) {
    this.deepLink = deepLink
  }

  @observable
  subordinatePartnerIds: string[] = null

  @action
  setSubordinatePartnerIds(partnerIds: string[]) {
    this.subordinatePartnerIds = partnerIds
  }

  @action
  async getSubordinatePartnerIds() {
    const { company } = this.appStore.authStore.currentUser
    const subordinatePartnerIds = await this.appStore.companyApi.getSubordinatePartnersOnAllClouds(
      company.partnerId,
      true,
    )
    this.setSubordinatePartnerIds(subordinatePartnerIds)
  }

  @observable
  idpSetup: Partial<IIdpInfo> = {}

  @action
  setIdpSetup(idpSetup: Partial<IIdpInfo>) {
    this.idpSetup = idpSetup
  }

  @action
  async handleIdpPopupError(errorMessage: string) {
    showAlert({
      title: 'errors.error',
      message: errorMessage,
      skipI18n: true,
    })
    await this.refreshIdpInfo()
    this.setIsLoadingModal(false)
  }

  @action
  async initIdpSettings() {
    this.cleanupIdpSettings()
    this.appStore.appWebSocket.on(idpSettingsSetTeamsSuccessEvent, (_message: string) => {
      this.handleSetIdpTeamsSuccess()
    })

    this.appStore.appWebSocket.on(idpErrorEvent, errMsg => this.handleIdpPopupError(errMsg))

    await this.getSubordinatePartnerIds()
    await this.refreshIdpInfo()
  }

  @action
  cleanupIdpSettings() {
    this.idpSetup = {}
    this.appStore.appWebSocket.removeAllListeners(idpSettingsSetTeamsSuccessEvent)
    this.appStore.appWebSocket.removeAllListeners(idpErrorEvent)
  }

  @action
  async refreshIdpInfo() {
    const { company } = this.appStore.authStore.currentUser
    const idpSetup = await this.appStore.companyApi.getIdpInfo(company.partnerId)
    this.setIdpStep(idpSetup.idpStep)
    this.setIdpSetup(idpSetup)
  }

  @action
  async createSkeletonIdp(isMultiPartnerIdP?: boolean) {
    this.setIsLoadingModal(true)
    try {
      const { company } = this.appStore.authStore.currentUser
      await this.appStore.companyApi.createSkeletonIdp(company.partnerId, isMultiPartnerIdP)
      await this.refreshIdpInfo()
      this.setIsLoadingModal(false)
    } catch (e) {
      this.handleIdpPopupError(e)
    }
  }

  @action.bound
  async getIdpMetadata() {
    const { company } = this.appStore.authStore.currentUser
    const idpMetadataXml = await this.appStore.companyApi.getIdpMetadata(company.partnerId)
    downloadFile(idpMetadataXml, 'plume-metadata.xml', 'application/xml')
  }

  @action
  async updateIdp(payload: IUpdateIdpPayload) {
    this.setIsLoadingModal(true)
    try {
      const { company } = this.appStore.authStore.currentUser
      await this.appStore.companyApi.updateIdp(company.partnerId, payload)
      await this.refreshIdpInfo()
      this.setIsLoadingModal(false)
    } catch (e) {
      this.handleIdpPopupError(e)
    }
  }

  @action
  async updateExistingMultiPartnerIdp(
    payload: IUpdateIdpPayload,
    teamNames: string[],
    oldPartnerIds: string[],
  ) {
    this.setIsLoadingModal(true)
    try {
      const { company } = this.appStore.authStore.currentUser
      await this.appStore.companyApi.updateIdp(company.partnerId, payload)
      await this.setIdpTeams(teamNames, oldPartnerIds)
    } catch (e) {
      this.handleIdpPopupError(e)
    }
  }

  /** Uses Websocket for response */
  @action
  async setIdpTeams(teamNames: string[], multiOldPartnerIds?: string[]) {
    this.setIsLoadingModal(true)
    const { company } = this.appStore.authStore.currentUser
    const payload = {
      teamNames,
      clientId: this.appStore.appWebSocket?.id,
      multiOldPartnerIds,
    }
    await this.appStore.companyApi.setIdpTeams(company.partnerId, payload)
  }

  @action
  async handleSetIdpTeamsSuccess() {
    await this.refreshIdpInfo()
    this.setIsLoadingModal(false)
  }

  @action
  async activateIdp() {
    this.setIsLoadingModal(true)
    try {
      const { company } = this.appStore.authStore.currentUser
      await this.appStore.companyApi.activateIdp(company.partnerId)
      await this.refreshIdpInfo()
      this.setIsLoadingModal(false)
    } catch (e) {
      this.handleIdpPopupError(e)
    }
  }

  @action
  async deactivateIdp() {
    this.setIsLoadingModal(true)
    try {
      const { company } = this.appStore.authStore.currentUser
      await this.appStore.companyApi.deactivateIdp(company.partnerId)
      await this.refreshIdpInfo()
      this.setIsLoadingModal(false)
    } catch (e) {
      this.handleIdpPopupError(e)
    }
  }

  @observable
  partnerConfigFeatureFlagsAllClouds: GetPartnerConfigFeatureFlagsResponse = null

  @action
  setPartnerConfigFeatureFlagsAllClouds(
    partnerConfigFeatureFlagsAllClouds: GetPartnerConfigFeatureFlagsResponse,
  ) {
    this.partnerConfigFeatureFlagsAllClouds = partnerConfigFeatureFlagsAllClouds
  }

  @observable
  partnerConfigFeatureFlagsError: string = null

  @action
  setPartnerConfigFeatureFlagsError(error: string) {
    this.partnerConfigFeatureFlagsError = error
  }

  @action
  public async getPartnerConfigFeatureFlags() {
    this.partnerConfigFeatureFlagsAllClouds = null
    const { company } = this.appStore.authStore.currentUser

    if (canAccess('staffCloudSettings', this.appStore.authStore.currentUser)) {
      try {
        const featureFlagsAllClouds = await this.appStore.companyApi.getPartnerConfigFeatureFlags(
          company.partnerId,
        )
        this.setPartnerConfigFeatureFlagsAllClouds(featureFlagsAllClouds)
      } catch (e) {
        this.setPartnerConfigFeatureFlagsError(e)
      }
    }
  }

  @action
  async submitGlobalAuthFeatureFlags(payload: GlobalAuthFeatureFlagsRequest) {
    this.isLoadingModal = true

    try {
      const cloudFeatureFlags = await this.appStore.companyApi.updatePartnerConfigFeatureFlags(
        this.appStore.authStore.currentUser.company.partnerId,
        payload,
      )
      this.handleUpdateGlobalAuthFeatureFlagsSuccess(cloudFeatureFlags, payload.cloud)
    } catch (e) {
      this.handleServerError(e)
    }
  }

  @action
  public async handleUpdateGlobalAuthFeatureFlagsSuccess(
    cloudFeatureFlags: PartnerConfigFeatureFlagsResponse,
    chosenCloud: SalesforceCloud,
  ) {
    this.setPartnerConfigFeatureFlagsAllClouds({
      ...this.partnerConfigFeatureFlagsAllClouds,
      [chosenCloud]: cloudFeatureFlags,
    })
    this.removeModal()
  }

  @observable
  partnerConfigResponses: RetrievePartnerConfigGetCallsResponse = null

  @action
  setPartnerConfigResponses(partnerConfigResponses: RetrievePartnerConfigGetCallsResponse) {
    this.partnerConfigResponses = partnerConfigResponses
  }

  @observable
  partnerConfigResponsesError: string = null

  @action
  setPartnerConfigResponsesError(error: string) {
    this.partnerConfigResponsesError = error
  }

  @action
  public async getPartnerConfigResponses() {
    if (!!this.partnerConfigResponses && !this.partnerConfigResponsesError) {
      return
    }
    this.partnerConfigResponses = null
    this.partnerConfigResponsesError = null
    const { company } = this.appStore.authStore.currentUser

    if (canAccess('staffCloudSettings', this.appStore.authStore.currentUser)) {
      try {
        const partnerConfigResponses = await this.appStore.companyApi.retrievePartnerConfigGetCalls(
          company.partnerId,
        )
        this.setPartnerConfigResponses(partnerConfigResponses)
      } catch (e) {
        this.setPartnerConfigResponsesError(e)
      }
    }
  }
}
