import * as React from 'react'

import style from './cloud-status.css'
import { useStores } from 'utils/hooks/useStores'
import { useCloudStatus } from 'hooks/useCloudStatus'
import { useTranslation } from 'react-i18next'
import { Icons, Tooltip } from 'plume-ui'
import { mixpanelActions } from 'utils/mixpanelActions'
import classNames from 'classnames'

interface CloudStatusProps {
  centerAlign?: boolean
}

interface IStatusColor {
  none: string
  minor: string
  major: string
  critical: string
}

const statusColor: IStatusColor = {
  none: '#16e2a8',
  minor: '#ffc500',
  major: '#ed1e79',
  critical: '#ed1e79',
}

const getStatusDescription = (string: string) => {
  if (!string) return

  const word = string.replace(/ /g, '')
  const firstLetter = word.substr(0, 1)

  return firstLetter.toLowerCase() + word.substr(1)
}

export const CloudStatus = (props?: CloudStatusProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const cloudEnv = appStore.authStore?.currentUser?.company?.cloudEnvironment
  const data = useCloudStatus(cloudEnv)?.data
  if (!data) {
    return null
  }

  const indicator = data?.status?.indicator
  const description = data?.status?.description
  const color = statusColor[indicator]
  const redirectUrl = data?.page?.url
  const partnerId = appStore.authStore?.currentUser?.company?.partnerId

  const handleCloudStatusClick = () => {
    mixpanelActions.track('Cloud Status Check', {
      Indicator: indicator,
      Description: description,
      Color: 'color',
      'Redirect Url': redirectUrl,
      'Partner Id': partnerId,
    })
  }

  const cloudStyle = props?.centerAlign
    ? classNames(style.cloudStatus, style.center)
    : style.cloudStatus

  return (
    <div className={cloudStyle}>
      <div className={style.cloudStatusText}>{t('header.cloudStatus')}</div>
      <Tooltip
        label={t(`statusPage.${getStatusDescription(description)}`)}
        position="below"
        alignment="right"
      >
        <a
          onClick={handleCloudStatusClick}
          href={redirectUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className={style.cloudStatusIcon}>
            <Icons.WifiIcon width="12px" color={color} />
          </div>
        </a>
      </Tooltip>
    </div>
  )
}
