import * as React from 'react'
import style from './empty-homes-or-error.css'
import { useTranslation } from 'react-i18next'

interface EmptyHomesOrErrorProps {
  serverError?: string
  mailAddress: string
  errorMessage?: string
}

export const EmptyHomesOrError = (props: EmptyHomesOrErrorProps) => {
  const { t } = useTranslation()

  const launchLink = () => {
    const mail = `mailto:${props.mailAddress}`
    const a = document.createElement('a')
    a.href = mail
    a.click()
  }
  return (
    <div className={style.root}>
      <div className={style.rootContent}>
        {props.errorMessage ? (
          <div>
            <div className={style.title}>{t('home.errorMsg')}</div>
            <div className={style.content}>
              {t('home.errorNoAccess')}{' '}
              <span className={style.partnerLink} onClick={launchLink}>
                {props.mailAddress}
              </span>
            </div>{' '}
          </div>
        ) : props.serverError ? (
          <div>
            <div className={style.title}>{t('home.errorMsg')}</div>
            <div className={style.content}>
              {t('home.errorContactUs')}{' '}
              <span className={style.partnerLink} onClick={launchLink}>
                {props.mailAddress}
              </span>
            </div>{' '}
          </div>
        ) : (
          <div>
            <div className={style.title}>{t('home.noHomesMsg')}</div>
            <div className={style.content}>
              {t('home.noHomesDesc')}{' '}
              <span className={style.partnerLink} onClick={launchLink}>
                {props.mailAddress}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
