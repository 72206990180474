import * as React from 'react'
import style from './support-contacts.css'
import { Close } from 'components/icons/index'
import { Button } from 'components/button'
import { AccountStore } from '../account-store'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import { Loader } from 'components/loader'
import { ContactPicker } from './contact-picker'

interface SupportContactsProps {
  onClose: () => Promise<void>
  accountStore: AccountStore
}

export const SupportContacts = observer((props: SupportContactsProps) => {
  const appStore = useStores()
  const { t } = useTranslation()

  const {
    isLoadingModal,
    serverError,
    firstEscalation,
    secondEscalation,
    managementEscalation,
    firstEscalationPhone,
    secondEscalationPhone,
    managementEscalationPhone,
  } = props.accountStore

  React.useEffect(() => {
    props.accountStore.initSupportContacts()
    if (!appStore.usersStore.users.length) {
      appStore.usersStore.fetchUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    props.accountStore.updateSupportContactsPhones = () => {
      firstEscalation.value &&
        appStore.usersStore.setUserPhone(firstEscalation.value, firstEscalationPhone.value)
      secondEscalation.value &&
        appStore.usersStore.setUserPhone(secondEscalation.value, secondEscalationPhone.value)
      managementEscalation.value &&
        appStore.usersStore.setUserPhone(
          managementEscalation.value,
          managementEscalationPhone.value,
        )
    }
    props.accountStore.submitSupportContacts()
  }

  const { onClose } = props
  const { usersStore } = appStore

  const isFeUserAllowed =
    !firstEscalation.hasBeenValidated ||
    !!appStore.usersStore.users.filter(u => !u.cloned).find(u => u.email === firstEscalation.value)
  const isSeUserAllowed =
    !secondEscalation.hasBeenValidated ||
    !!appStore.usersStore.users.filter(u => !u.cloned).find(u => u.email === secondEscalation.value)
  const isMeUserAllowed =
    !managementEscalation.hasBeenValidated ||
    !!appStore.usersStore.users
      .filter(u => !u.cloned)
      .find(u => u.email === managementEscalation.value)

  const areUsersValid =
    (!firstEscalation.value || isFeUserAllowed) &&
    (!secondEscalation.value || isSeUserAllowed) &&
    (!managementEscalation.value || isMeUserAllowed)

  const arePhonesValid =
    (!firstEscalationPhone.value || (!firstEscalationPhone.error && isFeUserAllowed)) &&
    (!secondEscalationPhone.value || (!secondEscalationPhone.error && isSeUserAllowed)) &&
    (!managementEscalationPhone.value || (!managementEscalationPhone.error && isMeUserAllowed))

  const contactsAreInvalid =
    (firstEscalationPhone.value && !firstEscalation.value) ||
    (secondEscalationPhone.value && !secondEscalation.value) ||
    (managementEscalationPhone.value && !managementEscalation.value)

  return (
    <div className={style.root}>
      <div className={style.subTitle}>{t('supportContacts.editSupportContacts')}</div>

      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>
      <form onSubmit={handleFormSubmit}>
        {usersStore.users.length && (
          <>
            <div className={style.listLabel}>{`${t('supportContacts.firstLevelEscalation')}`}</div>
            <ContactPicker emailSearchField={firstEscalation} phoneField={firstEscalationPhone} />
            <div className={style.listLabel}>{`${t('supportContacts.secondLevelEscalation')}`}</div>
            <ContactPicker emailSearchField={secondEscalation} phoneField={secondEscalationPhone} />
            <div className={style.listLabel}>{`${t(
              'supportContacts.managementLevelEscalation',
            )}`}</div>
            <ContactPicker
              emailSearchField={managementEscalation}
              phoneField={managementEscalationPhone}
            />
          </>
        )}
        {serverError && (
          <div className={style.rncServerError}>{t(`errors.${serverError}`, serverError)}</div>
        )}
        <div className={style.btnDiv}>
          {(isLoadingModal || !usersStore.users.length) && <Loader />}
          <Button
            className={style.sendInviteBtn}
            type="submit"
            disabled={isLoadingModal || !areUsersValid || !arePhonesValid || contactsAreInvalid}
          >
            {t('btn.save')}
          </Button>
        </div>
      </form>
    </div>
  )
})
