import { CompanyApi } from 'api/portal/company-api'
import { ICompany } from 'interfaces/api/portal/company-api'
import { action, observable, computed } from 'mobx'

export class UiStore {
  @observable
  windowWidth?: number

  @observable
  windowHeight?: number

  @observable
  testVal?: number

  @observable
  listMode?: boolean

  @observable
  exception?: any

  @observable
  public parentCompany: ICompany

  @observable
  public parentCompanyLoading = false

  constructor(private readonly companyApi?: CompanyApi) {
    this.updateDimensions(window.innerWidth, window.innerHeight)
    this.testVal = 1
    this.listMode = false
  }

  @action
  setTest(num: number) {
    this.testVal = num
  }

  @action.bound
  toggleListMode() {
    this.listMode = !this.listMode
  }

  @action.bound
  handleWindowWidthChange(e: Event) {
    e.target instanceof Window
      ? this.updateDimensions(e.target.innerWidth, e.target.innerHeight)
      : false
  }

  @action
  private updateDimensions(width: number, height: number) {
    this.windowWidth = width
    this.windowHeight = height
  }

  @action
  public setException(data: any) {
    this.exception = data
  }

  @computed
  get isMobile() {
    return this.windowWidth <= 1024
  }

  @action
  public async getParentPartnerCompany(company: ICompany) {
    try {
      if (!!company?.parentPartnerId) {
        this.setParentPartnerCompanyLoading(true)

        const companyData = await this.companyApi.getParentCompanyInfo(company.partnerId)

        this.setParentPartnerCompany(companyData)
        this.setParentPartnerCompanyLoading(false)
      }
    } catch (e) {
      // maybe add a message that backend services are experiencing an error
    }
  }

  @action.bound
  private async setParentPartnerCompanyLoading(loading: boolean) {
    this.parentCompanyLoading = loading
  }

  @action.bound
  private async setParentPartnerCompany(company: ICompany) {
    this.parentCompany = company
  }

  @action.bound
  async resetParentPartner() {
    this.parentCompany = null
    this.parentCompanyLoading = false
  }

  @observable
  menuEnabled = false

  @action.bound
  toggleMenuEnabled() {
    this.menuEnabled = !this.menuEnabled
  }
}
