import * as React from 'react'
import { Close } from 'components/icons/index'
import { useTranslation } from 'react-i18next'
import style from './node-reassignment.css'
import { Table } from 'plume-ui'
import classNames from 'classnames'
import * as Papa from 'papaparse'
import { CSVNodeColumns, NodeCSVFormat } from 'interfaces/api/portal/i.inventory-api'
import { Heading as TableHeader } from 'plume-ui/dist/components/Table/Table'

interface NodeCsvUploadPreviewProps {
  onClose: () => Promise<void>
  csvNodesString: string
}

export const NodeCsvUploadPreview = (props: NodeCsvUploadPreviewProps) => {
  const { t } = useTranslation()
  const { onClose, csvNodesString } = props

  const parsedCSV = Papa.parse<NodeCSVFormat>(csvNodesString, {
    header: true,
    delimiter: ',',
    skipEmptyLines: true,
  })

  const headers: TableHeader[] = CSVNodeColumns.map(c => ({ name: c, fieldName: c }))

  return (
    <div className={style.nodeUploadPreviewRoot}>
      <div className={style.previewCloseButton}>
        <Close onClick={onClose} />
      </div>
      <div className={style.previewSubtitle}>{t('manageInventory.previewUploadedFile')}</div>
      <Table
        classes={curr => ({
          ...curr,
          root: classNames(curr.root, style.override, style.nodeUploadPreviewTableRoot),
        })}
        stickyHeader
        truncateCellContent={false}
        noResultsMessage={t('header.noResults')}
        headerRow={headers}
        dataRows={parsedCSV.data}
      />
    </div>
  )
}
