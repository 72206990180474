import * as React from 'react'
import style from './login-layout.css'
import classNames from 'classnames'
import { Footer } from 'components/footer'
import { useTranslation } from 'react-i18next'

const Header = () => <header className={style.header} />

interface ILoginLayoutProps {
  children: React.ReactNode
  title: string
  description?: string
}

export const LoginLayout = ({ children, title, description }: ILoginLayoutProps) => {
  const { t } = useTranslation()

  return (
    <div className={style.root}>
      <Header />
      <section className={style.content}>
        <div className={style.subTitle}>{t('general.plumePartner')}</div>
        <div className={classNames(style.title, description && style.titleWithDescription)}>
          {title}
        </div>
        <div className={style.contentWrapper}>
          {description && <div className={style.description}>{description}</div>}
          {children}
        </div>
      </section>
      <Footer />
    </div>
  )
}
