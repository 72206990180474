import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button } from 'components/button'
import { useStores } from 'utils/hooks/useStores'
import style from './okta-error.css'
import { useTranslation } from 'react-i18next'

export const OktaError: React.FC<{ error: Error }> = observer(({ error }) => {
  const appStore = useStores()
  const { t } = useTranslation()

  const handleLogoutClick = () => {
    appStore.authStore.logout()
  }

  const errorHeading =
    error.name && error.message ? (
      <h2>
        {error.name}: {error.message}
      </h2>
    ) : (
      <h2>Error: {error.toString()}</h2>
    )

  return (
    <div className={style.root}>
      {errorHeading}
      <div>{t('errors.failedToLogin')}</div>
      <div className={style.separator} />
      <div className={style.buttonWrapper}>
        <Button className={style.actionBtn} onClick={handleLogoutClick}>
          {t('auth.signOut')}
        </Button>
      </div>
    </div>
  )
})
