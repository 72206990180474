import * as React from 'react'
import style from './trial-expired-or-terminated.css'
import { Button } from 'components/button'
import { withTranslation, WithTranslation } from 'react-i18next'
import { AuthStore } from 'stores/auth-store'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'
import { SignOut } from 'components/icons'
import { supportEmails } from 'constants/support-emails'
import { flattenSubordinateCompanies } from 'helpers/company-helpers'
import { salesforcePortalProducts } from 'constants/salesforce.constants'
import { AppStore } from 'stores/app-store'

interface TrialExpiredOrTerminatedProps extends WithTranslation {
  authStore?: AuthStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  authStore: appStore.authStore,
}))
@observer
class TrialExpiredOrTerminatedComponent extends React.Component<TrialExpiredOrTerminatedProps> {
  getSupportMailAddress = () => {
    const { currentUser } = this.props.authStore
    const { company } = currentUser

    const areAnyPortalProductsPurchased =
      company?.productsSummary?.purchased &&
      !!Object.keys(company?.productsSummary?.purchased).find(p =>
        salesforcePortalProducts.includes(p),
      )

    const supportEmail =
      areAnyPortalProductsPurchased || company.isChannelPartnerPortalCompany
        ? supportEmails.PARTNER_SUPPORT
        : company.ownerEmail

    return supportEmail
  }

  launchLink = () => {
    const mailAddress = this.getSupportMailAddress()
    const mail = `mailto:${mailAddress}`
    const a = document.createElement('a')
    a.href = mail
    a.click()
  }
  render() {
    const {
      t,
      authStore: {
        currentUser: { companies, hasMoreCompanies },
      },
    } = this.props

    const subordinateCompanies = flattenSubordinateCompanies(companies)
    const hasMultipleCompanies = (companies && hasMoreCompanies) || !!subordinateCompanies?.length
    const mailAddress = this.getSupportMailAddress()

    return (
      <section>
        <div className={style.modalRoot}>
          <div className={style.modalLayer}>
            <div className={style.root}>
              <div className={style.title}>{t('trialEnded.title')}</div>
              <div className={style.content}>
                {t('trialEnded.message')}{' '}
                <span className={style.partnerLink} onClick={this.launchLink}>
                  {mailAddress}
                </span>
              </div>
              <div className={style.buttonWrapper}>
                <Button
                  className={style.actionButton}
                  autoFocus
                  fullWidth
                  onClick={this.launchLink}
                >
                  {t('btn.contactUs')}
                </Button>
              </div>
              <div
                className={classNames(style.signOutButton, style.navItemWrapper)}
                onClick={this.handleLogoutClick}
              >
                <span className={style.icon}>
                  <SignOut />
                </span>
                <span className={style.navItemText}>{t('auth.signOut')}</span>
              </div>
              {hasMultipleCompanies && (
                <div
                  className={classNames(style.signOutButton, style.navItemWrapper)}
                  onClick={this.selectOtherPartnerCompany}
                >
                  <span className={style.icon}>
                    <SignOut />
                  </span>
                  <span className={style.navItemText}>{t('nav.companySelection')}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    )
  }
  handleLogoutClick = () => {
    this.props.authStore.logout()
  }
  selectOtherPartnerCompany = () => {
    this.props.authStore.selectOtherPartnerCompany()
  }
}

export const TrialExpiredOrTerminated = withTranslation()(TrialExpiredOrTerminatedComponent)
