import { useQuery } from 'react-query'
import { getCloudStatus } from 'api/statuspage'
import { SalesforceCloud } from 'constants/salesforce.constants'

export function useCloudStatus(cloud: SalesforceCloud) {
  return useQuery('cloudStatus', () => getCloudStatus(cloud), {
    retry: false,
    refetchInterval: 60000,
  })
}
