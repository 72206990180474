import { useEffect } from 'react'
import { useStores } from './useStores'

export function useUpdateCurrentUser() {
  const appStore = useStores()
  const currentUser = appStore.authStore.currentUser

  useEffect(() => {
    if (!currentUser) {
      appStore.authStore.update()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return currentUser
}
