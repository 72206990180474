import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { AuthStore } from 'stores/auth-store'
import { SignOut } from 'components/icons'
import style from './select-partner.css'
import { SelectPartnerStore } from './select-partner-store'
import { Loader } from 'components/loader'
import { ICompanyApi } from 'interfaces/api/portal/company-api'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import { parse } from 'querystring'
import { withTranslation, WithTranslation } from 'react-i18next'
import { DashboardLayout } from 'modules/dashboard/common/dashboard-layout'
import { AppStore } from 'stores/app-store'

interface SelectPartnerProps extends RouteComponentProps, WithTranslation {
  authStore?: AuthStore
  companyApi?: ICompanyApi
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  authStore: appStore.authStore,
  companyApi: appStore.companyApi,
}))
@observer
class SelectPartnerComponent extends React.Component<SelectPartnerProps> {
  selectPartnerStore: SelectPartnerStore

  constructor(props: SelectPartnerProps) {
    super(props)
    const options = parse(location.search.slice(1))

    this.props.authStore.update()
    this.selectPartnerStore = new SelectPartnerStore(
      this.props.companyApi,
      this.props.authStore,
      options.partnerId as string,
    )
  }

  render() {
    if (!this.props.authStore.currentUser || !this.props.authStore.allActiveCompanies) {
      return <Loader />
    }
    if (!this.props.authStore.currentUser.isEmployee) {
      return <Redirect to="/" />
    }

    const { isLoading } = this.selectPartnerStore

    if (isLoading) {
      return <Loader />
    }

    const { t } = this.props

    return this.props.authStore.currentUser.isEmployee ? (
      <DashboardLayout currentUser={this.props.authStore.currentUser} selectCompany>
        <div className={style.root}>
          <div className={style.companyWrapper}>
            <div className={style.title}>{t('selectPartner.accMgrMsg')}</div>
          </div>
          <div className={style.logout} onClick={this.handleLogoutClick}>
            <span className={style.logoutIcon}>
              <SignOut />
            </span>
            <span className={style.logoutText}>{t('auth.signOut')}</span>
          </div>
        </div>
      </DashboardLayout>
    ) : (
      <Redirect
        to={{
          pathname: '/missing-access',
          state: { from: this.props.location },
        }}
      />
    )
  }

  handleLogoutClick = () => {
    this.props.authStore.logout()
  }
}

export const SelectPartner = withTranslation()(SelectPartnerComponent)
