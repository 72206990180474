import * as React from 'react'
import style from '../add-user/add-edit-user.css'
import { Close } from 'components/icons/index'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import { Input } from 'components/input'

interface UserInfoProps {
  onClose: () => Promise<void>
}

export const UserInfo = observer((props: UserInfoProps) => {
  const appStore = useStores()
  const { t } = useTranslation()

  const { onClose } = props

  const { email, lastLogin, lastFailedLogin, isUserInfoModalVisible } = appStore.usersStore

  return (
    <div className={classNames(style.root, isUserInfoModalVisible && style.modalHidden)}>
      <div className={style.subTitle}>{t('users.userInfoTitle')}</div>
      <div className={style.closeButton}>
        <Close className={style.closeButtonScaling} onClick={onClose} />
      </div>

      <div className={classNames(style.formInput, style.inputDisabled)}>
        <Input
          className={style.input}
          autoFocus
          label={t('common.email')}
          value={email.value}
          disabled={true}
        />
      </div>
      <div className={classNames(style.formInput, style.inputDisabled)}>
        <Input
          className={style.input}
          autoFocus
          label={t('common.lastLogin')}
          value={lastLogin}
          disabled={true}
        />
      </div>

      <div className={classNames(style.formInput, style.inputDisabled)}>
        <Input
          className={style.input}
          autoFocus
          label={t('common.lastFailedLogin')}
          value={lastFailedLogin}
          disabled={true}
        />
      </div>
    </div>
  )
})
