import { notify } from 'plume-ui'
import { Socket } from 'socket.io-client'
import i18next from 'i18next'

export const connectSocketAsync = (socket: Socket) => {
  return new Promise(function (resolve, _reject) {
    socket.once('connect', () => {
      socket.removeAllListeners('connect')
      socket.removeAllListeners('connect_error')
      socket.removeAllListeners('connect_timeout')
      resolve(true)
    })
    socket.once('connect_error', () => {
      socket.removeAllListeners('connect')
      socket.removeAllListeners('connect_error')
      socket.removeAllListeners('connect_timeout')
      notify({
        title: i18next.t('errors.websocketConnectionFailed'),
        body: i18next.t('errors.websocketConnectionFailed'),
        type: 'error',
      })
      resolve(false)
    })
    socket.once('connect_timeout', () => {
      socket.removeAllListeners('connect')
      socket.removeAllListeners('connect_error')
      socket.removeAllListeners('connect_timeout')
      notify({
        title: i18next.t('errors.websocketConnectionFailed'),
        body: i18next.t('errors.websocketConnectionFailed'),
        type: 'error',
      })
      resolve(false)
    })
    socket.connect()
  })
}
