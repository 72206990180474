import * as React from 'react'
import style from './info-popover.css'
import { Tooltip } from 'components/tooltip'
import { InfoButton } from 'components/icons'
import classNames from 'classnames'

interface InfoPopoverProps {
  text: string
  cssVarsClass?: string
  infoButtonClass?: string
  rootClass?: string
}

export const InfoPopover = (props: InfoPopoverProps) => {
  const { text, cssVarsClass, infoButtonClass, rootClass } = props

  return (
    <Tooltip
      placement="right"
      overlay={<div className={classNames(style.tooltipInfoText, cssVarsClass)}>{text}</div>}
      align={{
        offset: [0, -5],
      }}
      trigger={['click']}
      mouseLeaveDelay={0}
    >
      <div className={classNames(style.infoTooltipContainter, cssVarsClass, rootClass)}>
        <InfoButton className={classNames(style.infoButton, infoButtonClass)} />
      </div>
    </Tooltip>
  )
}
