/**
 *
 * @param email
 */
const parseEmail = (email: string) => {
  if (!email || !email.includes('@')) {
    throw Error('Enter a valid email')
  }

  const text = email.split('@')[0]
  const replaceChars: any = { '+': '-', '.': '-', _: '-' }

  return text.replace(/[+._]/g, m => replaceChars[m])
}

export default parseEmail
