import * as React from 'react'
import style from './loader.css'
import smallStyle from './loader-small-theme.css'
import classnames from 'classnames'

interface LoaderProps {
  theme?: 'small'
  withBackground?: boolean
  fit?: boolean
  rootClassName?: string
  loaderClassName?: string
  additionalStyle?: Record<string, any>
}

export const Loader = ({
  theme,
  fit,
  rootClassName,
  loaderClassName,
  additionalStyle,
}: LoaderProps) => (
  <div
    className={classnames(
      rootClassName,
      fit ? style.fitRoot : style.wideRoot,
      !fit && style.rootWithBackground,
    )}
    style={additionalStyle}
  >
    <div
      className={classnames(loaderClassName, fit ? style.fitLoader : style.loader, {
        [smallStyle.loader]: theme === 'small',
      })}
    >
      Loading...
    </div>
  </div>
)
