import { PortalPermission } from 'constants/user/permissions'
import { containsAllMembers } from 'helpers/general-helpers'
import { User } from 'models'

type ActionPermissionType = {
  permissions: PortalPermission[]
  operator?: 'AND' | 'OR'
  employeeOnly?: boolean
  subscribersOnly?: boolean
}

const actionPermissionsConstType = {
  editUsers: { permissions: ['userManagement'] },
  viewSettings: {
    permissions: [
      'portalManagement',
      'plumeCloudManagement',
      'apiManagement',
      'applicationManagement',
      'networkManagement',
    ],
    operator: 'OR',
  },
  appCustomization: { permissions: ['plumeCloudManagement'] },
  teamManagement: { permissions: ['applicationManagement'] },
  portalManagementCard: { permissions: ['portalManagement'] },
  viewM2MTokens: { permissions: ['apiManagement'] },
  editM2MTokens: { permissions: ['apiManagement'], subscribersOnly: true },
  idpSettings: { permissions: ['networkManagement'] },
  settingsApplicationsCard: { permissions: ['applicationManagement'], employeeOnly: true },
  crmNotification: { permissions: ['portalManagement'], employeeOnly: true },
  staffCloudSettings: { permissions: ['plumeCloudManagement'], employeeOnly: true },
  viewApplications: { permissions: ['basicAccess'] },
  viewHome: { permissions: ['basicAccess'] },
  viewInventory: { permissions: ['plumeCloudManagement'] },
  viewManageTrials: { permissions: ['portalManagement'], subscribersOnly: true },
} satisfies Record<string, ActionPermissionType>

type PortalAction = keyof typeof actionPermissionsConstType
export const actionPermissions: Record<PortalAction, ActionPermissionType> =
  actionPermissionsConstType

export const canAccess = (action: PortalAction, user: User) => {
  const { portalPermissions: permissions, isEmployee } = user

  if (actionPermissions[action]?.employeeOnly && !isEmployee) {
    return false
  }
  if (actionPermissions[action]?.subscribersOnly && isEmployee) {
    return false
  }

  return actionPermissions[action]?.operator === 'OR'
    ? !!actionPermissions[action].permissions.find(ap => permissions.includes(ap))
    : containsAllMembers(permissions, actionPermissions[action].permissions)
}
