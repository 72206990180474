import * as React from 'react'
import classNames from 'classnames'
import style from './configuration-card.css'
import { Card, Button, Tooltip } from 'plume-ui'
import { Loader } from 'components/loader'
import i18next from 'i18next'
import { PersonPlume } from 'components/icons'

export const renderActionButton = (condition: boolean, content: string, handler: () => void) => {
  return (
    <>
      {condition && (
        <Button
          classes={curr => ({ ...curr, root: classNames(curr.root, style.cardButtonWidth) })}
          styleVariant="superprimary"
          onClick={() => handler()}
        >
          {i18next.t(content)}
        </Button>
      )}
    </>
  )
}

type ConfigurationCardProps = {
  title: React.ReactNode
  actionButton?: React.ReactNode
  children: React.ReactNode
  isLoading?: boolean
  accountManagerOnly?: boolean
}

export const ConfigurationCard = ({
  title,
  actionButton,
  children,
  isLoading,
  accountManagerOnly = false,
}: ConfigurationCardProps) => (
  <Card
    classes={curr => ({
      ...curr,
      root: classNames(curr.root, isLoading && style.CardLoadingHeight),
      content: classNames(
        curr,
        isLoading ? style.ConfigurationCardContentLoading : style.ConfigurationCardContent,
      ),
    })}
    header={
      <div className={style.ConfigurationCardHeader}>
        {accountManagerOnly && (
          <Tooltip alignment="left" openInPortal label={i18next.t('account.accountManagerOnly')}>
            <PersonPlume className={style.personPlumeIcon} />
          </Tooltip>
        )}

        <div className={style.showNewlines}>{title}</div>
        {actionButton ? (
          <div className={style.ConfigurationCardActionsContainer}>
            <div className={style.ConfigurationCardAction}>{actionButton}</div>
          </div>
        ) : undefined}
      </div>
    }
  >
    {isLoading ? <Loader fit additionalStyle={{ borderRadius: 8 }} /> : children}
  </Card>
)
