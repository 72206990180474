import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { observer } from 'mobx-react'
import style from './modal.css'
import classNames from 'classnames'
import { ModalLayerStore } from 'stores/modal-layer-store'

let modalRoot: HTMLElement

@observer
export class ModalLayer extends React.Component<{
  modalStore: ModalLayerStore
}> {
  private readonly el: HTMLElement

  state = {
    isMounted: false,
  }

  constructor(props: { modalStore: ModalLayerStore }) {
    super(props)
    if (!modalRoot) {
      modalRoot = document.createElement('div')
      modalRoot.className = style.modalRoot
      document.body.appendChild(modalRoot)
    }
    this.el = document.createElement('div')
    this.el.className = style.modalLayer
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el)
    this.setState({ isMounted: true })
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el)
  }

  render() {
    const { isMounted } = this.state
    if (!isMounted) return null
    const modals = this.props.modalStore.modals
    this.el.className = classNames(style.modalLayer, modals.length && style.modalLayerWithChildren)

    return ReactDOM.createPortal(<React.Fragment>{modals}</React.Fragment>, this.el)
  }
}
