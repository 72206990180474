import * as React from 'react'

import style from './error-screen.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { APPLICATIONS_ROUTE, HOME_ROUTE } from 'constants/routes'

export const ErrorScreen = () => {
  const { t } = useTranslation()

  return (
    <div className={style.errorScreen}>
      <h1>{t('errorScreen.title')}</h1>
      <h2>{t('errorScreen.description')}</h2>
      <Link to={APPLICATIONS_ROUTE} className={style.primaryBtn}>
        {t('btn.goToApplications')}
      </Link>
      <Link to={HOME_ROUTE} className={style.secondaryBtn}>
        {t('btn.goToHome')}
      </Link>
    </div>
  )
}
