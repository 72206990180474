import { accountSetupStatus } from 'constants/account-setup/account-state'
import { salesforcePrimaryClouds } from 'constants/salesforce.constants'
import { applications, isCorrectAppEnvironment } from 'constants/user/applications'
import { ICompany, SalesforceProductStatus } from 'interfaces/api/portal/company-api'
import moment from 'moment'
import { isDateAfterNow } from './general-helpers'

const isTrialStarted = (company: ICompany) => {
  return (
    company &&
    company.portalAccountState === accountSetupStatus.TRIAL_STARTED &&
    !!company.startedTrialTimestamp
  )
}

const didTrialExpire = (company: ICompany) => {
  return (
    company &&
    isTrialStarted(company) &&
    moment(company.endTrialTimestamp).isBefore(moment()) &&
    company.enforceTrialEndDate
  )
}

const isTerminatedState = (company: ICompany) => {
  return (
    company?.portalAccountState === 'Terminated' ||
    company?.portalAccountState === 'TERMINATED - TRIAL'
  )
}

export const isTerminated = (company: ICompany) => {
  return didTrialExpire(company) || isTerminatedState(company)
}

const isInTrial = (company: ICompany) => {
  return isTrialStarted(company) && !didTrialExpire(company)
}

const isContractSigned = (company: ICompany) => {
  return company && company.portalAccountState === accountSetupStatus.PARTNERSHIP_CONTRACT_SIGNED
}

const isInDemo = (company: ICompany) => {
  return company?.portalAccountState === accountSetupStatus.DEMO_AND_PRICING
}

export const isActive = (company: ICompany) => {
  return (
    company?.portalAccountState === 'Active' ||
    isInDemo(company) ||
    isContractSigned(company) ||
    isInTrial(company)
  )
}

export const flattenCompanies = (companies: ICompany[]) => {
  if (!companies) return
  if (Array.isArray(companies) && companies.length <= 0) return []

  const flat: ICompany[] = []

  companies.forEach((company: ICompany) => {
    flat.push({
      ...company,
      isParent: true,
    })

    if (company.subordinateCompanies?.length > 0) {
      company.subordinateCompanies.forEach(subordinateCompany => {
        const flatIds = flat.map(item => item.partnerId)

        if (!flatIds.includes(subordinateCompany.partnerId)) {
          flat.push({
            ...subordinateCompany,
            isSubordinatePartner: true,
          })
        }
      })
    }
  })

  const parentIds = flat.filter(item => item.isParent).map(company => company.partnerId)
  const filtered = flat.filter(company =>
    company.isSubordinatePartner ? !parentIds.includes(company.partnerId) : company,
  )

  return filtered
}

export const flattenSubordinateCompanies = (companies: ICompany[]) => {
  if (!companies) return
  if (Array.isArray(companies) && companies.length <= 0) return []

  const flatSubordinateCompanies: ICompany[] = []

  companies.forEach((company: ICompany) => {
    if (company.subordinateCompanies?.length > 0) {
      company.subordinateCompanies.forEach(subordinateCompany => {
        flatSubordinateCompanies.push(subordinateCompany)
      })
    }
  })

  return flatSubordinateCompanies
}

export const findParentCompany = (companies: ICompany[], company: ICompany) => {
  if (!companies || !company) return

  let parentCompany: ICompany

  companies.forEach(parentCompanyItem => {
    if (parentCompanyItem.partnerId === company.partnerId) {
      parentCompany = parentCompanyItem
    }

    if (parentCompanyItem.subordinateCompanies?.length > 0) {
      parentCompanyItem.subordinateCompanies.forEach(subordinateCompany => {
        if (subordinateCompany.partnerId === company.partnerId) {
          parentCompany = parentCompanyItem
        }
      })
    }
  })

  return parentCompany
}

export const getPortalOrCompanyName = (company: ICompany) => {
  return company?.portalNickname || company?.name || 'Company name'
}

export const isProductActive = (productStatus: SalesforceProductStatus) => {
  return productStatus?.isPurchased || isDateAfterNow(productStatus?.trialEndDate)
}

export const getActiveProductsArray = (company: ICompany) =>
  Object.keys(company.productsInfo).reduce(
    (activeArr, product) =>
      isProductActive(company.productsInfo[product]) ? [...activeArr, product] : activeArr,
    [] as string[],
  )

export const getCompanyAllowedApplications = (company: ICompany) =>
  applications.filter(a => {
    const isCorrectCloud = () =>
      salesforcePrimaryClouds.includes(a.cloud)
        ? company.cloudEnvironment === a.cloud
        : company.allDeployments.includes(a.cloud)
    return (
      (!a.sfProductName ||
        isProductActive(company.productsInfo[a.sfProductName]) ||
        company.applicationsAccessRights.includes(a.sfToolName)) &&
      isCorrectAppEnvironment(a.environment) &&
      (!a.cloud || isCorrectCloud())
    )
  })

export const getFormattedApplications = (company: ICompany): string => {
  const allowedApplications = getCompanyAllowedApplications(company)
  return allowedApplications?.length ? allowedApplications.map(a => a.name).join('\n') : ''
}

export const howManyDaysLeft = (date: string) => {
  const today = moment().startOf('day').format()
  const durationLeft = moment(date).diff(today, 'days')
  return date ? durationLeft : Infinity
}

export const getFormattedProfiles = (productsInfo: ICompany['productsInfo']) => {
  const formattedProfiles: string[] = []
  if (isProductActive(productsInfo['HomePass'])) {
    formattedProfiles.push('HomePass')
  }
  if (isProductActive(productsInfo['WorkPass'])) {
    formattedProfiles.push('WorkPass')
  }

  return formattedProfiles.join(', ')
}
