import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import i18nextHttpBackend from 'i18next-http-backend/esm'
import axios from 'axios'
import { getNavigatorLanguage } from 'utils/locale/portal-locale'
import merge from 'lodash.merge'

interface RequestResponse {
  status: number
  data: string
}

type RequestCallback = (error: any, response: RequestResponse) => void

i18next
  .use(i18nextHttpBackend)
  .use(initReactI18next)
  .init({
    lng: navigator.language,
    fallbackLng: 'en',
    preload: ['en'],
    // specify supported lngs for fallback
    supportedLngs: ['en', 'ja', 'fr', 'de', 'pt', 'es', 'it', 'vi'],
    backend: {
      // custom request
      request: async (_options: any, _url: string, _payload: any, callback: RequestCallback) => {
        const language = getNavigatorLanguage()
        const defaultLanguagePath = `/assets/i18n/en.json`
        const awsTranslationsBaseUrl = process.env.AWS_TRANSLATIONS_BASE_URL

        if (!!language.match(/(en|en-*)/)) {
          const res = await axios.get(defaultLanguagePath)

          callback(null, {
            status: res.status,
            data: JSON.stringify(res.data.translation),
          })
        } else {
          // try getting non-en translation from AWS
          try {
            const fallbackLng = await axios.get(defaultLanguagePath)
            const res = await axios.get(`${awsTranslationsBaseUrl}/${language}.json`)
            const translations = merge(fallbackLng.data.translation, res.data.translation)

            callback(null, {
              status: res.status,
              data: JSON.stringify(translations),
            })
          } catch (e) {
            // if nonthing matches or AWS failed, cb default lng
            if (e) {
              const res = await axios.get(defaultLanguagePath)

              callback(null, {
                status: res.status,
                data: JSON.stringify(res.data.translation),
              })
            }
          }
        }
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  })

export default i18next
