import React, { Component } from 'react'
import { Button } from 'components/button'
import { DashboardLayout } from 'modules/dashboard/common/dashboard-layout'
import { inject, observer } from 'mobx-react'
import { UiStore } from 'stores/ui-store'
import { AuthStore } from 'stores/auth-store'
import { History } from 'history'
import { parse } from 'query-string'
import { AppStore } from 'stores/app-store'

import style from './exception.css'

interface Exception500Props {
  uiStore?: UiStore
  authStore?: AuthStore
  location?: any
  history?: History
  statusCode?: number
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  uiStore: appStore.uiStore,
  authStore: appStore.authStore,
}))
@observer
export class Exception500 extends Component<Exception500Props> {
  handleLogoutClick = () => {
    this.props.authStore.logout()
  }

  render() {
    const { authStore, uiStore } = this.props

    const options = parse(location.search) as { statusCode?: string }
    const queryStatusCode = Number(options?.statusCode)

    const statusCode = this.props.statusCode || queryStatusCode || 500

    const companyName =
      authStore.currentUser && authStore.currentUser.company && authStore.currentUser.company.name
    const userEmail = authStore.currentUser && authStore.currentUser.email
    const requestEndpoint = uiStore.exception && uiStore.exception.endpoint
    const message = uiStore.exception && uiStore.exception.message
    const hasError = companyName || userEmail || requestEndpoint || message

    return (
      <DashboardLayout errorMode>
        <div className={style.root}>
          <div className={style.exceptionContent}>
            <div className={style.title}>{statusCode ? `${statusCode} ERROR` : <>&nbsp;</>}</div>
            <div className={style.text}>
              {statusCode === 503 && message === 'Server maintenance'
                ? 'Server Maintenance'
                : 'Oops! Something Went Wrong.'}
            </div>
            <div className={style.separator} />
            <div className={style.buttonWrapper}>
              <Button className={style.actionBtn} onClick={() => (window.location.href = '/')}>
                Return Home
              </Button>
            </div>
            <div className={style.buttonWrapper}>
              <Button className={style.actionBtn} onClick={this.handleLogoutClick}>
                Sign Out
              </Button>
            </div>
          </div>
          <div className={style.listWrapper}>
            {hasError && (
              <ul className={style.list}>
                {companyName && (
                  <li>
                    <span className={style.errorKey}>Company name:</span>
                    <span className={style.errorValue}>{companyName}</span>
                  </li>
                )}
                {userEmail && (
                  <li>
                    <span className={style.errorKey}>User:</span>
                    <span className={style.errorValue}>{userEmail}</span>
                  </li>
                )}
                {requestEndpoint && (
                  <li>
                    <span className={style.errorKey}>Request endpoint:</span>
                    <span className={style.errorValue}>{requestEndpoint}</span>
                  </li>
                )}
                {message && (
                  <li>
                    <span className={style.errorKey}>Error message:</span>
                    <span className={style.errorValue}>{message}</span>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </DashboardLayout>
    )
  }
}
