import classNames from 'classnames'
import * as React from 'react'
import defaultStyle from './checkbox-default-theme.css'
import defaultLargeStyle from './checkbox-default-theme-large.css'
import { useTranslation } from 'react-i18next'

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  theme?: 'default' | 'large' | 'partner'
  error?: string | boolean
  borderColor?: string
  backgroundColor?: string
  checkmarkColor?: string
}

export const Checkbox = (props: CheckboxProps) => {
  const { t } = useTranslation()
  const {
    className,
    error,
    borderColor: _borderColor,
    checkmarkColor: _checkmarkColor,
    backgroundColor: _backgroundColor,
    ...otherProps
  } = props
  const checkboxBackground = _borderColor || 'var(--primaryBlue)'
  const checkmarkColor = _checkmarkColor || 'var(--white)'
  const backgroundColor = _backgroundColor || 'var(--backgroundGray)'
  const isDefaultTheme = props.theme !== 'large'

  const rootClassName = classNames({
    [defaultStyle.root]: isDefaultTheme,
    [defaultLargeStyle.root]: !isDefaultTheme,
  })
  const computedClassName = classNames({
    [defaultStyle.root]: isDefaultTheme,
    [defaultLargeStyle.root]: !isDefaultTheme,
  })

  const checkmarkClassName = classNames({
    [defaultStyle.checkmark]: isDefaultTheme,
    [defaultLargeStyle.checkmark]: !isDefaultTheme,
  })

  const errorClassName = classNames({
    [defaultStyle.error]: isDefaultTheme,
    [defaultLargeStyle.error]: !isDefaultTheme,
  })

  const colorVars: any = {
    '--checkboxBackground': checkboxBackground,
    '--checkmarkColor': checkmarkColor,
    '--checkmarkBackgroundColor': backgroundColor,
  }

  return (
    <div className={className} style={colorVars}>
      <label className={rootClassName}>
        <input type="checkbox" className={computedClassName} {...otherProps} />
        <span className={checkmarkClassName} />
      </label>
      {typeof error === 'string' && error !== '' && (
        <div className={errorClassName}>{t([`errors.${error}`, 'errors.somethingWentWrong'])}</div>
      )}
    </div>
  )
}
