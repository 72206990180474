import * as React from 'react'
import style from './export.css'
import { Button } from 'components/button'
import { useTranslation } from 'react-i18next'

interface ExportProps {
  onClick?: () => void
}

export const Export = (props: ExportProps) => {
  const { t } = useTranslation()

  return (
    <div className={style.root}>
      <Button onClick={props.onClick} className={style.exportBtn}>
        {t('users.exportUsersToCSV')}
      </Button>
    </div>
  )
}
