import classNames from 'classnames'
import { BarChart } from 'components/bar-chart'
import { House, Mobile, Pod } from 'components/icons'
import { LineChart } from 'components/line-chart'
import { Paper } from 'components/paper'
import { salesforcePortalProducts } from 'constants/salesforce.constants'
import { supportEmails } from 'constants/support-emails'
import { isTerminated } from 'helpers/company-helpers'
import { observe } from 'mobx'
import { observer } from 'mobx-react-lite'
import { CollapseCard, Divider, Heading, Status } from 'plume-ui'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import { EmptyHomesOrError } from './empty-homes-or-error/empty-homes-or-error'
import style from './home-data.css'
import { InitialOpeningHomes } from './initial-opening-homes/initial-opening-homes'

export const HomeData = observer(() => {
  const { t } = useTranslation()
  const appStore = useStores()
  const { authStore } = appStore
  const { currentUser } = authStore

  const getSupportMailAddress = () => {
    const { company } = currentUser

    const areAnyPortalProductsPurchased =
      company?.productsSummary?.purchased &&
      !!Object.keys(company?.productsSummary?.purchased).find(p =>
        salesforcePortalProducts.includes(p),
      )

    const supportEmail =
      areAnyPortalProductsPurchased || company.isChannelPartnerPortalCompany
        ? supportEmails.PARTNER_SUPPORT
        : company.ownerEmail

    return supportEmail
  }

  const toggle = (name: 'locations' | 'nodes' | 'devices') =>
    appStore.homeStore.toggleCollapse(name)

  const formatNumber = (value: number) =>
    value < 1000000
      ? value < 10000
        ? value.toLocaleString('en')
        : `${(value / 1000).toFixed(1)}K`
      : `${(value / 1000000).toFixed(1)}M`

  const firstBarChartRef = React.useRef<BarChart>()
  const secondBarChartRef = React.useRef<BarChart>()
  const thirdBarChartRef = React.useRef<BarChart>()
  const firstLineChartRef = React.useRef<LineChart>()
  const secondLineChartRef = React.useRef<LineChart>()
  const thirdLineChartRef = React.useRef<LineChart>()

  const updateChildDimensions = () => {
    firstBarChartRef.current && firstBarChartRef.current.updateDimensions()
    secondBarChartRef.current && secondBarChartRef.current.updateDimensions()
    thirdBarChartRef.current && thirdBarChartRef.current.updateDimensions()
    firstLineChartRef.current && firstLineChartRef.current.updateDimensions()
    secondLineChartRef.current && secondLineChartRef.current.updateDimensions()
    thirdLineChartRef.current && thirdLineChartRef.current.updateDimensions()
  }

  useEffect(() => {
    const observeWidth = observe(appStore.uiStore, 'windowWidth', () => {
      updateChildDimensions()
    })
    const observeHeight = observe(appStore.uiStore, 'windowHeight', () => {
      updateChildDimensions()
    })

    return () => {
      observeWidth()
      observeHeight()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data,
    currentMonthActiveHouseholds,
    currentMonthActiveNodes,
    currentMonthActiveDevices,
    initialOpen,
    handleInitialOpen,
    serverError,
  } = appStore.homeStore

  const noAccess = serverError === 'GraphQL error: You do not have access to this partner.'
  const mailAddress = getSupportMailAddress()
  const { isMobile } = appStore.uiStore

  const isTrialExpiredOrTerminated = !currentUser?.isEmployee && isTerminated(currentUser?.company)
  const noHomeData = data.emptyHomes

  const renderHomeData = () => {
    return (
      <div className={style.dataContainer}>
        <div className={style.col}>
          <Paper
            title={t('home.locations')}
            name="locations"
            icon={<House />}
            toggle={toggle}
            isMobile={isMobile}
            isExpanded={data.locations.isExpanded}
          >
            <div className={style.paperContentWrapper}>
              <div className={style.stats}>
                <span className={style.icon}>
                  <Status
                    color="ok"
                    label={t('home.online')}
                    classes={styles => {
                      return {
                        ...styles,
                        label: classNames(styles.label, style.override, style.labelGraphOnline),
                      }
                    }}
                  />
                </span>
                <span className={classNames(style.block, style.value)}>
                  {formatNumber(data.locations.currentOnline)}
                </span>
              </div>
              <div className={style.stats}>
                <span className={style.icon}>
                  <Status
                    color="#999eff"
                    label={t('home.offline')}
                    classes={styles => {
                      return {
                        ...styles,
                        label: classNames(styles.label, style.override, style.labelGraphOffline),
                      }
                    }}
                  />
                </span>
                <span className={classNames(style.block, style.value)}>
                  {formatNumber(data.locations.currentOffline)}
                </span>
              </div>
            </div>
            <div className={style.graphWrapper}>
              <BarChart
                ref={firstBarChartRef}
                label={t('common.months.thisMonth')}
                data={{
                  online: currentMonthActiveHouseholds,
                  offline: [],
                }}
                formatNumber={formatNumber}
                t={t}
              />
            </div>
            {!data.monthly.locations.hidden && (
              <div className={style.graphWrapper}>
                <LineChart
                  ref={firstLineChartRef}
                  data={data.monthly.locations}
                  formatNumber={formatNumber}
                  t={t}
                />
              </div>
            )}
          </Paper>
        </div>
        <Divider orientation={'vertical'} isFlex />
        <div className={style.col}>
          <Paper
            title={t('home.nodes')}
            name="nodes"
            icon={<Pod />}
            toggle={toggle}
            isMobile={isMobile}
            isExpanded={data.nodes.isExpanded}
          >
            <div className={style.paperContentWrapper}>
              <div className={style.stats}>
                <span className={style.icon}>
                  <Status
                    color="ok"
                    label={t('home.online')}
                    classes={styles => {
                      return {
                        ...styles,
                        label: classNames(styles.label, style.override, style.labelGraphOnline),
                      }
                    }}
                  />
                </span>
                <span className={classNames(style.block, style.value)}>
                  {formatNumber(data.nodes.currentOnline)}
                </span>
              </div>
              <div className={style.stats}>
                <span className={style.icon}>
                  <Status
                    color="#999eff"
                    label={t('home.offline')}
                    classes={styles => {
                      return {
                        ...styles,
                        label: classNames(styles.label, style.override, style.labelGraphOffline),
                      }
                    }}
                  />
                </span>
                <span className={classNames(style.block, style.value)}>
                  {formatNumber(data.nodes.currentOffline)}
                </span>
              </div>
            </div>
            <div className={style.graphWrapper}>
              <BarChart
                ref={secondBarChartRef}
                label={t('common.months.thisMonth')}
                data={{ online: currentMonthActiveNodes, offline: [] }}
                formatNumber={formatNumber}
                t={t}
              />
            </div>
            {!data.monthly.nodes.hidden && (
              <div className={style.graphWrapper}>
                <LineChart
                  ref={secondLineChartRef}
                  data={data.monthly.nodes}
                  formatNumber={formatNumber}
                  t={t}
                />
              </div>
            )}
          </Paper>
        </div>
        <Divider orientation={'vertical'} isFlex />
        <div className={style.col}>
          <Paper
            title={t('home.devices')}
            name="devices"
            icon={<Mobile />}
            toggle={toggle}
            isMobile={isMobile}
            isExpanded={data.devices.isExpanded}
          >
            <div className={style.paperContentWrapper}>
              <div className={style.stats}>
                <span className={style.icon}>
                  <Status
                    color="ok"
                    label={t('home.online')}
                    classes={styles => {
                      return {
                        ...styles,
                        label: classNames(styles.label, style.override, style.labelGraphOnline),
                      }
                    }}
                  />
                </span>
                <span className={classNames(style.block, style.value)}>
                  {formatNumber(data.devices.currentOnline)}
                </span>
              </div>
              {/* {data.devices.currentOffline && (
                <div className={style.stats}>
                  <span className={style.icon}>
                    <Status
                      color="#999eff"
                      label={t('home.offline')}
                      classes={styles => {
                        return {
                          ...styles,
                          label: classNames(styles.label, style.override, style.labelGraphOffline),
                        }
                      }}
                    />
                  </span>
                  <span className={classNames(style.block, style.value)}>
                    {formatNumber(data.devices.currentOffline)}
                  </span>
                </div>
              )} */}
            </div>
            <div className={style.graphWrapper}>
              <BarChart
                ref={thirdBarChartRef}
                label={t('common.months.thisMonth')}
                data={{
                  online: currentMonthActiveDevices,
                  offline: [],
                }}
                formatNumber={formatNumber}
                t={t}
              />
            </div>
            {!data.monthly.devices.hidden && (
              <div className={style.graphWrapper}>
                <LineChart
                  ref={thirdLineChartRef}
                  data={data.monthly.devices}
                  formatNumber={formatNumber}
                  t={t}
                />
              </div>
            )}
          </Paper>
        </div>
      </div>
    )
  }

  const getActions = () => {
    if (noHomeData) {
      return (
        <Status
          color="warning"
          label="Incomplete"
          classes={styles => {
            return {
              ...styles,
              label: classNames(styles.label, style.override, style.labelIncomplete),
            }
          }}
        />
      )
    }
  }

  return (
    <CollapseCard
      title={<Heading size="xl">{t('home.networkTitle')}</Heading>}
      actions={[getActions()]}
    >
      <div className={style.container}>
        <div>
          {!isTrialExpiredOrTerminated && !initialOpen && (
            <InitialOpeningHomes onClose={handleInitialOpen} />
          )}

          {!isTrialExpiredOrTerminated && noAccess ? (
            <EmptyHomesOrError
              errorMessage={t('home.errorNoAccess')}
              mailAddress={supportEmails.PARTNER_SUPPORT}
            />
          ) : !isTrialExpiredOrTerminated && data.emptyHomes ? (
            <EmptyHomesOrError serverError={serverError} mailAddress={mailAddress} />
          ) : (
            renderHomeData()
          )}
        </div>
        <div className={style.colSpan2}>
          <p className={style.disclaimer}>{t('home.disclaimer')}</p>
        </div>
      </div>
    </CollapseCard>
  )
})
