export const getNavigatorLanguage = () => {
  let language = navigator.language

  if (language.includes('-')) {
    language = language.split('-')[0]
  }

  if (language.includes('_')) {
    language = language.split('_')[0]
  }

  return language
}

export const getUserLanguage = () => {
  const language = getNavigatorLanguage()

  switch (language) {
    case 'en':
      return 'en_US'
    case 'ja':
      return 'ja_JP'
    case 'fr':
      return 'fr_FR'
    case 'de':
      return 'de_DE'
    case 'pt':
      return 'pt_PT'
    case 'es':
      return 'es_ES'
    case 'it':
      return 'it_IT'
    case 'vi':
      return 'vi_VN'
    default:
      return 'en_US'
  }
}
