import * as React from 'react'
import style from './crm-notification.css'
import { Close } from 'components/icons/index'
import { Button } from 'components/button'
import { Loader } from 'components/loader'
import { observer } from 'mobx-react-lite'
import { useStores } from 'utils/hooks/useStores'
import { useTranslation } from 'react-i18next'
import { YesNoRadioButton } from 'components/radio-button/yesno-radio-button/yesno-radio-button'
import { ICRMNotificationConfigPayload } from 'interfaces/api/portal/company-api'

interface CRMNotificationProps {
  onClose: () => Promise<void>
}

export const CRMNotification = observer((props: CRMNotificationProps) => {
  const { onClose } = props
  const appStore = useStores()
  const { t } = useTranslation()

  const accountStore = appStore.accountStore
  const { serverError, isLoadingModal, crmNotificationConfig } = accountStore
  const [braze, setBraze] = React.useState(
    crmNotificationConfig.partnerFeatureFlags.brazeUpdateCustomAttributes,
  )
  const [qualtrics, setQualtrics] = React.useState(
    crmNotificationConfig.partnerFeatureFlags.qualtricsUpdateCustomAttributes,
  )

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const payload: ICRMNotificationConfigPayload = {
      partnerFeatureFlags: {
        brazeUpdateCustomAttributes: braze,
        qualtricsUpdateCustomAttributes: qualtrics,
      },
    }

    accountStore.submitCRMNotificationConfig(payload)
  }

  return (
    <div className={style.root}>
      <div className={style.subTitle}>{t('accountManager.crmNotificationConfig')}</div>
      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>
      <form onSubmit={handleSubmit}>
        <div className={style.label}>{t('accountManager.crmNotificationDescription')}</div>
        <div className={style.label}>{t('accountManager.crmNotificationBraze')}</div>
        <YesNoRadioButton
          id={'braze'}
          name={t('accountManager.crmNotificationBraze')}
          checkedValue={braze}
          handleOnFieldChange={() => setBraze(!braze)}
        />
        <div className={style.label}>{t('accountManager.crmNotificationQualtrics')}</div>
        <YesNoRadioButton
          id={'qualtrics'}
          name={t('accountManager.crmNotificationQualtrics')}
          checkedValue={qualtrics}
          handleOnFieldChange={() => setQualtrics(!qualtrics)}
        />
        {serverError && <div className={style.serverError}>{serverError}</div>}
        <div className={style.btnDiv}>
          {isLoadingModal && <Loader />}
          <Button className={style.updateNotificationBtn}>{t('btn.save')}</Button>
        </div>
      </form>
    </div>
  )
})

export default CRMNotification
